import React, {useState} from 'react';
import styled from 'styled-components';
import { demoimg } from '../../data/demoimg';
import { GrClose } from 'react-icons/gr';

const Section = styled.section`
    position: relative;
    width: 100vw;
    height: 60vh;
    /* border: 1px solid red; */
    display: grid;
    grid-template-rows: 3fr 3fr;
    grid-template-columns: 1.5fr 3fr 1.5fr;
    gap: 7px;
    @media screen and (max-width: 1024px) {  
        grid-template-columns: 3fr 3fr;
        height: 70vh;
    }
    @media screen and (max-width: 668px) {  
        grid-template-rows: 6fr;
        grid-template-columns: 6fr;
        gap: 0px;
    }
`;

const ImgBox = styled.div`
    position: relative;
    width: 100%;
    height: 100%;    
    /* border: 3px solid #D4AF37; */

    &:nth-child(3) {
        grid-column: 2;
        grid-row: 1 / span 2;
    }
    
    @media screen and (max-width: 1024px) {  
        &:nth-child(3) {
            grid-column: 2;
            grid-row: 1 ;
        }
    }

`;

const GalBtn =styled.div`
    position: absolute;
    bottom: 20px;
    left: 10px;
    width: 60px;
    height: 60px;
    /* background-color: red; */
    display: grid;
    place-content: center;
    border: 3px solid #D4AF37;
    color: #D4AF37;
    border-radius: 50%;
    cursor: pointer;
    /* background-color: #D4AF37; */
    background-color: rgba(0,0,0,0.7);
    font-size: 12px;
    font-weight: bold;

    &:hover {
        
    }

`;

const ImgData = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const GallSection = styled.section`
    position: fixed;
    top: 0;
    left: 0;
    background-color: lightgray;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    display: ${({displayGall}) => (displayGall ? '' : 'none')};
`;

const GallContainer = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: 100px auto;
`;
const GallHeading = styled.div`
    width: 80%;
    display: grid;
    justify-self: center;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    font-family: 'buda';
    border-bottom: 3px solid #D4AF37;
`;
const GallBoxs = styled.div`
    margin-top: 40px;
    height: calc(100vh - 140px);
    overflow-y: hidden;
    overflow-y: scroll;
    scroll-behavior: smooth;

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: lightgray;
    }

    &::-webkit-scrollbar
    {
        width: 12px;
        height: 10px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        background-color: #D4AF37;
    }

`;
const GallBoxsC = styled.div`
    width: 100%;

    display: grid;
    grid-template-columns: repeat(3, 2fr);
    padding:0 40px 40px;
    gap: 20px;
    @media screen and (max-width: 768px) {  
        grid-template-columns: repeat(2, 2fr);
        padding: 30px;
        gap: 20px;
    }
    @media screen and (max-width: 668px) {  
        grid-template-columns: repeat(1, 2fr);
        padding: 30px;
        gap: 20px;
    }
`;

const ImgBox1 =styled.div`
    position: relative;
    width: 100%;
    height: 400px;
    border: 5px solid #fff;

    @media screen and (max-width: 1404px) {  
        height: 300px;
    }
`;

const GallClose = styled.div`
    position: absolute;
    right: 20px;
    top: 20px;

    svg {
        width: 23px;
        height: 23px;
        cursor: pointer;
        transition: .3s;

        &:hover {
            transform: scale(1.1);
            transition: .3s;
        }
    }
`;

const ImageHandler = ({imgData}) => {
    const [gallDis, setGallDis] = useState(false);

    const disGall = () => {   
        if(gallDis == true) {
            setGallDis(false);
        }else {
            setGallDis(true);
        }
    }

    return (
        <>
            <Section>
                {imgData.map((item, index) => index <= 5 ? (
                    <ImgBox><ImgData src = {item.in_img} /></ImgBox>  
                ) : (
                    null
                ))}
                <GalBtn onClick={() => disGall()}>Gallery</GalBtn>
            </Section>
            <GallSection displayGall = {gallDis}>                
                <GallContainer>
                    <GallHeading>Gallery</GallHeading>
                    <GallBoxs>
                        <GallBoxsC>
                            {imgData.map((item, index) => (
                                <ImgBox1><ImgData src = {item.in_img} /></ImgBox1>
                            ))}
                        </GallBoxsC>
                    </GallBoxs>
                    <GallClose>
                        <GrClose onClick={() => disGall()}/>
                    </GallClose>
                </GallContainer>
            </GallSection>
        </>
    )
}

export default ImageHandler
