import React, {useEffect, useState} from 'react';
import BlockPage2 from '../component/about/BlockPage2';
import BlockPages from '../component/about/BlockPages';
import MemberPage from '../component/about/MemberPage';
import TravelproPage from '../component/about/TravelproPage';
import WhoWe from '../component/about/WhoWe';
import BackImg from '../component/BackImg';
import DropDown from '../component/DropDown';
import FooterSection from '../component/FooterSection';
import HalfImg from '../component/HalfImg';
import Navbargap from '../component/Navbargap';
import NavbarNormal from '../component/NavbarNormal';
import SlideUp from '../component/SlideUp';
import EnqForm from '../component/submitForm/EnqForm';
import SendMsg from '../component/submitForm/SendMsg';
import h4 from '../media/about/about.jpg';
import backImg from '../media/about/backImg.jpg';

const About = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [enOpen, setEnOpen] = useState(false);
    const [sendOpen, setSendOpen] = useState(false);
    const [enqyFor, setEnqyFor] = useState("");

    const toggle = () => {
        setIsOpen(!isOpen);
    }
    const enquiry = (enqData) => {
        setEnOpen(!enOpen);
        setEnqyFor(enqData);
    }
    const sendBox = () => {
        setSendOpen(!sendOpen);
    }
    useEffect(() => {
        window.scroll(0,0);
    },[]);
    return (
        <>
            <NavbarNormal toggle = {toggle}/>
            <DropDown isOpen = {isOpen} toggle = {toggle}/>
            {/* <SlideUp /> */}
            <Navbargap />
            <HalfImg img = {h4} heading = {"About"} />
            <WhoWe />
            <TravelproPage />
            <BackImg img = {backImg} />
            <BlockPages />
            <BlockPage2 />
            <MemberPage />
            <FooterSection enquiry = {enquiry}/>
            <EnqForm enForm = {enOpen} enFor = {enqyFor} enquiry = {enquiry} sendBox ={sendBox}/>
            <SendMsg dispSub = {sendOpen} sendBox ={sendBox}/>
        </>
    )
}

export default About
