import React, {useEffect, useState} from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import DropDown from '../component/DropDown';
import NavbarNormal from '../component/NavbarNormal';
import PackageInnBox from '../component/packages/PackageInnBox';
import PackageSection from '../component/packages/PackageSection';
import StatePage from '../component/packages/StatePage';
import { KarPackage } from '../data/KarPackage';


const Packages = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }
    useEffect(() => {
        window.scroll(0,0);
    },[]);
    return (
        <>            
            <NavbarNormal toggle = {toggle}/>
            <DropDown isOpen = {isOpen} toggle = {toggle}/>
            <PackageSection />
        </>
    )
}

export default Packages
