import belur from '../media/Location/belur.jpg';
import bettadabyreweshwaratemple from '../media/Location/bettadabyreweshwaratemple.jpg';
import bisaleghat from '../media/Location/bisaleghat.jpg';
import ettinabuja from '../media/Location/ettinabuja.jpg';
import jenukallupeak from '../media/Location/jenukallupeak.jpg';
import magajahallifalls from '../media/Location/magajahallifalls.jpg';
import manjarabadfort from '../media/Location/manjarabadfort.jpg';

import coorg_ABBEY_FALLS from '../media/coorg/ABBEY_FALLS.jpg';
import coorg_madikeri_fort from '../media/coorg/madikeri_fort.jpg';
import coorg_mallalli_falls from '../media/coorg/mallalli_falls.jpg';

import rs_1_1 from '../media/resort/sakleshpur/machaan/r1.jpg';
import rs_1_2 from '../media/resort/sakleshpur/machaan/r2.jpg';
import rs_1_3 from '../media/resort/sakleshpur/machaan/r3.jpg';
import rs_1_4 from '../media/resort/sakleshpur/machaan/r4.jpg';
import rs_1_5 from '../media/resort/sakleshpur/machaan/r5.jpg';
import rs_1_6 from '../media/resort/sakleshpur/machaan/r6.jpg';
import rs_1_7 from '../media/resort/sakleshpur/machaan/r7.jpg';
import rs_1_8 from '../media/resort/sakleshpur/machaan/r8.jpg';

import rs_2_1 from '../media/resort/sakleshpur/mookan/r1.jpeg';
import rs_2_2 from '../media/resort/sakleshpur/mookan/r2.jpeg';
import rs_2_3 from '../media/resort/sakleshpur/mookan/r3.jpeg';
import rs_2_4 from '../media/resort/sakleshpur/mookan/r4.jpeg';
import rs_2_5 from '../media/resort/sakleshpur/mookan/r5.jpeg';
import rs_2_6 from '../media/resort/sakleshpur/mookan/r6.jpeg';
import rs_2_7 from '../media/resort/sakleshpur/mookan/r7.jpeg';
import rs_2_8 from '../media/resort/sakleshpur/mookan/r8.jpeg';

import rs_3_1 from '../media/resort/sakleshpur/pebbles/r1.jpg';
import rs_3_2 from '../media/resort/sakleshpur/pebbles/r2.jpg';
import rs_3_3 from '../media/resort/sakleshpur/pebbles/r3.jpeg';
import rs_3_4 from '../media/resort/sakleshpur/pebbles/r4.jpg';
import rs_3_5 from '../media/resort/sakleshpur/pebbles/r5.jpg';
import rs_3_6 from '../media/resort/sakleshpur/pebbles/r6.jpg';
import rs_3_7 from '../media/resort/sakleshpur/pebbles/r7.jpeg';
import rs_3_8 from '../media/resort/sakleshpur/pebbles/r8.jpg';
import rs_3_9 from '../media/resort/sakleshpur/pebbles/r9.jpg';

import rs_4_1 from '../media/resort/sakleshpur/rottikallu/r1.jpg';
import rs_4_2 from '../media/resort/sakleshpur/rottikallu/r2.png';
import rs_4_3 from '../media/resort/sakleshpur/rottikallu/r3.jpg';
import rs_4_4 from '../media/resort/sakleshpur/rottikallu/r4.jpg';
import rs_4_5 from '../media/resort/sakleshpur/rottikallu/r5.png';
import rs_4_6 from '../media/resort/sakleshpur/rottikallu/r6.png';

import rs_5_1 from '../media/resort/sakleshpur/sabera/r1.jpg';
import rs_5_2 from '../media/resort/sakleshpur/sabera/r2.jpg';
import rs_5_3 from '../media/resort/sakleshpur/sabera/r3.jpg';
import rs_5_4 from '../media/resort/sakleshpur/sabera/r4.jpg';
import rs_5_5 from '../media/resort/sakleshpur/sabera/r5.jpg';
import rs_5_6 from '../media/resort/sakleshpur/sabera/r6.jpg';
import rs_5_7 from '../media/resort/sakleshpur/sabera/r7.jpg';
import rs_5_8 from '../media/resort/sakleshpur/sabera/r8.jpg';
import rs_5_9 from '../media/resort/sakleshpur/sabera/r9.jpg';
import rs_5_10 from '../media/resort/sakleshpur/sabera/r10.jpg';
import rs_5_11 from '../media/resort/sakleshpur/sabera/r11.jpg';
import rs_5_12 from '../media/resort/sakleshpur/sabera/r12.jpg';
import rs_5_13 from '../media/resort/sakleshpur/sabera/r13.jpg';

import rs_6_1 from '../media/resort/sakleshpur/stonevalley/r1.jpg';
import rs_6_2 from '../media/resort/sakleshpur/stonevalley/r2.jpg';
import rs_6_3 from '../media/resort/sakleshpur/stonevalley/r3.jpg';
import rs_6_4 from '../media/resort/sakleshpur/stonevalley/r4.jpg';
import rs_6_6 from '../media/resort/sakleshpur/stonevalley/r6.jpg';
import rs_6_7 from '../media/resort/sakleshpur/stonevalley/r7.jpg';
import rs_6_8 from '../media/resort/sakleshpur/stonevalley/r8.jpg';
import rs_6_9 from '../media/resort/sakleshpur/stonevalley/r9.jpg';
import rs_6_10 from '../media/resort/sakleshpur/stonevalley/r10.jpg';
import rs_6_11 from '../media/resort/sakleshpur/stonevalley/r11.jpg';
import rs_6_12 from '../media/resort/sakleshpur/stonevalley/r12.jpg';

import rs_7_1 from '../media/resort/sakleshpur/eka/1.jpg';
import rs_7_2 from '../media/resort/sakleshpur/eka/2.jpg';
import rs_7_3 from '../media/resort/sakleshpur/eka/3.jpg';
import rs_7_4 from '../media/resort/sakleshpur/eka/4.jpg';
import rs_7_5 from '../media/resort/sakleshpur/eka/5.jpg';
import rs_7_6 from '../media/resort/sakleshpur/eka/6.jpg';
import rs_7_7 from '../media/resort/sakleshpur/eka/7.jpg';
import rs_7_8 from '../media/resort/sakleshpur/eka/8.jpg';
import rs_7_9 from '../media/resort/sakleshpur/eka/9.jpg';

import rs_8_1 from '../media/resort/sakleshpur/aroha/1.jpg';
import rs_8_2 from '../media/resort/sakleshpur/aroha/2.jpg';
import rs_8_3 from '../media/resort/sakleshpur/aroha/3.jpg';
import rs_8_4 from '../media/resort/sakleshpur/aroha/4.jpg';
import rs_8_5 from '../media/resort/sakleshpur/aroha/5.jpg';

import rs_9_1 from '../media/resort/sakleshpur/scounty/1.jpg';
import rs_9_2 from '../media/resort/sakleshpur/scounty/2.jpg';
import rs_9_3 from '../media/resort/sakleshpur/scounty/3.jpg';
import rs_9_4 from '../media/resort/sakleshpur/scounty/4.jpg';
import rs_9_5 from '../media/resort/sakleshpur/scounty/5.jpg';
import rs_9_6 from '../media/resort/sakleshpur/scounty/6.jpg';
import rs_9_7 from '../media/resort/sakleshpur/scounty/7.jpg';
import rs_9_8 from '../media/resort/sakleshpur/scounty/8.jpg';
import rs_9_9 from '../media/resort/sakleshpur/scounty/9.jpg';



export const Sakresort = [
    {
        code: "1",
        name: "Machaan Plantation Resort",
        location:"Sakleshpur.",
        image: rs_1_3,
        facility: "Fun Activities, Food, Hot Water, Awesome View, Extra Beds",
        details: {
            in_name: "Machaan Plantation Resort",
            in_location: "Sakleshpur.",
            in_desc: "Welcome to Machaan Plantation Resort in Sakleshpur. One of the most off-beat destinations for a unique holiday in India. The resort is tucked away in this little provincial countryside surrounded by hills in the west and paddy fields in the north with breath-taking views all around.",
            in_inTime: "11:00 AM",
            in_outTime: "11:00 AM",
            images: [
                {
                    in_img: rs_1_3
                },
                {
                    in_img: rs_1_2
                },
                {
                    in_img: rs_1_1
                },
                {
                    in_img: rs_1_4
                },
                {
                    in_img: rs_1_5
                },
                {
                    in_img: rs_1_6
                },
                {
                    in_img: rs_1_7
                },
                {
                    in_img: rs_1_8
                },
            ],
            in_atv: [
                {
                    act: "Malnad Estate Life"
                },
                {
                    act: "Trekking/Nature Walks"
                },
                {
                    act: "Bonfire Evenings"
                },
            ],
            in_visit: [
                {
                    vi_img: belur,
                    vi_text: "Belur"
                },
                {
                    vi_img: ettinabuja,
                    vi_text: "Ettina Buja"
                },
                {
                    vi_img: bisaleghat,
                    vi_text: "Bisle Ghat"
                },
                {
                    vi_img: jenukallupeak,
                    vi_text: "Jenukallu Peak"
                },
                {
                    vi_img: magajahallifalls,
                    vi_text: "Magajahalli Falls"
                },
                {
                    vi_img: bettadabyreweshwaratemple,
                    vi_text: "Bettada Byreweshwara Temple"
                },
                {
                    vi_img: manjarabadfort,
                    vi_text: "Manjarabad Fort"
                }
            ]
        }
    },
    {
        code: "2",
        name: "Mookanana Resorts",
        location:"Sakleshpur.",
        image: rs_2_3,
        facility: "Fun Activities, Food, Hot Water, Awesome View, Extra Beds",
        details: {
            in_name: "Mookanana Resorts",
            in_location: "Sakleshpur.",
            in_desc: "Mookanana Resorts is the best resorts in Sakleshpur that defines new dimension of luxury sakleshpur resorts & homestay to discover sakleshpur tourist spots. Our resorts have all kind of luxury amenities for your vacation and stay like infinity pool, luxurious bed, hot water, shower, laundry facilities, restaurant facilities, swimming pool etc. We are always striving to offer the best services to our guests in Sakleshpur. We are the preferred partner of Sakleshpur vacationers & tourists. Our Resorts in Sakleshpur near Chikmagalur, Hassan is one of the best resorts near sakleshpur Karnataka. Our Sakleshpura Resort is the best weekend getaways that define the new dimension of luxury resorts between the Western Ghats in sakleshpur. It has beautiful hill stations lying at the foothills of the Western Ghats, well known for its beautiful mookana mane waterfalls, numerous undiscovered trekking routes, coffee plantation & enchanting temples. One of the main attractions on-the-way to Mookanana sakleshpur resorts & homestay is the Manjarabad Fort.",
            in_inTime: "11:00 AM",
            in_outTime: "11:00 AM",
            images: [
                {
                    in_img: rs_2_3
                },
                {
                    in_img: rs_2_2
                },
                {
                    in_img: rs_2_1
                },
                {
                    in_img: rs_2_4
                },
                {
                    in_img: rs_2_5
                },
                {
                    in_img: rs_2_6
                },
                {
                    in_img: rs_2_7
                },
                {
                    in_img: rs_2_8
                },
            ],
            in_atv: [
                {
                    act: "Mookanana Resort Trekking"
                },
                {
                    act: "Hosahalli Beta"
                },
                {
                    act: "Betakumari Falls"
                },
                {
                    act: "Bisale Ghat [Beauty Spot] Kumaradhara River View at 180ft"
                },
                {
                    act: "Study of Western Ghat Plants and Ayurvedic Plants"
                },
                {
                    act: "Study of Western Ghat Animals and Birds"
                },
                {
                    act: "Study of River and land Formation"
                },
                {
                    act: "Study of Florals and Faunas"
                },
                {
                    act: "Study of Hydrology, Current Meter and Rain Gauge Station"
                },
                {
                    act: "Study of Cropping Partners"
                },
                {
                    act: "Study of Enery Sectors - Generation and Transmission"
                },
                {
                    act: "Study of Water Bodies"
                },
            ],
            in_visit: [
                {
                    vi_img: ettinabuja,
                    vi_text: "Mookana Mane Abbi Waterfalls"
                },
                {
                    vi_img: bisaleghat,
                    vi_text: "Kaginahare View Point[Mini Switzerland]"
                },
                {
                    vi_img: jenukallupeak,
                    vi_text: "Kaginare Fort"
                },
                {
                    vi_img: magajahallifalls,
                    vi_text: "Betakumari Waterfalls"
                },
                {
                    vi_img: bettadabyreweshwaratemple,
                    vi_text: "Hosahalli View Point"
                }
            ]
        }
    },
    {
        code: "3",
        name: "Pebbles & Beans Resort",
        location:"Sakleshpur.",
        image: rs_3_3,
        facility: "Fun Activities, Food, Hot Water, Awesome View, Extra Beds",
        details: {
            in_name: "Pebbles & Beans Resort",
            in_location: "Sakleshpur.",
            in_desc: "The beauty of this hill top resort in sakleshpur lies  in driving along dense coffee plantations. surrounded by the historical rains of the first ever coffee cultivation, in the resort you will experience the highest level of comfort, service and cuisine, In a world that has been created purely for pleasure and indulgence… Weather it is a suite with plantations in your backyard or an elegantly furnished cottage , over looking     an expanse of trees, ferns and plantations from its chilled pool, you are sure to experience an unmatched luxury amidst this Beautiful coffee land of karnataka.",
            in_inTime: "11:00 AM",
            in_outTime: "11:00 AM",
            images: [
                {
                    in_img: rs_3_3
                },
                {
                    in_img: rs_3_2
                },
                {
                    in_img: rs_3_1
                },
                {
                    in_img: rs_3_4
                },
                {
                    in_img: rs_3_5
                },
                {
                    in_img: rs_3_6
                },
                {
                    in_img: rs_3_7
                },
                {
                    in_img: rs_3_8
                },
                {
                    in_img: rs_3_9
                }
            ],
            in_atv: [
                {
                    act: "Swimming Pool"
                },
                {
                    act: "Pedal Boating"
                },
                {
                    act: "Artificial Waterfall"
                },
                {
                    act: "Badminton Court"
                },
                {
                    act: "Trampoline"
                },
                {
                    act: "Rope Activity"
                },
                {
                    act: "Kids Play Area"
                },
                {
                    act: "Campfire"
                },
                {
                    act: "Coffee Plantation Walk"
                },
            ],
            in_visit: [
                {
                    vi_img: belur,
                    vi_text: "Belur"
                },
                {
                    vi_img: ettinabuja,
                    vi_text: "Ettina Buja"
                },
                {
                    vi_img: bisaleghat,
                    vi_text: "Bisle Ghat"
                },
                {
                    vi_img: jenukallupeak,
                    vi_text: "Jenukallu Peak"
                },
                {
                    vi_img: magajahallifalls,
                    vi_text: "Magajahalli Falls"
                },
                {
                    vi_img: bettadabyreweshwaratemple,
                    vi_text: "Bettada Byreweshwara Temple"
                },
                {
                    vi_img: manjarabadfort,
                    vi_text: "Manjarabad Fort"
                }
            ]
        }
    },
    {
        code: "4",
        name: "Rotti Kallu",
        location:"Sakleshpur.",
        image: rs_4_3,
        facility: "Fun Activities, Food, Hot Water, Awesome View, Extra Beds",
        details: {
            in_name: "Rotti Kallu",
            in_location: "Sakleshpur.",
            in_desc: "Welcome to a land that’s off the grid, situated at the foothills of one of the 4 biodiversity hotspots in India, the Western Ghats. Rottikallu is located in Sakleshpura that lies in the Malnad region nestled 3136 feet above sea level, flourishing with flora and fauna.  A delightful get-away with wide open spaces, serene surroundings, comfortable rooms and cottages and to complete the picture, we serve you a lavish spread with a variety of dishes every day that brings out the finest flavours unique to the Malnad region. Over the last 13+ years we have hosted an average of 7,000 guests per year giving them the perfect experience to relax and rejuvenate. We are currently outfitted with 14 Cottages amply furnished and a spectacular view of the hills on one side, a panoramic view of our property with a stream flowing through on the other side. Shrouded in mist and surrounded with lush greenery Rottikallu is an ideal get-away from the hustle and bustle of city life where you can be one with nature at its best. Our goal is to co-operate with nature and our cottages are built with care making sure we have not interfered with the natural surroundings.  Rottikallu is one of the best nature resorts around Bangalore making this a haven of natural beauty and elegant luxury. The perfect holiday destination from mundane city life to reconnect with nature.",
            in_inTime: "11:00 AM",
            in_outTime: "11:00 AM",
            images: [
                {
                    in_img: rs_4_3
                },
                {
                    in_img: rs_4_2
                },
                {
                    in_img: rs_4_1
                },
                {
                    in_img: rs_4_4
                },
                {
                    in_img: rs_4_5
                },
                {
                    in_img: rs_4_6
                }
            ],
            in_atv: [
                {
                    act: "Cottages"
                },
                {
                    act: "Restaurant"
                },
                {
                    act: "Bonfire"
                },
                {
                    act: "Indoor Games"
                },
                {
                    act: "Outdoor Games"
                },
                {
                    act: "Water Games"
                },
                {
                    act: "Boating/Kayaking"
                },
                {
                    act: "Child Friendly"
                },
                {
                    act: "Pet Friendly"
                },
                {
                    act: "Parking"
                },
                {
                    act: "Sight Seeing"
                },
                {
                    act: "Guided Trekking"
                },
            ],
            in_visit: [
                {
                    vi_img: belur,
                    vi_text: "Belur"
                },
                {
                    vi_img: ettinabuja,
                    vi_text: "Ettina Buja"
                },
                {
                    vi_img: bisaleghat,
                    vi_text: "Bisle Ghat"
                },
                {
                    vi_img: jenukallupeak,
                    vi_text: "Jenukallu Peak"
                },
                {
                    vi_img: magajahallifalls,
                    vi_text: "Magajahalli Falls"
                },
                {
                    vi_img: bettadabyreweshwaratemple,
                    vi_text: "Bettada Byreweshwara Temple"
                },
                {
                    vi_img: manjarabadfort,
                    vi_text: "Manjarabad Fort"
                }
            ]
        }
    },
    {
        code: "5",
        name: "Sabera Heritage Home",
        location:"Sakleshpur.",
        image: rs_5_3,
        facility: "Fun Activities, Food, Hot Water, Awesome View, Extra Beds",
        details: {
            in_name: "Sabera Heritage Home",
            in_location: "Sakleshpur.",
            in_desc: "My weekend trips to Sakleshpur, to our coffee estate was to getaway from the maddening, ever growing crowd, the chaotic city life, the incessant noise and the omnipresent social media, seeking peace and quiet a midst the lush green plantation spread over 100 acres. The bloom of coffee flowers, the far reaching fragrance filling my senses and the chirping of birds, the mist covered mountains, the fresh smell of earth after the rains, the star filled sky just pulled me in and brought about a calm and reduced my stress every time. It was my own little patch of heaven and it was available to me when I wanted.",
            in_inTime: "11:00 AM",
            in_outTime: "11:00 AM",
            images: [
                {
                    in_img: rs_5_3
                },
                {
                    in_img: rs_5_2
                },
                {
                    in_img: rs_5_1
                },
                {
                    in_img: rs_5_4
                },
                {
                    in_img: rs_5_5
                },
                {
                    in_img: rs_5_6
                },
                {
                    in_img: rs_5_7
                },
                {
                    in_img: rs_5_8
                },
                {
                    in_img: rs_5_9
                },
                {
                    in_img: rs_5_10
                },
                {
                    in_img: rs_5_11
                },
                {
                    in_img: rs_5_12
                },
                {
                    in_img: rs_5_13
                }
            ],
            in_atv: [
                {
                    act: "Bonfire with Music"
                },
                {
                    act: "Trekking in Sakleshpur"
                },
                {
                    act: "Jeep Rides"
                },
                {
                    act: "Cycling"
                },
                {
                    act: "Nature Walks"
                },
                {
                    act: "Indoor Games"
                },
                {
                    act: "Mukkanmane Waterfall"
                },
                {
                    act: "Waterfall Trek"
                },
            ],
            in_visit: [
                {
                    vi_img: belur,
                    vi_text: "Belur"
                },
                {
                    vi_img: ettinabuja,
                    vi_text: "Betta Byraveshwara Temple"
                },
                {
                    vi_img: bisaleghat,
                    vi_text: "Bisle Ghat"
                },
                {
                    vi_img: jenukallupeak,
                    vi_text: "Agni Gudda Hill"
                },
                {
                    vi_img: magajahallifalls,
                    vi_text: "Pandavar Gudda"
                },
                {
                    vi_img: bettadabyreweshwaratemple,
                    vi_text: "Bettada Byreweshwara Temple"
                },
                {
                    vi_img: manjarabadfort,
                    vi_text: "Manjarabad Fort"
                }
            ]
        }
    },
    {
        code: "6",
        name: "Stone Valley Resorts",
        location:"Sakleshpur.",
        image: rs_6_3,
        facility: "Fun Activities, Food, Hot Water, Awesome View, Extra Beds",
        details: {
            in_name: "Stone Valley Resorts",
            in_location: "Sakleshpur.",
            in_desc: "Stone Valley Resorts Sakleshpur is the last property in the valley, located inside the forest and is surrounded by hills. A mountain stream runs through the property bringing with it, the spirit of the mountains apart from the natural mineral water. Our packages include ALL Meals, Activities in the resort, swimming pool and also trekking in the nearby areas. 100% Tasty and hygienic food. Clean and comfortable rooms. DO NOT hold back the urge to feel the freshness of mother nature. ",
            in_inTime: "11:00 AM",
            in_outTime: "11:00 AM",
            images: [
                {
                    in_img: rs_6_3
                },
                {
                    in_img: rs_6_2
                },
                {
                    in_img: rs_6_1
                },
                {
                    in_img: rs_6_4
                },
                {
                    in_img: rs_6_6
                },
                {
                    in_img: rs_6_7
                },
                {
                    in_img: rs_6_8
                },
                {
                    in_img: rs_6_9
                },
                {
                    in_img: rs_6_10
                },
                {
                    in_img: rs_6_11
                },
                {
                    in_img: rs_6_12
                }
            ],
            in_atv: [
                {
                    act: "OFF-ROAD JEEP DRIVE"
                },
                {
                    act: "FRESH WATER SWIMMING POOl"
                },
                {
                    act: "MUD VOLLEYBALL"
                },
                {
                    act: "FRESH WATER NATURAL POOL"
                },
                {
                    act: "CAMP FIRE"
                },
                {
                    act: "HIGH ROPE ACTIVITY"
                },
                {
                    act: "HILLTOP NATURE TREKKING"
                },
                {
                    act: "STREAM WALKING"
                },
                {
                    act: "INDOOR GAMES"
                },
                {
                    act: "OUTDOOR GAMES"
                },
            ],
            in_visit: [
                {
                    vi_img: belur,
                    vi_text: "Belur"
                },
                {
                    vi_img: ettinabuja,
                    vi_text: "Betta Byraveshwara Temple"
                },
                {
                    vi_img: bisaleghat,
                    vi_text: "Bisle Ghat"
                },
                {
                    vi_img: jenukallupeak,
                    vi_text: "Agni Gudda Hill"
                },
                {
                    vi_img: magajahallifalls,
                    vi_text: "Pandavar Gudda"
                },
                {
                    vi_img: bettadabyreweshwaratemple,
                    vi_text: "Bettada Byreweshwara Temple"
                },
                {
                    vi_img: manjarabadfort,
                    vi_text: "Manjarabad Fort"
                }
            ]
        }
    },
    {
        code: "7",
        name: "eka Resort",
        location:"Sakleshpur.",
        image: rs_7_3,
        facility: "Fun Activities, Food, Hot Water, Awesome View, Extra Beds",
        details: {
            in_name: "eka Resort",
            in_location: "Sakleshpur.",
            in_desc: "If nature is tugging at your heartstrings, Sakleshpur is where you should head, and while here, the Eka Resort is where you should camp. For being enveloped in the luxury of cosy self-contained cottages and surrounded by the taste of a farm lifestyle is a fine way of answering the call. Here, freshly grown clove and pepper plantations revitalise your senses, cheerful red coffee berries add colour to your life and activities ensure there’s never a dull moment. Revisit the wondrous innocence of your childhood when your mind and body rejoice in its strength and vitality. Toss a ball, take a swim, fly a kite, catch a fish, and dream by a glowing fireside at twilight. So you may be you again. And when you’re rejuvenated, we’re only too happy to guide you on a fascinating journey through the endless wonders of an enduring secret called Sakleshpur.",
            in_inTime: "11:00 AM",
            in_outTime: "11:00 AM",
            images: [
                {
                    in_img: rs_7_3
                },
                {
                    in_img: rs_7_2
                },
                {
                    in_img: rs_7_1
                },
                {
                    in_img: rs_7_4
                },
                {
                    in_img: rs_7_5
                },
                {
                    in_img: rs_7_6
                },
                {
                    in_img: rs_7_7
                },
                {
                    in_img: rs_7_8
                },
                {
                    in_img: rs_7_9
                }
            ],
            in_atv: [
                {
                    act: "Table Tennis"
                },
                {
                    act: "Football"
                },
                {
                    act: "Camp Fire"
                },
                {
                    act: "Natures walk"
                },
                {
                    act: "Badminton"
                },
                {
                    act: "Cricket"
                },
                {
                    act: "Indoor games"
                }
            ],
            in_visit: [
                {
                    vi_img: belur,
                    vi_text: "Belur"
                },
                {
                    vi_img: coorg_madikeri_fort,
                    vi_text: "Madikeri"
                },
                {
                    vi_img: manjarabadfort,
                    vi_text: "Manjarabadh Fort"
                },
                {
                    vi_img: coorg_ABBEY_FALLS,
                    vi_text: "Abbi Waterfall"
                },
                {
                    vi_img: coorg_mallalli_falls,
                    vi_text: "Mallalli Waterfall"
                }
            ]
        }
    },
    {
        code: "8",
        name: "AROHA'S ECO HILL RESORT",
        location:"Sakleshpur.",
        image: rs_8_3,
        facility: "Fun Activities, Food, Hot Water, Awesome View, Extra Beds",
        details: {
            in_name: "AROHA'S ECO HILL RESORT",
            in_location: "Sakleshpur.",
            in_desc: "An eco hill resort that’s both cutting-edge and cosy, set in enchanting Sakleshpur countryside. Aroha’s Eco-Hill Resort is a place that needs no embellishment. The setting is uniquely beautiful. However, out cottages are as chic in style as they are comfortable and have magnificient views of the mountains. Throughout the year, Sakleshpur is a special place to visit. Inspiring mountains and winding riversprovide the opportunity to enjoy a vast range of activities. From exploring temples of historical significance, Zip line, Trekking, Muddy water rugby, exhilarating waterfall excursions, the choices are endless.",
            in_inTime: "11:00 AM",
            in_outTime: "11:00 AM",
            images: [
                {
                    in_img: rs_8_3
                },
                {
                    in_img: rs_8_2
                },
                {
                    in_img: rs_8_1
                },
                {
                    in_img: rs_8_4
                },
                {
                    in_img: rs_8_5
                }
            ],
            in_atv: [
                {
                    act: "Swimming Pool"
                },
                {
                    act: "Guided Trekking"
                },
                {
                    act: "Mud Volleyball"
                },
                {
                    act: "Campfire"
                },
                {
                    act: "Rain Dance"
                },
                {
                    act: "Badminton"
                },
                {
                    act: "Zipline"
                },
                {
                    act: "Rope Bridge"
                },
                {
                    act: "Indoor Games/ Cricket"
                },
                {
                    act: "Natural Pool"
                },
                {
                    act: "Visit Water Falls"
                }
            ],
            in_visit: [
                {
                    vi_img: belur,
                    vi_text: "Belur"
                },
                {
                    vi_img: coorg_madikeri_fort,
                    vi_text: "Madikeri"
                },
                {
                    vi_img: manjarabadfort,
                    vi_text: "Manjarabadh Fort"
                },
                {
                    vi_img: coorg_ABBEY_FALLS,
                    vi_text: "Abbi Waterfall"
                },
                {
                    vi_img: coorg_mallalli_falls,
                    vi_text: "Mallalli Waterfall"
                }
            ]
        }
    },
    {
        code: "9",
        name: "S County Resort",
        location:"Sakleshpur.",
        image: rs_9_3,
        facility: "Fun Activities, Food, Hot Water, Awesome View, Extra Beds",
        details: {
            in_name: "S County Resort",
            in_location: "Sakleshpur.",
            in_desc: "Welcome to S-COUNTY RESORTS in Sakleshpur- A Country retreat that spreads across 10 Acres of plantations, lakes, valleys, forests. The resort is snuggled in the abundant green of Western Ghats in Karnataka, India. S-COUNTY RESORTS is situated literally in the midst of the Western Ghats, just a 5-minute drive from the Sakleshpur town and a Great place for folks who like to relax, trek, and enjoy nature, adventure and many more. A marvellous place to have a memorable holiday in Sakleshpur. If you wish to have a serene place to spend your vacation, come and explore the wonderful stay with nature without compromising the luxury. S-COUNTY RESORTS is an ideal destination for a memorable leisure holiday.",
            in_inTime: "11:00 AM",
            in_outTime: "11:00 AM",
            images: [
                {
                    in_img: rs_9_3
                },
                {
                    in_img: rs_9_2
                },
                {
                    in_img: rs_9_1
                },
                {
                    in_img: rs_9_4
                },
                {
                    in_img: rs_9_5
                },
                {
                    in_img: rs_9_6
                },
                {
                    in_img: rs_9_7
                },
                {
                    in_img: rs_9_8
                },
                {
                    in_img: rs_9_9
                }
            ],
            in_atv: [
                {
                    act: "CRICKET"
                },
                {
                    act: "TABLE TENNIS"
                },
                {
                    act: "CARROM"
                },
                {
                    act: "SHUTTLE COCK"
                },
                {
                    act: "CAMPFIRE"
                },
                {
                    act: "VOLLEY BALL"
                }
            ],
            in_visit: [
                {
                    vi_img: belur,
                    vi_text: "Belur"
                },
                {
                    vi_img: manjarabadfort,
                    vi_text: "Manjarabadh Fort"
                },
                {
                    vi_img: magajahallifalls,
                    vi_text: "Magajahalli Waterfalls"
                },
                {
                    vi_img: coorg_mallalli_falls,
                    vi_text: "Mallalli Waterfall"
                }
            ]
        }
    }
]