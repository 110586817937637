import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';
import { PackageListOne } from '../../data/PackageListArr';

const Section = styled.section`
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-rows: 100px auto;
    @media screen and (max-width: 1024px) {
        height: 100%;
        grid-template-rows: 70px auto;
    }
`;
const NavbarSection = styled.div`
`;
const PackageContainer = styled.div`
    display: grid;
    grid-template-rows: 4fr 2fr;
    @media screen and (max-width: 1024px) {        
        grid-template-rows:auto;
    }
`;

const PackageList = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 5px;
    @media screen and (max-width: 1024px) {
        grid-template-columns: auto;
        grid-template-rows: auto;
        gap: 50px;
        padding: 50px;
    }
`;
const PackageText = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'open_san';
    /* text-transform: capitalize; */
    letter-spacing: 2px;
    font-size: 2rem;
    margin-top: 30px;
    strong {
        font-size: 3rem;
        padding: 0 15px;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    @media screen and (max-width: 1024px) {
        display: none;
    }
`;
const framship = keyframes`
    0% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(5deg);
    }
    50% {
        transform: rotate(0deg);
    }
    75% {
        transform: rotate(-5deg);
    }    
    100% {
        transform: rotate(0deg);
    }
`;
const PackageItem =styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    z-index: 10;

    &:hover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 11;
    }
    @media screen and (max-width: 1024px) {
        height: 250px;

        &:hover {
            position: relative;
            width: 100%;
            height: 250px;
            cursor: pointer;
            z-index: 11;
        }
    }
    &.spacial {
        @media screen and (min-width: 1024px) {
            display: none;
            /* position: absolute;
            bottom: -80px;
            width: 220px;
            height: 220px; */
            /* border-radius: 50%; */
            /* border: 7px solid #fff;
            left: 0;
            right: 0;
            margin: 0 auto;
            border-radius: 80%;             */
            /* animation: ${framship} 2s linear infinite; */
            /* display: ${({smartbox}) => (smartbox == "spacial-non" ? 'none' : '')}; */
            /* background-color: black; */

            img {
                /* border-radius: 80%; */
                /* display: none; */
            }
            &:hover {
                /* bottom: 0;
                width: 100%;
                height: 100%;
                border-radius: 0%;
                border: none; */
                /* animation: none; */

                img {
                    /* border-radius: 0%; */
                }
            }
        }
        @media screen and (max-width: 1024px) {
            display: block;
        }
    }
`;
const Packageimg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* &.spacial {
        @media screen and (min-width: 1024px) {
            
            border-radius: 50%;
        }
    } */
`;
const Packagetitle = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h4 {
        color: #fff;
        text-transform: capitalize;
        text-align: center;
        margin-bottom: 30px;
        font-weight: normal;
    }
    link {
        color: red;
    }
    button {
        font-size: 15px;
        padding: 5px 20px;
        background: transparent;
        border: 2px solid #fff;
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;
        display: none;
        text-decoration:none;
        transition: .4s;

        &:hover {
            background-color: #fff;
            color: #000;
            transition: .4s;
        }        
        @media screen and (max-width: 1024px) {        
            display: block;
        }


    }

    &:hover {
        background: rgba(0,0,0,0.3);
        button {
            display: block;
        }   
    @media screen and (max-width: 1024px) {          
        background: rgba(0,0,0,0.0);
    }
    }  
`;
const PcBtn = styled(Link)`
    font-size: 15px;
    padding: 5px 20px;
    background: transparent;
    border: 2px solid #fff;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
    display: none;
    transition: .4s;

    &:hover {
        background-color: #fff;
        color: #000;
        transition: .4s;
    }        
    @media screen and (max-width: 1024px) {        
        display: block;
    }
`;
const LinkBox = styled(Link)`
    text-decoration: none;
`;

const Allstatebtn = styled(Link)`
    text-decoration: none;
    /* position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto; */

    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: red;
    margin-right: 20px;
    color: #fff;
    font-size: 23px;
    text-transform: capitalize;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    text-align:center;
    cursor: pointer;
        transition: .4s;
    font-family: 'open_san';

    &:hover {
        transform: scale(1.1);
        transition: .4s;
    }

`;

const PackageSection = () => {
    return (
        <Section>
            <NavbarSection />
            <PackageContainer>
                <PackageList>
                    {PackageListOne.map((item, index) => (
                        <PackageItem className = {item.classTag} smartbox = {item.classTag} data-aos = "flip-up" data-aos-duration={item.dur} key = {index}>
                            <Packageimg src = {item.image} className = {item.classTag}/>
                            <Packagetitle>                                
                                <h4>{item.title}</h4>
                                <LinkBox to ={item.link}>
                                    <button>{item.btnName}</button>
                                </LinkBox>
                            </Packagetitle>
                        </PackageItem>
                    ))}
                </PackageList>
                <PackageText>
                    <Allstatebtn to ="/packages/multistate">
                        Multi State packages 
                    </Allstatebtn>
                    a <strong>journey</strong> of a thousand miles begin with single step.
                </PackageText>
            </PackageContainer>
        </Section>
    )
}

export default PackageSection
