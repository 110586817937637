import React, { useRef, useState} from 'react';
import styled from 'styled-components';
import t1 from '../../media/homestay/t1.jpg';
import { MdLocationOn } from 'react-icons/md';
import { AiFillCaretLeft } from 'react-icons/ai';
import { AiFillCaretRight } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const Section = styled.section`
    position: relative;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const ElementContainer = styled.div`
    width: 86vw;
    padding: 30px 20px;
    display: flex;
    overflow-x: hidden;
    overflow-x: scroll;
    scroll-behavior: smooth;

    &::-webkit-scrollbar-track {
        border-radius: 10px;
        background-color: #fff;
    }

    &::-webkit-scrollbar
    {
        width: 12px;
        height: 10px;
        background-color: #F5F5F5;
    }

    &::-webkit-scrollbar-thumb
    {
        border-radius: 10px;
        background-color: lightgray;
    }
`;
const Box = styled(Link)`
    text-decoration: none;
    max-width: 220px;
    min-width: 220px;
    height: 310px;
    display: grid;
    grid-template-rows: 4.3fr 1.7fr;
    border: none;
    margin-right: 30px;
    box-shadow: 0px 7px 7px -2px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 7px 7px -2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 7px 7px -2px rgba(0,0,0,0.75);
    border-radius: 7px;
    background: #FEF7DC;
    transition: .3s;


    &:nth-last-child(1) {
        margin-right: 0px;
    }
    @media screen and (max-width: 768px) { 
        height: 280px;
    }
    @media screen and (max-width: 668px) { 
        height: 280px;
        max-width: 200px;
        min-width: 200px;
    }
    &:hover {
        transform: scale(1.03);
        transition: .3s;
    }
`;
const Boximg = styled.div`
    position: relative;
`;
const Imgbox = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 7px 7px 0 0;
`;
const BoxData = styled.div`
    position: relative;
    top: -15px;
    border-radius: 15px;
    background: #FEF7DC;
    /* border-top: 4px solid #fff; */
    display: grid;
    grid-template-rows: 30px 45px auto;
    color: #000;
`;
const FakeBox = styled.div`
    position: absolute;
    top: -15px;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 70%;
    height: 45px;
    background: #FEF7DC;
    border-radius: 50% 50% 0 0;
    /* border-radius: 100%; */
    /* border: 4px solid #fff; */
    border-bottom: none;
    display: flex;
    justify-content: center;
    padding-top: 3px;
    z-index: 3;
`;

const BDLoc = styled.div`
    position: relative;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    color: green;
    z-index: 5;
`;
const BDTitle = styled.div`
/* border: 1px solid red; */
    font-size: 16px;
    display: grid;
    place-content: center;
    text-align: center;
    line-height: 18px;
    text-transform: capitalize;

`;
const BDPrice = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
const PriceTag = styled.div`
    padding: 3px 6px;
    width: 60%;
    font-size: 15px;
    font-weight: bold;
    border: none;
    display: flex;
    justify-content: center;
    background: #E93B81;
    color: #fff;
    border-radius: 5px;
    transition: .3s;

    &:hover {
        transform: scale(1.05);
        transition: .3s;
    }
`;

const LeftBox = styled.div`
    position: absolute;
    left: 25px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    cursor: pointer;
    transition: .3s;
    @media screen and (max-width: 1024px) {
        display: none;
    }

    &:hover {
        transform: scale(1.1);
        transition: .3s;
    }

`;
const RightBox = styled.div`
    position: absolute;
    right: 25px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    cursor: pointer;
    transition: .3s;
    @media screen and (max-width: 1024px) {
        display: none;
    }

    &:hover {
        transform: scale(1.1);
        transition: .3s;
    }

`;


const ElementSlider = ({enquiry, arrbox}) => {

    const [linkSt, setLinkSt] = useState(false);


    const elementRef = useRef(null);
    const slideScroll = (scrollVal) => {   
        elementRef.current.scrollLeft += scrollVal;
    }

    const setLink = (stLink) => {
        setLinkSt(stLink);
    }
    return (
        <Section>
            <ElementContainer  ref = {elementRef}>
                <LeftBox onClick={() => slideScroll(-800)}>
                    <AiFillCaretLeft                        
                        color = "#000"
                        size = "42px"
                    />
                </LeftBox>
                <RightBox onClick={() => slideScroll(800)}>
                    <AiFillCaretRight                       
                        color = "#000"
                        size = "42px"
                    />
                </RightBox>
                {arrbox.map((item,index) => (
                    <Box key = {index} 
                        to = {linkSt ? "" : {
                            pathname: "/homestay/homestaydetails",
                            state: {dataContent: item.details}
                        }}>
                        <Boximg>
                            <Imgbox src={item.image} />
                        </Boximg>
                        <BoxData>
                            <FakeBox>
                                <MdLocationOn 
                                    color = "#000"
                                    size = "18px"
                                />
                            </FakeBox>
                            <BDLoc>{item.location}</BDLoc>
                            <BDTitle>
                                {item.name}
                            </BDTitle>
                            <BDPrice>
                                <PriceTag  onMouseEnter={() => setLink(true)} onClick={() => enquiry(item.name)} onMouseLeave={() => setLink(false)}>
                                    Enquiry Now
                                </PriceTag>
                            </BDPrice>
                        </BoxData>
                    </Box>
                ))}                
            </ElementContainer>
        </Section>
    )
};

export default ElementSlider
