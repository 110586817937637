import React, { useState } from 'react';
import styled from 'styled-components';
import { FaArrowUp } from 'react-icons/fa';

const Box = styled.div`
    position: fixed;
    bottom: 100px;
    right: 18px;
    width: 65px;
    height: 65px;
    background: red;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    /* box-shadow: 0.5rem 0.5rem black, -0.5rem -0.5rem #ccc; */
    box-shadow: 0px 0px 3px 3px rgba(66, 63, 62,0.30);
    -webkit-box-shadow: 0px 0px 3px 3px rgba(66, 63, 62,0.30);
    -moz-box-shadow: 0px 0px 3px 3px rgba(66, 63, 62,0.30);
    z-index: 999;
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    transition: .4s;

    &:hover {
        transform: scale(1.2);
        transition: .4s;
    }
    @media screen and (max-width: 668px) {
        bottom: 100px;
        right: 15px;
    }

    &.active {
        display: none;
        transition: .4s;
    }
`;

const FloatEnq = ({enqTitle, enquiry}) => {
    const [upDisplay, setUpDisplay] = useState('active');

    const displayBox = () => {
        if(window.scrollY >= 650) {
            setUpDisplay('');
        }else {
            setUpDisplay('active');
        }
    }
    window.addEventListener('scroll', displayBox); 
    return (
        <Box className = {upDisplay} onClick={() => enquiry(enqTitle)}>
            Enquiry
        </Box>
    )
}

export default FloatEnq
