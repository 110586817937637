import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { MdLocationOn } from 'react-icons/md';
import { Link } from 'react-router-dom';
const Section = styled.section`
    width: 100vw;
    height: 100%;
    padding: 50px;
    display: grid;
    grid-template-columns: repeat(4, 1.5fr);
    gap: 40px;
    @media screen and (max-width: 1400px) {          
        grid-template-columns: repeat(3, 1.5fr);
    }
    @media screen and (max-width: 768px) {          
        grid-template-columns: repeat(2, 1.5fr);
    }
    @media screen and (max-width: 668px) {          
        grid-template-columns: repeat(1, 1.5fr);
        padding: 40px;

    }
`;

const BoxContainer = styled(Link)`
    text-decoration: none;
    height: 380px;
    padding: 10px 20px;
    display: grid;
    grid-template-columns:auto;
    grid-template-rows: 40px 2.5fr 2.7fr;
    gap: 10px;
    background: #EEEEEE;
    /* background-color: #fff; */
    box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.26) ;
    -webkit-box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.26) ;
    -moz-box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.26) ;
    margin-bottom: 40px;
    cursor: pointer;
    transition: .4s;

    &:hover {
        transform: scale(1.04);
        transition: .4s;
    }
`;

const ImgPart = styled.div`
    position: relative;
    /* border: 1px solid red; */
    background-color: transparent;
    border: none;
    box-shadow: 0px 7px 7px -1px rgba(0,0,0,0.53);
    -webkit-box-shadow: 0px 7px 7px -1px rgba(0,0,0,0.53);
    -moz-box-shadow: 0px 7px 7px -1px rgba(0,0,0,0.53);
    border-radius: 2px;
`;
const Img = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 2px;
`;
const InfoPart = styled.div`
    position: relative;
    /* border: 1px solid red; */
    display: grid;
    grid-template-rows: 5fr 40px;
`;

const HeadingPart = styled.div`
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-family: 'open_san';
    color: #000;
    font-weight: bold;
    padding-left: 5px;
    letter-spacing: 1px;
    @media screen and (max-width: 658px) {
        font-size: 1rem;
    }
`;

const OtherPart =styled.div`
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;

`;

const Loc = styled.div`
    /* border: 1px solid red; */
    width: 100%;
    height: 25px;
    font-size: 13px;
    /* text-transform: u; */
    /* font-family: 'open_san'; */
    display: flex;
    align-items: center;
    color: gray;
    
`;
const Falt = styled.div`
    /* border: 1px solid red; */
    width: 100%;
    padding: 5px 0;
    font-size: 13px;
    text-transform: capitalize;
    color: #000;

    strong {
        color: green;
        text-transform: uppercase;
    }
`;

const BtnP = styled.div`
    /* position: absolute;
    bottom: 0;
    right: 0; */
    display: grid;
    justify-self: end;
    width: 120px;
    height: 35px;
    background-color: red;
    color: #fff;
    border: none;
    display: grid;
    place-content: center;
    border-radius: 1px;
        transition: .3s;

    &:hover {
        transform: scale(1.05);
        transition: .3s;
    }
`;

const ViewAllBox = ({allData, enquiry}) => {
    const [linkSt, setLinkSt] = useState(false);
    const setLink = (stLink) => {
        setLinkSt(stLink);
    }
    const test = () => {
        alert("nam");
    }
    return (
        <Section>       
                    {allData.map((item, index) => (
                        <BoxContainer to = {linkSt ? "#" : {
                            pathname: "/homestay/homestaydetails",
                            state: {dataContent: item.details}
                        }}>
                            <HeadingPart>{item.name}</HeadingPart>
                            <ImgPart>
                                <Img src = {item.image} />
                            </ImgPart>
                            <InfoPart>                            
                                <OtherPart>
                                    <Loc>
                                        <MdLocationOn 
                                            color = "red"
                                            size = "17px"
                                        />
                                        {item.location}
                                    </Loc>
                                    <Falt><strong>Facility</strong> : {item.facility}</Falt>
                                </OtherPart>
                                <BtnP onMouseEnter={() => setLink(true)} onClick={() => enquiry(item.name)} onMouseLeave={() => setLink(false)}>
                                    Enquiry Now
                                </BtnP>
                            </InfoPart>
                        </BoxContainer>

                    ))}                    
               </Section>
    )
}

export default ViewAllBox
