import React from 'react';
import styled from 'styled-components';
import { menuLinks } from '../data/MenuLinks';
import { Link } from 'react-router-dom';
import {FaTimes} from 'react-icons/fa';
import logo from '../media/logo.png';
const DropdownContainer = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 999;
    background: #fff;
    padding: 20px;
    top: 0;
    right: 0;
    transition: 0.3s ease-in-out;
    opacity: ${({isOpen}) => (isOpen ? '1' : '0')};
    right: ${({isOpen}) => (isOpen ? '0' : '-100%')};
`;
const Icon =styled.div`
    position: absolute;
    top: 10px;
    right: 35px;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
    outline: none;
`;
const CloseIcon = styled(FaTimes)`
    color: #D4AF37;
`;
const DropdownWrapper =styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 1.5fr 4.5fr;

`;
const DropdownMenu =styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;
`;
const DropdownLink = styled(Link)`
    color: #000;
    text-decoration : none;
    padding: 15px 0;
    font-size: 20px;
    font-family: 'open_san';
    letter-spacing: 1px;
`;

const LogoPart = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 2px solid #D4AF37;
`;
const Logoimg = styled.img`
    width: 230px;
    height: 80px;
`;


const DropDown = ({isOpen, toggle}) => {
    return (
        <DropdownContainer isOpen={isOpen} onClick={toggle}>
            <Icon onClick={toggle}>
                <CloseIcon />
            </Icon>
            <DropdownWrapper>
                <LogoPart>
                    <Logoimg src = {logo} />
                </LogoPart>
                <DropdownMenu>
                    {menuLinks.map((item,index) => (
                        <DropdownLink to={item.link} key={index}>
                            {item.title}
                        </DropdownLink>
                    ))}
                </DropdownMenu>
            </DropdownWrapper>
        </DropdownContainer>
    )
};


export default DropDown
