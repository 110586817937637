

import hs_1_1 from '../media/chikmagalur/homestay/Niraamaya/1.jpeg';
import hs_1_2 from '../media/chikmagalur/homestay/Niraamaya/2.jpeg';
import hs_1_3 from '../media/chikmagalur/homestay/Niraamaya/3.jpeg';
import hs_1_4 from '../media/chikmagalur/homestay/Niraamaya/4.jpeg';
import hs_1_5 from '../media/chikmagalur/homestay/Niraamaya/5.jpeg';
import hs_1_6 from '../media/chikmagalur/homestay/Niraamaya/6.jpeg';
import hs_1_7 from '../media/chikmagalur/homestay/Niraamaya/7.jpeg';
import hs_1_8 from '../media/chikmagalur/homestay/Niraamaya/8.jpeg';
import hs_1_9 from '../media/chikmagalur/homestay/Niraamaya/9.jpeg';
import hs_1_10 from '../media/chikmagalur/homestay/Niraamaya/10.jpeg';
import hs_1_11 from '../media/chikmagalur/homestay/Niraamaya/11.jpeg';
import hs_1_12 from '../media/chikmagalur/homestay/Niraamaya/12.jpeg';



import mullayangiri from '../media/chikmagalur/comm/mullayangiri.jpg';
import BhadraWildlifesanctury from '../media/chikmagalur/comm/BhadraWildlifesanctury.jpg';
import hirkolaleLake from '../media/chikmagalur/comm/hirkolaleLake.jpg';
export const ChikMagalurHomestay = [
    {
        code: "1",
        name: "Niraamaya Private Residence",
        location:"Chikmagalur.",
        image: hs_1_3,
        facility: "Fun Activities, Food, Hot Water, Awesome View, Extra Beds",
        details: {
            in_name: "Niraamaya Private Residence",
            in_location: "Chikmagalur.",
            in_desc: "The Niraamaya Private Residence, Blue Mountain is tucked into the mountain tops of western ghats at Chikmagalur. This is a place where you can retreat to a world of rustic surroundings. Set in 2000 acres of coffee plantation and located at an elevation of 1500m above sea level, the luxurious colonial bungalow was first owned by British planters. This estate was founded in 1865 and is infused with colonial architecture and has a luxuriant canopy of tropical trees like Red Cider, Rosewood, Silver Oak and Ficus varieties. Our estate bungalow features three bedrooms, a dining area, a living room and 360-degree views of the mountain. It is a pleasant four and a half-hour drive from Bangalore and 30 km north of the main town of Chikmagalur. This Classic Estate Bungalow also offers a new-age experience in the lap of nature and is the perfect getaway from the bustle of the city. The travellers visiting are bound to savour old world charm, breath-taking mountains, cascading waterfalls and exciting coffee trails. You can relish authentic traditional cuisine and get an opportunity to learn recipes that have been passed through generations in a time-honoured tradition and collected over years of research. We also offer our signature Niraamaya experience which is a personalized butler and a private chef who are available to take care of every need. ",
            in_inTime: "11:00 AM",
            in_outTime: "11:00 AM",
            images: [
                {
                    in_img: hs_1_1
                },
                {
                    in_img: hs_1_2
                },
                {
                    in_img: hs_1_3
                },
                {
                    in_img: hs_1_4
                },
                {
                    in_img: hs_1_5
                },
                {
                    in_img: hs_1_6
                },
                {
                    in_img: hs_1_7
                },
                {
                    in_img: hs_1_8
                },
                {
                    in_img: hs_1_9
                },
                {
                    in_img: hs_1_10
                },
                {
                    in_img: hs_1_11
                },
                {
                    in_img: hs_1_12
                },
            ],
            in_atv: [
                {
                    act: "Hiking"
                }
            ],
            in_visit: [
                {
                    vi_img: mullayangiri,
                    vi_text: "Mullayanagiri"
                },
                {
                    vi_img: BhadraWildlifesanctury,
                    vi_text: "Bhadra Wildlife Sanctuary"
                },
                {
                    vi_img: hirkolaleLake,
                    vi_text: "Hirekolale Lake"
                }
            ]
        }
    },
]