import React from 'react';
import styled from 'styled-components';
import { ImLocation } from 'react-icons/im';
import { AiFillSafetyCertificate } from 'react-icons/ai';
import demo from '../../media/homepage/h7.jpg'

const Section = styled.section`
    width: 100vw;
    height: 100%;
    padding: 0 100px 50px;
    /* background-color: #DDDDDD; */
    @media screen and (max-width: 768px) {  
        padding: 0 50px 50px;
    }
    @media screen and (max-width: 668px) {  
        padding: 0 30px 50px;
    }
    
`;

const HeadingPart = styled.div`
    /* border: 1px solid red; */
    padding: 40px 0;
    display: grid;
    grid-template-columns: 6fr auto;
    @media screen and (max-width: 668px) {  
        grid-template-columns: 6fr;
    }
`;
const Heading = styled.div`
    /* border: 1px solid red; */
    font-size: 4rem;
    font-family: 'open_san';
    font-weight: bold;    
    color: #2B2B2B;
    @media screen and (max-width: 1024px) {
        font-size: 3rem;
    } 
    @media screen and (max-width: 768px) {
        font-size: 2.5rem;
    }
    @media screen and (max-width: 668px) {
        font-size: 1.5rem;
    }
`;
const BtnPart = styled.div`
    /* padding: 10px 30px; */
    /* border: black solid 1px; */
    display: grid;
    place-content: center;
    @media screen and (max-width: 1024px) {  
        padding: 5px;
    }
    @media screen and (max-width: 668px) {  
        grid-row: 3;
        margin-top: 20px;
        
    }
`;
const BtnBox = styled.div` 
    width: 75px;
    height: 75px;
    background: red;
    border-radius: 50%;
    display: grid;
    place-content: center;
    /* align-self: start; */
    cursor: pointer;
    box-shadow: 0px 0px 3px 3px rgba(66, 63, 62,0.30);
    -webkit-box-shadow: 0px 0px 3px 3px rgba(66, 63, 62,0.30);
    -moz-box-shadow: 0px 0px 3px 3px rgba(66, 63, 62,0.30);
    /* z-index: 999; */
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    transition: .4s;

    &:hover {
        transform: scale(1.2);
        transition: .4s;
    }
    /* @media screen and (max-width: 668px) {  
        font-size: 22px;  
        height: 50px ;
    } */
`;

const LocPart = styled.div`
    /* border: 1px solid red; */
    display: flex;
    align-items: center;
    /* padding-left: 10px; */
    
`;
const LocText = styled.div`
    margin-left: 10px;
    color: gray;
    @media screen and (max-width: 668px) {  
        font-size: 14px;  
    }
`;

const DecPart = styled.div`
    padding: 20px;
    background-color: #fafafa;
    /* background: #FFF8E3; */
    /* background-color: #D4AF37; */
    /* border-radius: 5px; */
    box-shadow: 0px 3px 6px -2px rgba(0,0,0,0.63);
    -webkit-box-shadow: 0px 3px 6px -2px rgba(0,0,0,0.63);
    -moz-box-shadow: 0px 3px 6px -2px rgba(0,0,0,0.63);
    margin-bottom: 50px;
    p {
        /* color: #D4AF37; */
        color: #456268;
        /* color: #fff; */
        font-family: 'buda';
        font-size: 20px;
        text-align: justify;
        @media screen and (max-width: 668px) {  
            font-size: 17px;  
        }
        
    }
`;

const ActPart = styled.div`
    display: grid;
    grid-template-rows: auto auto;
    gap: 20px;
`;
const ActHeading =styled.div`
    padding: 10px;
    font-family: 'open_san';
    text-transform: uppercase;
    display: grid;
    justify-content: center;
    h3 {
        border-bottom: 3px solid #D4AF37;
        width: 200px;
        height: 100%;
        display: grid;
        place-content: center;
    }
`;
const ActInfo =styled.div`
    ul {
        /* list-style: none;
        padding: 0;
        text-align: center; */
        li {
            /* border: 1px solid red; */
            margin-bottom: 10px;
            /* height: 30px; */
            /* background-color: lightyellow; */
        }
    }
`;
const PlcPart =styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 15px;
    margin-top: 40px;
    @media screen and (max-width: 1304px) {  
        grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 768px) {  
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 668px) {  
        grid-template-columns: repeat(1, 1fr);
    }
`;
const PlcBox = styled.div`    
    height: 200px;
    width: 250px;
    margin: 0 auto;
    display: grid;
    grid-template-rows: auto 50px;
    @media screen and (max-width: 668px) {  
        width: 200px;
    }
`;
const PlImg =styled.div`
    display: grid;
    place-content: center;
`;
const PlText =styled.div`
    display: grid;
    place-content: center;
    text-transform: capitalize;
    text-align: center;
`;
const PlImgCon = styled.img`
    /* border: gray 3px solid; */
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
    padding: 4px;
    box-shadow: 0px 0px 4px 4px rgba(0,0,0,0.59) inset;
    -webkit-box-shadow: 0px 0px 4px 4px rgba(0,0,0,0.59) inset;
    -moz-box-shadow: 0px 0px 4px 4px rgba(0,0,0,0.59) inset;
    cursor: none;

    /* &:hover {
        transform: scale(1.2);
    } */

`;

const OvrPart = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    justify-content: center;
    gap: 40px;
    padding: 30px 0;
    @media screen and (max-width: 668px) {  
        grid-template-columns: auto;
    }

`;
const OvrBox = styled.div`
    width: 230px;
    background-color: orange;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    font-family: 'buda';
    font-weight: bold;
    color: #fff;
    @media screen and (max-width: 668px) {  
        width: 200px;
        font-size: 13px;
    }
`;
const OvrKey = styled.div`
    text-transform: uppercase;
    margin-right: 10px;

`;
const OvrVal = styled.div``;
const FrPart = styled.div`
    border: 3px solid #D4AF37;
    padding: 20px;
    display: flex;
    justify-content: center;
    
`;
const FrBox = styled.div`
    width: 140px;
    height: 140px;
    display: grid;
    grid-template-rows: auto 30px;
`;
const Frimg = styled.div`
    display: grid;
    place-content: center;
`;
const FrText = styled.div`
    font-family: 'open_san';
    text-align: center;
    font-weight: bold;
    color: gray;
`;

const StayDetails = ({detailsData, enquiry}) => {
    return (
        <Section>
            <HeadingPart>
                <Heading>{detailsData.in_name}</Heading>
                <BtnPart>
                    <BtnBox onClick={() => enquiry(detailsData.in_name)}>
                        Enquiry
                    </BtnBox>
                </BtnPart>
                <LocPart>
                    <ImLocation color='red'/>
                    <LocText>{detailsData.in_location}</LocText>
                </LocPart>
            </HeadingPart>
            <DecPart>
                <p>
                    {detailsData.in_desc}
                </p>
            </DecPart>            
            {detailsData.in_atv.length > 0 &&
                <DecPart>
                    <ActPart>
                        <ActHeading>
                            <h3>Activities</h3>
                        </ActHeading>
                            <ActInfo>
                                <ul>
                                    {detailsData.in_atv.map((item, index) => (
                                        <li>{item.act}</li>
                                    ))}                            
                                </ul>
                            </ActInfo>
                    </ActPart>
                </DecPart>
            }
            <DecPart>
                <ActPart>
                    {/* <ActHeading>
                        <h3>Overview</h3>
                    </ActHeading>
                    <OvrPart>
                        <OvrBox>
                            <OvrKey>Check-in Time : </OvrKey>
                            <OvrVal>{detailsData.in_inTime}</OvrVal>
                        </OvrBox>
                        <OvrBox>
                            <OvrKey>Check-out Time : </OvrKey>
                            <OvrVal>{detailsData.in_outTime}</OvrVal>
                        </OvrBox>
                    </OvrPart> */}
                    <FrPart>
                        <FrBox>
                            <Frimg>
                                <AiFillSafetyCertificate 
                                    fontSize = '80px'
                                    color = "green"
                                />
                            </Frimg>
                            <FrText>
                                Travelpro Holiday Safe
                            </FrText>
                        </FrBox>
                    </FrPart>
                </ActPart>
            </DecPart>       
            <DecPart>
                <ActPart>
                    <ActHeading>
                        <h3>Places to Visit</h3>
                    </ActHeading>
                    <PlcPart>
                        {detailsData.in_visit.map((item, index) => (
                            <PlcBox>
                                <PlImg>
                                    <PlImgCon src = {item.vi_img}/>
                                </PlImg>
                                <PlText>{item.vi_text}</PlText>
                            </PlcBox>
                        ))}  
                    </PlcPart>
                </ActPart>
            </DecPart>

        </Section>
    )
}

export default StayDetails
