import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import h4 from '../../media/homepage/h4.jpg';
import t1 from '../../media/t1.jpg';
import t2 from '../../media/t2.jpg';

const Section = styled.section`
    width: 100vw;
    height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1024px) {
        height: 100vh;
    }
`;
const TripContainer =styled.div`
    width: 80%;
    height: 85%;
    background: #D4AF37;

    display: grid;
    grid-template-columns: 2fr 2fr 2fr;
    padding: 60px 95px;
    gap: 60px;

    @media screen and (max-width: 1024px) {
        grid-template-columns: auto;
        grid-template-rows: 2fr 2fr 2fr;
        gap: 60px;
    }
    @media screen and (max-width: 650px) {
        width: 80%;
        height: 90%;
        padding: 40px 45px;
        gap: 40px;
    }
`;

const ImgContainer = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
`;
const ImgNo = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const Singleimg = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
`;

const TripBox = styled(Link)`
    position: relative;
    background: #fff;
    margin: ${({boxaction}) => (boxaction ? '20px 0' : '0px 0')};
    box-shadow: 0px 7px 7px -2px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 7px 7px -2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 7px 7px -2px rgba(0,0,0,0.75);
    cursor: pointer;

    @media screen and (max-width: 1024px) {
        margin: 0;
    }
`;

const DarkBox = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    transition: .6s;

    h3 {
        color: #fff;
        margin-bottom: 30px;
        text-align: center;
        font-family: 'encode';
        font-weight: bold;   
        font-size: 22px;
        letter-spacing: 2px;
        /* text-transform: uppercase; */
        @media screen and (max-width: 1024px) {
            font-size: 18px;
        }
    }
    h5 {
        color: #fff;
         
        font-size: 18px;
        letter-spacing: 2px;
        display: none;
        /* text-transform: uppercase; */
    }
    &:hover {
        background: rgba(0,0,0,0.6);
        justify-content: center;
        transition: .6s;

        h5{
            display: block;
        }
    }
`;

const ColorBox = styled.div`
    width: 100%;
    height: 100%;
    background:${({colorb}) => (colorb ? '#9DDAC6' : '#C2FFD9')}; ;
`;

const TripPage = () => {
    return (
        <>
            <Section>
                <TripContainer>
                    <TripBox to = "/packages" data-aos = "slide-left" boxaction = {true}>
                        <Singleimg src = {t1} />
                        <DarkBox>
                            <h3>Packages</h3>
                        </DarkBox>
                    </TripBox>
                    <TripBox to = "/sakleshpur" data-aos = "fade" boxaction = {false}>
                        <Singleimg src = {h4} />
                        <DarkBox>
                            <h3>Sakleshpur</h3>
                        </DarkBox>
                    </TripBox>
                    <TripBox to = "/"  data-aos = "slide-right" boxaction = {true}>
                        <Singleimg src = {t2} />
                        <DarkBox>
                            <h3>Travelpro Holiday Projects</h3>
                            <h5>Coming Soon</h5>
                        </DarkBox>
                    </TripBox>
                </TripContainer>
            </Section>
        </>
    )
};


export default TripPage
