import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import img from '../../media/package/pak2.jpg';

const Section = styled.section`
    width: 100vw;
    text-decoration: none;
`;
const PackageContainer = styled.div`
    width: 100%;
    padding: 50px;
    display: grid;
    grid-template-columns: 2fr 2fr 2fr;
    gap: 50px;
    @media screen and (max-width: 1024px) {
        grid-template-columns: auto;
    }
`;
const Box = styled(Link)`
    height: 350px;
    /* border: 5px solid #9d8022; */
    background: #EEEEEE;
    display: grid;
    grid-template-rows: 1.7fr 3.3fr 1fr;
    padding: 0 30px;
    box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.26) ;
    -webkit-box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.26) ;
    -moz-box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.26) ;
    transition: .4s;
    cursor: pointer;
    text-decoration: none;
    color: #000;
    &:hover {
        transform: scale(1.03);
        transition: .4s;
    }
    @media screen and (max-width: 658px) {
        grid-template-rows: 1fr 3fr 2fr;
    }
`;

const HeadingPart = styled.div`
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    font-family: 'open_san';
    color: #000;
    font-weight: bold;
    @media screen and (max-width: 658px) {
        font-size: 1rem;
    }
`;
const ImagePart = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    box-shadow: 0px 7px 7px -1px rgba(0,0,0,0.53);
    -webkit-box-shadow: 0px 7px 7px -1px rgba(0,0,0,0.53);
    -moz-box-shadow: 0px 7px 7px -1px rgba(0,0,0,0.53);
`;
const BtnPart = styled.div`
    display: grid;
    grid-template-columns: 2.5fr 3.5fr;
    @media screen and (max-width: 658px) {
        grid-template-columns: auto;
    }
`;
const ImgPart = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const BPart = styled.div`
    display: flex;
    align-items: flex-end;
    padding-bottom: 10px;
`;
const DPart = styled.div`
    display: grid;
    grid-template-columns: 3fr auto;
    align-items: center;
    justify-content: end;
    gap: 20px;
`;
const Price = styled.div`
    height: 100%;
    display: none;
    h5 {
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 23px;
        @media screen and (max-width: 658px) {
            font-size: 19px;
            justify-content: flex-start;
        }
    }
`;
const Btn = styled.div`
    text-align:end;
    button {
        padding: 5px 15px;
        background: red;
        border: none;
        color: #fff;
        transition: .3s;
        @media screen and (max-width: 658px) {
            font-size: 14px;
        }
        &:hover {
            transform: scale(1.05);
            transition: .3s;
        }
    }
`;

const PackageBox = ({packages, enquiry}) => {
    const [linkSt, setLinkSt] = useState(false);
    const setLink = (stLink) => {
        setLinkSt(stLink);
    }
    return (
        <Section>
            <PackageContainer>     
                {packages.map((item, index) => (
                    <Box to = {linkSt ? "#" : {
                            pathname: "/packages/packagedetails",
                            state: {packageIt: item.itineary}
                        }}>
                        <HeadingPart>{item.ptitle}</HeadingPart>
                        <ImagePart>
                            <ImgPart src = {item.pimg}/>
                        </ImagePart>
                        <BtnPart>
                            <BPart>
                                {item.pdays} | {item.pfrom}
                            </BPart> 
                            <DPart>
                                <Price>
                                    <h5>{item.pprice}</h5>
                                </Price>
                                <Btn>
                                    <button onMouseEnter={() => setLink(true)} onClick={() => enquiry(item.ptitle)} onMouseLeave={() => setLink(false)}>
                                        Enquiry Now
                                    </button>
                                </Btn>
                            </DPart> 
                        </BtnPart>
                    </Box>
                ))}         
                
            </PackageContainer>
        </Section>
    )
}

export default PackageBox
