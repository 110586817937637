import React from 'react';
import styled, {css} from 'styled-components/macro';
const Section = styled.section`
    width: 100vw;
    height: 100%;
    padding: 100px;
    @media screen and (max-width: 1024px) {  
        height: 100%;
    }
    @media screen and (max-width: 650px) {  
        padding: 50px 25px;
    }
`;

const SectionContainer = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 3fr 3fr;
    gap: 100px;
    @media screen and (max-width: 1024px) {  
        grid-template-columns: auto;
        grid-template-rows: auto auto;
    }
`;

const PartCss = css`
    /* border-radius: 10px; */
    background: rgba(255,255,255,1);

`;
const PartOne = styled.div`
    ${PartCss};
    display: grid;
    grid-template-rows: 1.5fr 4.5fr;
    @media screen and (max-width: 1024px) {  
        grid-template-rows: 1fr 5fr;
        padding-top: 30px;
    }

`;

const PartHeading = styled.div`
    display: grid;
    place-content: center;
    color: #D4AF37;
    font-size: 25px;
    font-weight: bold;
    @media screen and (max-width: 1024px) {  
        font-size: 22px;
        /* padding: 30px; */
    }
    @media screen and (max-width: 650px) {  
        font-size: 17px;
        text-align: center;
    }
`;

const PartDesc = styled.div`
    padding: 30px 50px;
    text-indent: 50px;
    text-align: justify;
    font-family: 'buda';
    font-size: 20px;
    color: #D4AF37;
    @media screen and (max-width: 1024px) {  
        padding: 25px 35px;
        text-indent: 30px;
        font-size: 18px;
    }
`;

const BlockPage2 = () => {
    return (
        <>
            <Section>
                <SectionContainer>
                    <PartOne  data-aos="flip-up">
                        <PartHeading>TRAVELPRO HOLIDAY AS A DMC</PartHeading>
                        <PartDesc>
                            Travelpro Holiday is a DMC- Destination Management Company because it
                            provides ground handling services for their destination that can include translation,
                            airport meet and greet, transfers, car rentals and other transportation services,
                            hotel and restaurant reservations, tours conference venue and all tourism related
                            logistics being the ideal tool for the successful planning and realization of events
                            for MICE.
                        </PartDesc>
                    </PartOne>
                    <PartOne  data-aos="flip-up">
                        <PartHeading>WHY CHOOSE TRAVELPRO HOLIDAY</PartHeading>
                        <PartDesc>
                            We are most proud however of the fact that we are personal. Unlike many travel
                            Company, we don't expect you to just choose products out of a database yourself
                            without any advice. Travelpro Holiday provides you with the services of a
                            professional travel consultant who is right here to advice you. Your consultant will
                            be able to assist you by making recommendations and giving you more
                            background information to help you make your booking. But the amazing thing is
                            that even though you get this great service, it doesn’t cost you any more! We want
                            to give you the best service AND the best price.
                        </PartDesc>

                    </PartOne>
                </SectionContainer>
            </Section>
        </>
    )
}

export default BlockPage2
