import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
    width: 100vw;
    height: 100%;
    padding: 80px;
    background: #fff;
    @media screen and (max-width: 1024px) {        
        /* height: 60vh;         */
        height: 100%;
    }
    @media screen and (max-width: 768px) {        
        height: 100%;
        padding: 50px;
    }
    @media screen and (max-width: 668px) {       
        padding: 50px 25px;
    }
`;

const SectionContainer = styled.div`
    width: 100%;
    height: 100%;
    background: #D4AF37;    
    display: grid;
    grid-template-rows: 1.5fr 4.5fr;
    /* border-radius: 10px; */
    @media screen and (max-width: 668px) {  
        grid-template-rows: 0.8fr 5fr;
    }
`;
const HeadingBox = styled.div`
    display: grid;
    place-content: center;
`;
const Heading = styled.div`
    font-size: 40px;
    color: #fff;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    @media screen and (max-width: 668px) {  
        font-size: 30px;
    }
`;
const HeadingLine = styled.div`
    width: 90px;
    /* border-top: 4px solid black; */
    margin: 0 auto;
`;
const DescBox = styled.div`
    padding: 0 80px 80px;
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: center;
    
    @media screen and (max-width: 768px) {  
        padding: 0 30px 30px;
    }
`;
const ParaBox = styled.p`
    text-indent: 50px;
    text-align: justify;
    letter-spacing: 2px;
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */    
    font-family: 'buda';
    color: #fff;
    font-size: 20px;
    margin-bottom: 30px;
    @media screen and (max-width: 668px) {
        font-size: 18px;
        margin-bottom: 20px;

    }
`;

const CapsWord = styled.strong`
    font-family: 'buda';
    color: #fff;
    font-size: 28px;
    line-height: 1px;
    /* border: 1px solid red; */
`;

const WhoWe = () => {
    return (
        <Section>
            <SectionContainer data-aos="fade-up">
                <HeadingBox>
                    <Heading>
                        Who We Are
                    </Heading>
                    {/* <HeadingLine>

                    </HeadingLine> */}
                </HeadingBox>
                <DescBox>
                    <ParaBox>
                        <CapsWord>W</CapsWord>e are a team of tour operators and hotel management specialists involved in benefacting
                        extravagant Holiday experience and Destination promotion. We have a focus on business
                        drive revenue generation for budding superior class hotels and resorts. Our focus on luxury
                        packages is to provide world class service to our clientele with no compromise on service and
                        lifetime treasured memories. Our altruistic effort to Promote Sakleshpur, a serene paradise on
                        earth as a popular destination is the idea where we gear up as TravelProHoliday. Our team is
                        passionate about creation of best inbound travel experience.
                    </ParaBox>
                    <ParaBox>
                    Besides the must-do destinations in India Abroad we have a plethora of options
                    on offer - Cultural and Heritage tours; Beach Holidays with a decidedly Indian sun,
                    sand and sea experience; Adventure Holidays that include white water rafting,
                    paragliding, mountaineering and trekking; Wellness and Spa Holidays; Golf
                    Holidays; Wildlife Holidays; Festival Vacations; Buddhist trail; Temple Tours - the
                    list is long and you can have it on demand.
                    </ParaBox>
                </DescBox>
            </SectionContainer>
        </Section>
    )
}

export default WhoWe
