import React, {useEffect} from 'react';
import { Route, Switch } from 'react-router-dom';
import GlobleStyle from "./globelStyle";
import Home from "./pages/Home";
import Aos from 'aos';
import 'aos/dist/aos.css';
import Packages from './pages/Packages';
import StatePage from './component/packages/StatePage';
import { KarPackage } from './data/KarPackage';
import PackageInnBox from './component/packages/PackageInnBox';
import About from './pages/About';
import Sakleshpur from './pages/Sakleshpur';
import SlideUp from './component/SlideUp';
import ViewAllPage from './component/stayhandler/ViewAllPage';
import HomestayMaster from './component/stayhandler/HomestayMaster';
import Chikmagalur from './pages/Chikmagalur';
import { HomeStayA } from './data/HomeStayA';
import TripId from './pages/TripId';
import homestaytitleimg from './media/homestay/home1.jpg';
import resorttitle from './media/resort/resorttitle.jpg';
import { Sakresort } from './data/Sakresort';
import { RajasthanPackage } from './data/RajasthanPackage';
import { SevenSistersPackage } from './data/SevenSistersPackage';
import Coorg from './pages/Coorg';
import { AdventurePackage } from './data/AdventurePackage';
import { PilgrimagePackage } from './data/PilgrimagePackage';
import { JammuPackage } from './data/JammuPackage';
import { LehPackage } from './data/LehPackage';
import { MultiStatePackage } from './data/MultiStatePackage';
import { ChikMagalurHomestay } from './data/ChikmangalurHomestay';
import { CoorgResort } from './data/CoorgResort';

function App() {

  useEffect(() => {
      window.scroll(0,0);
      Aos.init({ duration: 2000 });
  },[]);
  return (
    <>
      <GlobleStyle />
      <SlideUp />
      <Switch>
        <Route exact path = "/" component = {Home} />
        <Route exact path = "/packages" component = {Packages} />
        <Route exact path = "/about" component = {About} />
        <Route exact path = "/sakleshpur" component = {Sakleshpur} />
        <Route exact path = "/coorg" component = {Coorg} />
        <Route exact path = "/coorg/resorts" render = {() => <ViewAllPage allData = {CoorgResort} vl_img={resorttitle} vl_text = "Resorts" />}/>
        <Route exact path = "/tripideas" component = {TripId} />
        <Route exact path = "/tripideas/adventure" render = {() => <StatePage packarr = {AdventurePackage}/>}/>
        <Route exact path = "/tripideas/pilgrimage" render = {() => <StatePage packarr = {PilgrimagePackage}/>}/>
        <Route exact path = "/chikmagalur" component = {Chikmagalur} />
        <Route exact path = "/chikmagalur/homestay" render = {() => <ViewAllPage allData = {ChikMagalurHomestay} vl_img={homestaytitleimg} vl_text = "HomeStay" />}/>
        {/* <Route exact path = "/chikmagalur/resorts" render = {() => <ViewAllPage allData = {Sakresort} vl_img={resorttitle} vl_text = "Resorts" />}/> */}
        <Route exact path = "/packages/karnataka" render = {() => <StatePage packarr = {KarPackage}/>}/>
        <Route exact path = "/packages/rajasthan" render = {() => <StatePage packarr = {RajasthanPackage}/>}/>
        <Route exact path = "/packages/sevensisters" render = {() => <StatePage packarr = {SevenSistersPackage}/>}/>
        <Route exact path = "/packages/jammukashmir" render = {() => <StatePage packarr = {JammuPackage}/>}/>
        <Route exact path = "/packages/lehladakh" render = {() => <StatePage packarr = {LehPackage}/>}/>
        <Route exact path = "/packages/multistate" render = {() => <StatePage packarr = {MultiStatePackage}/>}/>
        <Route exact path = "/packages/packagedetails" render = {(props) => <PackageInnBox {...props}/>} />        
        <Route exact path = "/homestay" render = {() => <ViewAllPage allData = {HomeStayA} vl_img={homestaytitleimg} vl_text = "HomeStay" />}/>
        <Route exact path = "/resorts" render = {() => <ViewAllPage allData = {Sakresort} vl_img={resorttitle} vl_text = "Resorts" />}/>
        <Route exact path = "/homestay/homestaydetails" render = {(props) => <HomestayMaster {...props}/>} />   
      </Switch>
    </>
  );
}

export default App;
