import React from 'react';
import styled from 'styled-components';
import TravelProWord from './TravelProWord';
const Section = styled.section`
    width: 100vw;
    height: 100vh;    
`;
const Hero = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    background: rgba(0,0,0,0.7);
    /* padding: 100px; */
    /* padding: 80px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const TravelproPage = () => {
    return (
        <>
            <Section>
                <Hero>
                    <TravelProWord lText = "" cText = "T" rText = "Trust" loc = "1" />
                    <TravelProWord lText = "Reliable" cText = "R" rText = ""loc = "0" />
                    <TravelProWord lText = "" cText = "A" rText = "Ambitious"loc = "1" />
                    <TravelProWord lText = "Value for Money" cText = "V" rText = ""loc = "0" />
                    <TravelProWord lText = "" cText = "E" rText = "Ethics"loc = "1" />
                    <TravelProWord lText = "Leadership" cText = "L" rText = ""loc = "0" />
                    <TravelProWord lText = "" cText = "P" rText = "Professional at Work"loc = "1" />
                    <TravelProWord lText = "Reachable And" cText = "R" rText = ""loc = "0" />
                    <TravelProWord lText = "" cText = "O" rText = "Organized"loc = "1" />
                </Hero>
            </Section>
        </>
    )
}

export default TravelproPage
