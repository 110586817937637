import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
    width: 100vw;
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    @media screen and (max-width: 1024px) {
        height: 40vh;
    }
    @media screen and (max-width: 768px) {
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding: 50px 0;
    }
`;

const Box = styled.div`
    width: 250px;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 668px) {
        width: 170px;
        height: 170px;
    }
`;
const SvgPart = styled.div`
    display: grid;
    place-content: center;
    background: #D4AF37;
    width: 170px;
    height: 170px;
    border-radius: 5px;
    cursor: pointer;
    @media screen and (max-width: 668px) {
        width: 130px;
        height: 130px;
    }
`;
const BoxSvg = styled.svg`
    width: 60px;
    height: 60px;
    fill: #fff;
    /* @media screen and (max-width: 668px) {
        width: 50px;
        height: 50px;
    } */
`;
const HeadPart = styled.div`
    padding: 5px 15px;
    font-size: 30px;
    font-family: 'open_san';
    letter-spacing: 3px;
    color: #D4AF37;
    font-weight: bold;
`;

const ButtonPart = () => {
    return (
        <Section>
            <Box data-aos="slide-up">
                <SvgPart>                    
                    <BoxSvg id="Layer_1" data-name="Layer 1" viewBox="0 0 122.88 115.63">
                        {/* <title>hut</title> */}
                        <path d="M19.86,63.7a3.33,3.33,0,0,1,0,.63l-7.12,45.16H42.52V87.16a18.26,18.26,0,0,1,36.52,0v22.33h30.85L103.67,64.2a4.07,4.07,0,0,1,0-.5Zm28.8,45.79H72.9V87.16a12.12,12.12,0,0,0-24.24,0v22.33ZM109.78,63.7l6.62,48.21a2.81,2.81,0,0,1,.07.65,3.06,3.06,0,0,1-3.07,3.07H9.13a2.49,2.49,0,0,1-.47,0,3.07,3.07,0,0,1-2.56-3.49L13.73,63.7H0A138.81,138.81,0,0,1,42.74,11.16c19.92-14.89,18.4-14.9,37.91.09A141.25,141.25,0,0,1,122.88,63.7Z"/>
                    </BoxSvg>
                </SvgPart>
                <HeadPart>Home Stay</HeadPart>
            </Box>
            <Box data-aos="slide-up">
                <SvgPart>  
                    <BoxSvg enable-background="new 0 0 20 20" height="48px" viewBox="0 0 20 20" width="48px" fill="#FFFFFF">
                        <g><rect fill="none" height="20" width="20" x="0"/></g><g><g><polygon points="4.37,10 3.38,10.18 4,13.5 4,17 5,17 5,14 7,14 7,17 8,17 8,13 4.92,13"/><polygon points="15.08,13 12,13 12,17 13,17 13,14 15,14 15,17 16,17 16,13.5 16.62,10.18 15.63,10"/><path d="M10,3L3,8h6.5v9h1V8H17L10,3z M6.12,7L10,4.23L13.88,7H6.12z"/></g></g>                            
                    </BoxSvg>
                </SvgPart>
                <HeadPart>Resorts</HeadPart>
            </Box>
            <Box data-aos="slide-up"> 
                <SvgPart>    
                    <BoxSvg enable-background="new 0 0 20 20" height="48px" viewBox="0 0 20 20" width="48px" fill="#FFFFFF">
                        <rect fill="none" height="20" width="20"/>
                        <path d="M9.25,3.75C9.25,2.78,10.03,2,11,2s1.75,0.78,1.75,1.75c0,0.97-0.79,1.75-1.75,1.75C10.04,5.5,9.25,4.72,9.25,3.75z M4.9,10.75l1.64,0.32l0.96-4.91L6.84,6.03C6.03,5.87,5.24,6.4,5.08,7.21l-0.57,2.94C4.46,10.43,4.63,10.69,4.9,10.75z M14,18h1V8 h-1l0,1.41c-2.11-0.42-2.13-2.19-3.11-2.97c-0.21-0.17-0.45-0.29-0.7-0.36c-0.81-0.23-1.7,0.27-1.88,1.16C8.15,8.06,6.2,18,6.2,18 h1.53l1.19-6.01l1.58,1.58V18H12v-5.05l-1.46-1.46l0.49-2.48c0.72,0.95,1.76,1.64,2.97,1.89L14,18z"/>
                    </BoxSvg>
                </SvgPart>
                <HeadPart>Trekking</HeadPart>
            </Box>
        </Section>
    )
}

export default ButtonPart
