import React, {useEffect, useState} from 'react';
import DropDown from '../component/DropDown';
import Navbargap from '../component/Navbargap';
import NavbarNormal from '../component/NavbarNormal';
import heading from '../media/chikmagalur/heading.jpg';
import TitlePart from '../component/sakleshpur/TitlePart';
import { TextChi } from '../component/sakleshpur/TextSa';
import { ChikMagalurHomestay } from '../data/ChikmangalurHomestay';
import ProductPage from '../component/ProductSlider/ProductPage';
import FooterSection from '../component/FooterSection';
import EnqForm from '../component/submitForm/EnqForm';
import SendMsg from '../component/submitForm/SendMsg';


const Chikmagalur = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [enOpen, setEnOpen] = useState(false);
    const [sendOpen, setSendOpen] = useState(false);
    const [enqyFor, setEnqyFor] = useState("");

    const toggle = () => {
        setIsOpen(!isOpen);
    }
    const enquiry = (enqData) => {
        setEnOpen(!enOpen);
        setEnqyFor(enqData);
    }
    const sendBox = () => {
        setSendOpen(!sendOpen);
    }
    useEffect(() => {
        window.scroll(0,0);
    },[]);
    return (
        <>
            <NavbarNormal toggle = {toggle}/>
            <DropDown isOpen = {isOpen} toggle = {toggle}/>
            <Navbargap />
            <TitlePart img = {heading} textData = {TextChi}/>
            <ProductPage enquiry = {enquiry} arraybox = {ChikMagalurHomestay} boxname = {'HomeStays'} link ={"/chikmagalur/homestay"}/>
            <FooterSection enquiry = {enquiry}/>
            <EnqForm enForm = {enOpen} enFor = {enqyFor} enquiry = {enquiry} sendBox ={sendBox}/>
            <SendMsg dispSub = {sendOpen} sendBox ={sendBox}/>
        </>
    )
}

export default Chikmagalur
