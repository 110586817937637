import React, {useEffect, useState, useRef} from 'react';
import styled from 'styled-components';
import ImgSliderA from '../ImgSliderA';
import Navbargap from '../Navbargap';
import { AiFillCaretDown } from 'react-icons/ai';
import FooterSection from '../FooterSection';
import NavbarNormal from '../NavbarNormal';
import DropDown from '../DropDown';
import EnqForm from '../submitForm/EnqForm';
import SendMsg from '../submitForm/SendMsg';
import FloatEnq from '../submitForm/FloatEnq';

const Section = styled.section`
    width: 100vw;
    height: 100%;
`;
const Details = styled.div`
    width: 100%;
    height: 100%;
    padding: 0 30px;
    @media screen and (max-width: 768px) {
        padding: 0 30px;
    }
`;

const PackDetails = styled.div`
    position: sticky;
    top: 0;
    margin: 0;
    width: 100%;
    height: 100%;
    padding: 50px;
    display: grid;
    grid-template-columns: 5fr 100px;    
    
    @media screen and (max-width: 1024px) {
        grid-template-columns: auto;
        grid-template-rows : auto auto ;
    }
    @media screen and (max-width: 768px) {
        padding: 20px;
    }
    @media screen and (max-width: 668px) {
        padding: 10px;
    }
`;

const PackHeading = styled.div`
    padding: 30px 0;
`;
const Heading = styled.div`
    font-size: 4rem;
    font-family: 'open_san';
    font-weight: bold;    
    @media screen and (max-width: 1024px) {
        font-size: 3rem;
    } 
    @media screen and (max-width: 768px) {
        font-size: 2.5rem;
    }
    @media screen and (max-width: 668px) {
        font-size: 1.5rem;
    }
`;
const Price = styled.div`
    font-size: 1.5rem;
    @media screen and (max-width: 668px) {
        font-size: 1rem;
    }
`;

const EnqBtn = styled.div`
    /* padding: 30px; */
    display: grid;
`;
const EnqCon = styled.div`
    /* border: 4px solid #D4AF37 ; */
    /* position: sticky;
    top: 0; */
    display: grid;
    /* grid-template-rows: auto 60px; */
    padding: 40px;
    text-align:center;
    background: #fff;
    align-items: center;
    h3 {
        display: none;
    }
`;
const Ebtn =styled.div`
    /* border: 1px solid black; */
    width: 75px;
    height: 75px;
    background: red;
    border-radius: 50%;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    display: grid;
    place-content: center;
    align-self: start;
    cursor: pointer;
    box-shadow: 0px 0px 3px 3px rgba(66, 63, 62,0.30);
    -webkit-box-shadow: 0px 0px 3px 3px rgba(66, 63, 62,0.30);
    -moz-box-shadow: 0px 0px 3px 3px rgba(66, 63, 62,0.30);
    /* z-index: 999; */
    color: #fff;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    transition: .4s;

    &:hover {
        transform: scale(1.2);
        transition: .4s;
    }
`;
const DaySection = styled.div`
    width: 100%;
    height: 100%;
    padding: 50px;
    @media screen and (max-width: 768px) {
        padding: 20px;
    }
    @media screen and (max-width: 668px) {
        padding: 10px;
    }
`;
const DayContainer = styled.div`
    padding: 30px;
    display: grid;
    grid-template-rows: 50px auto;
    gap: 30px;
    /* border: 4px solid #D4AF37 ; */
    background: #FFF8E3;
`;
const DayHeading = styled.div`
    font-size: 28px;
    font-family: 'open_san';
    text-transform: uppercase;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
`;
const DayList = styled.div`
    display: grid;
    grid-template-rows: auto;
    gap: 30px;
    @media screen and (max-width: 668px) {
        gap: 20px;
    }
`;

const Day = styled.div`    
    border: 1px solid #D4AF37;
    border-radius: 30px;
    /* background: #D4AF37; */
    background: #fff;
    width: 100%;
    height: ${({BoxLen}) => (BoxLen ? '100%' : '60px')};
    overflow-y: hidden;
    box-shadow: 0px 5px 5px 0px rgba(175,166,166,0.75);
    -webkit-box-shadow: 0px 5px 5px 0px rgba(175,166,166,0.75);
    -moz-box-shadow: 0px 5px 5px 0px rgba(175,166,166,0.75);
    transition: .3s;
    @media screen and (max-width: 668px) {
        height: ${({BoxLen}) => (BoxLen ? '100%' : '40px')};
    }
`;

const DayBoxTitle = styled.div`    
    height: 60px;
    border-bottom: 1px solid #D4AF37;
    display: grid;
    grid-template-columns: 150px auto 60px;
    
    @media screen and (max-width: 768px) {
        grid-template-columns: 150px 50px;
        justify-content: space-between;
    }
    @media screen and (max-width: 668px) {
        grid-template-columns: 80px 50px; 
        height: 40px;
    }
`;
const DayNo = styled.div`
    display: grid;
    align-items: center;
    font-size: 24px;
    font-family: 'open_san';
    padding-left: 10px;
    @media screen and (max-width: 668px) {
        font-size: 20px;
    }

`;
const DayNoBox = styled.div`
    width: 100px;
    padding: 3px 0;
    border-radius: ${({RotBox}) => (RotBox ? '24px 0 0 0px' : '30px 0 0 30px')};
    display: grid;
    place-content: center;
    background: red;
    color: #fff;
    @media screen and (max-width: 668px) {
        padding: 0px 0;
    }
`;
const DayTitle = styled.div`
    display: flex;
    align-items: center;
    font-size: 24px;
    font-family: 'open_san';

    /* &::before {
        content: '';
        height: 60%;
        border-left: 2px solid #000;
        padding-right: 20px;
    } */
    
    @media screen and (max-width: 768px) {
        display: none;
    }
`;
const DayLogo = styled.div`
    display: grid;
    place-content: center;
    cursor: pointer;
    transform: ${({RotBox}) => (RotBox ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: .3s;
`;


const DayBoxDetails = styled.div`
    padding: 20px 40px;
    
    @media screen and (max-width: 768px) {
        padding: 20px 20px;
    }
    @media screen and (max-width: 668px) {
        padding: 10px 5px;
    }
`;

const DBTTitle = styled.div`
    height: 50px;
    font-size: 18px;
    padding: 10px 30px ;
    display: none;
    @media screen and (max-width: 768px) {
        display: block;
    }
    @media screen and (max-width: 668px) {
        padding: 10px 5px;
        height: 40px;
        font-size: 15px;
        /* padding: 10px 10px ; */
    }
`;
const DBTText = styled.div`
    padding: 10px 40px;
    color: grey;
    ul {
        li {
            text-align: justify;
        }
    }
    @media screen and (max-width: 768px) {
        padding: 10px 20px;
    }
    @media screen and (max-width: 668px) {
        padding: 10px 10px 0 0px;
        font-size: 13px;
    }
`;

const InfoSection = styled.div`
    width: 100%;
    height: 100%;
    padding: 50px;
    transition: .3s;
    @media screen and (max-width: 768px) {
        padding: 20px;
    }
    @media screen and (max-width: 668px) {
        padding: 10px;
    }
`;
const Infobox = styled.div`
    padding: 30px;
    display: grid;
    grid-template-rows: 50px auto;
    gap: 30px;
    /* border: 4px solid #D4AF37 ; */
    background: #FFF8E3;
    overflow-y: hidden;
    height: ${({BoxLen}) => (BoxLen == "1" ? '100%' : '100px')};
    margin-bottom: 50px;
    transition: .3s;
`;
const Infotitle = styled.div`
    position: relative;
    font-size: 28px;
    font-family: 'open_san';
    text-transform: uppercase;
    letter-spacing: 2px;
    display: flex;
    align-items: center;

`;
const Infotext = styled.div`
    padding: 10px 30px;
    color: grey;
    ul {
        li {
            text-align: justify;
        }
    }
    @media screen and (max-width: 768px) {
        padding: 10px 20px;
    }
    @media screen and (max-width: 668px) {
        padding: 10px 10px 0 0px;
        font-size: 13px;
    }
`;

const InfoLogo = styled.div`
    position: absolute;
    top: 00px;
    right: 15px;
    cursor: pointer;
    transform: ${({RotBox}) => (RotBox == "1" ? 'rotate(180deg)' : 'rotate(0deg)')};
    transition: .3;
    @media screen and (max-width: 668px) {
        right: -15px;
    }
`;

const InfoTextSection = styled.div`
    color: grey;
    h4 {
        font-size: 18px;
        @media screen and (max-width: 668px) {
            font-size: 15px;
        }
    }
    p{
        font-size: 15px;
        text-align: justify;
        @media screen and (max-width: 668px) {
            font-size: 13px;
        }
        strong {
            font-size: 17px;
            color: red;
            text-transform: uppercase;
        }
    }
`;

const PackageInnBox = (props) => {
    let itn = props.location.state.packageIt;

    const [indexNo, setIndexNo] = useState(999);
    const [indexAct, setIndexAct] = useState(false);
    const [classVar, setClassVar] = useState("");

    const [p1, setP1] = useState("0");
    const [p2, setP2] = useState("0");
    const [p3, setP3] = useState("0");

    const box1 = (patt) => {
        if(patt == "1") {
            if(p1 == "1") {
                setP1("0");
            }else {
                setP1("1");
            }
        }else if(patt == "2") {
            if(p2 == "1") {
                setP2("0");
            }else {
                setP2("1");
            }
        }else if(patt == "3") {
            if(p3 == "1") {
                setP3("0");
            }else {
                setP3("1");
            }
        }
        
    }

    const displayBox = (id) => {
        var element = document.getElementById(id);
        setIndexNo(id);
        if(element.classList.contains('packDetails')) {
            setIndexAct(false);
            setClassVar("");
        }else {
            setIndexAct(true);
            setClassVar("packDetails");
        }

    }
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }
    useEffect(() => {
        window.scroll(0,0);
    },[]);
    const [enOpen, setEnOpen] = useState(false);
    const [sendOpen, setSendOpen] = useState(false);
    const [enqyFor, setEnqyFor] = useState("");

    const enquiry = (enqData) => {
        setEnOpen(!enOpen);
        setEnqyFor(enqData);
    }
    const sendBox = () => {
        setSendOpen(!sendOpen);
    }
    return (
        <Section>
            <FloatEnq enqTitle = {itn.ititle} enquiry = {enquiry}/>
            <NavbarNormal toggle = {toggle}/>
            <DropDown isOpen = {isOpen} toggle = {toggle}/>
            <Navbargap />
            <ImgSliderA images = {itn.iimgs} />
            <Details>
                <PackDetails>
                    <PackHeading>
                        <Heading>
                            {itn.ititle}
                        </Heading>
                        <Price>
                            {itn.idays}
                        </Price>
                    </PackHeading>
                    <EnqBtn>   
                        <EnqCon>
                            {/* <h3>{itn.iprice}</h3> */}
                            <Ebtn onClick={() => enquiry(itn.ititle)}>
                                Enquiry
                            </Ebtn>
                        </EnqCon>
                    </EnqBtn>
                </PackDetails>
                <DaySection>
                    <DayContainer>
                        <DayHeading>Itinerary</DayHeading>
                        <DayList>
                            {itn.days.map((item, index) => (
                                <Day className = {classVar} key = {index} id={index} BoxLen = {indexNo === index ? indexAct : ""}>
                                    <DayBoxTitle>
                                        <DayNo><DayNoBox RotBox = {indexNo === index ? indexAct : ""} >{item.dayName}</DayNoBox></DayNo>
                                        <DayTitle>{item.dayTitle}</DayTitle>
                                        <DayLogo>
                                            <AiFillCaretDown
                                                onClick={() => displayBox(index)}
                                                color = "#D4AF37"
                                                size = "35px"
                                            />
                                        </DayLogo>
                                    </DayBoxTitle>     
                                    <DayBoxDetails>
                                        <DBTTitle>{item.dayTitle}</DBTTitle>
                                        <DBTText>
                                            <ul>
                                                {item.dayList.map((day, dindex) => (
                                                    <li>{day.dayListDes}</li>
                                                ))}
                                            </ul>
                                        </DBTText>
                                    </DayBoxDetails>                           
                                </Day>
                            ))}                            
                        </DayList>
                    </DayContainer>
                </DaySection>
                <InfoSection>
                    {itn.inclusions.length > 0 &&
                        <Infobox BoxLen = {p1}>                        
                            <Infotitle>
                                Package Inclusions
                                <InfoLogo RotBox={p1}>
                                    <AiFillCaretDown
                                        onClick={() => box1("1")}
                                        color = "#D4AF37"
                                        size = "35px"
                                    />
                                </InfoLogo>
                            </Infotitle>
                            <Infotext>
                                <ul>
                                    {itn.inclusions.map((item, index) => (
                                        <li>{item.inc_data}</li>
                                    ))}
                                </ul>
                            </Infotext>
                        </Infobox>
                    }
                    {itn.exclusions.length > 0 &&
                        <Infobox BoxLen = {p2}>                        
                            <Infotitle>
                                Exclusions
                                <InfoLogo RotBox={p2}>
                                    <AiFillCaretDown
                                        onClick={() => box1("2")}
                                        color = "#D4AF37"
                                        size = "35px"
                                    />
                                </InfoLogo>
                            </Infotitle>
                            <Infotext>
                                <ul>
                                    {itn.exclusions.map((item, index) => (
                                        <li>{item.exc_data}</li>
                                    ))}
                                </ul>
                            </Infotext>
                        </Infobox>
                    }
                    <Infobox BoxLen = {p3}>                        
                        <Infotitle>
                            Cancellation Policy
                            <InfoLogo RotBox={p3}>
                                <AiFillCaretDown
                                    onClick={() => box1("3")}
                                    color = "#D4AF37"
                                    size = "35px"
                                />
                            </InfoLogo>
                        </Infotitle>
                        <InfoTextSection>
                            <h4>APPLICABLE POST CONFIRMATION OF THE RESERVATION WITH AN ADVANCE DEPOSIT</h4>
                            <p>Due to the special nature of the programs featured, suppliers impose rigid cancellation fees; please be aware that Travelpro Holiday has no control over such fees and cannot make any exceptions to these charges.</p>
                            <p><strong>Note</strong> : All cancellations must be received by Travelpro Holiday in writing; cancellations will be effective when the written notice is received at Travelpro Holiday&#39;s offices. If a cancellation is received after Travelpro Holiday is in possession of the initial deposit, but more than 60 days prior to departure (or 90 days if applicable), the cancellation fee incurred will be $250 per person (a higher cancellation fee may apply to some tours). If cancellation is received between 59 and 45 days (or 89 - 45 days) prior to departure the fee incurred will be 25% of the entire tour price. If final payment has been received and cancellation occurs before 60 days (or 90 days) prior to departure, the fee incurred will be 25% of the entire tour price. Cancellation/s received between 44 days and 30 days prior to departure will be charged a fee of 50% of the entire tour price. NO REFUNDS whatsoever will be made for cancellations received within the last 30 days prior to departure.</p>
                        </InfoTextSection>
                    </Infobox>
                </InfoSection>
            </Details>        
            <FooterSection enquiry = {enquiry}/>
            <EnqForm enForm = {enOpen} enFor = {enqyFor} enquiry = {enquiry} sendBox ={sendBox}/>
            <SendMsg dispSub = {sendOpen} sendBox ={sendBox}/>
        </Section>
    )
}

export default PackageInnBox
