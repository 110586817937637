import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import demoImg from '../../media/sakleshpur/headpage.jpg'
import { AiFillCloseSquare } from 'react-icons/ai';
import { SakleshpurDetails } from '../../data/SakleshpurDetails';
// import { SakleshpurDetails } from '../../data/sakleshpurDetails';

const Section = styled.section`
    width: 100vw;
    padding: 50px 100px 100px;
    background-color: #D4AF37;

    display: grid;
    grid-template-rows: 70px auto;
    gap: 50px;
    @media screen and (max-width: 768px) {
        padding: 50px 50px 100px;
    }
    @media screen and (max-width: 668px) {
        padding: 50px 30px 100px;
    }
`;
const Heading = styled.div`
    /* border: 1px solid red; */
    display: grid;
    place-content: center;
    font-family: 'open_san';
    font-size: 2.3rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
    color: #fff;
`;
const Cat = styled.div`
    /* border: 1px solid red; */
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 55px;
    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 668px) {
        grid-template-columns: repeat(1, 1fr);
    }
`;

const GallBox = styled.div`
    /* border: 5px solid #fff; */
    height: 310px;
    display: grid;
    grid-template-rows: auto 50px;
    background-color: #fff;
    padding: 5px;
    cursor: pointer;

`;
const ImgPart = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;
const HeadingPart = styled.div`
    background-color: #fff;
    display: grid;
    place-content: center;
    letter-spacing: 1px;
    font-size: 17px;
    text-transform: uppercase;
    text-align:center;
`;
const ImgDir = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .3s;

    &:hover {
        transform: scale(1.2);
        transition: .3s;
    }
`;
const GalleryBox =styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: transparent;
    display: grid;
    place-content: center;
    display: ${({DispGall}) => (DispGall ? '' : 'none')};;

    z-index: 999999999999999;
`;

const Gallerymain =styled.div`   
    position: relative;
    background-color: #fff;
    border: 5px solid gray;
    width: 95vw;
    /* height: 90vh; */
    padding: 50px;
    /* height: calc(100vh - 10vh); */
    /* overflow-y: scroll; */
    display: grid;
    @media screen and (max-width: 1024px) {
        width: 100vw;
        height: 100vh;
        padding: 70px 50px;
    }
    @media screen and (max-width: 768px) {
        padding: 50px 0px;
    }

`;
const GallImgBox =styled.div`
    position: relative;
    /* border: 1px solid red; */
    height: 300px;
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.75);
-webkit-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.75);
-moz-box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.75);

`;

const GallImgBoxData = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const CloseGall =styled.div`
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
`;
const MainGall =styled.div`
    padding: 10px 40px;
    height: calc(100vh - 25vh);
    overflow-y: scroll;

    
    &::-webkit-scrollbar {
        width: 7px;
        background: none;
    }
    
    &::-webkit-scrollbar-track {
        /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: #D4AF37;
        outline: 1px solid slategrey;
        border-radius: 10px;
        border: none;
        scroll-behavior: smooth;
    }
    @media screen and (max-width: 1024px) {
        height: calc(100vh - 10vh);
    }
    @media screen and (max-width: 668px) {
        padding: 10px 10px;
    }
`;

const MainGall1 =styled.div`
    display: grid;
    grid-template-columns: repeat(2,1fr);
    gap: 50px;
    @media screen and (max-width: 668px) {
        grid-template-columns: repeat(1,1fr);
    }
`;

const Gallery = () => {
    const [gallOpen, setGallOpen] = useState(false);
    const [dataArr, setDataArr] = useState([]);

    const gallFun = (array) => {
        setGallOpen(!gallOpen);
        setDataArr(array)
    }

    return (
        <Section>
            <Heading >
                Gallery
            </Heading>
            <Cat>
                {SakleshpurDetails.map((item,index) => (
                    <GallBox  onClick={() => gallFun(item.arrayImg)}  data-aos="zoom-in-up">
                        <ImgPart>
                            <ImgDir src={item.titleImage} />
                        </ImgPart>
                        <HeadingPart>
                            {item.titleHeading}
                        </HeadingPart>
                    </GallBox>   
                ))}
            </Cat>                          
            <GalleryBox DispGall = {gallOpen}>
                    <Gallerymain>
                        <CloseGall>
                            <AiFillCloseSquare 
                                        onClick={() => gallFun([])}
                                        color = "red"
                                        size = "30px"
                                        />
                        </CloseGall>
                        <MainGall>
                            <MainGall1>
                                {dataArr.map((item,index) => (
                                    <GallImgBox>
                                        <GallImgBoxData src={item.imgD}/>
                                    </GallImgBox>
                                ))}
                            </MainGall1>
                        </MainGall>
                    </Gallerymain>
                </GalleryBox>  
        </Section>
    )
}

export default Gallery
