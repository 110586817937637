import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import DropDown from '../DropDown';
import FooterSection from '../FooterSection';
import Navbargap from '../Navbargap';
import NavbarNormal from '../NavbarNormal';
import EnqForm from '../submitForm/EnqForm';
import FloatEnq from '../submitForm/FloatEnq';
import SendMsg from '../submitForm/SendMsg';
import ImageHandler from './ImageHandler';
import StayDetails from './StayDetails';

const HomestayMaster = (props) => {
    let dataMaster = props.location.state.dataContent;
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }
    useEffect(() => {
        window.scroll(0,0);
    },[]);
    
    const [enOpen, setEnOpen] = useState(false);
    const [sendOpen, setSendOpen] = useState(false);
    const [enqyFor, setEnqyFor] = useState("");

    const enquiry = (enqData) => {
        setEnOpen(!enOpen);
        setEnqyFor(enqData);
    }
    const sendBox = () => {
        setSendOpen(!sendOpen);
    }
    return (
        <>
            <FloatEnq enqTitle = {dataMaster.in_name} enquiry = {enquiry}/>
            <NavbarNormal toggle = {toggle} />
            <DropDown isOpen = {isOpen} toggle = {toggle} />
            <Navbargap />
            <ImageHandler imgData={dataMaster.images}/>
            <StayDetails detailsData={dataMaster} enquiry = {enquiry}/>
            <FooterSection enquiry = {enquiry}/>
            <EnqForm enForm = {enOpen} enFor = {enqyFor} enquiry = {enquiry} sendBox ={sendBox}/>
            <SendMsg dispSub = {sendOpen} sendBox ={sendBox}/>
        </>
    )
}

export default HomestayMaster
