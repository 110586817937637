import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import DropDown from '../DropDown';
import Navbargap from '../Navbargap';
import NavbarNormal from '../NavbarNormal';
import { MdLocationOn } from 'react-icons/md';
import { Link } from 'react-router-dom';

import SmallHalfImg from '../../SmallHalfImg';
import htImg1 from '../../media/homestay/home1.jpg';
import FooterSection from '../FooterSection';
import EnqForm from '../submitForm/EnqForm';
import ViewAllBox from './ViewAllBox';
import SendMsg from '../submitForm/SendMsg';


const ViewAllPage = ({allData,vl_img, vl_text}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [enOpen, setEnOpen] = useState(false);
    const [sendOpen, setSendOpen] = useState(false);
    const [enqyFor, setEnqyFor] = useState("");

    const toggle = () => {
        setIsOpen(!isOpen);
    }
    const enquiry = (enqData) => {
        setEnOpen(!enOpen);
        setEnqyFor(enqData);
    }
    const sendBox = () => {
        setSendOpen(!sendOpen);
    }
    useEffect(() => {
        window.scroll(0,0);
    },[]);
    return (
        <>
               <NavbarNormal toggle = {toggle}/>
               <DropDown isOpen = {isOpen} toggle = {toggle}/>
               <Navbargap />
               <SmallHalfImg img = {vl_img} heading={vl_text} />
               <ViewAllBox allData = {allData} enquiry = {enquiry} />
               <FooterSection enquiry = {enquiry}/>
               <EnqForm enForm = {enOpen} enFor = {enqyFor} enquiry = {enquiry} sendBox ={sendBox}/>
               <SendMsg dispSub = {sendOpen} sendBox ={sendBox}/>
        </>
    )
}

export default ViewAllPage
