

export const TextSa = [
    {text:'S', num: 1},
    {text:'A', num: 2},
    {text:'K', num: 3},
    {text:'L', num: 4},
    {text:'E', num: 5},
    {text:'S', num: 6},
    {text:'H', num: 7},
    {text:'P', num: 8},
    {text:'U', num: 9},
    {text:'R', num: 10}
]

export const TextChi = [
    {text:'C', num: 1},
    {text:'H', num: 2},
    {text:'I', num: 3},
    {text:'K', num: 4},
    {text:'M', num: 5},
    {text:'A', num: 6},
    {text:'G', num: 7},
    {text:'A', num: 8},
    {text:'L', num: 9},
    {text:'U', num: 10},
    {text:'R', num: 11}
]

export const TextCoorg = [
    {text:'C', num: 1},
    {text:'O', num: 2},
    {text:'O', num: 3},
    {text:'R', num: 4},
    {text:'G', num: 5}
]