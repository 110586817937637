import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import HalfImg from '../HalfImg';
import tripTitleImg from '../../media/tripideas/tripid.jpg';
import heritage from '../../media/tripideas/heritage/title.jpg';
import honeymoon from '../../media/tripideas/honeymoon/title.jpg';
import adventure from '../../media/tripideas/adventure/title.jpg';
import beach from '../../media/tripideas/beach/title.jpg';
import hills from '../../media/tripideas/hillstation/title.jpg';
import pil from '../../media/tripideas/pilgrimage/title.jpg';
import train from '../../media/tripideas/traintour/title.jpg';
import well from '../../media/tripideas/wellness/title.jpg';
import mice from '../../media/tripideas/mice/title.jpg';

const Section = styled.section`
    width: 100vh;
`;
const TripContainer = styled.div`
    width: 100vw;
    padding: 50px 100px;
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    gap: 40px;   
    @media screen and (max-width: 1024px) {   
        grid-template-columns: repeat(2, 2fr);
    }
    @media screen and (max-width: 768px) {   
        padding: 50px;
    }
    @media screen and (max-width: 668px) {   
        grid-template-columns: repeat(1, 2fr);
        padding: 50px 30px;
    }

`;

const TripidBox = styled(Link)`
    text-decoration: none;
    border: none;
    height: 350px;
    background-color: gray;
    padding: 15px 10px;
    display: grid;
    grid-template-rows: auto 60px;
    border-radius: 10px;
    cursor: pointer;
`;

const TripidImg = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;
const ConImg = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 6px solid #fff;
        /* transform: scale(1.1); */

    &:hover {
        /* transform: scale(1.1); */
    }

`;
const TripidText = styled.div`
    color: #fff;
    display: grid;
    align-content: end;
    justify-content: center;
    font-size: 1.7rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;       
    @media screen and (max-width: 1204px) {   
        font-size: 1.5rem;
    }
`;

const CmgSoon = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: gray;
    font-size: 18px;
    border-radius: 10px;
    display: ${({YN}) => (YN ? 'none' : '')};

`;

const TripBox = () => {
    return (
        <Section>
            <HalfImg img = {tripTitleImg} heading = {"Trip Ideas"} />
            <TripContainer>
                <TripidBox data-aos = "fade" to ='#'>
                    <TripidImg>
                        <ConImg src = {heritage}/>
                    </TripidImg>
                    <TripidText>Heritage</TripidText>
                    <CmgSoon YN = {false}>Coming Soon..</CmgSoon>
                </TripidBox>
                <TripidBox data-aos = "fade" to ='#'>
                    <TripidImg>
                        <ConImg src = {honeymoon}/>
                    </TripidImg>
                    <TripidText>Honeymoon</TripidText>                    
                    <CmgSoon YN = {false}>Coming Soon..</CmgSoon>
                </TripidBox>
                <TripidBox data-aos = "fade" to ='/tripideas/adventure'>
                    <TripidImg>
                        <ConImg src = {adventure}/>
                    </TripidImg>
                    <TripidText>Adventure</TripidText>                    
                    <CmgSoon YN = {true}>Coming Soon..</CmgSoon>
                </TripidBox>
                <TripidBox data-aos = "fade" to ='#'>
                    <TripidImg>
                        <ConImg src = {beach}/>
                    </TripidImg>
                    <TripidText>Beaches / Backwater</TripidText>                    
                    <CmgSoon YN = {false}>Coming Soon..</CmgSoon>
                </TripidBox>
                <TripidBox data-aos = "fade" to ='#'>
                    <TripidImg>
                        <ConImg src = {hills}/>
                    </TripidImg>
                    <TripidText>Hill Station</TripidText>                    
                    <CmgSoon YN = {false}>Coming Soon..</CmgSoon>
                </TripidBox>
                <TripidBox data-aos = "fade" to ='/tripideas/pilgrimage'>
                    <TripidImg>
                        <ConImg src = {pil}/>
                    </TripidImg>
                    <TripidText>Pilgrimage</TripidText>                    
                    <CmgSoon YN = {true}>Coming Soon..</CmgSoon>
                </TripidBox>
                <TripidBox data-aos = "fade" to ='#'>
                    <TripidImg>
                        <ConImg src = {train}/>
                    </TripidImg>
                    <TripidText>Train Tour</TripidText>                    
                    <CmgSoon YN = {false}>Coming Soon..</CmgSoon>
                </TripidBox>
                <TripidBox data-aos = "fade" to ='#'>
                    <TripidImg>
                        <ConImg src = {well}/>
                    </TripidImg>
                    <TripidText>Wellness & Ayurveda</TripidText>                    
                    <CmgSoon YN = {false}>Coming Soon..</CmgSoon>
                </TripidBox>
                <TripidBox data-aos = "fade" to ='#'>
                    <TripidImg>
                        <ConImg src = {mice}/>
                    </TripidImg>
                    <TripidText>Mice / Corporate</TripidText>                    
                    <CmgSoon YN = {false}>Coming Soon..</CmgSoon>
                </TripidBox>
            </TripContainer>
        </Section>
    )
}

export default TripBox
