import { css } from 'styled-components';
import allerta from '../fonts/allerta.woff2';
import open_san from '../fonts/open_san.woff2';
import arima from '../fonts/arima.woff2';
import badscript from '../fonts/badscript.woff2';
import buda from '../fonts/buda.woff2';
import encode from '../fonts/encode.woff2';

export const fontFaces = css`
    @font-face {
        font-family: 'allerta';
        src: url(${allerta}) format('woff2');
        font-style: normal;
    }
    @font-face {
        font-family: 'open_san';
        src: url(${open_san}) format('woff2');
        font-style: normal;
    }
    @font-face {
        font-family: 'arima';
        src: url(${arima}) format('woff2');
        font-style: normal;
    }
    @font-face {
        font-family: 'badscript';
        src: url(${badscript}) format('woff2');
        font-style: normal;
    }
    @font-face {
        font-family: 'buda';
        src: url(${buda}) format('woff2');
        font-style: normal;
    }
    @font-face {
        font-family: 'encode';
        src: url(${encode}) format('woff2');
        font-style: normal;
    }
`;