import React from 'react';
import styled from 'styled-components';
import ElementSlider from './ElementSlider';
import { Link } from 'react-router-dom';

const Section = styled.section`
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
`;
const ProductHeading = styled.div`
    width: 100%;
    padding: 30px;
    height: 130px;
    display: grid;
    place-content: center;
    font-size: 30px;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
`;
const ProductBtn = styled.div`
    width: 100%;
    padding: 30px;
    height: 150px;
    display: grid;
    place-content: center;
`;
const Btn = styled(Link)`
    text-decoration: none;
    border: none;
    background: grey;
    border-radius: 4px;
    padding: 5px 30px;
    font-size: 20px;
    color: #fff;
`;


const ProductPage = ({enquiry, arraybox, boxname, link}) => {
    return (
        <Section>
            <ProductHeading>
                {boxname}
            </ProductHeading>
            <ElementSlider enquiry = {enquiry} arrbox = {arraybox}/>
            <ProductBtn>
                <Btn to = {link}>View All</Btn>
            </ProductBtn>
        </Section>
    )
}

export default ProductPage
