import React, { useState } from 'react';
import styled from 'styled-components';
import BoxSlider from '../BoxSlider';
import { Link } from 'react-router-dom';
import { menuLinks } from '../../data/MenuLinks';

const Section = styled.section`
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-rows: auto;
`;
const BlackBox = styled.div`
    /* border: 1px solid #fff; */
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.25);
    
`;
const BBox = styled.div`
    position: relative;
    background: rgba(0,0,0,0);
    width: 97%;
    height:97%;
`;

const LinkBox = styled.div`
    position: absolute;
    bottom: 10px;
    right: 0;
    /* background: #fff; */
    width: 50%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media screen and (max-width: 1024px) {
        display: none;
    }
`;
const NavLink = styled(Link)`
    position: relative;
    text-decoration: none;
    color: #fff;
    transition: .3s;
    font-weight: normal;
    text-transform: uppercase;
    font-family: 'open_san';
    letter-spacing: 1px;
    padding: 0 17px;
    z-index: 997;
    display: ${({navactive}) => (navactive ? 'none' : 'block')};
    &:hover {
        color: #D4AF37;
        transition: .3s;
    }
    @media screen and (max-width: 1380px) {
        padding: 0 10px;
        font-size: 13px;
    }
`;

const ImgaeSlideBox = styled.div``;

const PageA = () => {
    const [navbar, setNavbar] = useState(0);

    const changeBackground = () => {
        if(window.scrollY >= 150) {
            setNavbar(1);
        }else {
            setNavbar(0);
        }
    }

    window.addEventListener('scroll', changeBackground); 
    return (
        <>
           <Section>
               <ImgaeSlideBox>
                    <BoxSlider />
               </ImgaeSlideBox>
               <BlackBox>
                   {/* <BBox>
                       <LinkBox>
                            {menuLinks.map((item, index) => (
                                <NavLink navactive = {navbar} to = {item.link} key = {index}>{item.title}</NavLink>
                            ))}
                       </LinkBox>
                   </BBox> */}
               </BlackBox>
           </Section>
        </>
    )
}

export default PageA
