import React from 'react';
import styled from 'styled-components';
const Box = styled.div`
    width: 100%;
    height: 60px;

    display: grid;
    grid-template-columns: 2.5fr 80px 2.5fr;
    position: relative;
    left: ${({locSpace}) => (locSpace == '1' ? '-30px' : '30px')} ;
`;

const TextF = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    color: #D4AF37;
    font-family: 'allerta';
    /* font-weight: bold; */
    font-size: 20px;
    letter-spacing: 2px;
    @media screen and (max-width: 668px) {
        font-size: 17px;
    }
`;
const LetterBox = styled.div`
    
    display: grid;
    place-content: center;
    font-size: 30px;
    font-family: 'open_san';
    font-weight: 800;
    /* border: 4px solid #D4AF37; */
    border: 4px solid #D4AF37;
    color: #D4AF37;
    background: #fff;
    width: 60px;
    /* border-radius: 5px; */
    background: #630A10;
`;
const TextL = styled.div`
    display: flex;
    align-items: center;
    padding-left: 20px;
    color: #D4AF37;
    font-family: 'allerta';
    /* font-weight: bold; */
    font-size: 20px;
    letter-spacing: 2px;
    @media screen and (max-width: 668px) {
        font-size: 17px;
    }
`;

const TravelProWord = ({lText, cText, rText, loc}) => {
    return (
        <>
            <Box locSpace = {loc}>
                <TextF data-aos="fade-left">{lText}</TextF>
                <LetterBox>{cText}</LetterBox>
                <TextL data-aos="fade-right">{rText}</TextL>
            </Box>
        </>
    )
}

export default TravelProWord
