import { createGlobalStyle } from "styled-components";
import { fontFaces } from "./fonts/AllFont";

const GlobleStyle = createGlobalStyle`
    ${fontFaces};
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        /* font-family: 'Montserrat', sans-serif; */
    }

    html, body{
        overflow-x: hidden;
        scroll-behavior: smooth;
    }

    /* body::-webkit-scrollbar {
        width: 50px; */
        /* display: none; */
    /* } */

    /* .packDetails {
        height: 100%;
        transition: .5s;
    } */
    body::-webkit-scrollbar {
        width: 5px;
        background: none;
    }
    
    body::-webkit-scrollbar-track {
        /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    }
    
    body::-webkit-scrollbar-thumb {
        background-color: #D4AF37;
        outline: 1px solid slategrey;
    }
`;

export default GlobleStyle;