import coorg_ABBEY_FALLS from '../media/coorg/ABBEY_FALLS.jpg';
import coorg_chiklihole_reservoir from '../media/coorg/chiklihole_reservoir.jpg';
import coorg_dubare_elephant_camp from '../media/coorg/dubare_elephant_camp.jpg';
import coorg_gaddige from '../media/coorg/gaddige.jpg';
import coorg_harangi_dam from '../media/coorg/harangi_dam.jpg';
import coorg_honnamana_kere_lake from '../media/coorg/honnamana_kere_lake.jpg';
import coorg_karada_village from '../media/coorg/karada_village.jpg';
import coorg_kotebetta_trek from '../media/coorg/kotebetta_trek.jpg';
import coorg_madikeri_fort from '../media/coorg/madikeri_fort.jpg';
import coorg_mallalli_falls from '../media/coorg/mallalli_falls.jpg';
import coorg_mandalpatti_trek from '../media/coorg/mandalpatti_trek.jpg';
import coorg_nalknad_aramane from '../media/coorg/nalknad_aramane.jpg';
import coorg_omkareswara_temple from '../media/coorg/omkareswara_temple.jpg';
import coorg_padi_lggutappa from '../media/coorg/padi_lggutappa.jpg';
import coorg_raja_seat from '../media/coorg/raja_seat.jpg';
import coorg_talakaveri_temple from '../media/coorg/talakaveri_temple.jpg';

import chelavaraFalls from '../media/coorg/chelavara.jpg';
import namfrollingMonastery from '../media/coorg/golden_temple.jpg';
import kaverinisargadhama from '../media/coorg/nisargadhama.jpg';
import barapole from '../media/coorg/barapole.jpg';


import c_rs_1_1 from '../media/coorg/cliffs/1.jpeg';
import c_rs_1_2 from '../media/coorg/cliffs/2.jpg';
import c_rs_1_3 from '../media/coorg/cliffs/3.jpeg';
import c_rs_1_4 from '../media/coorg/cliffs/4.jpeg';
import c_rs_1_5 from '../media/coorg/cliffs/5.jpeg';
import c_rs_1_6 from '../media/coorg/cliffs/6.jpg';
import c_rs_1_7 from '../media/coorg/cliffs/7.jpeg';
import c_rs_1_8 from '../media/coorg/cliffs/8.jpeg';
import c_rs_1_9 from '../media/coorg/cliffs/9.jpeg';

import c_rs_2_1 from '../media/coorg/purple/1.jpeg';
import c_rs_2_2 from '../media/coorg/purple/2.jpeg';
import c_rs_2_3 from '../media/coorg/purple/3.jpeg';
import c_rs_2_4 from '../media/coorg/purple/4.jpeg';
import c_rs_2_5 from '../media/coorg/purple/5.jpeg';
import c_rs_2_6 from '../media/coorg/purple/6.jpeg';
import c_rs_2_7 from '../media/coorg/purple/7.jpeg';
import c_rs_2_8 from '../media/coorg/purple/8.jpeg';
import c_rs_2_9 from '../media/coorg/purple/9.jpeg';
import c_rs_2_10 from '../media/coorg/purple/10.jpeg';
import c_rs_2_11 from '../media/coorg/purple/11.jpeg';
import c_rs_2_12 from '../media/coorg/purple/12.jpeg';
import c_rs_2_13 from '../media/coorg/purple/13.jpeg';
import c_rs_2_14 from '../media/coorg/purple/14.jpeg';
import c_rs_2_15 from '../media/coorg/purple/15.jpeg';
import c_rs_2_16 from '../media/coorg/purple/16.jpeg';
import c_rs_2_17 from '../media/coorg/purple/17.jpeg';
import c_rs_2_18 from '../media/coorg/purple/18.jpeg';
import c_rs_2_19 from '../media/coorg/purple/19.jpeg';


import c_rs_3_1 from '../media/coorg/YELLOWBAMBOORESORTS/1.jpeg';
import c_rs_3_2 from '../media/coorg/YELLOWBAMBOORESORTS/2.jpeg';
import c_rs_3_3 from '../media/coorg/YELLOWBAMBOORESORTS/3.jpeg';
import c_rs_3_4 from '../media/coorg/YELLOWBAMBOORESORTS/4.jpeg';
import c_rs_3_5 from '../media/coorg/YELLOWBAMBOORESORTS/5.jpeg';
import c_rs_3_6 from '../media/coorg/YELLOWBAMBOORESORTS/6.jpeg';
import c_rs_3_7 from '../media/coorg/YELLOWBAMBOORESORTS/7.jpeg';
import c_rs_3_8 from '../media/coorg/YELLOWBAMBOORESORTS/8.jpeg';
import c_rs_3_9 from '../media/coorg/YELLOWBAMBOORESORTS/9.jpeg';
import c_rs_3_10 from '../media/coorg/YELLOWBAMBOORESORTS/10.jpeg';    
import c_rs_3_11 from '../media/coorg/YELLOWBAMBOORESORTS/11.jpeg';    
import c_rs_3_12 from '../media/coorg/YELLOWBAMBOORESORTS/12.jpeg';    
import c_rs_3_13 from '../media/coorg/YELLOWBAMBOORESORTS/13.jpeg';    
import c_rs_3_14 from '../media/coorg/YELLOWBAMBOORESORTS/14.jpeg';    
import c_rs_3_15 from '../media/coorg/YELLOWBAMBOORESORTS/15.jpeg';    
import c_rs_3_16 from '../media/coorg/YELLOWBAMBOORESORTS/16.jpeg';     

export const CoorgResort = [
    {
        code: "1",
        name: "Coorg Cliffs Resort & Spa",
        location:"Coorg",
        image: c_rs_1_6,
        facility: "Fun Activities, Food, Hot Water, Awesome View, Extra Beds",
        details: {
            in_name: "Coorg Cliffs Resort & Spa",
            in_location: "Coorg",
            in_desc: "The road to Coorg Cliffs Resort meanders through stretches of paddy fields and anonymous hamlets, past banana, cardamom and wild black pepper, areca nut and coffee plantations. A mild chill permeates the fragrant air as the road begins to climb the eastern slopes of the Western Ghats, embraced by dense, dry deciduous and evergreen forests. As the resort draws near, the views open out to coffee and spice plantations, fruit orchards and vegetable patches. Situated at an edge of a hilltop, the lobby at Coorg Cliffs Resort is furnished with plush sofas and brass oil lamps. Guests are ceremoniously welcomed by gracious hostesses in traditional attire. A dining area, contiguous to the lobby, offers stunning views through large expanses of glass on two ends. On one end, across an infinity pool, the view extends into the plains in the distance and mountains on the horizon. At the other end, into a bamboo thicket, a landscaped garden and a play area for children. The resort's banquet areas and fitness centre are situated in a block beside the lobby. Set a little distance away, in a clearing, campfires are organized for guests. Above the banquet areas an exquisitely furnished ‘honeymoon suite’ awaits guests, looking out a spectacular view – at the pool below, a valley that drops on a plain and the mountains.",
            in_inTime: "11:00 AM",
            in_outTime: "11:00 AM",
            images: [
                {
                    in_img: c_rs_1_3
                },
                {
                    in_img: c_rs_1_1
                },
                {
                    in_img: c_rs_1_2
                },
                {
                    in_img: c_rs_1_5
                },
                {
                    in_img: c_rs_1_4
                },
                {
                    in_img: c_rs_1_7
                },
                {
                    in_img: c_rs_1_6
                },
                {
                    in_img: c_rs_1_8
                },
                {
                    in_img: c_rs_1_9
                },
            ],
            in_atv: [
                {
                    act: "ROMANTIC DINNER"
                },
                {
                    act: "ROPE ACTIVITIES + ZIPLINE"
                },
                {
                    act: "FISHING"
                },
                {
                    act: "CAMPFIRE & MUSIC"
                },
                {
                    act: "ROOM DECORATION"
                },
                {
                    act: "YOGA"
                },
                {
                    act: "CYCLING"
                },
                {
                    act: "BIRD WATCHING"
                },
                {
                    act: "JEEP DRIVE"
                },
                {
                    act: "NIGHT SAFARI"
                },
                {
                    act: "COFFEE PLANTATION VISIT"
                },
                {
                    act: "RIVER RAFTING"
                },
                {
                    act: "WILDLIFE SAFARI IN NAGARHOLE NATIONAL PARK"
                },
                {
                    act: "TADIANDAMOL TREK"
                },
                {
                    act: "MANDALPATTI TREK"
                },
            ],
            in_visit: [
                {
                    vi_img: coorg_ABBEY_FALLS,
                    vi_text: "ABBEY FALLS"
                },
                {
                    vi_img: coorg_chiklihole_reservoir,
                    vi_text: "CHIKLIHOLE RESERVOIR"
                },
                {
                    vi_img: coorg_mandalpatti_trek,
                    vi_text: "MANDALPATTI"
                },
                {
                    vi_img: coorg_dubare_elephant_camp,
                    vi_text: "DUBARE ELEPHANT CAMP"
                },
                {
                    vi_img: coorg_harangi_dam,
                    vi_text: "HARANGI DAM"
                },
                {
                    vi_img: coorg_honnamana_kere_lake,
                    vi_text: "HONNAMANA KERE LAKE"
                },
                {
                    vi_img: coorg_omkareswara_temple,
                    vi_text: "OMKARESWARA TEMPLE"
                },
                {
                    vi_img: coorg_madikeri_fort,
                    vi_text: "MADIKERI FORT"
                },
                {
                    vi_img: coorg_kotebetta_trek,
                    vi_text: "KOTEBETTA PEAK TREK"
                },
                {
                    vi_img: coorg_mallalli_falls,
                    vi_text: "MALLALLI FALLS"
                },
                {
                    vi_img: coorg_padi_lggutappa,
                    vi_text: "PADI IGGUTHAPPA TEMPLE"
                },
                {
                    vi_img: coorg_nalknad_aramane,
                    vi_text: "NALKNAD ARAMANE PALACE"
                },
                {
                    vi_img: coorg_raja_seat,
                    vi_text: "RAJA'S SEAT"
                },
                {
                    vi_img: coorg_talakaveri_temple,
                    vi_text: "TALAKAVERI TEMPLE & WATERFALLS"
                },
                {
                    vi_img: coorg_karada_village,
                    vi_text: "KARADA VILLAGE"
                },
                {
                    vi_img: coorg_gaddige,
                    vi_text: "GADDIGE / RAJA’S TOMB"
                }
            ]
        }
    },
    {
        code: "2",
        name: "Purple Palms Resort & Spa",
        location:"Coorg",
        image: c_rs_2_1,
        facility: "Fun Activities, Food, Hot Water, Awesome View, Extra Beds",
        details: {
            in_name: "Purple Palms Resort & Spa",
            in_location: "Coorg",
            in_desc: "Step into the charming Purple Palms Resort and Spa, set in the heart of coffee-scented Coorg. Relax in a magical paradise that makes your greatest wishes come true. Revel in a luxurious ambiance that makes you feel absolutely royal. If you can’t resist the allure of the hills, step out for a regal sojourn. Indulge in a magical world. We are the first resort among resorts in coorg to be spotted as you enter Coorg. This Coorg resorts has all what you and your family is looking in for a perfect holiday. With all the tourist attractions in the vicinity, this resort in Coorg is where you will want to be. Relax and rejuvenate at the Resort’s Spa with our experts pampering you with gentle Ayurveda and Western massage that will cleanse your mind and body to make your stay at our resort more meaningful.",
            in_inTime: "11:00 AM",
            in_outTime: "11:00 AM",
            images: [
                {
                    in_img: c_rs_2_3
                },
                {
                    in_img: c_rs_2_2
                },
                {
                    in_img: c_rs_2_1
                },
                {
                    in_img: c_rs_2_4
                },
                {
                    in_img: c_rs_2_5
                },
                {
                    in_img: c_rs_2_6
                },
                {
                    in_img: c_rs_2_7
                },
                {
                    in_img: c_rs_2_8
                },
                {
                    in_img: c_rs_2_9
                },
                {
                    in_img: c_rs_2_10
                },
                {
                    in_img: c_rs_2_11
                },
                {
                    in_img: c_rs_2_12
                },
                {
                    in_img: c_rs_2_13
                },
                {
                    in_img: c_rs_2_14
                },
                {
                    in_img: c_rs_2_15
                },
                {
                    in_img: c_rs_2_16
                },
                {
                    in_img: c_rs_2_17
                },
                {
                    in_img: c_rs_2_18
                },
                {
                    in_img: c_rs_2_19
                },
            ],
            in_atv: [
                {
                    act: "Enjoy a Refreshing Swim"
                },
                {
                    act: "It’s Gym Mania"
                },
                {
                    act: "Be Game for Anything"
                },
                {
                    act: "Kids Play Area"
                },
                {
                    act: "Trekking in Coorg"
                },
                {
                    act: "White Water Rafting in Coorg"
                },
                {
                    act: "Coffee Plantation Walk"
                },
            ],
            in_visit: [
                {
                    vi_img: coorg_ABBEY_FALLS,
                    vi_text: "ABBEY FALLS"
                },
                {
                    vi_img: coorg_mandalpatti_trek,
                    vi_text: "MANDALPATTI"
                },
                {
                    vi_img: coorg_dubare_elephant_camp,
                    vi_text: "DUBARE ELEPHANT CAMP"
                },
                {
                    vi_img: coorg_harangi_dam,
                    vi_text: "HARANGI DAM"
                },
                {
                    vi_img: coorg_madikeri_fort,
                    vi_text: "MADIKERI FORT"
                },
                {
                    vi_img: coorg_mallalli_falls,
                    vi_text: "MALLALLI FALLS"
                },
            ]
        }
    },
    {
        code: "2",
        name: "The Yellow Bamboo Resots & SPA",
        location:"Coorg",
        image: c_rs_3_1,
        facility: "Fun Activities, Food, Hot Water, Awesome View, Extra Beds",
        details: {
            in_name: "The Yellow Bamboo Resots & SPA",
            in_location: "Coorg",
            in_desc: "The Yellow Bamboo resort, nestled amidst nature in the district of Coorg, is the preferred choice of travelers. The customers rate us a one of the best resort in Coorg and is a preferred honeymoon destination. This Coorg resort welcomes the discerning traveler, come and get pampered by our chefs. relax by the pool, rejuvenate at the spa or go outdoors on an estate walk or a wildlife safari",
            in_inTime: "11:00 AM",
            in_outTime: "11:00 AM",
            images: [
                {
                    in_img: c_rs_3_3
                },
                {
                    in_img: c_rs_3_2
                },
                {
                    in_img: c_rs_3_1
                },
                {
                    in_img: c_rs_3_4
                },
                {
                    in_img: c_rs_3_5
                },
                {
                    in_img: c_rs_3_6
                },
                {
                    in_img: c_rs_3_7
                },
                {
                    in_img: c_rs_3_8
                },
                {
                    in_img: c_rs_3_9
                },
                {
                    in_img: c_rs_3_10
                },
                {
                    in_img: c_rs_3_11
                },
                {
                    in_img: c_rs_3_12
                },
                {
                    in_img: c_rs_3_13
                },
                {
                    in_img: c_rs_3_14
                },
                {
                    in_img: c_rs_3_15
                },
                {
                    in_img: c_rs_3_16
                },
            ],
            in_atv: [
                {
                    act: "SPA"
                },
            ],
            in_visit: [
                {
                    vi_img: coorg_madikeri_fort,
                    vi_text: "MADIKERI FORT"
                },
                {
                    vi_img: coorg_mallalli_falls,
                    vi_text: "MALLALLI FALLS"
                },
                {
                    vi_img: coorg_harangi_dam,
                    vi_text: "Harangi Dam"
                },
                {
                    vi_img: chelavaraFalls,
                    vi_text: "Chelvaara Falls"
                },
                {
                    vi_img: kaverinisargadhama,
                    vi_text: "Kaveri Nisargadhaam"
                },
                {
                    vi_img: barapole,
                    vi_text: "Barapole River"
                },
                {
                    vi_img: namfrollingMonastery,
                    vi_text: "Namdroling Monastery"
                },
            ]
        }
    },
]