import React from 'react';
import styled from 'styled-components';

const Gap = styled.div`
    width: 100vw;
    height: 100px;
    background: #fff;
    @media screen and (max-width: 1024px) {
        height: 70px;
        display: none;
    }
`;


const Navbargap = () => {
    return (
        <Gap>
            
        </Gap>
    )
}

export default Navbargap
