import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import DropDown from '../component/DropDown';
import FooterSection from '../component/FooterSection';
import Navbargap from '../component/Navbargap';
import NavbarNormal from '../component/NavbarNormal';
import ProductPage from '../component/ProductSlider/ProductPage';
import ButtonPart from '../component/sakleshpur/ButtonPart';
import DescText from '../component/sakleshpur/DescText';
import Gallery from '../component/sakleshpur/Gallery';
import { TextSa } from '../component/sakleshpur/TextSa';
import TitlePart from '../component/sakleshpur/TitlePart';
import EnqForm from '../component/submitForm/EnqForm';
import SendMsg from '../component/submitForm/SendMsg';
import { HomeStayA } from '../data/HomeStayA';
import { Sakresort } from '../data/Sakresort';
import headimg from '../media/sakleshpur/headpage.jpg';

const Sakleshpur = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [enOpen, setEnOpen] = useState(false);
    const [sendOpen, setSendOpen] = useState(false);
    const [enqyFor, setEnqyFor] = useState("");

    const toggle = () => {
        setIsOpen(!isOpen);
    }
    const enquiry = (enqData) => {
        setEnOpen(!enOpen);
        setEnqyFor(enqData);
    }
    const sendBox = () => {
        setSendOpen(!sendOpen);
    }
    useEffect(() => {
        window.scroll(0,0);
    },[]);
    return (
        <>
            <NavbarNormal toggle = {toggle}/>
            <DropDown isOpen = {isOpen} toggle = {toggle}/>
            <Navbargap />
            <TitlePart img = {headimg} textData = {TextSa}/>
            <DescText />   
            <ProductPage enquiry = {enquiry} arraybox = {HomeStayA} boxname = {'HomeStays'} link ={"/homestay"}/>
            <ProductPage enquiry = {enquiry} arraybox = {Sakresort} boxname = {'Resort'} link ={"/resorts"}/>            
            <Gallery />
            <FooterSection enquiry = {enquiry}/>
            <EnqForm enForm = {enOpen} enFor = {enqyFor} enquiry = {enquiry} sendBox ={sendBox}/>
            <SendMsg dispSub = {sendOpen} sendBox ={sendBox}/>
        </>
    )
}

export default Sakleshpur
