import React from 'react';
import styled from 'styled-components';
import { TextSa } from './TextSa';
import WavyText from './WavyText';

const Section = styled.section`
    width: 100vw;
    height: 60vh;
    @media screen and (max-width: 1024px) {
        height: 80vh;
    }
`;
const ImgBox = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;
const ImgData = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;

const Box = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.2);
    display: grid;
    place-content: center;
`;
    



const TitlePart = ({img, textData}) => {
    return (
        <Section>
            <ImgBox>
                <ImgData src = {img} />
                <Box>
                    <WavyText data = {textData} />
                </Box>
            </ImgBox>
        </Section>
    )
}

export default TitlePart
