import title from '../media/tripideas/adventure/title.jpg';

import l1 from '../media/package/Adventure/1.jpg';
import l2 from '../media/package/Adventure/2.jpg';
import l3 from '../media/package/Adventure/3.jpg';
import l4 from '../media/package/Adventure/4.jpg';

export const AdventurePackage = {
    packageName: "Adventure",
    titleImg: title,
    packages:  [    
        {
            pimg: l1,
            ptitle: "JEEP SAFARI TOUR - Srinagar, Pahalgam, Gulmarg, Sonamarg, Kargil, Leh, Pangong, Khardongla, Leh",
            pdays: "7N|8D",
            pfrom: "Srinagar",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: l1
                    },
                    {
                        iimg: l2
                    }
                ],
                ititle: "JEEP SAFARI TOUR - Srinagar, Pahalgam, Gulmarg, Sonamarg, Kargil, Leh, Pangong, Khardongla, Leh",
                iprice: "₹ 25000",
                idays: "7N|8D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Srinagar",
                        dayList: [
                            {
                                dayListDes: "On arrival at Srinagar airport, our representative will meet you and escort you to Houseboat, Afternoon Shikara ride on Dal Lake visiting interesting places around the lake overnight at Houseboat.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Srinagar – Pahalgam – Srinagar",
                        dayList: [
                            {
                                dayListDes: "Full day tour of Pahalgam one of the beautiful and fascinating ideal resorts all the year round. It is the base for several treks in the region. Situated at the confluence of the Lidder River and set amidst fir and pine covered mountains, one can have a real feelings of Paradise. You can enjoy the pony ride to Shikargah & Baisaran.  In the evening return to Houseboat for overnight stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Srinagar – Tangmarg – Gulmarg – Srinagar",
                        dayList: [
                            {
                                dayListDes: "Full day trip to Gulmarg, one of the most beautiful summer/winter resorts in the valley, one can have a gondola ride (cable car) from Gulmarg to Khilanmarg to have real view of the natural beauty in summer and full snow view in winter. You can have a pony ride in the open fields to seven springs and to a lake, which remains frozen till June, in the evening, return to Srinagar Enroute Tangmarg for its fast flowing stream, overnight at Houseboat. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Srinagar- Sonamarg - Kargil (205 Kms)",
                        dayList: [
                            {
                                dayListDes: "Leave in the morning and drive to Kargil by car / coach rod passes through the panoramic village reach Sonamarg (2740 Mtrs). After Sonamarg, most rough road and wet Zojila pass 3527 Mtrs (Gateway of Ladakh). Continue drive towards Drass (the second coldest inhibited place in the world) we stop here for tea and passport check. Another two and half hours drive will take us to Kargil (2710 Mtrs) which does have its importance after opening of Ladakh for tourists in 1974, overnight at Hotel",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: " Kargil – Leh (235 Kms)",
                        dayList: [
                            {
                                dayListDes: "Leave in the morning full day drive to Leh today we have to ascend three passes this day will be tiresome but panoramic too. First Buddhist village will be Mulbek. We have to cross the highest pass (Fotula 13749 ft.) on Srinagar Leh route. At lunch time, we will be at Lamayuru oldest Monastery. Continue drive to next village Nimu a view of Zanaskar River following into Indus. Finally, we reach Leh 3500 Mtrs. Overnight at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Leh - Karu - Pangong Tso Lake – Leh",
                        dayList: [
                            {
                                dayListDes: "After early breakfast we drive to Pangong Tso Lake. From Leh we reach Karu, an Army camp on Leh-Manali road and then turn left and cross Changla Pass (17500 ft) and finally to Tangtse.",
                            },
                            {
                                dayListDes: "Pangong Lake, situated at 14,000 feet (4,267 m), is a long narrow basin of inland drainage, hardly six to seven kilometers at its widest point and over 135 km long. About 1/3rd of the lake is under Indian occupation and remaining under China. The Chinese border is just beyond the mountains across the lake. Spangmik, 7 km along the southern shore from the head of the lake is the farthest point to which foreigners are permitted. There are spectacular views of the mountains of Changchenmo range to the north and their shimmering reflection in the ever-changing blues and greens of the lake’s brackish waters is breathtaking. Above Spangmik are the glaciers and snow-capped peaks of the Pangong range. Spangmik and other scattered tiny villages along the lake’s southern shore are the summer homes of a scanty population of Chang-pa, the semi-nomadic herdsmen of Tibet and southeast Ladakh. The Pangong Chang-pa cultivate sparse crops of barely and peas in summers. During the winters that they pack their Camps (rebo) and take their flocks of sheep and Pashmina goats out to the distant pastures. Overnight stay little beyond Tangtse in Camped camp at Lukung near Pangong lakeshores.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "Leh – Khardongla Pass – Leh 85 Kms",
                        dayList: [
                            {
                                dayListDes: "Day excursion to Khardongla pass which is the highest motorable pass in the world 5600 Mtrs. and has been recorded in the Gunnies Book of world Records, at the top enjoy the beauty of snow capped mountains. In the late afternoon return back to the Hotel, Overnight at hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "Leh – Delhi",
                        dayList: [
                            {
                                dayListDes: "Transfer in time to the airport to connect onward destination flight.",
                            },
                            {
                                dayListDes: "Tour Ends but sweet memories remain.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        }, 
        {
            pimg: l3,
            ptitle: "JEEP SAFARI TOUR - Manali, Jispa, Sarchu, Leh, Diskit, Tso-Moriri, Leh",
            pdays: "7N|8D",
            pfrom: "Manali",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: l3
                    },
                    {
                        iimg: l4
                    }
                ],
                ititle: "JEEP SAFARI TOUR - Manali, Jispa, Sarchu, Leh, Diskit, Tso-Moriri, Leh",
                iprice: "₹ 25000",
                idays: "7N|8D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Manali – Jispa (140 Kms)",
                        dayList: [
                            {
                                dayListDes: "Drive to Jispa via Rohtang Pass 3978 Mtrs, Through Manali Region, We reach Khoksar where we stop for Passport checking. After short drive, we reach Key long the head district in this area and we will stop here for one hour to visit around the local Bazaar and continue drive to Jispa, over night in Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Jispa - Sarchu (116 Kms)",
                        dayList: [
                            {
                                dayListDes: "One may visit to Dalai Lama monastery, which has been recently built during the Kalachakra programme, from this day, we drive on Leh route and the road winds through extensive fields and long river of Bhaga on the way we stop at Darcha for Passport check. Darcha is the base point for many different trekking routes. Continue drive to Baralachala pass 4883 Mtrs. There is a lake known as Surajtal and it is worth to stop here for pictures. Finally, we reach Sarchu, which is the boundary line between Himachal and Jammu And Kashmir State. Tonight our camp will be at an altitude of 4200 Mtrs. Moreover, it will be the coldest night during the whole tour, over night in Camps.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Sarchu to Leh, via Baralachala Pass (4850m)",
                        dayList: [
                            {
                                dayListDes: "Keylong Sarai the view is different here, Sarchu, a plain area, the boundary of Himachal and Jammu Kashmir, Gata Loops, Nukeela Pass (4700m), Lachungla Pass (4860m), Pang  one of the oldest land in the world, more plain a 50 km long Ground on 4700m high. Leh is headquartering of Ladakh region, Indus valley. The last  Shangri-la, A business centre of  Silk Route, A place where the  Sindhu  Civilization flourish & the battle field of different religious kings. It is a capital of Ladakh. A Land behind high passes, full of different dry grey valley, beautiful snow capped mountain with pinnacle of peaks, Colour Mountain & artistic rocks. Place of monasteries, Thankas & magnificent lakes, Overnight in Hotel at Leh.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Leh - Diskit 118 Kms",
                        dayList: [
                            {
                                dayListDes: "Drive passing through Gangles villages the road then rises to reach Khardongla pass (5600 Mtrs) the world's highest motorable road. The road then passes through a bridge laid on Glacier, which finds its way in Guinness Book of world records. In the afternoon visit Diskit monastery which is 515 years old situated on a hill top giving panoramic view of the valley and evening walk around town of Diskit, overnight at Guest House/Camp.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Diskit - Panamic - Diskit – Leh",
                        dayList: [
                            {
                                dayListDes: "Morning half day visit to Panamic hot sulphur spring and on the way back visit Samstalling and Sumur monastery, after noon visit Hunder which is a short drive, later continue drive to Leh via Khardongla pass, after noon free for personal activities, overnight at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Leh – Chumathang – Tso-moriri (240 Kms)",
                        dayList: [
                            {
                                dayListDes: "Leave in the morning and drive to Chumathang stop at Upshi for passport checking. From here the road turns towards left and reach Karu. Chumathang is famous for medical hot spring and people visit to this place for medical bath and visit Chumathang monastery. From here a short drive upto Maha bridge the main checking point to enter to this new area. From here the road is rough. On the way visit Sumdah village and another 55 Kms drive will take you to Tso-moriri. The lake is breeding grounds for numerous species of birds. Chief among them are the bar headed goose, found in great numbers, crested grebe, the Brahmin duck (ruddy Sheldrake) and the brown headed gull. The lake is 8 Kms. Wide and 28 Kms. Long, overnight in Camps.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "Tso-moriri – Puga – Tsokar – Tanglangla – Leh (245 Kms)",
                        dayList: [
                            {
                                dayListDes: "Drive to Mahe village and the road turns left from here towards Puga Valley. The valley is an unforgettable scene of shooting fountain and white clouds hanging upon. This is Bhutan gas for which Puga valley is famous. Continue drive to Tsokar via Polokongka pass (13500 ft.) and visit Tsokar (small lake) and one can also visit Thugke Gompa. The place is famous for wild asses are generally seen here grazing and they seem moving in flocks. From here short drive and reach on National Highway route (Leh - Manali). Continue drive towards Leh via Tanglangla pass (5350 Mtrs.) which is the second highest motorable pass in the world, arrive Leh in the evening.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: " Airport transfers",
                        dayList: [
                            {
                                dayListDes: "Morning transfer to Airport to fly for onward destination.",
                            },
                            {
                                dayListDes: "Tour Ends but sweet memories remain.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },        
    ]

}