import React, {useState} from 'react';
import styled from 'styled-components';
import { AiOutlineClose } from 'react-icons/ai';
// import PhoneInput from 'react-phone-number-input';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import axios from 'axios';


const Section = styled.section`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.1);
    z-index: 9999999999;
    display:${({dispBox}) => (dispBox ? '' : 'none')};

    
`;
const DisPart = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CloseBox = styled.div`
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;

    svg {

        transition: .3s;
        &:hover {
            transform: scale(1.1);
            transition: .3s;
        }
    }
`;
const FormBox = styled.div`
    position: relative;
    /* width: 400px; */
    width: 40vw;
    /* height: 90vh; */
    border: #D4AF37 5px solid;
    background-color: #fff;
    border-radius: 10px;
    background-color: #F9F3DF;
    /* background-color: rgba(0,0,0,0.59); */
    box-shadow: 0px 0px 4px 4px rgba(0,0,0,0.59) inset;
    -webkit-box-shadow: 0px 0px 4px 4px rgba(0,0,0,0.59) inset;
    -moz-box-shadow: 0px 0px 4px 4px rgba(0,0,0,0.59) inset;

    display: grid;
    grid-template-rows: 60px auto auto;
    z-index: 99999999999;    
    @media screen and (max-width: 1024px) { 
        width: 80%;
    }  
    @media screen and (max-width: 668px) { 
        border-radius: 0px;
        width: 100vw;
        height: 100vh;
        grid-template-rows: 120px auto auto;
    }
    @media screen and (max-width: 340px) { 
        width: 100vw;
        height: 100vh;
        grid-template-rows: 90px auto auto;
    }
`;
const Heading = styled.div`
    display: grid;
    justify-self: center;
    place-content: center;
    text-transform: uppercase;
    font-size: 24px;
    width: 70%;
    border-bottom: 3px solid #D4AF37;
    letter-spacing: 2px;
    color: #3D0000;
    font-weight: bold;
`;
const FormPart = styled.div`
    width: 100%;
    /* height: 100%; */
`;

const Form =styled.form`
    display: grid ;
    grid-template-columns: 3fr 3fr;
    justify-content: center;
    padding: 15px 60px;
    width: 100%;
    height: 100%;
    gap: 10px;
    /* .mobileNumber {
        border: 1px solid red;
    } */
    @media screen and (max-width: 1024px) { 
        grid-template-columns: 6fr;
    }

    @media screen and (max-width: 668px) { 
        gap: 15px;
        padding: 25px 40px;
        gap: 8px;
    }
`;

const InputBox = styled.div`
    width: 100%;
    /* height: 35px; */
    /* height: 10; */
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 1024px) { 
        &:nth-child(1) {
            grid-column: 1 / span 2;
        }
    }
    @media screen and (max-width: 668px) { 
        height: 43px;
    }
    @media screen and (max-width: 380px) { 
        height: 35px;
    }
    @media screen and (max-width: 340px) { 
        height: 30px;
    }
    /* margin-bottom: 10px; */
`;
const DateBox = styled.div`
    width: 100%;
    height: 35px;
    /* margin-bottom: 10px; */
    display: grid;
    grid-template-columns: 60px auto;
    @media screen and (max-width: 668px) { 
        height: 43px;
    }
    @media screen and (max-width: 380px) { 
        height: 35px;
    }
    @media screen and (max-width: 340px) { 
        height: 30px;
    }
`;
const DateTab = styled.input`
    width: 100%;
    height: 35px;
    border-radius: 0 5px 5px 0;
    display: flex;
    align-items: center;
    padding: 0 15px;
    border: 1px solid #D4AF37;
    background-color: #fff;
    font-size: 15px;
    cursor: pointer;
    border-left: none;
    @media screen and (max-width: 668px) { 
        font-size: 13px;
    }
`;
const DateTitle =styled.div`
    width: 100%;
    height: 35px;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding-left: 15px;
    border: 1px solid #D4AF37;
    border-radius: 5px 0 0 5px;
    border-right: none;
    background-color: #fff;
    color: #000;
    /* border: none; */
    @media screen and (max-width: 668px) { 
        font-size: 13px;
    }
`;

const InputTab = styled.input`
    width: 100%;
    /* height: 100%; */
    height: 35px;

    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    border: 1px solid #D4AF37;
    background-color: #fff;
    font-size: 15px;
    cursor: pointer;
    @media screen and (max-width: 668px) { 
        font-size: 13px;
    }
`;

const ErrMsg = styled.span`
    font-size: 10px;
    /* line-height: 14px; */
    text-transform: capitalize;
    font-weight: bold;
    color: red;
    display: ${({errMsg}) => (errMsg ? '' : 'none')};

    /* border: 1px solid red; */
`;

const MessTab =styled.textarea`
    width: 100%;
    height: 35px;
    border-radius:5px;
    display: flex;
    align-items: center;
    padding: 5px 15px;
    border: 1px solid #D4AF37;
    background-color: #fff;
    font-size: 15px;
    @media screen and (max-width: 668px) { 
        font-size: 13px;
    }
`;
const BtnPart = styled.div`
   display: flex;
   flex-direction: column;
`;

const BtnHeading = styled.div`
    font-size: 10px;
    padding: 10px;
    color: gray;
    text-align: center;
    
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
`;
const BtnB = styled.div`
    border: 1px solid red;
    width: 150px;
    height: 35px;
    margin: 15px 0;
    display: grid;
    justify-self: center;
    align-self: center;
    justify-content: center;
    align-items: center;
    background-color: red;
    border-radius: 5px;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: .3s;
    &:hover {
        transform: scale(1.05);
        transition: .3s;
    }
`;

const EnqForm = ({enForm, enFor, enquiry, sendBox}) => {
    var date = new Date();
    var formatedDate = `${date.getFullYear()}-${date.getMonth()+1}-${date.getDate()}`;
    console.log(date);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [from, setFrom] = useState(formatedDate);
    const [to, setTo] = useState(formatedDate);
    const [pep, setPep] = useState("");
    const [mess, setMess] = useState("");
    const [errName, setErrName] = useState(false);
    const [errEmail, setErrEmail] = useState(false);
    const [errMobile, setErrMobile] = useState(false);
    const [errPep, setErrPep] = useState(false);
    const enqfor = enFor;
    const sendMail = () => {
        let data = {
            enquiry:enqfor,
            name:name,
            email:email,
            mobile:mobile,
            from:from,
            to:to,
            pep:pep,
            mess:mess
        }
        

        if(name != "") {
            setErrName(false);
            if(email !="") {
                setErrEmail(false);
                if(mobile.length > 3 ) {
                    setErrMobile(false);
                    if(pep != "") {
                        setErrPep(false);

                        // console.log(data);

                        fetch("https://www.eskaysoftech.com/app/travelproemail/", {
                            method: 'POST',
                            mode: 'no-cors',
                            headers: {
                                'Accept':'application/json',
                                'Content-Type':'application/json'
                            },
                            body:JSON.stringify(data)
                        }).then((result)=>{
                            // console.log(result);
                            setName("");
                            setEmail("");
                            setMobile("");
                            setPep("");
                            setMess("");

                            enquiry();
                            sendBox();
                        })

                        // axios.post('http://api.travelproholiday.com/', data)
                        // .then(function (response) {
                        //     console.log(response);

                        //     setName("");
                        //     setEmail("");
                        //     setMobile("");
                        //     setPep("");
                        //     setMess("");

                        //     enquiry();
                        //     sendBox();
                        // })
                        // .catch(function (error) {
                        //     console.log(error);
                        // });

                        // setName("");
                        // setEmail("");
                        // setMobile("");
                        // setPep("");
                        // setMess("");

                        // enquiry();
                        // sendBox();
                    }else {
                        setErrPep(true);
                    }
                }else {
                    setErrMobile(true);
                }
            }else {
                setErrEmail(true);
            }
        }else {
            setErrName(true);
        }
    }
    return (
        <Section dispBox = {enForm} >
            <DisPart>
                <FormBox>
                    <CloseBox>
                        <AiOutlineClose 
                            onClick={enquiry}
                            color = "#D4AF37"
                            size = "28px"
                        />
                    </CloseBox>
                    <Heading>
                        Enquire
                    </Heading>
                    <FormPart>
                        <Form>
                            <InputBox>
                                <InputTab 
                                    type = "text" 
                                    placeholder = "Your Name" 
                                    value = {name} 
                                    onChange = {(e) => setName(e.target.value)} 
                                />
                                <ErrMsg errMsg = {errName}>name field is required.</ErrMsg>
                            </InputBox>
                            <InputBox>
                                <InputTab  
                                    type = "text" 
                                    placeholder = "Your Email"                                
                                    value = {email} 
                                    onChange = {(e) => setEmail(e.target.value)} 
                                />
                                <ErrMsg errMsg = {errEmail}>email field is required.</ErrMsg>
                            </InputBox>
                            <InputBox>
                                <PhoneInput  
                                    inputStyle={{width:'100%'}}        
                                    value = {mobile} 
                                    onChange = {setMobile} 
                                    country='in'
                                />
                                <ErrMsg errMsg = {errMobile}>mobile field is required.</ErrMsg>
                            </InputBox>
                            <DateBox>
                                <DateTitle>From</DateTitle>
                                <DateTab 
                                    type = "date"         
                                    value = {from}
                                    onChange = {(e) => setFrom(e.target.value)}  
                                />
                            </DateBox>
                            <DateBox>
                                <DateTitle>To</DateTitle>
                                <DateTab 
                                    type = "date"           
                                    value = {to} 
                                    onChange = {(e) => setTo(e.target.value)}    
                                />
                            </DateBox>
                            <InputBox>
                                <InputTab  
                                    type = "number" 
                                    placeholder = "Number of People"             
                                    value = {pep} 
                                    onChange = {(e) => setPep(e.target.value)}  
                                />
                                <ErrMsg errMsg = {errPep}>This field is required.</ErrMsg>
                            </InputBox>
                            <InputBox>
                                <MessTab 
                                    rows = "2" 
                                    placeholder = "Message ..."            
                                    value = {mess} 
                                    onChange = {(e) => setMess(e.target.value)}  
                                />
                            </InputBox>
                        </Form>
                    </FormPart>
                    <BtnPart>
                        <BtnHeading>
                            <ul>
                                <li>We assure the privacy of your contact data.</li>
                                <li>This data will only be used by our team to contact you and no other purposes.</li>
                            </ul>
                        </BtnHeading>
                        <BtnB onClick={sendMail}>
                            Submit
                        </BtnB>
                    </BtnPart>
                </FormBox>
            </DisPart>
        </Section>
    )
}

export default EnqForm
