import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import logo from '../media/logo.png';
import { FaBars } from 'react-icons/fa';
import { menuLinks } from '../data/MenuLinks';

const NavbarBox = styled.div`
    position: relative;
    width: 100vw;
    height: 100px;
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    grid-template-columns: 1.5fr 3.5fr;
    transition: .3s;
    z-index: 999;
    @media screen and (max-width: 1024px) {
        height: 70px;
    }

    &.active {
        background: #fff;
        border-bottom: 4px solid #D4AF37;
        transition: .3s;        
        box-shadow: 0px 5px 7px 0px rgba(0,0,0,0.61);
        -webkit-box-shadow: 0px 5px 7px 0px rgba(0,0,0,0.61);
        -moz-box-shadow: 0px 5px 7px 0px rgba(0,0,0,0.61);
    }
`;
const ToggleBox = styled.div`
    position: absolute;
    top: 15px;
    right: 20px;
    width: 50px;
    height: 40px;
    display: none;
    @media screen and (max-width: 1024px) {
        display: block;
    }
`;
const NavLogo = styled.div`
    position: relative;
    display: grid;
    place-content: center;
    z-index: 9999999;
`;
const NavBtn = styled.div`
    /* background-color: green; */
    position: relative;    
    z-index: 9999999;
    display: grid;
    place-content: center;

    button {
        padding: 10px 30px;
        background-color: red;
        border: none;
        border-radius: 5px;
        text-transform: capitalize;
        color: #fff;
    }

`;

const LogoImg = styled.img`
    width: 200px;
    height: 70px;
    @media screen and (max-width: 1024px) {
        width: 170px;
        height: 50px;
    }    
    @media screen and (max-width: 650px) {
        padding-left: 20px;
        width: 150px;
        height: 45px;
    }
`;

const NnavLinks = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 50px;
    @media screen and (max-width: 1024px) {
        display: none;
    }
`;
const NavLink = styled(Link)`
    /* color: #D4AF37; */
    text-decoration: none;
    color: ${({navactive}) => (navactive ? '#000' : '#fff')};
    /* display: ${({navactive}) => (navactive ? 'block' : 'none')}; */
    transition: .3s;
    /* color: #fff; */
    font-weight: normal;
    text-transform: uppercase;
    font-family: 'open_san';
    letter-spacing: 2px;
    padding: 0 13px;
    font-weight: bold;
    &:hover {
        color: #D4AF37;
        transition: .3s;
    }
`;

const NavBar = ({toggle}) => {
    const [navbar, setNavbar] = useState(0);
    const [navbarclass, setNavbarclass] = useState('');

    const changeBackground = () => {
        if(window.scrollY >= 150) {
            setNavbar(1);
            setNavbarclass('active');
        }else {
            setNavbar(0);
            setNavbarclass('');
        }
    }
    

    window.addEventListener('scroll', changeBackground); 
    return (
        <>
            <NavbarBox className = {navbarclass}>
                <NavLogo>
                    <LogoImg src={logo} />
                </NavLogo>
                <NnavLinks>
                    {menuLinks.map((item, index) => (
                        <NavLink navactive = {navbar} to = {item.link} key = {index}>{item.title}</NavLink>
                    ))}
                </NnavLinks>
                <ToggleBox>
                    <FaBars 
                    onClick={toggle}
                    color = "#D4AF37"
                    size = "35px"
                    />
                </ToggleBox>
            </NavbarBox>
        </>
    )
}

export default NavBar
