import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import DropDown from '../DropDown';
import FooterSection from '../FooterSection';
import HalfImg from '../HalfImg';
import Navbargap from '../Navbargap';
import NavbarNormal from '../NavbarNormal';
import EnqForm from '../submitForm/EnqForm';
import SendMsg from '../submitForm/SendMsg';
import PackageBox from './PackageBox';


const Section = styled.section`
    width: 100vw;
    height: 100%;
    text-decoration: none;
`;

const StatePage = ({packarr}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [enOpen, setEnOpen] = useState(false);
    const [sendOpen, setSendOpen] = useState(false);
    const [enqyFor, setEnqyFor] = useState("");

    const toggle = () => {
        setIsOpen(!isOpen);
    }
    const enquiry = (enqData) => {
        setEnOpen(!enOpen);
        setEnqyFor(enqData);
    }
    const sendBox = () => {
        setSendOpen(!sendOpen);
    }
    useEffect(() => {
        window.scroll(0,0);
    },[]);
    return (
        <Section>
            <NavbarNormal toggle = {toggle}/>
            <DropDown isOpen = {isOpen} toggle = {toggle}/>
            <Navbargap />
            <HalfImg img = {packarr.titleImg} heading = {packarr.packageName} />
            <PackageBox packages = {packarr.packages} enquiry = {enquiry}/>
            <FooterSection enquiry = {enquiry}/>
            <EnqForm enForm = {enOpen} enFor = {enqyFor} enquiry = {enquiry} sendBox ={sendBox}/>
            <SendMsg dispSub = {sendOpen} sendBox ={sendBox}/>
        </Section>
    )
}

export default StatePage
