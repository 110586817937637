import React from 'react';
import styled, { keyframes } from 'styled-components';
// import { TextSa } from './TextSa';

const Wavy = styled.div`
    position: relative;
    -webkit-box-reflect: below -12px linear-gradient(transparent, rgba(0,0,0,0.4));
`;

const animate = keyframes`
    0% {
        transform: translateY(0px);
    }
    20% {
        transform: translateY(-40px);
    }
    40% {
        transform: translateY(0px);
    }
    100% {
        transform: translateY(0px);
    }
`;
const TextData = styled.span`
    position: relative;
    display: inline-block;
    color: #fff;
    font-size: clamp(1rem, 6vw, 20rem);
    text-transform: uppercase;
    animation: ${animate} 1.7s ease-in-out infinite;
    animation-delay: ${({TextT}) => (0.1 * TextT)}s;
    margin-right: 7px;
    font-family: 'buda';
`;

const WavyText = ({data}) => {
    return (
        <Wavy>
            {/* <TextData TextT = {1}>s</TextData>
            <TextData TextT = {2}>a</TextData>
            <TextData TextT = {3}>k</TextData>
            <TextData TextT = {4}>l</TextData>  
            <TextData TextT = {5}>e</TextData>
            <TextData TextT = {6}>s</TextData>
            <TextData TextT = {7}>h</TextData>
            <TextData TextT = {8}>p</TextData>
            <TextData TextT = {9}>u</TextData>
            <TextData TextT = {10}>r</TextData> */}
            {/* <TextData TextT = {1}>s</TextData> */}
            {data.map((item, index) => (
                <TextData TextT = {item.num}>{item.text}</TextData>
            ))}
        </Wavy>
    )
};

export default WavyText
