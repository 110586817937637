import timg from '../media/package/kar/pack1/titleimg.jpg';
import p2 from '../media/package/kar/pack2/timg.jpg';
import p3 from '../media/package/kar/pack3/timg.jpg';
import p4 from '../media/package/kar/pack4/timg.jpg';
import p1 from '../media/package/kar/pack4/timg.jpg';
import i11 from '../media/package/kar/pack1/i1.jpg';
import i21 from '../media/package/kar/pack1/i2.jpg';
import i12 from '../media/package/kar/pack2/i1.jpg';
import i22 from '../media/package/kar/pack2/i2.jpg';
import i13 from '../media/package/kar/pack3/i1.jpg';
import i23 from '../media/package/kar/pack3/i2.jpg';
import i14 from '../media/package/kar/pack4/i1.jpg';
import i24 from '../media/package/kar/pack4/i2.jpg';

import p51 from '../media/package/kar/pack5/1.jpg';
import p52 from '../media/package/kar/pack5/2.jpg';
import p53 from '../media/package/kar/pack5/3.jpg';
import p54 from '../media/package/kar/pack5/4.jpg';
import p55 from '../media/package/kar/pack5/5.jpg';
import p56 from '../media/package/kar/pack5/6.jpg';
import p57 from '../media/package/kar/pack5/7.jpg';




export const KarPackage = {
    packageName: "Karnataka",
    titleImg: timg,
    packages:  [
        {
            pimg: p1,
            ptitle: "2N Bangalore, 2N Kabini",
            pdays: "4N|5D",
            pfrom: "Banglore",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: i11
                    },
                    {
                        iimg: i21
                    }
                ],
                ititle: "2N Bangalore, 2N Kabini",
                iprice: "₹ 25000",
                idays: "4N|5D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Bangalore",
                        dayList: [
                            {
                                dayListDes: "On arrival at Bengaluru International Airport, you will be contacted and greeted by our  Chauffeur. With all necessary assistance proceed to luxurious hotel in Bangalore.",
                            },
                            {
                                dayListDes: "Choose to spend rest of the day at leisure or set off on a optional sightseeing spree of the Bangalore city. Experience lively Bangalore with its well known exotic restaurants and pubs or enjoy a bit of shopping at some of the many popular shopping malls and streets .",
                            },
                            {
                                dayListDes: "Return to the hotel. Overnight stay at Hotel in Bangalore.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Bangalore – Kabini (240 ms / 5 – 6 Hrs Drive)",
                        dayList: [
                            {
                                dayListDes: "After breakfast check out from Hotel and drive to Kabini. The most popular wildlife destination of Karnataka. The name Kabini is not the official name of this place. It is the southern part of Nagarhole national park. The name comes to it as the forest stretch lies on  the banks of river kabini also known as Kapila. Sighting of tigers, leapards, black panthers, wild dog, gaur and elephants have been witnessed by few of the travellers previously. On arrival Checkin to the resort and after lunch, choose a boat or vehicle safari into Nagarahole National Park and through Bandipur National Park (Optional).",
                            },
                            {
                                dayListDes: "Return to the resort, Overnight stay at Resort in Kabini.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Kabini",
                        dayList: [
                            {
                                dayListDes: "Best time to spot animals and birds is early morning. You will be receiving a wake up call to head to the wildlife safari. After returning from safari, enjoy a filling breakfast. Later Choose to go on a boat ride (seasonal) and head out to befriend elephants and enjoy a unique elephant ride (Optional). After lunch, experience a memorable ride on a coracle, a traditional round-shaped boat (Optional), and drift down the Kabini River, enjoy the various sights and sounds around you, including a glorious sunset.",
                            },
                            {
                                dayListDes: "Return to the resort. Overnight stay at Resort in Kabini.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Kabini – Bangalore (240 Kms / 5 – 6 Hrs Drive)",
                        dayList: [
                            {
                                dayListDes: "Take an early morning guided nature walk, leave for Bangalore after breakfast. Choose to visit the famed Mysore Palace as well as Srirangapattna en route. (Optional)",
                            },
                            {
                                dayListDes: "Arrive at Bangalore Hotel, rest of the day at leisure.",
                            },
                            {
                                dayListDes: "Overnight stay at Hotel in Bangalore.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Bangalore – Bangalore Airport (45 Kms / 1.5 Hrs Drive)",
                        dayList: [
                            {
                                dayListDes: "After breakfast check out and drive to Bangalore International Airport for your return journey .",
                            },
                            {
                                dayListDes: "Tour Ends",
                            }
                        ]
                    }
                ],
                inclusions: [
                    {
                        inc_data: "2 Nights accommodation at Bangalore with Breakfast &amp; Taxes."
                    },
                    {
                        inc_data: "2 Nights accommodation at Kabini with Lunch, Dinner, Breakfast &amp; Taxes."
                    },
                    {
                        inc_data: "All sightseeing &amp; Transfers by Toyota Innova Crysta as per the above Itinerary from Bangalore International Airport to Bangalore International airport."
                    }
                ],
                exclusions: [
                    {
                        exc_data: "Telephone, laundry, Tips etc."
                    },
                    {
                        exc_data: "Boat Safari / Jeep Safari in kabini/nagarhole /Bandipur National Forest."
                    },
                    {
                        exc_data: "Entrances at Mysore Palace &amp; Srirangapatna Or Guide Charges."
                    },
                    {
                        exc_data: "Any other services availed other than mentioned in above cost include."
                    },
                    {
                        exc_data: "Any other optional tour which is not mentioned in above cost includes."
                    }
                ],

            }
        },
        {
            pimg: p2,
            ptitle: "2N Bangalore, 3N Coorg",
            pdays: "5N|6D",
            pfrom: "Banglore",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: i12
                    },
                    {
                        iimg: i22
                    }
                ],
                ititle: "2N Bangalore, 3N Coorg",
                iprice: "₹ 25000",
                idays: "5N|6D",
                days:[
                    {
                        dayName: "day 1",
                        dayTitle: "Bangalore",
                        dayList: [
                            {
                                dayListDes: "On arrival at Bengaluru International Airport, you will be contacted and greeted by our  Chauffeur. With all necessary assistance proceed to luxurious hotel in Bangalore.",
                            },
                            {
                                dayListDes: "Choose to spend rest of the day at leisure or set off on a optional sightseeing spree of the Bangalore city. Experience lively Bangalore with its well known exotic restaurants and pubs or enjoy a bit of shopping at some of the many popular shopping malls and streets .",
                            },
                            {
                                dayListDes: "Return to the hotel. Overnight stay at Hotel in Bangalore.",
                            }
                        ]
                    },
                    {
                        dayName: "day 2",
                        dayTitle: "Bangalore – Coorg (260 Kms / 5 – 6 Hrs Drive)",
                        dayList: [
                            {
                                dayListDes: "Following breakfast at the hotel, checkout and drive to Coorg, a popular hill station popularly known as Scotland of India. The journey through misty roads takes you through aromatic coffee landscapes, set amidst western Ghat.",
                            },
                            {
                                dayListDes: "Relax for the rest of day or optional take off on a guided tour of coffee and spice plantation.",
                            },
                            {
                                dayListDes: "Overnight stay at Resort in Coorg.",
                            }
                        ]
                    },
                    {
                        dayName: "day 3",
                        dayTitle: "Coorg",
                        dayList: [
                            {
                                dayListDes: "After enjoying the local cuisine breakfast like otti, kadambutt, paaputt which you should never miss on, begin the day exploring the local villages and visit Talakaveri.",
                            },
                            {
                                dayListDes: "Visit the Ayne Mane village and choose to relish an authentic Kodava Lunch (Optional).",
                            },
                            {
                                dayListDes: "Take a sightseeing trip to Bylakuppe, a town renowned as the second largest Tibetan settlement in India. Return to the resort by evening.",
                            },
                            {
                                dayListDes: "Overnight at Resort in Coorg.",
                            }
                        ]
                    },
                    {
                        dayName: "day 4",
                        dayTitle: "Coorg",
                        dayList: [
                            {
                                dayListDes: "Enjoy Full day sightseeing at Dubare elephant camp and Nisargadhama. Spend your evening visiting the the misty cool Rajaseat.",
                            },
                            {
                                dayListDes: "Overnight stay at Resort.",
                            }
                        ]
                    },
                    {
                        dayName: "day 5",
                        dayTitle: "Coorg – Bangalore (260 Kms / 5 – 6 Hrs Drive)",
                        dayList: [
                            {
                                dayListDes: "Take a early morning guided nature walk, leave for Bangalore after breakfast. Choose to visit the famed Mysore Palace as well as Srirangapatna en route (Optional).",
                            },
                            {
                                dayListDes: "Arrive at Hotel in Bangalore, Overnight stay at Hotel in Bangalore. (optional -Suggested time can be spent at some excuisit restaurants or pubs at Indranagar).",
                            }
                        ]
                    },
                    {
                        dayName: "day 6",
                        dayTitle: "Bangalore – Bangalore Airport (45 Kms / 1.5 Hrs Drive)",
                        dayList: [
                            {
                                dayListDes: "After breakfast check out and drive to Bangalore International Airport for your return journey back.",
                            },
                            {
                                dayListDes: "Tour Ends",
                            }
                        ]
                    }
                ],
                inclusions: [
                    {
                        inc_data: "1 Night accommodation at Hotel in Bangalore with Breakfast &amp; Taxes."
                    },
                    {
                        inc_data: "3 Nights accommodation at resort in Coorg with Lunch, Dinner, Breakfast &amp; Taxes."
                    },
                    {
                        inc_data: "All sightseeing at Transfers by Toyota Innova Crysta as per the above Itinerary from Bangalore International Airport to Bangalore International airport."
                    }
                ],
                exclusions: [
                    {
                        exc_data: "Telephone, laundry, Tips etc."
                    },
                    {
                        exc_data: "Lunch at Ayne Mane Coorg."
                    },
                    {
                        exc_data: "Entrances at Mysore Palace & Srirangapatna Or Guide Charges."
                    },
                    {
                        exc_data: "Any other services availed other than mentioned in above cost include."
                    },
                    {
                        exc_data: "Any other optional tour which is not mentioned in above cost includes."
                    }
                ],
            }
        },
        {
            pimg: p3,
            ptitle: "2N Bangalore, 2N Coorg, 2N Kabini",
            pdays: "6N|7D",
            pfrom: "Banglore",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: i13
                    },
                    {
                        iimg: i23
                    }
                ],
                ititle: "2N Bangalore, 2N Coorg, 2N Kabini",
                iprice: "₹ 25000",
                idays: "6N|7D",
                days:[
                    {
                        dayName: "day 1",
                        dayTitle: "Bangalore",
                        dayList: [
                            {
                                dayListDes: "On arrival at Bengaluru International Airport, you will be contacted and greeted by our Chauffeur. With all necessary assistance proceed to luxurious hotel in Bangalore.",
                            },
                            {
                                dayListDes: "Choose to spend the rest of the at leisure at the hotel or set off on a optional sightseeing spree of the Bangalore city, organized by the hotel. Experience the various famed sights of Bangalore or enjoy a bit of shopping at some of the many popular shopping malls and streets of the city in known for.",
                            },
                            {
                                dayListDes: "Return to the hotel. Overnight stay at Hotel in Bangalore.",
                            }
                        ]
                    },
                    {
                        dayName: "day 2",
                        dayTitle: "Bangalore – Coorg (260 Kms / 5 – 6 Hrs Drive)",
                        dayList: [
                            {
                                dayListDes: "Following breakfast at the hotel, embark on the drive to Coorg, a popular hill station high in the Western Ghats on the South West of Karnataka. The journey through winding shaded roads takes you through a fantastic range of rich landscapes, and leads you to exotic resort, set amidst virgin forests.",
                            },
                            {
                                dayListDes: "Relax for the rest of the afternoon then take off on a guided tour of the coffee and spice plantation.",
                            },
                            {
                                dayListDes: "Overnight stay at Resort in Coorg.",
                            }
                        ]
                    },
                    {
                        dayName: "day 3",
                        dayTitle: "Coorg",
                        dayList: [
                            {
                                dayListDes: "Being the day exploring the local village on a guided cycling trip. Return for breakfast at the resort.",
                            },
                            {
                                dayListDes: "Visit the Ayne Mane village and choose to relish an authentic Kodava Lunch*.",
                            },
                            {
                                dayListDes: "Take a sightseeing trip to Bylakuppe, a town renowned as the second largest Tibetan settlement in India. Return to the resort and enjoy the evening with a bonfire, culture activities and dinner.",
                            },
                            {
                                dayListDes: "Overnight at Resort in Coorg.",
                            }
                        ]
                    },
                    {
                        dayName: "day 4",
                        dayTitle: "KCoorg – Kabini (130 Kms / 3 Hrs Drive)",
                        dayList: [
                            {
                                dayListDes: "Let the day begin with birdsong on an early morning guided bird-watching walk followed by breakfast at the resort after which you head for the most popular wildlife destination of Karnataka, Kabini, and drive into the picturesque Kabini Resort set amidst sweeping panoramic vistas. After lunch, choose a boat or vehicle safari into Nagarahole National Park and through Bandipur National Park (Optional).",
                            },
                            {
                                dayListDes: "Return to the resort, Overnight stay at Resort in Kabini.",
                            }
                        ]
                    },
                    {
                        dayName: "day 5",
                        dayTitle: "Kabini",
                        dayList: [
                            {
                                dayListDes: "Choose to go on a boat or vehicle safari into the famed Nagarahole Park. Return to the resort for breakfast, then head out to befriend elephant sand enjoy a unique elephant ride (Optional). After lunch, experience a memorable ride on a coracle, a traditional round-shaped boat, and drift down the Kabini River (Optional), enjoy the various sights and sounds around you, including a glorious sunset.",
                            },
                            {
                                dayListDes: "OverReturn to the resort and relax. Overnight stay at Resort in Kabini.",
                            }
                        ]
                    },
                    {
                        dayName: "day 6",
                        dayTitle: "Kabini – Bangalore (230 Kms / 5 Hrs Drive)",
                        dayList: [
                            {
                                dayListDes: "Take a early morning guided nature walk, leave for Bangalore after breakfast. Choose to visit the famed Mysore Palace as well as Srirangapattna en route (Optional).",
                            },
                            {
                                dayListDes: "Arrive at Hotel in Bangalore, rest of the day at leisure. Overnight stay at Hotel in Bangalore.",
                            }
                        ]
                    },
                    {
                        dayName: "day 7",
                        dayTitle: "Bangalore – Bangalore Airport (45 Kms / 1.5 Hrs Drive)",
                        dayList: [
                            {
                                dayListDes: "After breakfast check out and drive to Bangalore International Airport for your return journey back.",
                            },
                            {
                                dayListDes: "Tour Ends",
                            }
                        ]
                    }
                ],
                inclusions: [
                    {
                        inc_data: "1 Night accommodation at Hotel in Bangalore with Breakfast &amp; Taxes."
                    },
                    {
                        inc_data: "2 Nights accommodation at Resort in Coorg with Lunch, Dinner, Breakfast &amp; Taxes."
                    },
                    {
                        inc_data: "2 Nights accommodation at Resort in Kabini with Lunch, Dinner, Breakfast &amp; Taxes."
                    },
                    {
                        inc_data: "1 Night accommodation at Hotel in Bangalore with Breakfast &amp; Taxes."
                    },
                    {
                        inc_data: "All sightseeing at Transfers by Toyota Innova Crysta as per the above Itinerary from Bangalore International Airport to Bangalore International airport."
                    }
                ],
                exclusions: [
                    {
                        exc_data: "Telephone, laundry, Tips etc."
                    },
                    {
                        exc_data: "Lunch at Ayne Mane Coorg."
                    },
                    {
                        exc_data: "Boat Safari / Jeep Safari at Bandipur National Forest."
                    },
                    {
                        exc_data: "Entrances at Mysore Palace &amp; Srirangapatna Or Guide Charges."
                    },
                    {
                        exc_data: "Any other services availed other than mentioned in above cost include."
                    },
                    {
                        exc_data: "Any other optional tour which is not mentioned in above cost includes."
                    }
                ],
            }
        },
        {
            pimg: p4,
            ptitle: "2N Bangalore, 2N Kabini, 2N Coorg & 1N Mysore",
            pdays: "7N|8D",
            pfrom: "Banglore",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: i14
                    },
                    {
                        iimg: i24
                    }
                ],
                ititle: "2N Bangalore, 2N Kabini, 2N Coorg & 1N Mysore",
                iprice: "₹ 25000",
                idays: "7N|8D",
                days:[
                    {
                        dayName: "day 1",
                        dayTitle: "Bangalore",
                        dayList: [
                            {
                                dayListDes: "On arrival at Bengaluru International Airport, you will be contacted and greeted by our Chauffeur. With all necessary assistance proceed to luxurious hotel in Bangalore.",
                            },
                            {
                                dayListDes: "Choose to spend the rest of the at leisure at the hotel or set off on a optional sightseeing spree of the Bangalore city, organized by the hotel. Experience the various famed sights of Bangalore or enjoy a bit of shopping at some of the many popular shopping malls and streets of the city in known for.",
                            },
                            {
                                dayListDes: "Return to the hotel. Overnight stay at Hotel in Bangalore.",
                            }
                        ]
                    },
                    {
                        dayName: "day 2",
                        dayTitle: "Bangalore – Kabini (240 ms / 5 – 6 Hrs Drive)",
                        dayList: [
                            {
                                dayListDes: "After breakfast check out from Hotel and drive to Kabini. The most popular wildlife destination of Karnataka. The name Kabini is not the official name of this place. It is the southern part of Nagarhole national park. The name comes to it as the forest stretch lies on the banks of river kabini also known as Kapila. Sighting of tigers, leapards, black panthers, wild dog, gaur and elephants have been witnessed by few of the travellers previously. On arrival Checkin to the resort and after lunch, choose a boat or vehicle safari into Nagarahole National Park and through Bandipur National Park (Optional).",
                            },
                            {
                                dayListDes: "Return to the resort, Overnight stay at Resort in Kabini.",
                            }
                        ]
                    },
                    {
                        dayName: "day 3",
                        dayTitle: "Kabini",
                        dayList: [
                            {
                                dayListDes: "Best time to spot animals and birds is early morning. You will be receiving a wake up call to head to the wildlife safari. After returning from safari, enjoy a filling breakfast. Later Choose to go on a boat ride (seasonal) and head out to befriend elephants and enjoy a unique elephant ride (Optional). After lunch, experience a memorable ride on a coracle, a traditional round-shaped boat (Optional), and drift down the Kabini River, enjoy the various sights and sounds around you, including a glorious sunset.",
                            },
                            {
                                dayListDes: "Return to the resort. Overnight stay at Resort in Kabini.",
                            }
                        ]
                    },
                    {
                        dayName: "day 4",
                        dayTitle: "Kabini – Coorg (110 Kms / 3 Hrs Drive)",
                        dayList: [
                            {
                                dayListDes: "Following breakfast at the hotel, drive to Coorg, a popular hill station popularly known as Scotland of India. The journey through misty roads takes you through aromatic coffee landscapes, set amidst western Ghat. Take a sightseeing trip to Bylakuppe, a town renowned as the second largest Tibetan settlement in India. Return to the resort and enjoy the evening.",
                            },
                            {
                                dayListDes: "Overnight at Resort in Coorg.",
                            }
                        ]
                    },
                    {
                        dayName: "day 5",
                        dayTitle: "Coorg",
                        dayList: [
                            {
                                dayListDes: "Enjoy Full day sightseeing at Dubare elephant camp and Nisargadhama. Spend your evening visiting the the misty cool Rajaseat.",
                            },
                            {
                                dayListDes: "Overnight at Resort in Coorg.",
                            }
                        ]
                    },
                    {
                        dayName: "day 6",
                        dayTitle: "Coorg – Mysore (121 Kms / 2.5 Hrs Drive)",
                        dayList: [
                            {
                                dayListDes: "After breakfast check out and proceed to Mysore. Mysore is the second-largest city in the state of Karnataka, India. Located at the base of the Chamundi Hills about 146 km (91 mi) southwest of the state capital Bangalore, Until 1947, Mysore served as the capital of the Kingdom of Mysore. The kingdom was ruled by the Wodeyar dynasty, except for a brief period in the late 18th century when Hyder Ali and Tipu Sultan usurped power. Patrons of art and culture, the Wodeyars contributed significantly to the cultural growth of the city. Mysore is noted for its palaces, including the Mysore Palace, and for the festivities that take place during the Dasara festival when the city receives a large number of tourists. It lends its name to the Mysore style of painting, the sweet dish Mysore Pak, the Mysore Peta (a traditional silk turban) and the garment known as the Mysore silk saree.",
                            },
                            {
                                dayListDes: "Arrive Mysore and check in to Hotel/Resort. Afternoon, visit Mysore Palace and Museum. Evening enjoy the beauty of Krishnaraj Sagara Gardens.",
                            },
                            {
                                dayListDes: "Back to Hotel/Resort by night. Overnight stay at Hotel/Resort.",
                            }
                        ]
                    },
                    {
                        dayName: "day 7",
                        dayTitle: "Mysore - Bangalore (145 Kms / 3.5 Hrs Drive)",
                        dayList: [
                            {
                                dayListDes: "After breakfast check out and proceed to Bangalore. Enroute Choose to visit the famed Srirangapattna*.",
                            },
                            {
                                dayListDes: "Arrive at Bangalore, rest of the day at leisure. Overnight stay at Hotel in Bangalore.",
                            }
                        ]
                    },
                    {
                        dayName: "day 8",
                        dayTitle: "Mysore - Bangalore (145 Kms / 3.5 Hrs Drive)",
                        dayList: [
                            {
                                dayListDes: "After breakfast check out and drive to Bangalore International Airport for your return journey back.",
                            },
                            {
                                dayListDes: "Tour Ends",
                            }
                        ]
                    }
                ],
                inclusions: [
                    {
                        inc_data: "2 Night accommodation at Hotel in Bangalore with Breakfast &amp; Taxes."
                    },
                    {
                        inc_data: "2 Nights accommodation at resort in Coorg with Lunch, Dinner, Breakfast &amp; Taxes."
                    },
                    {
                        inc_data: "2 Nights accommodation at resort in Kabini Lunch, Dinner, Breakfast &amp; Taxes."
                    },
                    {
                        inc_data: "1 Night accommodation at resort in Mysore with Breakfast &amp; Taxes."
                    },
                    {
                        inc_data: "All sightseeing &amp; Transfers by Toyota Innova Crysta as per the above Itinerary from Bangalore International Airport to Bangalore International airport."
                    }
                ],
                exclusions: [
                    {
                        exc_data: "Telephone, laundry, Tips etc."
                    },
                    {
                        exc_data: "Lunch at Ayne Mane Coorg."
                    },
                    {
                        exc_data: "Boat Safari / Jeep Safari at Bandipur National Forest."
                    },
                    {
                        exc_data: "Entrances at Mysore Palace, Museum, KrishnarajaSagar &amp; Srirangapatna Or Guide Charges."
                    },
                    {
                        exc_data: "Any other services availed other than mentioned in above cost include."
                    },
                    {
                        exc_data: "Any other optional tour which is not mentioned in above cost includes."
                    }
                ],
            }
        },
        {
            pimg: p54,
            ptitle: "3N Bangalore, 2N Hassan, 2N Mysore & 2N Bandipur",
            pdays: "9N|10D",
            pfrom: "Banglore",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: p53
                    },
                    {
                        iimg: p52
                    },
                    {
                        iimg: p51
                    },
                    {
                        iimg: p54
                    },
                    {
                        iimg: p55
                    }
                ],
                ititle: "3N Bangalore, 2N Hassan, 2N Mysore & 2N Bandipur",
                iprice: "₹ 25000",
                idays: "9N|10D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Bangalore",
                        dayList: [
                            {
                                dayListDes: "On arrival at Bengaluru International Airport, you are greeted and whisked into town to the boutique hotel in Bangalore.",
                            },
                            {
                                dayListDes: "Choose to spend the rest of the at leisure at the hotel or set off on a optional sightseeing spree of the Bangalore city, organized by the Travelpro Holiday. Experience the various famed sights of Bangalore or enjoy a bit of shopping at some of the many popular shopping malls and streets of the city in known for.",
                            },
                            {
                                dayListDes: "Return to the hotel. Overnight stay at Bangalore.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Bangalore - Hassan (185 Kms / 4 Hours drive)",
                        dayList: [
                            {
                                dayListDes: "Early breakfast at hotel, check out and proceed to Hassan, enroute visit Shravanabelagola, which is one of most important Jain Pilgrim centers. ",
                            },
                            {
                                dayListDes: "Shravanabelagola has two hills, Chandragiri and Vindhyagiri. Acharya Bhadrabahu and his pupil Chandragupta Maurya are believed to have meditated there. Chandragupta Basadi, which was dedicated to Chandragupta Maurya, was originally built there by Ashoka in the third century BC. Chandragiri also has memorials to numerous monks and Śrāvakas who have meditated there since the fifth century AD, including the last king of the Rashtrakuta dynasty of Manyakheta. Chandragiri also has a famous temple built by Chavundaraya, who was a disciple of Nemichandra.",
                            },
                            {
                                dayListDes: "The 57-feet tall monolithic statue of Gommateshvara is located on Vindyagiri Hill. It is considered to be the world's largest monolithic stone statue. The base of the statue has an inscriptions in Kannada as well as the oldest evidence of written Marathi, i.e. devnagari script, dating from 981 AD. The inscription praises the king who funded the effort and his general, Chavundaraya, who erected the statue for his mother. Every twelve years, thousands of devotees congregate here to perform theMahamastakabhisheka, a spectacular ceremony in which the statue is covered with milk, curds, ghee, saffron and gold coins. The next Mahamastakabhisheka will be held in 2018.",
                            },
                            {
                                dayListDes: "Later proceed to hotel, check-in, lunch and day is at leisure.",
                            },
                            {
                                dayListDes: "Overnight stay at hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Hassan",
                        dayList: [
                            {
                                dayListDes: "After breakfast visit Halebeedu & Belur.",
                            },
                            {
                                dayListDes: "Halebidu (which was previously called Dorasamudra or Dwarasamudra): was the regal capital of the Hoysala Empire in the 12th century. It is home to one of the best examples ofHoysala architecture in the ornate Hoysaleswara and Kedareswara temples. This name is given because this city was ruined two times by Bahmani Sultanate.",
                            },
                            {
                                dayListDes: "The Hoysaleswara temple was built during this time by Ketamala and attributed to Vishnuvardhana, the Hoysala ruler. It enshrines Hoysaleswara and Shantaleswara, named after the temple builder Vishnuvardhana Hoysala and his wife, Queen Shantala.",
                            },
                            {
                                dayListDes: "Belur: The town is renowned for its Chennakesava temple, one of the finest examples of Hoysala workmanship.",
                            },
                            {
                                dayListDes: "The main attraction in Belur is the Chennakeshava temple complex which contains the Chennakesava Temple (dedicated to Chennakeshava, meaning handsome Vishnu) as the centre piece, surrounded by the Kappe Chennigraya temple built by Shantaladevi, queen of king Vishnuvardhana.",
                            },
                            {
                                dayListDes: "There are two more shrines here that are still in use by devotees and there is a Pushkarni or stepped well to the right side of the main entrance. The Dravida style rayagopuram at the entrance which was a later addition by the Vijayanagar kings, who considered this deity as one of their Kuladevata or family god.",
                            },
                            {
                                dayListDes: "The temple is one of the finest examples of Hoysala architecture. It was built by king Vishnuvardhana in commemoration of his victory over the Cholas at Talakad in 1117 CE. Legend has it that it took 103 years to complete and Vishnuvardhana's grandsonVeera Ballala II completed the task. The facade of the temple is filled with intricate sculptures and friezes with no portion left blank. The intricate workmanship includes elephants, lions, horses, episodes from the Indian mythological epics, and sensuous dancers (Shilabalikas). Inside the temple are a number of ornate pillars. Darpana Sundari (Lady with the mirror) carved on walls of Belur Temple is one of major attractions in the complex.",
                            },
                            {
                                dayListDes: "The credit of carving most of the sculptures goes to Jakanachari. A legend says that Dankanachari(son of Jakanachari) found that the main statue of Lord Vishnu was faulty and had frog inside it. Jakana, shocked to believe this, cuts his right hand. He will begin to build the other temple and contemplates the task. Later, the old temple(which had faulty statue) came to be known as kappechennigaraaya's temple (kappe=frog)",
                            },
                            {
                                dayListDes: "Return to the hotel for lunch (optional). late afternoon enjoy the ancient traditional mode of transport bullock cart.",
                            },
                            {
                                dayListDes: "Rest of the day is at leisure. Overnight stay at hotel",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Hassan - Mysore (120 Kms / 3 Hours drive)",
                        dayList: [
                            {
                                dayListDes: "The Kingdom of Mysore was ruled by the Wodeyar dynasty, except for a brief and illustrious period in the late 18th century when Hyder Ali and Tipu Sultan were the distinguished rulers. Patrons of art and culture, the Wodeyars contributed significantly to the cultural growth of the city. The cultural ambience and achievements of Mysore earned it the sobriquet Cultural capital of Karnataka. ",
                            },
                            {
                                dayListDes: "Check-in to hotel, lunch at hotel (optional). Afternoon get ready to visit the most famous Mysore Palace and Museum.",
                            },
                            {
                                dayListDes: "The Palace of Mysore (also known as the Amba Vilas Palace) is a historical palace situated in the city of Mysore in Karnataka, southern India. It is the official residence of the Wodeyars, the former royal family of Mysore, which ruled the princely state of Mysore from 1399 to 1947. The palace also houses two durbar halls (ceremonial meeting hall of the royal court).",
                            },
                            {
                                dayListDes: "Mysore is commonly described as the City of Palaces, however the term 'Mysore Palace' refers specifically to the one within the old fort. ",
                            },
                            {
                                dayListDes: "Evening leisure time or to explore the Mysore town on own.",
                            },
                            {
                                dayListDes: "Overnight stay at hotel",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Mysore",
                        dayList: [
                            {
                                dayListDes: "After breakfast, set for an exploration of some more places around Mysore visiting, Sri Chamundeshwari Temple, Big Bull Temple, Local flower market & St. Philomena's Church. Return to hotel for lunch (optional) evening visit Brindavan Gardens is a show garden that has a beautiful botanical park, full of exciting fountains, as well as boat rides beneath the dam. Diwans of Mysore planned and built the gardens in connection with the construction of the dam. Display items include a musical fountain. Various biological research departments are housed here.",
                            },
                            {
                                dayListDes: "Rest of the day is at leisure. Overnight stay at hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Mysore - Bandipur (90 kms / 2 Hours drive)",
                        dayList: [
                            {
                                dayListDes: "After breakfast check-out and proceed to Bandipur Wildlife Sanctuary. Enroute visit to Nanjanagude : The Srikanteshwara Temple at Nanjangud is dedicated to the Hindu God Shiva, also called Sri Nanjundeshwara, ('The God who drank poison') to save the earth. In Hindu mythology, the Gods and demons churned the ocean in search of the ambrosia. During this churning, poison emanated first, followed by ambrosia. To prevent the poison from spreading across the universe, Shiva drank it. His consort Parvati then held his throat tightly to prevent the poison from spreading to the rest of his body. The poison remained in his throat, making it blue in colour. For this reason, Shiva is also called Neelakantha, or 'the blue-throated one'. Nanjanagud literally means 'the place where Nanja (Nanjundeshwara) resides' or 'home of Nanja (Nanjundeshwara)'.",
                            },
                            {
                                dayListDes: "Nanjangud is also called as 'Dakshina Kashi' (southern Kashi). This city is also famous for a variety of banana grown in the region, the Nanjanagoodu rasabale. Afternoon check-in to resort, lunch (optional). Late aftrenoon take safari in Bandipur wildlife sanctuary (optional). ",
                            },
                            {
                                dayListDes: "Overnight stay at resort.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "Bandipur",
                        dayList: [
                            {
                                dayListDes: "Early morning wakeup call, take an optional Safari again (optional). rest of the day is at leisure or watch wildlife movies in resort library.",
                            },
                            {
                                dayListDes: "Overnight stay at resort.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "Bandipur - Bangalore (220 kms / 4 Hours drive)",
                        dayList: [
                            {
                                dayListDes: "Check-out from resort after breakfast and travel back once again to Karnataka's capital city Bangalore. Enroute lunch (optional). Choose to visit the Srirangapattna en route. (Optional)",
                            },
                            {
                                dayListDes: "check-in to hotel. rest of the day is at leisure.",
                            },
                            {
                                dayListDes: "Overnight stay at Bangalore. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "Bangalore ",
                        dayList: [
                            {
                                dayListDes: "After breakfast at hotel, proceed to Half a Day Bangalore sightseeing – Drive past Vidhana Soudha & Cubbon Park, Bull Temple, Tippu Palace & Bangalore Palace.",
                            },
                            {
                                dayListDes: "Return back to hotel, and enjoy rest of the day at leisure.",
                            },
                            {
                                dayListDes: "Overnight stay at Bangalore.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 10",
                        dayTitle: "Bangalore – Bangalore Airport (45 Kms / 1.5 Hrs Drive)",
                        dayList: [
                            {
                                dayListDes: "After breakfast check out and drive to Bangalore International Airport for your return journey back",
                            },
                            {
                                dayListDes: "Tour Ends",
                            }
                        ]
                    }
                ],
                inclusions: [
                ],
                exclusions: [
                ],

            }
        },
        {
            pimg: p57,
            ptitle: "3N Goa, 3N Badami, 3N Hampi & 2N Bangalore",
            pdays: "11N|12D",
            pfrom: "Goa",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: p51
                    },
                    {
                        iimg: p2
                    },
                    {
                        iimg: p56
                    },
                    {
                        iimg: p57
                    }
                ],
                ititle: "3N Goa, 3N Badami, 3N Hampi & 2N Bangalore",
                iprice: "₹ 25000",
                idays: "11N|12D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Goa",
                        dayList: [
                            {
                                dayListDes: "Arrive Goa, meet and greet at the airport, transfer to hotel. Check in to hotel. Rest of the day is at leisure.",
                            },
                            {
                                dayListDes: "Overnight stay at hotel.",
                            },
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Goa",
                        dayList: [
                            {
                                dayListDes: "After breakfast half a day visit to Old Goa Churches. afternoon return to hotel, rest of the day is at leisure. Overnight stay.",
                            },
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Goa",
                        dayList: [
                            {
                                dayListDes: "Breakfast at hotel. Full days is at leisure to enjoy the Goa beach. Over night at hotel.",
                            },
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Goa - Badami (approx 275 Kms / 6 to 7 Hours drive)",
                        dayList: [
                            {
                                dayListDes: "Early morning drive to Badami via Hubli. (7 – 8 Hrs), check in to hotel. Overnight stay at hotel in Badami.",
                            },
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Badami",
                        dayList: [
                            {
                                dayListDes: "AM Sightseeing of Badami Covering",
                            },
                            {
                                dayListDes: "Cave No. 1: Made of red sandstone, dates back to 578 A.D. and was probably the first cave to be carved. One has to climb up 40 odd steps to reach the colonnaded verandah, a hall with numerous pillars and a square shaped sanctum hollowed in the control back wall. Column shafts are masterfully crafted. On the ceiling one can see the paintings of amorous couples. Shiva and his consort Parvati, and a coiled serpent. The main attraction of the cave is the 18-armed Lord Nataraja, seen in 81 different dancing poses.",
                            },
                            {
                                dayListDes: "Cave No. 2: Dedicated to Lord Vishnu, the cave has depiction of the Lord in one of his incarnations as a dwarf or Vaman. This Vaman or Trivikrama had the ability to grow and take the earth and sky in one strand. The secon cave atop a sand stone hill shows this image of the Lord. Another form of Vishnu portrayed here is as ''Varaha'' or as a boar. Then there is a frieze endearingly depicting Vishnu as Lord Krishna.",
                            },
                            {
                                dayListDes: "Cave No 3: As one climbs the flight of steps, he reaches the huge 70 feet wide cave number three. This is a beautiful cave with its sheer artistry and sculptural genius, it has become the highlight of Deccan art.  It gives a virtual insight into the art and culture of the 6th century like costumes, jewelry hairstyle lifestyle etc. The other attractions to be looked carefully in this cave are the high relief of Vishnu with a serpent, Vishnu as Narasimha (Vishnu as Man-Lion) Varaha, Harihara (Shiva Vishnu) and Vishnu as Trivikrama.",
                            },
                            {
                                dayListDes: "Cave No. 4: The only Jain cave among the lot, construction of which completed only 100 years later than the earlier three caves. Here one can see the carvings of the Tirthankara Parshavnatha with a serpent at his feet; Mahavira in a sitting posture also can be seen in the shrine.",
                            },
                            {
                                dayListDes: "Overnight at Hotel in Badami.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Badami - Pattadkallu - Aihole - Badami (approx 70 Kms round trip)",
                        dayList: [
                            {
                                dayListDes: "Pattadakal, place for Chalukyas Coronation, the capital of the Chalukya dynasty of Karnataka in Southern India, who built the temples between the 7th and 9th centuries. There are ten temples including a Jain sanctuary surrounded by numerous small shrines and plinths in fusion of various Indian architectural styles (Rekha, Nagara, Prasada and Dravida Vimana). Four temples were built in Chalukya Dravidian style, four in Nagara style of Northern India and the Papanatha temple in mixed style. Nine Shiva temples and one Jaina basadi, situated along the northern course of the River, which is considered as very auspicious according to Holy Scriptures.",
                            },
                            {
                                dayListDes: "Pattadakal is a great centre of Chalukya art and architectural, noted for its temples and inscriptions. According to inscriptions, the place was known by the names Kisuvolal (Red Town - mostly mountains near pattadakal gave this name), Raktapura, Pattada Kisuvolal. The literary work Hammira Kavya of 1540. quotes the place as Pattashilapura and Hammirapura. It has been mentioned in the 11th and 12th century inscriptions, as well as in the literary work Singirajapurana of 1500 and Hammira Kavya as the place where the Chalukya kings were crowned.",
                            },
                            {
                                dayListDes: "Pattadakal continued to be an important centre under the Rashtrakutas and the Kalyani Chalukyas. It became a chief city for a small region called Kisukadu-70. The Sindhas of Yaramabarige (Yelburgi) also ruled it for some time.",
                            },
                            {
                                dayListDes: "Aihoḷe was known as Ayyavoḷe and Aryapura in its inscriptions. It was established in 450 C.E. as first capital of Chalukya kings and has about 125 stone temples, some which were constructed as experimental structures by artisans of Chalukyan period. A place known by the name Morera Angadigalu near the Meguti hillocks has a large number of cists of pre-historic period. The place was an agraharam. Aihoḷe has been described as one of the cradles of temple architecture. Some brick structures of pre-Chalukyan times have also been excavated.",
                            },
                            {
                                dayListDes: "According to mythology Aihole is the place where Parashurama washed his axe after killing the Kshatriyas. Aihole has historical significance and is called as cradle of Hindu rock architecture (Cradle of Indian architecture).",
                            },
                            {
                                dayListDes: "Pulakesi-I, one of the greatest rulers of this dynasty, moved the capital to Badami nearby. Badami was then known as Vatapi. It is from these temples that the Chalukyas gained their experience and went on to build the great temples of Pattadakal. The first phase of temple building in Aihole dates back to the 6th century CE, the second phase up to the 12th century CE.",
                            },
                            {
                                dayListDes: "Return to hotel and overnight stay at hotel in Badami.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "Badami to Hospet (approx 130 kms / 2.5 Hour drive)",
                        dayList: [
                            {
                                dayListDes: "After breakfast check-out and proceed to Hospet. Check-in to hotel, rest of the day is at leisure, overnight stay at hotel in Hospet.",
                            },
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "Hospet - Hampi - Hospet (approx 30 Kms round trip)",
                        dayList: [
                            {
                                dayListDes: "This morning Sightseeing of Hampi covering: - Elephant Stables,  Vithala Temple Pillar (left) and Stone Chariot, Virupaksha Temple. ",
                            },
                            {
                                dayListDes: "Overnight stay at hotel in Hospet.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "Hospet - Daroji Sloth Bear Sanctuary - Hospet ",
                        dayList: [
                            {
                                dayListDes: "After breakfast, take half day excursion to Daroji Sloth Bear Sanctuary. Return to hotel. Day at leisure.",
                            },
                            {
                                dayListDes: "Overnight stay at hotel in Hospet.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 10",
                        dayTitle: "Hospet - Bangalore (approx 370 Kms / 6 - 7 Hour drive)",
                        dayList: [
                            {
                                dayListDes: "Arrive Bangalore and check-in to hotel. day is at leisure.",
                            },
                            {
                                dayListDes: "Overnight stay at Bangalore. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 11",
                        dayTitle: "Bangalore",
                        dayList: [
                            {
                                dayListDes: "Morning, proceed for the sightseeing tour of Bangalore covering: Bull Temple, Tippu’s Palace, Botanical Garden, Bangalore Palace & Drive past Vidhana Soudha. ",
                            },
                            {
                                dayListDes: "Overnight at the Bangalore. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 12",
                        dayTitle: "Bangalore",
                        dayList: [
                            {
                                dayListDes: "After breakfast check out from hotel and you will be dropped to Bangalore Airport to catch a flight to Bangalore.",
                            },
                            {
                                dayListDes: "Tour Ends. ",
                            }
                        ]
                    }
                ],
                inclusions: [
                ],
                exclusions: [
                ],

            }
        }
    ]
}