import React, {useEffect, useState} from 'react';
import DropDown from '../component/DropDown';
import FooterSection from '../component/FooterSection';
import PageA from '../component/home/PageA';
import TripPage from '../component/home/TripPage';
import NavBar from '../component/NavBar';
import ProductPage from '../component/ProductSlider/ProductPage';
import EnqForm from '../component/submitForm/EnqForm';
import SendMsg from '../component/submitForm/SendMsg';
import { HomeStayA } from '../data/HomeStayA';
import { Sakresort } from '../data/Sakresort';

const Home = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [enOpen, setEnOpen] = useState(false);
    const [sendOpen, setSendOpen] = useState(false);
    const [enqyFor, setEnqyFor] = useState("");

    const toggle = () => {
        setIsOpen(!isOpen);
    }
    const enquiry = (enqData) => {
        setEnOpen(!enOpen);
        setEnqyFor(enqData);
    }
    const sendBox = () => {
        setSendOpen(!sendOpen);
    }
    useEffect(() => {
        window.scroll(0,0);
    },[]);
    return (
        <>
            <NavBar toggle = {toggle}/>
            <DropDown isOpen = {isOpen} toggle = {toggle}/>
            <PageA />
            <TripPage />
            <ProductPage enquiry = {enquiry} arraybox = {HomeStayA} boxname = {'HomeStays'} link ={"/homestay"}/>
            <ProductPage enquiry = {enquiry} arraybox = {Sakresort} boxname = {'Resort'} link ={"/resorts"}/>
            <FooterSection enquiry = {enquiry}/>
            <EnqForm enForm = {enOpen} enFor = {enqyFor} enquiry = {enquiry} sendBox ={sendBox}/>
            <SendMsg dispSub = {sendOpen} sendBox ={sendBox}/>
        </>
    )
}

export default Home
