import demoImg from '../media/package/uttarkhand.jpg';
import demoImg1 from '../media/package/tamilnadu.jpg';

import g11 from '../media/sakleshpur/gallery/AbbiGundiFalls/1.jpg';
import g12 from '../media/sakleshpur/gallery/AbbiGundiFalls/2.jpeg';
import g13 from '../media/sakleshpur/gallery/AbbiGundiFalls/3.jpeg';
import g14 from '../media/sakleshpur/gallery/AbbiGundiFalls/4.jpg';

import g21 from '../media/sakleshpur/gallery/BettadaByraveshwara/1.jpg';
import g22 from '../media/sakleshpur/gallery/BettadaByraveshwara/2.jpg';
import g23 from '../media/sakleshpur/gallery/BettadaByraveshwara/3.jpg';
import g24 from '../media/sakleshpur/gallery/BettadaByraveshwara/4.jpg';
import g25 from '../media/sakleshpur/gallery/BettadaByraveshwara/5.jpg';
import g26 from '../media/sakleshpur/gallery/BettadaByraveshwara/6.jpg';
import g27 from '../media/sakleshpur/gallery/BettadaByraveshwara/7.jpg';
import g28 from '../media/sakleshpur/gallery/BettadaByraveshwara/8.jpg';
import g29 from '../media/sakleshpur/gallery/BettadaByraveshwara/9.jpg';

import g31 from '../media/sakleshpur/gallery/BisleViewPoint/1.jpg';
import g32 from '../media/sakleshpur/gallery/BisleViewPoint/2.jpg';
import g33 from '../media/sakleshpur/gallery/BisleViewPoint/3.jpg';
import g34 from '../media/sakleshpur/gallery/BisleViewPoint/4.jpg';
import g35 from '../media/sakleshpur/gallery/BisleViewPoint/5.jpg';
import g36 from '../media/sakleshpur/gallery/BisleViewPoint/6.jpg';
import g37 from '../media/sakleshpur/gallery/BisleViewPoint/7.jpg';

import g41 from '../media/sakleshpur/gallery/GaviBetta/1.jpg';
import g42 from '../media/sakleshpur/gallery/GaviBetta/2.jpg';
import g43 from '../media/sakleshpur/gallery/GaviBetta/3.jpg';
import g44 from '../media/sakleshpur/gallery/GaviBetta/4.jpg';
import g45 from '../media/sakleshpur/gallery/GaviBetta/5.jpg';
import g46 from '../media/sakleshpur/gallery/GaviBetta/6.jpg';
import g47 from '../media/sakleshpur/gallery/GaviBetta/7.jpg';
import g48 from '../media/sakleshpur/gallery/GaviBetta/8.jpg';
import g49 from '../media/sakleshpur/gallery/GaviBetta/9.jpg';
import g410 from '../media/sakleshpur/gallery/GaviBetta/10.jpg';

import g51 from '../media/sakleshpur/gallery/KadmaneEstate/1.jpg';
import g52 from '../media/sakleshpur/gallery/KadmaneEstate/2.jpg';
import g53 from '../media/sakleshpur/gallery/KadmaneEstate/3.jpg';
import g54 from '../media/sakleshpur/gallery/KadmaneEstate/4.jpg';

import g61 from '../media/sakleshpur/gallery/MookanamaneFalls/1.jpg';
import g62 from '../media/sakleshpur/gallery/MookanamaneFalls/2.jpg';
import g63 from '../media/sakleshpur/gallery/MookanamaneFalls/3.jpg';
import g64 from '../media/sakleshpur/gallery/MookanamaneFalls/4.jpg';

import g71 from '../media/sakleshpur/gallery/Yedakumari/1.jpg';
import g72 from '../media/sakleshpur/gallery/Yedakumari/2.jpg';
import g73 from '../media/sakleshpur/gallery/Yedakumari/3.jpg';
import g74 from '../media/sakleshpur/gallery/Yedakumari/4.jpg';
import g75 from '../media/sakleshpur/gallery/Yedakumari/5.jpg';
import g76 from '../media/sakleshpur/gallery/Yedakumari/6.jpg';



export const SakleshpurDetails = [
    {
        titleImage : g11,
        titleHeading: "Abbi Gundi Falls",
        arrayImg: [
            {
                imgD: g11
            },
            {
                imgD: g12
            },
            {
                imgD: g13
            },
            {
                imgD: g14
            },
        ]
    },
    {
        titleImage : g21,
        titleHeading: "Bettada Byraveshwara",
        arrayImg: [
            {
                imgD: g21
            },
            {
                imgD: g22
            },
            {
                imgD: g23
            },
            {
                imgD: g24
            },
            {
                imgD: g25
            },
            {
                imgD: g27
            },
            {
                imgD: g28
            },
            {
                imgD: g29
            },
        ]
    },
    {
        titleImage : g31,
        titleHeading: "Bisle View Point",
        arrayImg: [
            {
                imgD: g31
            },
            {
                imgD: g32
            },
            {
                imgD: g34
            },
            {
                imgD: g35
            },
            {
                imgD: g36
            },
            {
                imgD: g37
            }
        ]
    },
    {
        titleImage : g41,
        titleHeading: "Gavi Betta",
        arrayImg: [
            {
                imgD: g41
            },
            {
                imgD: g42
            },
            {
                imgD: g43
            },
            {
                imgD: g44
            },
            {
                imgD: g45
            },
            {
                imgD: g46
            },
            {
                imgD: g47
            },
            {
                imgD: g48
            },
            {
                imgD: g49
            },
            {
                imgD: g410
            }
        ]
    },
    {
        titleImage : g51,
        titleHeading: "Kadmane Estate",
        arrayImg: [
            {
                imgD: g51
            },
            {
                imgD: g52
            },
            {
                imgD: g53
            },
            {
                imgD: g54
            }
        ]
    },
    {
        titleImage : g61,
        titleHeading: "Mookanamane Falls",
        arrayImg: [
            {
                imgD: g61
            },
            {
                imgD: g62
            },
            {
                imgD: g63
            },
            {
                imgD: g64
            }
        ]
    },
    {
        titleImage : g71,
        titleHeading: "Yedakumari",
        arrayImg: [
            {
                imgD: g71
            },
            {
                imgD: g72
            },
            {
                imgD: g73
            },
            {
                imgD: g74
            },
            {
                imgD: g75
            },
            {
                imgD: g76
            }
        ]
    }
]