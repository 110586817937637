import React from 'react';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import h2 from '../media/homepage/h2.jpg';
import h4 from '../media/homepage/h4.jpg';
import h7 from '../media/homepage/h7.jpg';
import h8 from '../media/homepage/h8.jpg';
import h9 from '../media/homepage/h9.jpg';
import h10 from '../media/homepage/h10.jpg';
import h11 from '../media/homepage/h11.jpg';
import v1 from '../media/homepage/v2.mp4';

const BoxImg = styled.img`
    /* margin-top: 100px; */
    height: calc(100vh - 0px);
    object-fit: fill;
`;
// const Heading = styled.h1`
//     position: relative;
//     font-size: 60px;
//     padding: 20px 0;
//     font-family: 'arima';
//     text-transform: capitalize;
//     z-index: 998;
//     color: #fff;
//     text-align: center;
//     p {
//         color: #fff;
//         font-size: 19px;
//         text-align: center;
//     }
// `;
// const ExBox = styled.button`
//     position: relative;
//     padding: 6px 40px;
//     background: transparent;
//     border: 2px solid #fff;
//     text-transform: uppercase;
//     font-size: 20px;
//     font-family: 'open_san';
//     color: #fff;
//     margin-bottom: 30px;
//     cursor: pointer;
//     z-index: 998;
// `;
const HeroData = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const BoxVi = styled.video`
    height: calc(100vh);
    object-fit: cover;
   
`;
const BoxSlider = () => {
    return (
        <>
            <Carousel>
                <Carousel.Item>
                    <BoxImg
                    className="d-block w-100"
                    src={h2}
                    alt="Second slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <BoxImg
                    className="d-block w-100"
                    src={h8}
                    alt="Second slide"
                    />
                </Carousel.Item>
                {/* <Carousel.Item>
                    <BoxImg
                    className="d-block w-100"
                    src={h5}
                    alt="Second slide"
                    />
                </Carousel.Item> */}
                <Carousel.Item>
                    <BoxImg
                    className="d-block w-100"
                    src={h7}
                    alt="Second slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <BoxImg
                    className="d-block w-100"
                    src={h11}
                    alt="Second slide"
                    />
                </Carousel.Item>
                {/* <Carousel.Item>
                    <BoxImg
                    className="d-block w-100"
                    src={h1}
                    alt="Second slide"
                    />
                </Carousel.Item> */}
                <Carousel.Item>
                    <BoxImg
                    className="d-block w-100"
                    src={h9}
                    alt="Second slide"
                    />
                    {/* <HeroData>
                        <Heading>Sakleshpur</Heading>
                        <ExBox>explore</ExBox>
                    </HeroData> */}
                </Carousel.Item>
                <Carousel.Item>
                    <BoxImg
                    className="d-block w-100"
                    src={h10}
                    alt="Second slide"
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <BoxImg
                    className="d-block w-100"
                    src={h4}
                    alt="Third slide"
                    />
                    <HeroData>
                        {/* <Heading>
                            TravelPro Holiday
                            <p>rediscovery luxury...</p>
                        </Heading> */}
                    </HeroData>
                </Carousel.Item>
                <Carousel.Item interval={10000}>
                    <BoxVi className="d-block w-100" src= {v1} muted loop autoPlay/>
                </Carousel.Item>
            </Carousel>
        </>
    )
}

export default BoxSlider
