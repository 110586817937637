import dehli1 from '../media/package/commImage/dehli1.jpg';
import dehli2 from '../media/package/commImage/dehli2.jpg';
import bikaner1 from '../media/package/commImage/bikaner1.jpg';
import jaipur1 from '../media/package/commImage/jaipur1.jpg';
import jaipur2 from '../media/package/commImage/jaipur2.jpg';
import jaipur3 from '../media/package/commImage/jaipur3.jpg';
import jaipur4 from '../media/package/commImage/jaipur4.jpg';
import jaipur5 from '../media/package/commImage/jaipur5.jpg';
import jaisalmer1 from '../media/package/commImage/jaisalmer1.jpg';
import jaisalmer2 from '../media/package/commImage/jaisalmer2.jpg';
import jodhpur1 from '../media/package/commImage/jodhpur1.jpg';
import jodhpur2 from '../media/package/commImage/jodhpur2.jpg';
import udaipur1 from '../media/package/commImage/udaipur1.jpg';
import udaipur2 from '../media/package/commImage/udaipur2.jpg';
import udaipur3 from '../media/package/commImage/udaipur3.jpg';

export const RajasthanPackage = {
    packageName: "Rajasthan",
    titleImg: jodhpur2,
    packages:  [
        {
            pimg: jaipur4,
            ptitle: "2N Delhi, 2N Jaipur, 1N Bikaner, 2N Jaisalmer, 1N Jodhpur, 2N Udaipur",
            pdays: "10N|11D",
            pfrom: "Delhi",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: dehli2
                    },
                    {
                        iimg: jaipur2
                    },
                    {
                        iimg: jaipur5
                    },
                    {
                        iimg: jaisalmer1
                    },
                    {
                        iimg: udaipur3
                    }
                ],
                ititle: "2N Delhi, 2N Jaipur, 1N Bikaner, 2N Jaisalmer, 1N Jodhpur, 2N Udaipur",
                iprice: "₹ 25000",
                idays: "10N|11D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrive at Delhi",
                        dayList: [
                            {
                                dayListDes: "On arrival at Delhi International Airport, you are welcomed by the Travelpro Holiday representative and whisked into town to your hotel. Choose to spend the rest of the day at leisure at the hotel or on exploring Delhi on your own.",
                            },
                            {
                                dayListDes: "Overnight stay at the hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "In Delhi",
                        dayList: [
                            {
                                dayListDes: "After breakfast, set off on a sightseeing spree of New & Old Delhi. Visit Qutab Minar, the soaring and gracefully fluted 12th century tower that stands 234 feet tall. Standing close to the Qutub Minar is the Iron Pillar, still withstanding the ravages of time and without a touch of rust in over 840 years! Visit the elegant mausoleum of the Mughal Emperor Humayun and the modern Birla Temple. Take a leisurely drive past the President's House, the Parliament House and the Secretariat buildings. Take a trip down history lane with a visit to the historical Red Fort, built in 1648 by Mughal Emperor Shah Jahan.  Experience the majesty of the Jama Masjid, one of the largest mosques in India. Retreat for a few moments of quiet and respectful solitude at The Raj Ghat and Shanti Vana, the cremation sites of Mahatma Gandhi and Jawaharlal Nehru respectively. Enjoy a break from history and visit Chandni Chowk for a unique shopping experience at Old Delhi. ",
                            },
                            {
                                dayListDes: "Overnight stay at hotel in Delhi. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Delhi - Jaipur (approx 275 Kms/ 4 - 5 Hour drive)",
                        dayList: [
                            {
                                dayListDes: "Leave early in the morning for Jaipur and on arrival check-in at the hotel. Spend the rest of the day at leisure.",
                            },
                            {
                                dayListDes: "Overnight stay at Hotel in Jaipur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Jaipur",
                        dayList: [
                            {
                                dayListDes: "After breakfast, embark on a sightseeing tour of Amber Fort, the ancient capital of the State till 1728, and of Jaipur city. Take a memorable and unique ride on elephant back to and from the top of the hill on which the Fort stands. Enjoy visits to the Temple of Kali, the Hall of Victory or Jai Mahal and Jagmandir. The city of Jaipur takes its name from Maharajah Jai Singh, a renowned prince and astronomer, who had designed and founded this famous city in 1727. Don’t miss a visit to the quaint observatory built by him and equipped with instruments he had designed. These devices are of remarkable sizes and with impeccable accuracy even in today’s age! Also visit the Maharaja's City Palace, a former royal residence and now a museum that houses rare manuscripts, fine pieces of Rajput and Mughal paintings and weapons. Drive through the pink toned residential and business areas, past the Hawa Mahal or Palace of Winds, a landmark of Jaipur.  Overnight stay at hotel in Jaipur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Jaipur - Bikaner (approx 330 Kms / 5 - 6 Hours/ drive)",
                        dayList: [
                            {
                                dayListDes: "Set off early in the morning to Bikaner. Check-in at Hotel on arrival and in the afternoon take the sightseeing tour of Bikaner, a royal fortified city set in the north of the desert state. Bolstered by imposing walls, Bikaner has a timeless appeal, retaining a medieval splendor that pervades the city’s lifestyle. Dotted with many sand dunes Bikaner is popularly called the 'Camel Country', and is renowned for the best riding camels in the world. Take a visit to Lalgarh Palace, an architectural masterpiece in red sandstone, built by Maharaja Ganga Singh in memory of his father Maharaja Lal Singh.",
                            },
                            {
                                dayListDes: "Also enjoy exploring Junagarh Fort, built in 1593 A.D by Raja Rai Singh, a General in the army of Emperor Akbar. This fort is a formidable structure encircled by a moat and has several exquisite palaces within. These palaces, crafted in red sandstone and marble, create a picturesque ensemble of courtyards, balconies, kiosks and windows across the entire structure. The fort is distinguished by a wide range of pavilions, a long line of balconies and innumerable aerial windows of various intricate designs. An enormous and elaborately bedecked doorway leads to the Joramal Temple and the royal Chapel, Har Mandir.",
                            },
                            {
                                dayListDes: "Overnight stay at Hotel in Bikaner. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Bikaner to Jaisalmer (approx 330 Kms / 5 to 6 Hours drive)",
                        dayList: [
                            {
                                dayListDes: "Early the next morning set off on the drive to Jaisalmer, and on arrival check-in at Hotel. Spend the evening at the sand dunes and enjoy camel rides and a glorious sunset. Nestled in the heart of the Thar Desert, the sand dunes are intricately moulded from burnished sandstone. ",
                            },
                            {
                                dayListDes: "Overnight stay at Hotel in Jaisalmer.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "Jaisalmer",
                        dayList: [
                            {
                                dayListDes: "Following breakfast, spend the morning sightseeing in Jaisalmer. Visit Sonar Fort where a fourth of the city’s residents reside. Also visit Patwon Ki Haveli, an exquisite haveli with a beautifully latticed façade. Another famous haveli is the Salim Singh Ki Haveli, over three centuries old and still lived in, with an arched roof that is held high by well-executed brackets shaped as peacocks. Then there is the Nathmalji Ki Haveli that was built by two architect brothers. Spend the afternoon at leisure. ",
                            },
                            {
                                dayListDes: "Overnight stay at Hotel in Jaisalmer.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "Jaisalmer to Jodhpur (approx 290 Kms / 7 Hours drive)",
                        dayList: [
                            {
                                dayListDes: "Set off for Jodhpur early in the morning and check-in at hotel on arrival. In the afternoon take a sightseeing tour of Jodhpur, set at the very edge of the Thar Desert. Founded in 1459 A.D. by Rao Jodha and once the capital of the Marwar State, this imperial city echoes with tales of antiquity within the emptiness of the desert. Rao Jodha had been the chief of the Rathore clan of Rajputs, who claimed to be descendants of Rama - the epic hero of the Ramayana. The 5 km long majestic Mehrangarh Fort on a 125 m high hill is one of the most impressive and formidable structures in Jodhpur. Although invincible from the outside, the Fort has four gates approachable by a winding road. Within the Fort are some splendid palaces with magnificently carved panels, latticed windows and evocative names. Noteworthy of these are the Moti Mahal, the Phool Mahal, the Sheesh Mahal, the Sileh Khana and the Daulat Khana. Another monument that must be visited is the Jaswant Thada, a cluster of royal cenotaphs in white marble, built in 1899 A.D. in memory of Maharaja Jaswant Singh II. ",
                            },
                            {
                                dayListDes: "Overnight stay at Hotel in Jodhpur. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "Jodhpur To Udaipur (approx 85 Kms / 2 Hours drive)",
                        dayList: [
                            {
                                dayListDes: "Drive to Udaipur in the morning and check-in at hotel on arrival. Take an afternoon excursion to Eklingji, home to the private temple of the head of the Sisodia clan, and visit the Nagda temples. ",
                            },
                            {
                                dayListDes: "Overnight stay at Hotel in Udaipur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 10",
                        dayTitle: "Udaipur",
                        dayList: [
                            {
                                dayListDes: "After breakfast, set off on a sightseeing tour of Udaipur. Take a leisurely drive around the manmade Fatehsagar Lake, built by Maharana Fateh Singh in 1754 A.D. Visit the Jagdish Temple, the largest and the most venerated temple of Udaipur. Built in 1651 A.D. by Maharana Jagat Singh I, the Jagdish Temple is beautifully decorated with friezes. Visit the Garden of Maidens – ‘Sahelion-Ki-Bari’, with its ornamental pool set amidst delicately carved ‘chhatries’ of black and white marble and soft stone. Also visit the Maharana's City Palace, an imposing edifice of granite and marble. Enjoy the afternoon at leisure. ",
                            },
                            {
                                dayListDes: "Overnight stay at Hotel in Udaipur. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 11",
                        dayTitle: "Udaipur Airport",
                        dayList: [
                            {
                                dayListDes: "Check-out from hotel in the morning and in time transfers to the airport where you can take your connecting flight to onward destination via Mumbai / Delhi. ",
                            },
                            {
                                dayListDes: "Tour Ends",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        }
    ]
}