import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
`;

const BGImg = styled.img`    
    width: 100vw;
    height: 100vh;
    object-fit: cover;

`;

const BackImg = ({img}) => {
    return (
        <Section>
            <BGImg src = {img} />
        </Section>
    )
}

export default BackImg
