import pak1 from '../media/package/pak1.jpg';
import pak2 from '../media/package/pak2.jpg';
import pak3 from '../media/package/pak31.jpg';
import pak4 from '../media/package/pak4.jpg';
import pak5 from '../media/package/pak5.jpg';
import pak6 from '../media/package/pak6.jpg';
import pak7 from '../media/package/pak7.jpg';
import uk from '../media/package/uttarkhand.jpg';
import tn from '../media/package/tamilnadu.jpg';
import os from '../media/package/orissa.jpg';
import ne from '../media/package/ne.jpg';
import mp from '../media/package/mp.jpg';
import mh from '../media/package/mh.jpg';
import hy from '../media/package/hy.jpg';
import pak8 from '../media/package/multipack.jpg';
export const PackageListOne = [
    {
        title: 'Karnataka',
        link: '/packages/karnataka',
        image: pak1,
        dur: '900',
        classTag: "spacial-non",
        btnName: 'Explore'
    },
    {
        title: 'Rajasthan',
        link: '/packages/rajasthan',
        image: pak2,
        dur: '1200',
        classTag: "spacial-non",
        btnName: 'Explore'
    },
    {
        title: 'goa',
        link: '#',
        image: pak3,
        dur: '1400',
        classTag: "spacial-non",
        btnName: 'Coming Soon'
    },
    {
        title: 'himachal pradesh',
        link: '#',
        image: pak4,
        dur: '1700',
        classTag: "spacial-non",
        btnName: 'Coming Soon'
    },
    {
        title: 'Multi State packages',
        link: '/packages/multistate',
        image: pak8,
        dur: '3600',
        classTag: "spacial",
        btnName: 'Explore'
    },
    {
        title: 'jammu & kashmir',
        link: '/packages/jammukashmir',
        image: pak5,
        dur: '2000',
        classTag: "spacial-non",
        btnName: 'Explore'
    },
    {
        title: 'kerala',
        link: '#',
        image: pak6,
        dur: '2300',
        classTag: "spacial-non",
        btnName: 'Coming Soon'
    },
    {
        title: 'leh ladakh',
        link: '/packages/lehladakh',
        image: pak7,
        dur: '2600',
        classTag: "spacial-non",
        btnName: 'Explore'
    },
    {
        title: 'uttarakhand',
        link: '#',
        image: uk,
        dur: '2600',
        classTag: "spacial-non",
        btnName: 'Coming Soon'
    },
    {
        title: 'madhya pradesh',
        link: '#',
        image: mp,
        dur: '2300',
        classTag: "spacial-non",
        btnName: 'Coming Soon'
    },
    {
        title: '7 sisters northeast',
        link: '/packages/sevensisters',
        image: ne,
        dur: '2000',
        classTag: "spacial-non",
        btnName: 'Explore'
    },
    {
        title: 'tamil nadu',
        link: '#',
        image: tn,
        dur: '1700',
        classTag: "spacial-non",
        btnName: 'Coming Soon'
    },
    {
        title: 'maharashtra',
        link: '#',
        image: mh,
        dur: '1400',
        classTag: "spacial-non",
        btnName: 'Coming Soon'
    },
    {
        title: 'andhra pradesh & telangana',
        link: '#',
        image: hy,
        dur: '1200',
        classTag: "spacial-non",
        btnName: 'Coming Soon'
    },
    {
        title: 'orissa',
        link: '#',
        image: os,
        dur: '900',
        classTag: "spacial-non",
        btnName: 'Coming Soon'
    },
];