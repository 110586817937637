import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
    width: 100vw;
    height: 60vh;
    @media screen and (max-width: 1024px) {
        height: 80vh;
    }
`;

const ImgBox = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;
const ImgData = styled.img`
    width: 100%;
    height: 100%;
    object-fit: cover;
`;
const HeadingBox = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.3);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 20px;
    color: #fff;
    font-family: 'allerta';
    font-size: 2rem;

`;

const HalfImg = ({img, heading}) => {
    return (
        <Section>
            <ImgBox>
                <ImgData src = {img} />
                <HeadingBox >
                    {heading}
                </HeadingBox>
            </ImgBox>
        </Section>
    )
}

export default HalfImg
