import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
    width: 100vw;
    /* height: 500px; */
    /* background-color: red; */
    padding: 50px 0;

    display: grid;
    grid-template-columns: 20px 6fr 20px;
    @media screen and (max-width: 668px) {
        grid-template-columns: 8px 6fr 8px;
    }
`;


const DumLine = styled.div`
    background-color: #D4AF37;
    margin: 100px 0;
    @media screen and (max-width: 668px) {
        /* display: none; */
    }
`;
const MainContainer = styled.div`
    /* border: 1px solid red; */
    padding: 0px 100px;
    display: grid;
    grid-template-rows: 100px auto;
    @media screen and (max-width: 668px) {
        padding: 0px 30px;
    }
`;
const HeadingPart = styled.div`
    /* border: 1px solid red; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-family: 'arima';
    font-size: 2.5rem;
    letter-spacing: 2px;

    span {
        /* color: red; */
        font-size: 3.2rem;
        color: #D4AF37;

        &:nth-child(2), &:nth-last-child(2) {
            font-size: 2.8rem;
        }
        &:nth-child(3), &:nth-last-child(3) {
            font-size: 2.5rem;
        }
        &:nth-child(4), &:nth-last-child(4) {
            font-size: 2.5rem;
        }
        &:nth-child(5), &:nth-last-child(5) {
            font-size: 2.5rem;
        }
        @media screen and (max-width: 668px) {  
            font-size: 2.2rem;

            &:nth-child(2), &:nth-last-child(2) {
                font-size: 1.8rem;
            }
            &:nth-child(3), &:nth-last-child(3) {
                font-size: 1.5rem;
            }
            &:nth-child(4), &:nth-last-child(4) {
                font-size: 1.5rem;
            }
            &:nth-child(5), &:nth-last-child(5) {
                font-size: 1.5rem;
            }
        }
    }

`;
const DataPart = styled.div`
    /* border: 1px solid red; */
    padding: 30px 0;
    display: grid;
`;
const TextBox = styled.div`
    /* border: 1px solid red; */
    text-align:justify;
    font-family:'buda';
    font-size: 20px;
    margin-bottom: 5px;

    span {
        padding: 0 30px;
        /* border: 1px solid red; */
    }
    @media screen and (max-width: 668px) {
        font-size: 17px;
    }
`;

const DescText = () => {
    return (
        <Section>
            <DumLine />
            <MainContainer>
                <HeadingPart>
                    <span data-aos="flip-up" data-aos-duration= "1000">s</span>
                    <span data-aos="flip-up" data-aos-duration= "1500">a</span>
                    <span data-aos="flip-up" data-aos-duration= "2000">k</span>
                    <span data-aos="flip-up" data-aos-duration= "2500">l</span>
                    <span data-aos="flip-up" data-aos-duration= "3000">e</span>
                    <span data-aos="flip-up" data-aos-duration= "3000">s</span>
                    <span data-aos="flip-up" data-aos-duration= "2500">h</span>
                    <span data-aos="flip-up" data-aos-duration= "2000">p</span>
                    <span data-aos="flip-up" data-aos-duration= "1500">u</span>
                    <span data-aos="flip-up" data-aos-duration= "1000">r</span>
                </HeadingPart>
                <DataPart>
                    <TextBox data-aos="fade-left">
                        <span></span>Sakleshpur is a town located in foothills of western ghats in the region of Malnad, offers splendid view and adventure , lush green hills abundantly covered with cardamom , coffee, pepper plantations that are perfect. The town lies in the Malnad region on the hills of the biodiversity hotspot, the Western Ghats. It has a temperate climate surrounded with lofty green hills full of coffee, cardamom, pepper and areca plantations. These crops which contribute mainly to the economy of the taluk are grown in the surrounding villages and the entire taluk are brought to Sakleshpur city for sale. The town lies on the National Highway 75 (NH 75) which connects the port city of Mangalore (128 km), with the capital city Bangalore (224 km) of Karnataka state. The nearest airport is Mangalore International Airport, situated at a distance of 129 km (80 mi). 
                    </TextBox>
                    <TextBox data-aos="fade-right">
                        <span></span>Sakleshpur taluk is bounded by Belur taluk to the northeast, Alur taluk to the east, Dakshina Kannada district to the west, Chikkamagaluru district to the northwest and Kodagu district to the southeast and south. 
                    </TextBox>
                    <TextBox data-aos="fade-left">
                        <span></span>Sakleshpur has to offer varieties of homestays, resorts and plantation bunglows which are all unique in its own way. Travel Pro Holidays have a special way to offer all these with best four wheel drive experience with best stay to match up to the guest needs. 
                    </TextBox>
                </DataPart>
            </MainContainer>
            <DumLine />            
        </Section>
    )
}

export default DescText
