import React from 'react';
import styled from 'styled-components';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';
import h1 from '../media/homepage/h1.jpg';
import h2 from '../media/homepage/h2.jpg';
import h3 from '../media/homepage/h3.jpg';
const BoxImg = styled.img`
    height: 60vh;
    object-fit: cover;
`;


const ImgSliderA = ({images}) => {
    return (
        <>
             <Carousel>
                 {images.map((item,index) => (
                     <Carousel.Item>
                        <BoxImg
                        className="d-block w-100"
                        src={item.iimg}
                        alt="Second slide"
                        />
                    </Carousel.Item>
                 ))}
            </Carousel>
        </>
    )
}

export default ImgSliderA
