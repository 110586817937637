import React from 'react';
import styled from 'styled-components';
import logo from '../media/logo.png';
import { AiFillInstagram } from 'react-icons/ai';
import { AiFillFacebook } from 'react-icons/ai';
import { FaTwitterSquare } from 'react-icons/fa';
import { FaYoutube } from 'react-icons/fa';
import { AiOutlineMail } from 'react-icons/ai';
import { SiGmail } from 'react-icons/si';
import { FaMobileAlt } from 'react-icons/fa';
import { ImLocation } from 'react-icons/im';
import { Link } from 'react-router-dom';
import { menuLinks } from '../data/MenuLinks';

const Section = styled.section`
    width: 100vw;
    height: 80vh;
    background: -webkit-linear-gradient(180deg, #fff 45%, #D4AF37 45%);
    background: -o-linear-gradient(180deg, #fff 45%, #D4AF37 45%);
    background: -moz-linear-gradient(180deg, #fff 45%, #D4AF37 45%);
    background: linear-gradient(180deg, #fff 45%, #D4AF37 45%);
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1204px) { 
        height: 90vh;
    }
    @media screen and (max-width: 1024px) { 
        height: 80vh;
    }
    @media screen and (max-width: 768px) { 
        height: 100%;
        padding-bottom: 200px;
        padding-top: 200px;
    }
`;

const FooterBox = styled.div`
    width: 80vw;
    /* height: 70%; */
    background: #000;
    border-radius: 20px;
    display: grid;
    grid-template-columns: 2fr 2fr 2fr 2fr;
    padding: 20px 0;
    @media screen and (max-width: 1204px) { 
        width: 85vw;
        height: 85%;
    }
    @media screen and (max-width: 1024px) { 
        grid-template-columns: 3fr 3fr;
        grid-template-rows: 3fr 3fr;
    }
    @media screen and (max-width: 768px) { 
        height: 100%;
        grid-template-columns: 6fr;
        grid-template-rows: 2fr 2fr 2fr 2fr;
        padding: 20px 00px;
    }
`;

const LogoPart = styled.div`
    border-right: 1px solid #6A5615;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    @media screen and (max-width: 1024px) { 
        border-right: 1px solid #6A5615;
    }
    @media screen and (max-width: 768px) { 
        border-right: none;
        border-bottom: 1px solid #6A5615;
    }
`;
const PagePart = styled.div`
    border-right: 1px solid #6A5615;
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    @media screen and (max-width: 1024px) { 
        border-right: none;
        border-bottom: 1px solid #6A5615;
    }
`;
const ContactPart = styled.div`
    /* border: 1px solid red; */
    border-right: 1px solid #6A5615;
    display: flex;
    flex-direction: column;
    padding: 0 30px;
    @media screen and (max-width: 1024px) { 
        border-right: 1px solid #6A5615;
        border-top: 1px solid #6A5615;
    }
    @media screen and (max-width: 768px) { 
        border-right: none;
        border-top: none;
        border-bottom: 1px solid #6A5615;
        padding: 30px;
    }
`;
const MapPart = styled.div`
    padding: 30px;
    display: grid;
    grid-template-rows: 4fr 2fr;
`;

//Logo part
const LogoImg = styled.img`
    width: 200px;
    height: 70px; 
`;

const SocIcon = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-evenly;
`;
const IconLink = styled.div`

`;

//PagePart
const TextHeading = styled.div`
    font-size: 17px;
    font-weight: bold;
    padding: 10px 0;
    color: #D4AF37;
`;
const TextDesc = styled(Link)`
    text-decoration:none;
    font-size: 13px;
    font-weight: normal;
    letter-spacing: 1px;
    padding: 0px 0;
    color: #FCF1CE;
    transition: .3s;

    &:hover {
        color: gray;
        transition: .3s;
    }
`;

//Contact Page
const ContactLink = styled.div`
    padding: 10px 0;
    display: flex;
    align-items: center;
    color: #FCF1CE;
    font-size: 14px;
    /* width: 270px; */

    span {
        padding: 0 10px;
        color: #D4AF37;
    }
    @media screen and (max-width: 658px) { 
        font-size: 12px;
    }
`;
const ContactLinkA = styled.div`
    display: flex;
    align-items: center;
    color: #FCF1CE;
    font-size: 14px;
    padding-left: 47px;
    /* width: 270px; */
`;

// Map Part
const Map = styled.div`
    position: relative;
    iframe {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 10px;        
    }
`;

const Btn = styled.div`
    display: grid;
    place-content: center;
`;

const BtnBox = styled.div`
    width: 140px;
    height: 40px;
    color: #fff;
    background: red;
    display: grid;
    place-content: center;
    border-radius: 5px;
    cursor: pointer;
    transition: .3s;

    &:hover {
        transform: scale(1.05);
        transition: .3s;
    }
`;


const FooterSection = ({enquiry}) => {
    return (
        <>
            <Section>
                <FooterBox>
                    <LogoPart>
                        <LogoImg src={logo}/>
                        <SocIcon>
                            <IconLink>
                                <AiFillFacebook 
                                    color = "#D4AF37"
                                    size = "25px"
                                />
                            </IconLink>
                            <IconLink>
                                <AiFillInstagram 
                                    color = "#D4AF37"
                                    size = "25px"
                                />
                            </IconLink>
                            <IconLink>
                                <FaTwitterSquare 
                                    color = "#D4AF37"
                                    size = "25px"
                                />
                            </IconLink>
                            <IconLink>
                                <FaYoutube 
                                    color = "#D4AF37"
                                    size = "25px"
                                />
                            </IconLink>
                        </SocIcon>
                    </LogoPart>
                    <PagePart>
                        <TextHeading>Travelpro Holiday</TextHeading>
                        {menuLinks.map((item,index) => (
                            <TextDesc to = {item.link} key = {index}>{item.title}</TextDesc>
                        ))}
                    </PagePart>
                    <ContactPart>
                        <TextHeading>Contact US</TextHeading>
                        <ContactLink>
                            <AiOutlineMail 
                                color = "#D4AF37"
                                size = "25px"
                            /><span>:</span> sandeep@travelproholiday.com
                        </ContactLink>
                        <ContactLink>
                            <SiGmail 
                                color = "#D4AF37"
                                size = "25px"
                            /><span>:</span> travelproholiday@gmail.com
                        </ContactLink>
                        <ContactLink>
                            <FaMobileAlt 
                                color = "#D4AF37"
                                size = "25px"
                            /><span>:</span> +91 9880552711
                        </ContactLink>
                        <ContactLink>
                            <FaMobileAlt 
                                color = "#D4AF37"
                                size = "25px"
                            /><span>:</span> +91 9141809654
                        </ContactLink>
                        <ContactLink>
                            <ImLocation 
                                color = "#D4AF37"
                                size = "25px"
                            /><span>:</span> #114/5, 3rd Main, Ankappa Layout,
                        </ContactLink>
                        <ContactLinkA>
                            Uttarahalli, Bangalore - 560061.
                        </ContactLinkA>
                    </ContactPart>
                    <MapPart>
                        <Map>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5234.105905224418!2d77.54987781215259!3d12.907765068070056!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae3fda85f660c5%3A0xeb846f9813019af6!2s37%2F114%2C%203rd%20Main%20Rd%2C%20Kempanna%20Layout%2C%20Krishnanagar%2C%20Ankappa%20Layout%2C%20Uttarahalli%20Hobli%2C%20Bengaluru%2C%20Karnataka%20560061!5e0!3m2!1sen!2sin!4v1631132171484!5m2!1sen!2sin"></iframe>
                            
                        </Map>
                        <Btn>
                            <BtnBox onClick={() => enquiry("All")}>Enquiry Now</BtnBox>
                        </Btn>
                    </MapPart>
                </FooterBox>
            </Section>
        </>
    )
}

export default FooterSection
