import React from 'react';
import styled, {css} from 'styled-components/macro';
const Section = styled.section`
    /* border: 1px solid red; */
    width: 100vw;
    height: 100%;
    padding: 80px;
    background: #fff;
    @media screen and (max-width: 1024px) {  
        width: 100vw;
        height: 100%;
        padding: 50px;
    }
    @media screen and (max-width: 768px) {  
        padding: 30px;
    }
    
`;

const SectionContainer = styled.div`
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 3fr 3fr;
    @media screen and (max-width: 768px) {  
        display: flex;
        flex-direction:  ${({pos}) => (pos ? 'column' : 'column-reverse')};
    }
`;

const TitlePart = styled.div`
    display: grid;
    align-items: center;
    font-size: 3.8rem;
    text-align: ${({BlockAl}) => (BlockAl ? 'left' : 'right')};
    color: #D4AF37;
    @media screen and (max-width: 1340px) {  
        font-size: 3rem;
    }
    @media screen and (max-width: 1024px) {  
        font-size: 2.5rem;
    }
    @media screen and (max-width: 768px) {  
        text-align: center;
        font-size: 2rem;
        margin-bottom: 20px;
    }
`;
const DocPart = styled.div`
    background: #D4AF37;    
    /* border-radius: 10px; */
    text-indent: 50px;
    text-align: justify;
    font-family: 'buda';
    color: #fff;
    font-size: 20px;
    margin-bottom: 30px;
    padding: 50px;
    @media screen and (max-width: 1024px) {  
        padding: 30px;
    }
    @media screen and (max-width: 768px) {  
        padding: 20px;
    }
`;


const BlockPages = () => {
    return (
        <>
            <Section>
                <SectionContainer pos = {1}>
                <TitlePart BlockAl = {1}>
                    VISION
                </TitlePart>
                <DocPart data-aos="fade-left">
                    We through a dedicated group of youngsters found an exciting company with a
                    vision of traveling for serving while respecting the land and her people. While
                    others focus on attractions and creating a western environment, our vision is face
                    to face travel at a grassroots level exploring &amp; experience the real virtue of
                    natures delight in its own plight.
                </DocPart>
                </SectionContainer>
            </Section>
            <Section>
                <SectionContainer pos = {0}>
                    <DocPart data-aos="fade-right">
                        Every customer traveled through us is a part of Travelpro Holiday and we always
                        seek to understand our customer’s needs, and provide them with timely, accurate,
                        quality service. All initiatives we undertake, products we develop, and systems
                        and processes we establish, are aligned with increasing value to our customers.
                    </DocPart>
                    <TitlePart BlockAl = {0}>
                        CUSTOMER FOCUS & UNDERSTANDING
                    </TitlePart>
                </SectionContainer>
            </Section>
        </>
    )
}

export default BlockPages
