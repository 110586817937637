import title from '../media/tripideas/pilgrimage/title.jpg';

import l1 from '../media/package/Pilgrimage/1.jpg';
import l2 from '../media/package/Pilgrimage/2.jpg';
import l3 from '../media/package/Pilgrimage/3.jpg';

export const PilgrimagePackage = {
    packageName: "Adventure",
    titleImg: title,
    packages:  [
        {
            pimg: l1,
            ptitle: "AMARNATH CAVE BY FOOT - Srinagar, Pahalgam, Sheshnag, Panchtarni, Cave, Baltal, Srinagar",
            pdays: "6N|7D",
            pfrom: "Srinagar",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: l1
                    },
                    {
                        iimg: l2
                    }
                ],
                ititle: "AMARNATH CAVE BY FOOT - Srinagar, Pahalgam, Sheshnag, Panchtarni, Cave, Baltal, Srinagar",
                iprice: "₹ 25000",
                idays: "6N|7D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: " Srinagar - Pahalgam (93 Kms drive)",
                        dayList: [
                            {
                                dayListDes: "Leave Srinagar by cars/coach 93 Kms three hours drive to Pahalgam (2440 Mtrs) Enroute visit Saffron fields, Awantipora ruins and Mattan Temple. On the way see some beautiful villages. Pahalgam is the most famous place for Indian Film Industry. Enjoy the nature charm of the valley. Overnight at Hotel,",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Pahalgam - Chandanwari (2895 M) 16 Kms drive - Sheshnag 12 Kms trek",
                        dayList: [
                            {
                                dayListDes: "Full day tour of Pahalgam one of the beautiful and fascinating ideal resorts all the year round. It is the base for several treks in the region. Situated at the confluence of the Lidder River and set amidst fir and pine covered mountains, one can have a real feelings of Paradise. You can enjoy the pony ride to Shikargah & Baisaran.  In the evening return to Houseboat for overnight stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Sheshnag (3377 M) - Panchtarni 14 Kms trek",
                        dayList: [
                            {
                                dayListDes: "Start early morning for a steep ascent upto Mahagunas (The great Viper 14500 Ft) then as easy descend upto Panchtarni situated at the foot of mount Bhairva. Overnight in Camps  ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Panchtarni (3657 M) – Amarnath Cave (3953 M) 6 Kms trek - Baltal 4 Hrs Tre",
                        dayList: [
                            {
                                dayListDes: "Start after breakfast steep ascent towards Amarnath Holy Cave where you can see ICE LINGAM. This is the holy cave for Hindus and thousands of people visit the place during full Moon day. From Amarnath steep descend towards Baltal 3/4 hrs walk, overnight in Camps. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Baltal – Sonamarg – Srinagar (113 Kms drive)",
                        dayList: [
                            {
                                dayListDes: "From Baltal it is 15 Kms drive to Sonamarg (2690 Mtrs) which is the most beautiful spot of Kashmir valley. Sonamarg is known as Gateway of Ladakh. Continue drive to Srinagar which takes 3 Hrs from Sonamarg; on arrival check in House Boat.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Mughal Gardens",
                        dayList: [
                            {
                                dayListDes: "Morning tour of Mughal Gardens visit the Nishat Bagh (The Garden of Pleasure), Chashmashahi (The Royal Spring) & Shalimar Bagh (Abode of love), later return back to Houseboat for dinner & overnight stay. Afternoon tour of Dal Lake visit Floating Gardens, Lotus Gardens, Char Chinar, Kaboter Khana (Pigeon Palace) and inside of the Lake by Shikara. Dinner & Overnight stay at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "Srinagar Airport/Jammu by road",
                        dayList: [
                            {
                                dayListDes: "From Srinagar which takes 1 Hrs. drive to Airport or Jammu by road which is 300 kms drive.",
                            },
                            {
                                dayListDes: "Tour Ends but sweet memories remain.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        }, 
        {
            pimg: l2,
            ptitle: "Amarnath Cave by Helicopter - Srinagar, Sonamarg, Baltal- Cave, Baltal, Srinagar",
            pdays: "2N|3D",
            pfrom: "Srinagar",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: l2
                    },
                    {
                        iimg: l3
                    }
                ],
                ititle: "Amarnath Cave by Helicopter - Srinagar, Sonamarg, Baltal- Cave, Baltal, Srinagar",
                iprice: "₹ 25000",
                idays: "2N|3D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Srinagar",
                        dayList: [
                            {
                                dayListDes: "Arrival at Srinagar airport our representative will meet you and transfer you by non A.C. Sumo to Sonamarg which is 125 Kms of drive and 3 & ½ hours drive via river Sindh, continue drive to Sonamarg on arrival in Sonamarg check in Hotel Sonamarg glacier, overnight stay in Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Baltal- Cave",
                        dayList: [
                            {
                                dayListDes: "Wake up call will be given at 4 am morning & will leave Hotel 5:30 am morning & drive to Baltal which is 14 Kms & 1 Hour drive on arrival in Baltal & will reach Baltal at 6:30 am morning & will start security checking for helicopter at 7 am to board helicopter & will start sorties from Baltal to cave. You will reach in ten minutes to cave; Darshan will be done on V.V. IP priority basis. Each sortie will take 4 passengers at a time. After return from cave the view of Baltal where taking breakfast. After all the passengers are back from Cave, we will drive to Thajiwas Glacier one can walk to the Glacier or ponies ride, leave from Sonamarg for Srinagar which is 100 Kms & 3 Hours drive. Tour of Mughal garden at Srinagar visit the Nishat Bagh (The garden of pleasure), Shalimar garden (Abode of love) and Chashmashahi (The royal spring). On arrival in Srinagar Check in Hotel/Deluxe Houseboat, overnight stay and dinner in Hotel/House boat. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Srinagar",
                        dayList: [
                            {
                                dayListDes: "Lake rides by Shikara to visit Floating Gardens, Lotus Gardens, Charchinar, Kaboter Khana (Pigeon Palace) and inside of the lake, afternoon transfer to airport.",
                            },
                            {
                                dayListDes: "Tour Ends but sweet memories remain.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        }, 
        {
            pimg: l3,
            ptitle: "Katra Mata Vaishno Devi Srinagar Temples",
            pdays: "4N|5D",
            pfrom: "Jammu ",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: l3
                    }
                ],
                ititle: "Katra Mata Vaishno Devi Srinagar Temples",
                iprice: "₹ 25000",
                idays: "4N|5D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Jammu",
                        dayList: [
                            {
                                dayListDes: "Arrive at Jammu Airport/Railway station. Our assistant will meet you and transfer you to Katra. Enroute visit the Raghunath Temple, Ranbiveshwar Temple and other important places of interest. Check in Hotel in Katra.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Katra",
                        dayList: [
                            {
                                dayListDes: "After breakfast leave for Darshan and back, Forenoon free for personal activation and rest.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Katra - Srinagar",
                        dayList: [
                            {
                                dayListDes: "Morning leave for Srinagar (8hrs. drive) enroute Patnitop, at arrival in Srinagar check in Houseboat.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Srinagar",
                        dayList: [
                            {
                                dayListDes: "Full day tour of Mughal gardens visiting the Nishat Bagh (The garden of pleasure) and Shalimar garden (Abode of love), Fairy Palace, Chashmashahi (The Royal spring), Shankrachariya Temple on Hill & Jeethyar Temple.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Srinagar",
                        dayList: [
                            {
                                dayListDes: "Lake rides by Shikara to visit Floating Gardens, Lotus Gardens, Charchinar, Kaboter Khana (Pigeon Palace) and inside of the lake, afternoon transfer to Airport",
                            },
                            {
                                dayListDes: "Tour Ends but sweet memories remain.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        }, 
    ]

}