import title from '../media/package/pak7.jpg' ;

import leh1 from '../media/package/Leh/leh1.jpg';
import leh2 from '../media/package/Leh/leh2.jpg';
import leh3 from '../media/package/Leh/leh3.jpg';
import leh4 from '../media/package/Leh/leh4.jpg';
import leh5 from '../media/package/Leh/leh5.jpg';

import zanskar1 from '../media/package/Leh/zanskar1.jpg';
import zanskar2 from '../media/package/Leh/zanskar2.jpg';
import zanskar3 from '../media/package/Leh/zanskar3.jpg';

import sarchu1 from '../media/package/Leh/sarchu1.jpg';

import Lamayuru1 from '../media/package/Leh/Lamayuru1.jpg';
import Lamayuru2 from '../media/package/Leh/Lamayuru2.jpg';

import pangong1 from '../media/package/Leh/pangong1.jpg';
import pangong2 from '../media/package/Leh/pangong2.jpg';
import pangong3 from '../media/package/Leh/pangong3.jpg';
import pangong4 from '../media/package/Leh/pangong4.jpg';
import pangong5 from '../media/package/Leh/pangong5.jpg';

export const LehPackage = {
    packageName: "Leh Ladakh",
    titleImg: title,
    packages:  [
        {
            pimg: Lamayuru1,
            ptitle: "LADAKH MONASTERIES TOUR - Leh, Alchi, Lamayuru, Khardongla, Leh",
            pdays: "7N|8D",
            pfrom: "Leh",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: Lamayuru1
                    },
                    {
                        iimg: leh1
                    }
                ],
                ititle: "LADAKH MONASTERIES TOUR - Leh, Alchi, Lamayuru, Khardongla, Leh",
                iprice: "₹ 25000",
                idays: "7N|8D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Leh",
                        dayList: [
                            {
                                dayListDes: "On arrival at Leh airport, you will be met by our representative at the airport and escort you to Hotel. Complete day for the acclimatization which is 3500 Mtrs above sea level. In the evening short walk around Leh Bazar, overnight in Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Leh- Monasteries ",
                        dayList: [
                            {
                                dayListDes: "Full day excursion to the famous monasteries, it is interesting to visit early in the morning to enjoy the praying Ceremony at Thiksey monastery. Continue drive to Hemis monastery which is one of the most famous monasteries of the Ladakh region. On the way back visit to Shey Palace, overnight in Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Leh – Monasteries",
                        dayList: [
                            {
                                dayListDes: "Morning half day tour of Spituk and visit Phyang monastery, afternoon visit by foot to Kings Palace, Leh Mosque, Leh Gompa, Shankar Gompa and Leh Bazar overnight in Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Leh – Khardongla Pass – Leh 85 Kms",
                        dayList: [
                            {
                                dayListDes: "Day excursion to Khardongla pass which is the highest motorable pass in the world 5600 Mtrs. and has been recorded in the Guineas Book of world Records, At the top enjoy the beauty of snow capped mountains. In the late afternoon return back to the Hotel, Overnight at hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Leh – Alchi 72 Kms",
                        dayList: [
                            {
                                dayListDes: "Leave for Alchi monastery known as Alchi Choskor is the only monastery in the Ladakh on flat ground. On the way visit Bazgo Castle lie on the right bank of the Indus. By the lunch time we reach Alchi. Afternoon visit the Alchi monastery and explore the nature beauty of Alchi village, overnight at Hotel / Guest house.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Alchi – Lamayuru - Leh 168 Kms",
                        dayList: [
                            {
                                dayListDes: "Leave Alchi and continue drive to Lamayuru via Khaltsi (Passport checking point). From here another 22 Kms drive will take us to Lamayuru. The oldest and spectacularly set Lamayuru monastery founded in the 10th century, visit the monastery. In the late afternoon drive back to Leh which is 4/5 hours drive, overnight at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "Leh – Stok Palace",
                        dayList: [
                            {
                                dayListDes: "Morning half day visit to Stok Palace, The Palace was built by King Tsepal Tondup Namgyal in 1825. On the way back visit the Choglamsar village (the Tibetan refugee Camp) which is famous for Tibetan Culture, History and Buddhism, Afternoon day for personal activities or visit to the Local Ladakhi family house which can be arranged on request, overnight at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "Leh – Delhi",
                        dayList: [
                            {
                                dayListDes: "Transfer in time to the airport to connect onward destination flight.",
                            },
                            {
                                dayListDes: "Tour Ends but sweet memories remain.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: pangong1,
            ptitle: "PONAROMA OF LADAKH -  Leh, Khardongla, Hemis, Pangong Lake, Leh",
            pdays: "5N|6D",
            pfrom: "Leh",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: pangong1
                    },
                    {
                        iimg: leh2
                    }
                ],
                ititle: "PONAROMA OF LADAKH -  Leh, Khardongla, Hemis, Pangong Lake, Leh",
                iprice: "₹ 25000",
                idays: "5N|6D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrival Leh",
                        dayList: [
                            {
                                dayListDes: "Airport received by our representative and transferred to Hotel. Check in to the hotel. Complete rest for the day after welcome tea and coffee in Hotel, walk around Leh town includes a visit to Leh Market, Leh Palace or Shankar monastery, in the evening return back to Hotel for overnight stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Leh – Khardongla Pass – Leh 85 Kms",
                        dayList: [
                            {
                                dayListDes: "Day excursion to Khardongla pass which is the highest motorable pass in the world 5600 Mtrs and has been recorded in the Guineas Book of world Records, at the top enjoy the beauty of snow capped mountains. In the late afternoon return back to the Hotel, Overnight at hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Leh Sight Seeing",
                        dayList: [
                            {
                                dayListDes: "After breakfast drive for full day sight seeing of Shey Palace, Thiksey, Hemis monastery & Stok Palace Museum. In the evening drive back to Hotel for overnight stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Leh - Karu - Pangong Tso Lake (7-8hrs)",
                        dayList: [
                            {
                                dayListDes: "After early breakfast we drive to Pangong Tso Lake. From Leh we reach Karu, an Army camp on Leh-Manali road and then turn left and cross Changla Pass (17500 ft) and finally to Tangtse. Evening at leisure, overnight stay little beyond Tangtse in Camped camp at Lukung near Pangong lakeshores.",
                            },
                            {
                                dayListDes: "Pangong Lake, situated at 14,000 feet (4,267 m), is a long narrow basin of inland drainage, hardly six to seven kilometers at its widest point and over 135 km long. About 1/3rd of the lake is under Indian occupation and remaining under China. The Chinese border is just beyond the mountains across the lake. Spangmik, 7 km along the southern shore from the head of the lake is the farthest point to which foreigners are permitted. There are spectacular views of the mountains of Changchenmo range to the north and their shimmering reflection in the ever-changing blues and greens of the lake’s brackish waters is breathtaking. Above Spangmik are the glaciers and snow-capped peaks of the Pangong range. Spangmik and other scattered tiny villages along the lake’s southern shore are the summer homes of a scanty population of Chang-pa, the semi-nomadic herdsmen of Tibet and southeast Ladakh. The Pangong Chang-pa cultivate sparse crops of barely and peas in summers. During the winters that they pack their Camps (rebo) and take their flocks of sheep and Pashmina goats out to the distant pastures. Overnight stay little beyond Tangtse in Camped camp at Lukung near Pangong lakeshores. Day 05: Pangong Tso Lake – Leh.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Pangong Tso Lake – Leh",
                        dayList: [
                            {
                                dayListDes: "After Breakfast leave from Pangong Lake and drive back to Leh at same route and walk around Leh town includes a visit to Leh Market, in the evening return back to Hotel for overnight stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Leh",
                        dayList: [
                            {
                                dayListDes: "Transfer in time to the airport to connect onward destination flight.",
                            },
                            {
                                dayListDes: "Tour Ends but sweet memories remain.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: leh3,
            ptitle: "SAND DUNES OF LADAKH -  Leh, Nubra Valley, Leh",
            pdays: "3N|4D",
            pfrom: "Leh",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: leh3
                    },
                    {
                        iimg: leh4
                    }
                ],
                ititle: "SAND DUNES OF LADAKH -  Leh, Nubra Valley, Leh",
                iprice: "₹ 25000",
                idays: "3N|4D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrival Leh",
                        dayList: [
                            {
                                dayListDes: "Arrive Leh airport received by our representative and transferred to Hotel. Check in to the hotel. Complete rest for the day after welcome tea and coffee in Hotel. Walk around Leh town includes a visit to Leh Market, Leh Palace & Shankar monastery, overnight stay in Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Leh – Nubra Valley",
                        dayList: [
                            {
                                dayListDes: "After breakfast drive for Nubra Valley via Khardongla (World’s highest motorable road, 18380 Feet). On arrive in Diskit check in the Camp, rest of the day at disposal for activities by owns, overnight stay at camp.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Nubra Valley - Leh",
                        dayList: [
                            {
                                dayListDes: "After Breakfast proceed for a short walk visit Diskit monastery and drive back to Leh, overnight stay at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Leh to Delhi",
                        dayList: [
                            {
                                dayListDes: "Morning transferred to Airport and fly back to Delhi with sweet memories of Ladakh.",
                            },
                            {
                                dayListDes: "Tour Ends but sweet memories remain.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: Lamayuru2,
            ptitle: "TRIBAL AREAS OF LADAKH -  Leh, Tso, Moriri Lake, Leh",
            pdays: "3N|4D",
            pfrom: "Leh",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: Lamayuru2
                    },
                    {
                        iimg: leh1
                    }
                ],
                ititle: "TRIBAL AREAS OF LADAKH -  Leh, Tso, Moriri Lake, Leh",
                iprice: "₹ 25000",
                idays: "3N|4D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrival Leh",
                        dayList: [
                            {
                                dayListDes: "Arrive Leh airport received by our representative and transferred to Hotel. Check in to the hotel. Complete rest for the day after welcome tea and coffee in Hotel, walk around Leh town includes a visit to Leh Market, Leh Palace & Shankar monastery, overnight stay at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Leh Tso – Moriri Lake",
                        dayList: [
                            {
                                dayListDes: "Early in the morning leave for Tso-moriri Lake (which is 240 Kms. Approx.) via Push, Chumathang (Hot Spring), on arrival in Tso-moriri check in fix Camp, afterwards take a walk around the Lake to enjoy the scenic beauty, overnight stay in Camp.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Tso-moriri - Leh",
                        dayList: [
                            {
                                dayListDes: "After a leisurely breakfast walk around the lake and drive back to Leh, overnight stay at Hotel",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Leh Sightseeing",
                        dayList: [
                            {
                                dayListDes: "After breakfast drive for full day sight seeing of Shey Palace, Thiksey, Hemis monastery and Stok Palace Museum, in the evening drive back to Hotel for overnight stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Leh to Delhi",
                        dayList: [
                            {
                                dayListDes: "Morning transferred to Airport and fly back to Delhi with sweet memories of Ladakh.",
                            },
                            {
                                dayListDes: "Tour Ends but sweet memories remain.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        }
    ]
}