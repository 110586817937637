import React from 'react';
import styled, {css} from 'styled-components/macro';
const Section = styled.section`
    width: 100vw;
    height: 100%;
    padding: 80px;
    background: #fff;
    @media screen and (max-width: 1024px) {  
        height: 100%;
    }
    @media screen and (max-width: 650px) {  
        padding: 30px;
    }
`;

const SectionContainer = styled.div`
    width: 100%;
    height: 100%;
    background: #D4AF37;    
    display: grid;
    grid-template-columns: 3fr 3fr;
    /* border-radius: 10px; */
    @media screen and (max-width: 1024px) { 
        grid-template-columns: auto;
        grid-template-rows: auto auto;
    }
`;

const MemberImage = styled.div`
    /* border: 1px solid #000; */

`;
const MemberDesc = styled.div`
    color: #fff;
    padding: 50px 80px;
    text-indent: 50px;
    text-align: justify;
    font-family: 'buda';
    font-size: 20px;
    @media screen and (max-width: 1024px) { 
        padding: 30px;
    }
`;
const ImgBox = styled.div`
    /* border: 1px solid #000; */
`;

const MemberPage = () => {
    return (
        <>
            <Section>
                <SectionContainer>
                    <MemberImage>
                        <ImgBox>

                        </ImgBox>
                    </MemberImage>
                    <MemberDesc>
                        As said, a good leader takes a little less than his share of credit, Mr. Sandeep
                        Dumale, the founder of TravelPro holiday, Bangalore. is a well known face at travel fraternity.
                        Experienced as, MICE consultant, wedding event management, Domestic holiday expert,
                        inbound travel operations at renowned travel companies and heading the sales of luxury
                        Hotel brands in India. With the passion to travel, Mr. Sandeep has visited more than 40
                        countries multiple times to learn about destination and vivid culture. After the vast experience
                        in travel industry, the objective to start TravelPro was to provide the world class luxury
                        experience to clientels visiting India. India offers many hidden gems which is exquisite in
                        nature. With the vision to showcase the destination in a different horizon , here we are to
                        render the best travel service.
                    </MemberDesc>
                </SectionContainer>
            </Section>
        </>
    )
}

export default MemberPage
