import React, { useState} from 'react';
import styled, { keyframes } from 'styled-components';
import { BiMessageCheck } from 'react-icons/bi';

const Section = styled.section`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.1);
    z-index: 9999999999;
    display:${({dispBox}) => (dispBox ? '' : 'none')};
`;
const MsgContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const MsgBox = styled.div`
    width: 300px;
    height: 350px;
    background-color: #F9F3DF;
    border: #D4AF37 5px solid;
    border-radius: 10px;
    box-shadow: 0px 0px 4px 4px rgba(0,0,0,0.59) inset;
    -webkit-box-shadow: 0px 0px 4px 4px rgba(0,0,0,0.59) inset;
    -moz-box-shadow: 0px 0px 4px 4px rgba(0,0,0,0.59) inset;
    display: grid;
    grid-template-rows: 3fr 3fr;
    
`;
const Move = keyframes`
    from {
        transform: rotate(-10deg);
    }
    to {
        transform: rotate(10deg);
    }
`;
const ImgPart = styled.div`
    display: grid;
    place-content: center;

    svg {
        animation: ${Move} .6s infinite;
        animation-direction: alternate;
    }
`;
const TextPart = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'allerta';
`;
const Button = styled.div`
    background-color: #D4AF37;
    width: 80px;
    height: 40px;
    display: grid;
    place-content: center;
    margin-top: 20px;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'open_san';
    letter-spacing: 1px;
    font-weight: bold;
`;

const SendMsg = ({dispSub, sendBox}) => {
    return (
        <Section dispBox = {dispSub}>
            <MsgContainer>
                <MsgBox>
                    <ImgPart>
                        <BiMessageCheck                                 
                            color = "green"
                            size = "7rem"
                        />
                    </ImgPart> 
                    <TextPart>
                        <h2>Thank You</h2>
                        <p>Your submission has been sent. </p>
                        <Button onClick={sendBox}>OK</Button>
                    </TextPart>
                </MsgBox>
            </MsgContainer>
        </Section>
    )
}

export default SendMsg
