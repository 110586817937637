import title from '../media/package/pak5.jpg' ;

import Gulmarg1 from '../media/package/Jammu/Gulmarg1.jpg';
import Gulmarg2 from '../media/package/Jammu/Gulmarg2.jpg';
import Gulmarg3 from '../media/package/Jammu/Gulmarg3.jpg';
import Gulmarg4 from '../media/package/Jammu/Gulmarg4.jpg';

import kargil1 from '../media/package/Jammu/kargil1.jpg';
import kargil2 from '../media/package/Jammu/kargil2.jpg';

import Pahalgam1 from '../media/package/Jammu/Pahalgam1.jpg';
import Pahalgam2 from '../media/package/Jammu/Pahalgam2.jpg';
import Pahalgam3 from '../media/package/Jammu/Pahalgam3.jpg';
import Pahalgam4 from '../media/package/Jammu/Pahalgam4.jpg';

import srinagar1 from '../media/package/Jammu/srinagar1.jpg';
import srinagar2 from '../media/package/Jammu/srinagar2.jpg';
import srinagar3 from '../media/package/Jammu/srinagar3.jpg';
import srinagar4 from '../media/package/Jammu/srinagar4.jpg';
import srinagar5 from '../media/package/Jammu/srinagar5.jpg';
import srinagar6 from '../media/package/Jammu/srinagar6.jpg';
import srinagar7 from '../media/package/Jammu/srinagar7.jpg';

import leh2 from '../media/package/Leh/leh2.jpg';
import leh5 from '../media/package/Leh/leh5.jpg';
import zanskar1 from '../media/package/Leh/zanskar1.jpg';
import zanskar2 from '../media/package/Leh/zanskar2.jpg';



export const JammuPackage = {
    packageName: "Jammu & Kashmir",
    titleImg: title,
    packages:  [
        {
            pimg: srinagar1,
            ptitle: "UNFORGOTTABLE OF KASHMIR & LADAKH - Srinagar, Kargil, Ladakh Tour",
            pdays: "7N|8D",
            pfrom: "Srinagar",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: srinagar1
                    },
                    {
                        iimg: kargil1
                    }
                ],
                ititle: "UNFORGOTTABLE OF KASHMIR & LADAKH - Srinagar, Kargil, Ladakh Tour",
                iprice: "₹ 25000",
                idays: "7N|8D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Srinagar",
                        dayList: [
                            {
                                dayListDes: "Arrival at Srinagar airport and transfer to Deluxe Houseboat at Dal Lake, Afternoon rest & relax",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Srinagar ",
                        dayList: [
                            {
                                dayListDes: "Full day tour of Mughal gardens visiting the Nishat Bagh (The garden of pleasure), Chashmashahi (The Royal Spring) and Shalimar garden (Abode of love), old city visiting important places like Jamia Mosque & Hariparbat Fort, and overnight stay at Houseboat.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Srinagar – Kargil (202 Kms 08 Hrs)",
                        dayList: [
                            {
                                dayListDes: "Early morning depart for Kargil driving over Zojila pass, 3600 Mts. Upon arrival, check into a Hotel for overnight stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Kargil – Leh",
                        dayList: [
                            {
                                dayListDes: "Early Morning depart for Leh driving over Namkila Pass 3600 Mts and Fatila Pass 4000 Mts. Enroute visit Mulbeck Chamba (Future Buddha Statue) and Lamayuru Monastery, Alchi, Likir and Phyang Monastery, stay in Hotel for overnight.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Leh Morning Sightseeing",
                        dayList: [
                            {
                                dayListDes: "Morning sightseeing of Shey Monastery and SOS Children Village, afternoon depart for sightseeing tour of Shanti Stupa and Shankar Monastery & Leh Palace return to Hotel for overnight stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Leh",
                        dayList: [
                            {
                                dayListDes: "Morning walk up to Leh Palace, which is now almost in ruins, but one gets a beautiful view of the Indus Valley and Leh town from here, after visiting one is free to walk around in the Leh market on, their own rest of the day at leisure, overnight in Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "Leh Sightseeing",
                        dayList: [
                            {
                                dayListDes: "For full day sight seeing of Thiksey, Hemis, Chemre and Tokthak Monasteries, return in the evening to Hotel for overnight stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "Leh-Depart",
                        dayList: [
                            {
                                dayListDes: "After breakfast leave for airport to onwards your destination.",
                            },
                            {
                                dayListDes: "Tour Ends but sweet memories remain.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: Pahalgam1,
            ptitle: "ECO TOUR OF KASHMIR - Srinagar, Naranag, Pahalgam, Aru, Srinagar",
            pdays: "5N|6D",
            pfrom: "Srinagar",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: srinagar2
                    },
                    {
                        iimg: Pahalgam1
                    }
                ],
                ititle: "ECO TOUR OF KASHMIR - Srinagar, Naranag, Pahalgam, Aru, Srinagar",
                iprice: "₹ 25000",
                idays: "5N|6D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Airport Arrival",
                        dayList: [
                            {
                                dayListDes: "Arrival at Srinagar airport and transfer to Deluxe Houseboat at Dal Lake rest, Afternoon Tour of Mughal gardens visits the Nishat Bagh (The garden of pleasure), Chashmashahi (The Royal Spring), and Shalimar garden (Abode of love). Evening have a Lake rides by Shikara to visit Floating Gardens, Lotus Gardens, Charchinar, Kaboter Khana (Pigeon Palace) and inside of the Lake Dinner & overnight stay at Houseboat.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Srinagar – Naranag ",
                        dayList: [
                            {
                                dayListDes: "After breakfast leave for Naranag is the beautiful place for real nature lovers, after ½ hour walk on a mountain you can reach at a beautiful lake called Gangabal Lake, Naranag is 65 Kms it takes 1½ Hour reach at Naranag visit Buddhist monastery. It is said that 3rd world conference of Buddhist was held some where around this place, Overnight stay at Camp",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Naranag",
                        dayList: [
                            {
                                dayListDes: "Morning leave for short trek 1 hour to reach Doomale there meets two child Rivers, Overnight stay at Camp.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Naranag – Pahalgam – Aru",
                        dayList: [
                            {
                                dayListDes: "After breakfast leave for Pahalgam which is 170 Kms it takes 4/5 Hours to reach Pahalgam via Awantipora ruins and Saffron fields on arrival in Pahalgam. Situated 2130m up at the confluence of the streams flowing from Sheshnag Lake and the Lidder River, Pahalgam was once a humble shepherd's village with breath taking views. Now it is Kashmir's premier resort, deliciously cool even in the height of summer, and a wonderful day is spent exploring the natural pine forests and enjoying nature's splendour. We trek steadily along the river to the pretty log-hut village of Aru, 11 kms from Pahalgam. At Aru, the camp is already set up for you beside the Lidder River. We eat a delicious dinner round the fire and rest.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Aru – Pahalgam – Srinagar",
                        dayList: [
                            {
                                dayListDes: "After breakfast leave form Aru via Pahalgam to Srinagar which is 110 Kms it takes 2 ½ Hours via Saffron fields and Awantipora ruins evening reach to Srinagar overnight stay at Houseboat.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Airport transfers ",
                        dayList: [
                            {
                                dayListDes: "Morning transfer to Airport to fly for onward destination.",
                            },
                            {
                                dayListDes: "Tour Ends but sweet memories remain.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: Gulmarg1,
            ptitle: "KASHMIR DELIGHT TOUR - Srinagar, Gulmarg, Yusmarg, Pahalgam, Srinagar",
            pdays: "5N|6D",
            pfrom: "Srinagar",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: Gulmarg1
                    },
                    {
                        iimg: srinagar3
                    },
                    {
                        iimg: Pahalgam2
                    }
                ],
                ititle: "KASHMIR DELIGHT TOUR - Srinagar, Gulmarg, Yusmarg, Pahalgam, Srinagar",
                iprice: "₹ 25000",
                idays: "5N|6D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Airport Arrival",
                        dayList: [
                            {
                                dayListDes: "Arrival at Srinagar airport and transfer to Deluxe Houseboat at Dal Lake/Nigeen Lake, Afternoon rest & relax.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Srinagar – Gulmarg – Srinagar",
                        dayList: [
                            {
                                dayListDes: "Full day trip to Gulmarg one of the most beautiful summer/winter resorts in the valley. One can have a gondola ride (Cable car) from Gulmarg to Khilanmarg to have a real view in natural beauty of the summer and full snow view in winter. You can have a pony ride in the open fields to seven springs and to a lake, which remains frozen until June, overnight at Houseboat. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Srinagar – Yusmarg – Doodh Ganga – Srinagar",
                        dayList: [
                            {
                                dayListDes: "Full day excursion to Yusmarg (Meadow of flowers) Enroute visit village of Charar sheriff, Charar Sheriff Shrine and town of Charar Sheriff, Afternoon visit Doodh Ganga by pony, return to Srinagar for overnight stay at Houseboat.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Srinagar – Pahalgam – Srinagar",
                        dayList: [
                            {
                                dayListDes: "Full day tour of Pahalgam one of the beautiful and fascinating ideal resorts all the year round. It is the base for several treks in the region. Situated at the confluence of the Lidder River and set amidst fir and pine covered mountains, one can have a real feelings of Paradise. You can enjoy the pony ride to Shikargah & Baisaran.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Sightseeing of Mughal Gardens",
                        dayList: [
                            {
                                dayListDes: "Full tour of Mughal gardens visiting the Nishat Bagh (The garden of pleasure) Shalimar garden (Abode of love), Fairy palace & Chashmashahi (The Royal Spring), dinner and overnight stay in Houseboat.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Airport transfers ",
                        dayList: [
                            {
                                dayListDes: "Morning tour around city, afternoon transfer to airport Srinagar.",
                            },
                            {
                                dayListDes: "Tour Ends but sweet memories remain.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: zanskar1,
            ptitle: "KASHMIR LADAKH ZANSKAR - Srinagar, Kargil, Zanaskar, Ladakh",
            pdays: "12N|13D",
            pfrom: "Srinagar",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: zanskar1
                    },
                    {
                        iimg: leh2
                    },
                    {
                        iimg: srinagar4
                    }
                ],
                ititle: "KASHMIR LADAKH ZANSKAR - Srinagar, Kargil, Zanaskar, Ladakh",
                iprice: "₹ 25000",
                idays: "12N|13D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Srinagar",
                        dayList: [
                            {
                                dayListDes: "On arrival at Srinagar airport, our representative will meet you and escort you to Houseboat, Afternoon Shikara ride on Dal Lake visiting interesting places around the lake overnight in Houseboat.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Srinagar Tour of Mughal Gardens",
                        dayList: [
                            {
                                dayListDes: "Morning half day tour of world famous Mughal gardens visiting Nishat (the Garden of Pleasure) built by Asif khan in 1636 AD, And Shalimar Bag (Abode of love) built by Jehangir for his beloved Noor Jahan in 1619 AD. Afternoon visit Srinagar old city Jamia Masjid, Shah Hamdan Mosque, Srinagar Museum and enjoy the walking through the narrow bazaar of the city, overnight at Houseboat.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Srinagar- Kargil (205 Kms)",
                        dayList: [
                            {
                                dayListDes: "Full day drive to Kargil, After Sonamarg you start steep ascending most rough road and wet Zojila pass 3527 Mtrs Known as (Gateway of Ladakh) continue drive towards Drass second coldest place in the world here we stop for tea and passport check. Another two and half hours drive will take us Kargil, overnight at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Kargil - Rangdom (135 Kms)",
                        dayList: [
                            {
                                dayListDes: "After Kargil drives towards Zanaskar valley on the way see Panikar and Parka Chan village’s view of Nun & Kun peaks are arrival Rangdom in the evening, overnight in Camps.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Rangdom - Padam (150 Kms)",
                        dayList: [
                            {
                                dayListDes: "After short drive, we stop at Rangdom Gompa and visit the Gompa Continue drive to Padam, which is the capital of Zanaskar valley on the way cross Pensile pass 4401 Mtrs and some ancient Buddhist villages. After crossing Tungri bridge visit Sunni Gompa finally, we reach Padam in the late afternoon, overnight in Camps.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Padam",
                        dayList: [
                            {
                                dayListDes: "Day to visit Pipitong, Thonde Gompa and Zangla 3450 Mtrs this is the most beautiful village with ancient Zangla Gompa and you may get chance to visit queen's house at Zangla. On the way, return visit Karsha Gompa that is one of the oldest Monastery, overnight in Camps.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "Padam - Bardan Gompa",
                        dayList: [
                            {
                                dayListDes: "(You Can do the trek also 7/8 Hrs. Trek), Full day trek to Bardan Gompa on the way back visit Padam market and Padam Gompa, overnight in Camps.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "Padam - Kargil (285 Kms)",
                        dayList: [
                            {
                                dayListDes: "Full day drive to Kargil and in the evening we reach Kargil, overnight at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "Kargil - Uletokpo (153 Kms)",
                        dayList: [
                            {
                                dayListDes: "Full day drive to Uletokpo and we have to cross highest pass on Srinagar Leh route Fotula 13749/Fts. On the way visit, Mulbek and Lamayuru monastery one of the oldest Monastery in Ladakh region, continue drive to Uletokpo in the late afternoon one may visit to Rizong monastery by foot 5 Kms back and forth, overnight in Camps.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 10",
                        dayTitle: "Uletokpo – Alchi – Leh (81 Kms)",
                        dayList: [
                            {
                                dayListDes: "After short drive, we reach to Alchi Monastery and continue drive to Leh via some interesting Buddhist villages, afternoon free for personal activities. Over Night Stay at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 11",
                        dayTitle: "Leh (Ladakh)",
                        dayList: [
                            {
                                dayListDes: "Full day excursion to Shey, Thiksey, and Hemis monastery that is most popular monastery in Ladakh region, return back to Hotel in the late afternoon, overnight at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 12",
                        dayTitle: "Leh (Ladakh)",
                        dayList: [
                            {
                                dayListDes: "Morning half day to sightseeing tour of Spituk and Phyang monastery, afternoon visit by foot to Kings palace, Tsemo Gompa, Leh Mosque, Leh Bazar and Shankar Gompa, overnight at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 13",
                        dayTitle: "Leh",
                        dayList: [
                            {
                                dayListDes: "Transfer in time to the airport to connect onward destination flight.",
                            },
                            {
                                dayListDes: "Tour Ends but sweet memories remain.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: Gulmarg2,
            ptitle: "KASHMIR ROYAL TOUR, Srinagar, Pahalgam, Gulmarg",
            pdays: "4N|5D",
            pfrom: "Srinagar",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: Gulmarg2
                    },
                    {
                        iimg: Pahalgam3
                    },
                    {
                        iimg: srinagar5
                    }
                ],
                ititle: "KASHMIR ROYAL TOUR, Srinagar, Pahalgam, Gulmarg",
                iprice: "₹ 25000",
                idays: "4N|5D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Srinagar",
                        dayList: [
                            {
                                dayListDes: "Arrival at Srinagar airport and transfer to Deluxe Houseboat (SAVOY GROUP) at Dal Lake, Afternoon rest & relax. Lake rides by Shikara to visit Floating Gardens, Lotus Gardens, Char Chinar, Kaboter Khana (Pigeon Palace) and inside of the Lake. Dinner & Overnight stay at Deluxe Houseboat.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Srinagar",
                        dayList: [
                            {
                                dayListDes: "After breakfast transfer to (Hotel The Lalit 5*) Srinagar, Afternoon tour of Mughal Gardens visiting the Nishat Bagh (The Garden of Pleasure), Chashmashahi (The Royal Spring) and Shalimar Bagh (Abode of love), return to Hotel The Lalit.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Srinagar – Pahalgam - 2 hours drive 95 km",
                        dayList: [
                            {
                                dayListDes: "After breakfast, leave for Pahalgam, Via Awantipora ruins and Saffron fields; on arrival in Pahalgam check inn Hotel Pahalgam. Pahalgam is one of the beautiful and fascinating ideal resorts all the year round. It is the base for several treks in the region. Situated at the confluence of the Lidder River and set amidst fir and pine covered mountains, one can have a real feelings of Paradise. You can enjoy the pony ride to Shikargah & Baisaran. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Pahalgam – Gulmarg – 3 hours drive and distance of 160 km. ",
                        dayList: [
                            {
                                dayListDes: "Leave after Breakfast for Gulmarg via Martand located atop a plateau. Close to the township of Anantanag, the Temple dedicated to Surya “The Sun God”. Continue drive through Alpine Forest on arrival in Gulmarg Check inn Hotel Highland Park. Gulmarg is one of the most beautiful summer/winter resorts in the valley. One can have a Gondola ride (Cable car) from Gulmarg to Khilanmarg to have a real view in natural beauty of the summer and full snow view in winter. You can have a pony ride in the open fields to seven springs and to a lake, which remains frozen until June.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Gulmarg – Srinagar – Airport Drop 60 Kms 1 Hour Drive",
                        dayList: [
                            {
                                dayListDes: "After breakfast leave and drive via Tangmarg and outer line of Srinagar drive towards airport.",
                            },
                            {
                                dayListDes: "Tour Ends but sweet memories remain.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: srinagar6,
            ptitle: "PANORAMA OF KASHMIR - Srinagar, Gulmarg, Pahalgam, Srinagar",
            pdays: "6N|7D",
            pfrom: "Srinagar",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: srinagar6
                    },
                    {
                        iimg: Pahalgam4
                    },
                    {
                        iimg: Gulmarg3
                    }
                ],
                ititle: "PANORAMA OF KASHMIR - Srinagar, Gulmarg, Pahalgam, Srinagar",
                iprice: "₹ 25000",
                idays: "6N|7D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Srinagar",
                        dayList: [
                            {
                                dayListDes: "Arrival at Srinagar airport and transfer to a Deluxe Houseboat at Dal Lake/Nigeen Lake. Afternoon rest & relax. Forenoon Lake rides by Shikara to visit floating gardens, Lotus garden, Charchinar, and Kaboter Khana (Pigeon palace) and inside of the lake, dinner & overnight stay at Houseboat.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Srinagar – Gulmarg – Khilanmarg – Gulmarg",
                        dayList: [
                            {
                                dayListDes: "After breakfast leave for Gulmarg, one of the most beautiful summer/winter resorts in the valley. One can have a Gondola ride (Cable car) from Gulmarg to Khilanmarg to have real view in winter. You can have a pony ride in the open fields to seven springs and to a lake, which remains frozen till June, overnight stay in Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Gulmarg – Tangmarg- Pahalgam",
                        dayList: [
                            {
                                dayListDes: "After breakfast leave for full day trip to Pahalgam. Arrive at Pahalgam and check in at Hotel. Afternoon rest & relax, Forenoon visit to the local market around Pahalgam, overnight stay in Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Pahalgam",
                        dayList: [
                            {
                                dayListDes: "After breakfast leave for full day visit to Chandanwari – 16kms from Pahalgam–accessible by Jeep or pony. It is one of the most beautiful and fascinating idyllic resorts all the year round. It is the base for several treks in the region. Situated at the confluence of the Lidder river and set amidst fir and pine covered mountains, overnight stay in Pahalgam.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Pahalgam - Srinagar",
                        dayList: [
                            {
                                dayListDes: "Leave for Srinagar, check in Houseboat, Afternoon free for personal activities with evening Shikara ride, overnight stay at Houseboat.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Srinagar",
                        dayList: [
                            {
                                dayListDes: "Full day tour of Mughal gardens visiting the Nishat Bagh (The garden of pleasure), Chashmashahi (The Royal Spring), Shalimar garden (Abode of love), Harwan, Fairy palace & Shankaracharya temple, overnight stay at Houseboat. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "Airport transfers ",
                        dayList: [
                            {
                                dayListDes: "Morning visit to the market for Kashmir Handicrafts, afternoon transfer to airport.",
                            },
                            {
                                dayListDes: "Tour Ends but sweet memories remain.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: srinagar7,
            ptitle: "PARADISE OF KASHMIR - Srinagar, Pahalgam, Sonamarg, Gulmarg, Srinagar",
            pdays: "6N|7D",
            pfrom: "Srinagar",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: srinagar7
                    },
                    {
                        iimg: Pahalgam2
                    },
                    {
                        iimg: Gulmarg4
                    }
                ],
                ititle: "PARADISE OF KASHMIR - Srinagar, Pahalgam, Sonamarg, Gulmarg, Srinagar",
                iprice: "₹ 25000",
                idays: "6N|7D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Srinagar",
                        dayList: [
                            {
                                dayListDes: "Our representative at the airport will meet you and transfer you to a Deluxe Houseboat, Afternoon free for personal activities, overnight at Houseboat.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Srinagar – Pahalgam – Srinagar",
                        dayList: [
                            {
                                dayListDes: "Full day tour of Pahalgam one of the beautiful and fascinating ideal resorts all the year round. It is the base for several treks in the region. Situated at the confluence of the Lidder River and set amidst fir and pine covered mountains, one can have a real feelings of Paradise. You can enjoy the pony ride to Shikargah & Baisaran.  In the evening return to Houseboat for overnight stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Srinagar",
                        dayList: [
                            {
                                dayListDes: "Morning half day tour to Old City, Afternoon free for personal activities with evening Shikara rides.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Srinagar – Tangmarg – Gulmarg – Srinagar",
                        dayList: [
                            {
                                dayListDes: "Full day trip to Gulmarg, one of the most beautiful summer/winter resorts in the valley, one can have a Gondola ride (Cable car) from Gulmarg to Khilanmarg to have real view of the natural beauty in summer and full snow view in winter. You can have a pony ride in the open fields to seven springs and to a lake, which remains frozen till June, in the evening, return to Srinagar Enroute Tangmarg for its fast flowing stream, overnight at Houseboat. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Srinagar",
                        dayList: [
                            {
                                dayListDes: "Morning tour of Mughal gardens visiting the Nishat Bagh (The garden of pleasure), Chashmashahi (The Royal Spring) and Shalimar garden (Abode of love). Afternoon lake rides by Shikara to visit floating gardens, lotus gardens, Charchinar, Kaboter khana (Pigeon palace) and inside of the lake, dinner & overnight stay at Houseboat.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Srinagar – Sonamarg – Srinagar",
                        dayList: [
                            {
                                dayListDes: "Full day tour of Sonamarg (Meadows of gold), It has sheer scenic splendour. The very way-a metaled road snakes through massive mountains laden with forests and firs. After a breath-taking journey from Srinagar you come across Sindh meanders that are abounded with trout & Mahseer. Onwards the valley opens up to a meadow. Ponies can be hired for a trip up to Thajiwas glacier-a major attraction during the summer months. Sonamargs importance lies in the fact that it is the last halt on the Kashmir side for the drive from Srinagar to Leh. You will be fascinated to see snowy mountains against a cerulean sky against its backdrop. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "Airport transfers ",
                        dayList: [
                            {
                                dayListDes: "Morning free, afternoon transfer to airport.",
                            },
                            {
                                dayListDes: "Tour Ends but sweet memories remain.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        }
    ]
}