import title from '../media/package/multipack.jpg' ;

import dehli1 from '../media/package/commImage/dehli1.jpg';
import dehli2 from '../media/package/commImage/dehli2.jpg';

import agra1 from '../media/package/agra/agra1.jpg';
import agra2 from '../media/package/agra/agra2.jpg';

import shimla1 from '../media/package/shimla/shimla1.jpg';
import shimla2 from '../media/package/shimla/shimla2.jpg';

import Gwalior1 from '../media/package/Gwalior/Gwalior1.jpg';
import Gwalior2 from '../media/package/Gwalior/Gwalior2.jpg';

import mysore1 from '../media/package/kar/pack5/3.jpg';
import banglore1 from '../media/package/kar/pack5/1.jpg';
import kar1 from '../media/package/kar/pack1/i2.jpg';

import Chennai1 from '../media/package/Chennai/Chennai1.jpg';
import Chennai2 from '../media/package/Chennai/Chennai2.jpg';

import Bandipur1 from '../media/package/bandipur/Bandipur1.jpg';
import Bandipur2 from '../media/package/bandipur/Bandipur2.jpg';
import Bandipur3 from '../media/package/bandipur/Bandipur3.jpg';

import Ooty1 from '../media/package/ooty/Ooty1.jpg';
import Ooty2 from '../media/package/ooty/Ooty2.jpg';
import Ooty3 from '../media/package/ooty/Ooty3.jpg';

import Kumarakom1 from '../media/package/Kumarakom/Kumarakom1.jpg';

import munnar1 from '../media/package/munnar/munnar1.jpg';
import munnar2 from '../media/package/munnar/munnar2.jpg';
import munnar3 from '../media/package/munnar/munnar3.jpg';


import jaipur1 from '../media/package/commImage/jaipur1.jpg';
import jaipur2 from '../media/package/commImage/jaipur2.jpg';
import jaipur3 from '../media/package/commImage/jaipur3.jpg';
import jaipur4 from '../media/package/commImage/jaipur4.jpg';
import jaipur5 from '../media/package/commImage/jaipur5.jpg';


import jaisalmer1 from '../media/package/commImage/jaisalmer1.jpg';
import jaisalmer2 from '../media/package/commImage/jaisalmer2.jpg';
import jodhpur1 from '../media/package/commImage/jodhpur1.jpg';
import jodhpur2 from '../media/package/commImage/jodhpur2.jpg';
import udaipur1 from '../media/package/commImage/udaipur1.jpg';
import udaipur2 from '../media/package/commImage/udaipur2.jpg';
import udaipur3 from '../media/package/commImage/udaipur3.jpg';

import mahabalipuram from '../media/package/mahabalipuram/mahabalipuram.jpg';
import m2 from '../media/package/mahabalipuram/m2.jpg';

import mumbai1 from '../media/package/mumbai/mumbai1.jpg';
import mumbai2 from '../media/package/mumbai/mumbai2.jpg';
import mumbai3 from '../media/package/mumbai/mumbai3.jpg';

import cochin1 from '../media/package/cochin/cochin1.jpg';
import cochin2 from '../media/package/cochin/cochin2.jpg';
import cochin3 from '../media/package/cochin/cochin3.jpg';
import madurai1 from '../media/package/cochin/madurai1.jpg';
import madurai2 from '../media/package/cochin/madurai2.jpg';
import goa1 from '../media/package/cochin/goa1.jpg';

export const MultiStatePackage = {
    packageName: "Multi State",
    titleImg: title,
    packages:  [
        {
            pimg: jaipur4,
            ptitle: "2N Delhi, 1N Neemrana, 2N Jaipur, 2N Sariska, 1N Agra, 1N Delhi",
            pdays: "8N|9D",
            pfrom: "Delhi",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: dehli2
                    },
                    {
                        iimg: jaipur2
                    },
                    {
                        iimg: jaipur5
                    },
                    {
                        iimg: jaipur1
                    },
                    {
                        iimg: dehli1
                    }
                ],
                ititle: "2N Delhi, 1N Neemrana, 2N Jaipur, 2N Sariska, 1N Agra, 1N Delhi",
                iprice: "₹ 25000",
                idays: "8N|9D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrive at Delhi",
                        dayList: [
                            {
                                dayListDes: "Arrive Delhi and meet the Travelpro Holiday representative at the arrival hall and transfer to Hotel. Overnight at Hotel in Delhi.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "In Delhi",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of New & Old Delhi. Visit the 12th Century Qutab Minar; gracefully fluted and hand carved for its entire height of 234 feet. Near it stands the Iron Pillar which has withstood the ravages of time and hasn’t rusted in 840 years. Also, visit the graceful mausoleum of the Mughal Emperor Humayun and the modern Birla Temple. Drive past the President's House, the Parliament House and the Secretariat buildings. The Red Fort, which was built in 1648 by Mughal Emperor Shah Jahan and the Jama Masjid, which is one of the largest mosques in India are other places of interest. The Raj Ghat and Shanti Vana are the cremation sites of Mahatma Gandhi and Jawaharlal Nehru respectively and make for a peaceful retreat. Besides these, visit Chandni Chowk for a unique shopping experience at Old Delhi. Overnight at Hotel in Delhi.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "To Neemrana - 120 Kms - 3 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Neemrana. Arrive Neemrana and check-in at Hotel. Evening at leisure. Overnight at Hotel in Neemrana.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "To Jaipur – 138 Kms – 3 hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Jaipur. Arrive Jaipur and check-in at Hotel. Overnight at Hotel in Jaipur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "In Jaipur",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of Amber Fort and Jaipur city. Amber Fort was the ancient capital of the State until 1728. Visit the Temple of Kali, the Hall of Victory or Jai Mahal and Jagmandir. You can also experience a unique ride on an elephant’s back to and from the top of the hill on which the Fort is situated. The city of Jaipur takes its name from Maharajah Jai Singh, a prince and an astronomer, who designed and founded the city in 1727. Visit the quaint observatory built by him, which is equipped with instruments that he designed. These devices are of remarkable sizes, with such accuracy that is difficult to attain even in today’s age. Also visit the Maharaja's City Palace, a former royal residence and now a museum containing rare manuscripts, fine specimens of Rajput and Mughal paintings and weapons. Drive through the pink-rosed residential and business areas, past the Hawa Mahal or Palace of Winds, which is the landmark of Jaipur. Overnight at Hotel in Jaipur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "To Sariska - 110 Kms - 2 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Sariska. Arrive Sariska and check-in at Hotel. Sariska is probably one of the most visited wildlife parks in India. Like many other wildlife parks, Sariska too was the hunting reserve of the royal family in the area. In this case, it was the Royal family of Alwar. The reserve was declared a wildlife sanctuary in 1958 and came under the 'Project Tiger' as a tiger reserve in 1979. The park covers a large area of 800 square kilometres, 480 square kilometres of which form the core area of the national park.",
                            },
                            {
                                dayListDes: "Overnight at Hotel in Sariska.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "In Sariska",
                        dayList: [
                            {
                                dayListDes: "Morning and afternoon you can enjoy game safaris in the company of a naturalist. Overnight at Hotel in Sariska.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "To Agra - 210 Kms - 5 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Agra. En-route visit Fatehpur Sikri. Fatehpur Sikri is an impressive fortified city, which was built by Emperor Akbar. In spite of being a capital city, it was hardly ever inhabited because of shortage of water. Even today it stands magnificent, with majestic palaces, the five-tiered Panch Mahal and the tomb of Salim Chisthi, a saint who had predicted the birth of Akbar's son. Arrive Agra and check-in at Hotel. Overnight at Hotel in Agra.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "Departure from Delhi",
                        dayList: [
                            {
                                dayListDes: "In-time transfer to airport to board your flight for onward destination.",
                            },
                            {
                                dayListDes: "End of Tour",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: jaipur3,
            ptitle: "2N Delhi, 2N Jaipur, 1N Agra, 1N Delhi, 2N Shimla, 1N Delhi",
            pdays: "9N|10D",
            pfrom: "Delhi",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: dehli1
                    },
                    {
                        iimg: jaipur3
                    },
                    {
                        iimg: shimla1
                    },
                    {
                        iimg: agra1
                    }
                ],
                ititle: "2N Delhi, 2N Jaipur, 1N Agra, 1N Delhi, 2N Shimla, 1N Delhi",
                iprice: "₹ 25000",
                idays: "9N|10D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrive at Delhi",
                        dayList: [
                            {
                                dayListDes: "Arrive Delhi and meet the Travelpro Holiday representative at the arrival hall and transfer to Hotel. Overnight at Hotel in Delhi.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "In Delhi",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for sightseeing tour of New & Old Delhi. Visit the 12th Century Qutab Minar; gracefully fluted and hand carved for its entire height of 234 feet. Near it stands the Iron Pillar which has withstood the ravages of time and hasn't rusted in 840 years. Also, visit the graceful mausoleum of the Mughal Emperor Humayun and the modern Birla Temple . Drive past the President's House, the Parliament House and the Secretariat buildings. The Red Fort, which was built in 1648 by Mughal Emperor Shahjehan and the Jama Masjid, which is one of the largest mosques in India are other places of interest. The Raj Ghat and Shanti Vana are the cremation sites of Mahatma Gandhi and Jawaharlal Nehru respectively and make for a peaceful retreat. Besides these, visit Chandni Chowk for a unique shopping experience at Old Delhi. Overnight at Hotel in Delhi.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "To Jaipur - 260 Kms - 5 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Jaipur. Arrive Jaipur and check-in at Hotel. Overnight at Hotel in Jaipur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "In Jaipur",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of Amber Fort and Jaipur city. Amber Fort was the ancient capital of the State until 1728. Visit the Temple of Kali, the Hall of Victory or Jai Mahal and Jagmandir. You can also experience a unique ride on an elephant's back to and from the top of the hill on which the Fort is situated. The city of Jaipur takes its name from Maharajah Jai Singh, a prince and an astronomer, who designed and founded the city in 1727. Visit the quaint observatory built by him, which is equipped with instruments that he designed. These devices are of remarkable sizes, with such accuracy that is difficult to attain even in today's age. Also visit the Maharaja's City Palace, a former royal residence and now a museum containing rare manuscripts, fine specimens of Rajput and Mughal paintings and weapons. Drive through the pink-rosed residential and business areas, past the Hawa Mahal or Palace of Winds, which is the landmark of Jaipur. Overnight at Hotel in Jaipur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "To Agra - 238 Kms - 5 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Agra. En-route visit Fatehpur Sikri. Fatehpur Sikri is an impressive fortified city, which was built by Emperor Akbar. In spite of being a capital city, it was hardly ever inhabited because of shortage of water. Even today it stands magnificent, with majestic palaces, the five-tiered Panch Mahal and the tomb of Salim Chisthi, a saint who had predicted the birth of Akbar's son. Arrive Agra and check-in at Hotel. Overnight at Hotel in Agra.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "To Delhi - 204 Kms - 4 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for sightseeing tour of Agra. Visit Taj Mahal, which is the epitome of Shah Jahan's monumental testimony of love for his empress, Mumtaz Mahal. This beautiful mausoleum has become synonymous not only with this city but with India as a whole. The construction of Taj Mahal is believed to have taken 18 years (1631-1648) with over 20,000 craftsmen working around the clock. Also visit the Agra Fort, which is on the banks of the river Yamuna and is almost in the centre of the town. This Fort represents the architecture of the period during the reign of three great Mughal emperors; Akbar, Jahangir and Shah Jahan. The conspicuous structures within this complex are Jahangiri Mahal, Anguri Bagh, Khas Mahal and Moti Masjid. Later drive to Delhi. Arrive Delhi and check-in at Hotel. Overnight at Hotel in Delhi.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "To Shimla by Train",
                        dayList: [
                            {
                                dayListDes: "In-time transfer to Railway Station to board Express Train to Kalka. Arrive Kalka and further connect with Toy Train to Shimla. Meet the Travelpro Holiday representative on arrival at Shimla Railway Station and transfer to Hotel. Overnight at Hotel in Shimla.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "In Shimla",
                        dayList: [
                            {
                                dayListDes: "After breakfast proceed for full day city tour of Shimla. Shimla; a pleasant, sprawling town set amongst the pine-clad hills, presents you with glimpses of fading colonial charm. The buildings, the Mall and the picnic spots tell you a story of the era when Shimla was the summer capital of the British India . A visit to the Mall brings you to the hub of activity in this city. Move around the city and you get to see some of the fascinating sights of the landscape around. Other major attractions here are Jakhoo Hill, Glen, Summer Hill, Chadwick Falls, Prospect Hill, Institute of Advance Studies, Sankat Mochan Temple, Taradevi Temple and Himachal State Museum. Overnight at the Hotel in Shimla.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "To Delhi by Train",
                        dayList: [
                            {
                                dayListDes: "In-time transfer to Railway Station to board Express Train to Kalka. Arrive Kalka and further connect with Train to Delhi . Meet the Travelpro Holiday representative on arrival at Delhi Railway Station and transfer to Hotel. Overnight at Hotel in Delhi.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 10",
                        dayTitle: "Departure from Delhi",
                        dayList: [
                            {
                                dayListDes: "In-time transfer to airport to board your flight for onward destination.",
                            },
                            {
                                dayListDes: "End of Tour",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: Gwalior2,
            ptitle: "2N Delhi, 1N Mandawa, 2N Jaipur, 1N Agra, 1N Gwalior, 1N Delhi",
            pdays: "8N|9D",
            pfrom: "Delhi",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: dehli2
                    },
                    {
                        iimg: Gwalior2
                    },
                    {
                        iimg: jaipur5
                    },
                    {
                        iimg: jaipur1
                    }
                ],
                ititle: "2N Delhi, 1N Mandawa, 2N Jaipur, 1N Agra, 1N Gwalior, 1N Delhi",
                iprice: "₹ 25000",
                idays: "8N|9D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrive at Delhi",
                        dayList: [
                            {
                                dayListDes: "Arrive Delhi and meet the Travelpro Holiday representative at the arrival hall and transfer to Hotel. Overnight at Hotel in Delhi.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "In Delhi",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of New & Old Delhi. Visit the 12th Century Qutab Minar; gracefully fluted and hand carved for its entire height of 234 feet. Near it stands the Iron Pillar which has withstood the ravages of time and hasn’t rusted in 1500 years. Also, visit the graceful mausoleum of the Mughal Emperor Humayun and the modern Birla Temple. Drive past the President's House, the Parliament House and the Secretariat buildings. The Red Fort, which was built in 1648 by Mughal Emperor Shah Jahan and the Jama Masjid, which is one of the largest mosques in India are other places of interest. The Raj Ghat and Shanti Vana are the cremation sites of Mahatma Gandhi and Jawaharlal Nehru respectively and make for a peaceful retreat. Besides these, visit Chandni Chowk for a unique shopping experience at Old Delhi. Overnight at Hotel in Delhi.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "To Mandawa - 250 Kms - 05 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Mandawa. Arrive Mandawa and check-in at Hotel. Mandawa was founded in the 18th century. This medieval fort dominates the town with its painted arched gateway, which is adorned with images of Lord Krishna and his cows. The Chokhani and Ladia Havelis along with the Saraf Havelis are some of the splendid examples of this region’s havelis. The Shiva Temple with a rock crystal ‘lingam’ is also worth a visit. The fort is now converted into a heritage hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "To Jaipur - 170 Kms - 04 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Jaipur. Arrive Jaipur and check-in at Hotel. Overnight at Hotel in Jaipur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "In Jaipur",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of Amber Fort and Jaipur city. Amber Fort was the ancient capital of the State until 1728. Visit the Temple of Kali, the Hall of Victory or Jai Mahal and Jagmandir. You can also experience a unique ride on an elephant’s back to and from the top of the hill on which the Fort is situated. The city of Jaipur takes its name from Maharajah Jai Singh, a prince and an astronomer, who designed and founded the city in 1727. Visit the quaint observatory built by him, which is equipped with instruments that he designed. These devices are of remarkable sizes, with such accuracy that is difficult to attain even in today’s age. Also visit the Maharaja's City Palace, a former royal residence and now a museum containing rare manuscripts, fine specimens of Rajput and Mughal paintings and weapons. Drive through the pink-rosed residential and business areas, past the Hawa Mahal or Palace of Winds, which is the landmark of Jaipur. Overnight at Hotel in Jaipur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "To Agra - 238 Kms - 4 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Agra. En-route visit Fatehpur Sikri. Fatehpur Sikri is an impressive fortified city, which was built by Emperor Akbar. In spite of being a capital city, it was hardly ever inhabited because of shortage of water. Even today it stands magnificent, with majestic palaces, the five-tiered Panch Mahal and the tomb of Salim Chisthi, a saint who had predicted the birth of Akbar's son. Arrive Agra and check-in at Hotel. Overnight at Hotel in Agra.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "To Gwalior - 119 Kms - 03 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for sightseeing tour of Agra. Visit Taj Mahal, which is the epitome of Shah Jahan’s monumental testimony of love for his empress, Mumtaz Mahal. This beautiful mausoleum has become synonymous not only with this city but with India as a whole. The construction of Taj Mahal is believed to have taken 18 years (1631-1648) with over 20,000 craftsmen working around the clock. Also visit the Agra Fort, which is on the banks of the river Yamuna and is almost in the centre of the town. This Fort represents the architecture of the period during the reign of three great Mughal emperors; Akbar, Jahangir and Shah Jahan. The conspicuous structures within this complex are Jahangiri Mahal, Anguri Bagh, Khas Mahal and Moti Masjid. Later drive to Gwalior. Arrive Gwalior and check-in at Hotel. Overnight at Hotel in Gwalior.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "To Delhi (By Train)",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of Gwalior. Visit the majestic Gwalior Fort, which is one of the largest and mightiest forts in India. Also visit the Sas-Bahu Temples that are 11th-century temples of Lord Vishnu. Besides this, you can see the Teli-ka-Mandir, which is a 300-metre-high Vishnu temple dating back to the 9th century A.D. Later, transfer to the Railway Station to board Express train to Delhi. Arrive Delhi in the evening and meet the Travelpro Holiday representative at Railway Station and transfer to Hotel. Overnight at Hotel in Delhi.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "Departure from Delhi",
                        dayList: [
                            {
                                dayListDes: "In-time transfer to airport to board your flight for onward destination.",
                            },
                            {
                                dayListDes: "End of Tour",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: Chennai1,
            ptitle: "2N Chennai, 2N Mysore, 2N Bandipur, 1N Ooty, 2N Cochin, 1N Kumarakom, 1N Periyar, 2N Munnar",
            pdays: "13N|14D",
            pfrom: "Chennai",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: Chennai1
                    },
                    {
                        iimg: Bandipur2
                    },
                    {
                        iimg: Ooty2
                    },
                    {
                        iimg: Kumarakom1
                    }
                ],
                ititle: "2N Chennai, 2N Mysore, 2N Bandipur, 1N Ooty, 2N Cochin, 1N Kumarakom, 1N Periyar, 2N Munnar",
                iprice: "₹ 25000",
                idays: "13N|14D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrive Chennai",
                        dayList: [
                            {
                                dayListDes: "Transfer on your own to Delhi airport to board the flight to Chennai. On arrival you will be met and transferred to Hotel for overnight stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "In Chennai",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of Chennai including. The St. George Fort, built in 1653, is currently used as the state government's secretariat. Santhome Cathedral was built around 14th/15th century and is another colonial structure worth visiting. Dedicated to Lord Shiva, the Kapaleeshwar Temple is the oldest temple in Chennai. Another important temple in the city dedicated to Lord Krishna is Parthsarthy Temple , Marina Beach, the second longest beach in the world. Overnight at the hotel in Chennai.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Chennai / Bangalore - By flight Bangalore / Mysore - By road - 150 Kms / 03 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Jaipur. Arrive Jaipur and check-in at Hotel. Overnight at Hotel in Jaipur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Mysore",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for an excursion to Ranganathittu the bird sanctuary near Mysore , Daria Daulat Bagh and the Gumbaz in Srirangapatnam etc and Somnathpur. Overnight at hotel in Mysore",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Mysore To Bandipur - 85kms - 2 hours drive",
                        dayList: [
                            {
                                dayListDes: "Afternoon city tour including Maharaja's Palace built as recently as 1897, which is one of the biggest in India ; St Philomena's Church etc; Sri Jauyachamarajendra Art Gallery housed in the Jagmohan Palace , which has a collection of exquisite paintings, Folklore museum, Brindavan Gardens etc. Afternoon drive to Bandipur. On arrival, check in at Hotel for overnight stay .",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "In Bandipur",
                        dayList: [
                            {
                                dayListDes: "Morning jungle visit to the Park. In the afternoon jungle visit to the Park. Overnight at the hotel in Bandipur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "Bandipur To Ooty - 80kms - 3 hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning you will be drive to Ooty. On arrival check in at hotel for overnight stay. The history of Ooty goes back to about 900 years when it was ruled by Vishnu Vardhana, the Hoysala king from AD 1104 to 1141. The city also finds mention in the writing of Jacome Ferico, the first European to visit this place in 1603. In 1799, this hilly region came under the control of the British East India Company. The British established Ooty to serve as the summer headquarters of the Madras government. A government house was built and keeping in tune with the English lifestyle, the British set up cottages and clubs, with facilities for tennis, golf, and horse riding. With the establishment of numerous tea estates in the subsequent years, Ooty made its presence felt in the tourist map of India. Overnight at hotel in Ooty.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "Ooty To Cochin - 250 Kms - 5 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Cochin . On arrival in Cochin check into Hotel for overnight stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "In Cochin",
                        dayList: [
                            {
                                dayListDes: "Morning sightseeing tour of Cochin including Fort Cochin, The Dutch Palace at Mattancherry is renowned for its wall murals depicting scenes from the Ramayana. The Jewish Synagogue at the heart of what is locally called the Jew Town. The St. Francis Church, the oldest European church in India . The Santa Cruz Basilica, originally built in 1557 by the Portuguese. The Chinese fishing nets are huge cantilevered fishing nets believed to have brought to Kochi by Chinese traders. Overnight at the hotel in Cochin.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 10",
                        dayTitle: "Cochin To Kumarakom - 90 kms - 02 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Kumarakom. On arrival in Kumarakom check in at Hotel for overnight stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 11",
                        dayTitle: "Kumarakom To Periyar - 140 kms - 4 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Periyar. Arrive Periyar and check in at Hotel. Afternoon enjoy boat cruise on Lake Periyar in the National Park, known for herds of wild elephant, bison, deer and spectacular bird life. Evening proceed to visit the spice market. Overnight at the Hotel in Periyar.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 12",
                        dayTitle: "Periyar To Munnar - 124 Kms - 4 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Munnar, the famous hill station of Kerala. Munnar - surrounded by lush green-carpeted tea and coffee plantations, Munnar is a picturesque hill station situated in the eastern part of Kerala. The town is located at the confluence of three mountain streams-Mudrapuzha, Nallathanni, and Kudala - and is known for its clean, neat, and brightly painted ambience. The beautiful landscapes, cosy weather, and the smell of tealeaves make Munnar one of the most sought-after destinations in South India . Precisely these were the reason why the British always retreated to Munnar when the heat and humidity was on its peak in other parts of Kerala. Munnar is situated near the Anaimudi Peak , the highest peak in the Western Ghats . The hills here are covered with a carpet of flowers in bloom. Also found here is an exotic plant called Neelakurunji that flowers just once in a 12-year period. Its next scheduled flowering is in 2006. On arrive in Munnar, check in at Hotel. Overnight at the hotel in Munnar",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 13",
                        dayTitle: " In Munnar",
                        dayList: [
                            {
                                dayListDes: "Full day will be at leisure to relax or explore the city.  Overnight will be at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 14",
                        dayTitle: "Munnar To Cochin - 130 kms - 04 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Cochin. On arrival you will be met and transfer to the international airport to board the flight to onward destination",
                            },
                            {
                                dayListDes: "End of our services.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: munnar1,
            ptitle: "2N Chennai, 2N Mysore, 2n Nagarhole, 2N Bandipur, 1N Ooty, 2N Cochin, 1N Kumarakom, 1N Periyar, 2N Munnar",
            pdays: "15N|16D",
            pfrom: "Chennai",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: Chennai2
                    },
                    {
                        iimg: Bandipur1
                    },
                    {
                        iimg: Ooty3
                    },
                    {
                        iimg: Kumarakom1
                    }
                ],
                ititle: "2N Chennai, 2N Mysore, 2n Nagarhole, 2N Bandipur, 1N Ooty, 2N Cochin, 1N Kumarakom, 1N Periyar, 2N Munnar",
                iprice: "₹ 25000",
                idays: "15N|16D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrive Chennai",
                        dayList: [
                            {
                                dayListDes: "Transfer on your own to Delhi airport to board the flight to Chennai. On arrival you will be met and transferred to Hotel for over night stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "In Chennai",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of Chennai including. The St. George Fort, built in 1653, is currently used as the state government's secretariat. Santhome Cathedral was built around 14th/15th century and is another colonial structure worth visiting. Dedicated to Lord Shiva, the Kapaleeshwar Temple is the oldest temple in Chennai. Another important temple in the city dedicated to Lord Krishna is Parthsarthy Temple , Marina Beach, the second longest beach in the world. Overnight at the hotel in Chennai.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Chennai / Bangalore - By flight Bangalore / Mysore - By road - 150 Kms / 03 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning you will be transferred to the airport to board the flight to Bangalore . You will be met on arrival and later drive to Mysore . On arrival, check in at Hotel for overnight stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Mysore",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for an excursion to Ranganathittu the bird sanctuary near Mysore , Daria Daulat Bagh and the Gumbaz in Srirangapatnam etc and Somnathpur. Overnight at hotel in Mysore",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "To Nagarhole - 100kms - 3 hours drive",
                        dayList: [
                            {
                                dayListDes: "Afternoon city tour including Maharaja's Palace built as recently as 1897, which is one of the biggest in India ; St Philomena's Church etc; Sri Jauyachamarajendra Art Gallery housed in the Jagmohan Palace , which has a collection of exquisite paintings, Folklore museum, Brindavan Gardens etc. Afternoon drive to Nagarhole. On arrival, check in at Hotel for overnight stay .",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Nagarhole",
                        dayList: [
                            {
                                dayListDes: "Morning jungle visit to the Park. In the afternoon jungle visit to the Park. Overnight at the hotel in Nagarhole.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "To Bandipur - 190 kms - 04 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Bandipur. ON arrival check in at hotel. Afternoon jungle visit to the park. Overnight at the hotel in Bandipur. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "In Bandipur",
                        dayList: [
                            {
                                dayListDes: "Morning jungle visit to the Park. In the afternoon jungle visit to the Park. Overnight at the hotel in Bandipur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "To Ooty - 80kms - 3 hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning you will be drive to Ooty. On arrival check in at hotel for overnight stay. The history of Ooty goes back to about 900 years when it was ruled by Vishnu Vardhana, the Hoysala king from AD 1104 to 1141. The city also finds mention in the writing of Jacome Ferico, the first European to visit this place in 1603. In 1799, this hilly region came under the control of the British East India Company. The British established Ooty to serve as the summer headquarters of the Madras government. A government house was built and keeping in tune with the English lifestyle, the British set up cottages and clubs, with facilities for tennis, golf, and horse riding. With the establishment of numerous tea estates in the subsequent years, Ooty made its presence felt in the tourist map of India. Overnight at hotel in Ooty",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 10",
                        dayTitle: "To Cochin - 250 Kms - 5 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Kumarakom. On arrival in Kumarakom check in at Hotel for overnight stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 11",
                        dayTitle: "Kumarakom To Periyar - 140 kms - 4 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning sightseeing tour of Cochin including Fort Cochin , The Dutch Palace at Mattancherry is renowned for its wall murals depicting scenes from the Ramayana. The Jewish Synagogue at the heart of what is locally called the Jew Town . The St. Francis Church, the oldest European church in India . The Santa Cruz Basilica, originally built in 1557 by the Portuguese. The Chinese fishing nets are huge cantilevered fishing nets believed to have brought to Kochi by Chinese traders. Overnight at the hotel in Cochin.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 12",
                        dayTitle: "To Kumarakom - 90 kms - 02 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Kumarakom. On arrival in Kumarakom check in at Hotel for overnight stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 13",
                        dayTitle: "To Periyar - 127 kms - 3 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Periyar. Arrive Periyar and check in at Hotel. Afternoon enjoy boat cruise on Lake Periyar in the National Park, known for herds of wild elephant, bison, deer and spectacular bird life. Evening proceed to visit the spice market. Overnight at the Hotel in Periyar.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 14",
                        dayTitle: "To Munnar - 124 Kms - 3 hours drive",
                        dayList: [
                            {
                                dayListDes: "lush green-carpeted tea and coffee plantations, Munnar is a picturesque hill station situated in the eastern part of Kerala. The town is located at the confluence of three mountain streams-Mudrapuzha, Nallathanni, and Kudala - and is known for its clean, neat, and brightly painted ambience. The beautiful landscapes, cosy weather, and the smell of tealeaves make Munnar one of the most sought-after destinations in South India . Precisely these were the reason why the British always retreated to Munnar when the heat and humidity was on its peak in other parts of Kerala. Munnar is situated near the Anaimudi Peak , the highest peak in the Western Ghats . The hills here are covered with a carpet of flowers in bloom. Also found here is an exotic plant called Neelakurunji that flowers just once in a 12-year period. Its next scheduled flowering is in 2006. On arrive in Munnar, check in at Hotel. Overnight at the hotel in Munnar",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 15",
                        dayTitle: "In Munnar",
                        dayList: [
                            {
                                dayListDes: "lush green-carpeted tea and coffee plantations, Munnar is a picturesque hill station situated in the eastern part of Kerala. The town is located at the confluence of three mountain streams-Mudrapuzha, Nallathanni, and Kudala - and is known for its clean, neat, and brightly painted ambience. The beautiful landscapes, cosy weather, and the smell of tealeaves make Munnar one of the most sought-after destinations in South India . Precisely these were the reason why the British always retreated to Munnar when the heat and humidity was on its peak in other parts of Kerala. Munnar is situated near the Anaimudi Peak , the highest peak in the Western Ghats . The hills here are covered with a carpet of flowers in bloom. Also found here is an exotic plant called Neelakurunji that flowers just once in a 12-year period. Its next scheduled flowering is in 2006. On arrive in Munnar, check in at Hotel. Overnight at the hotel in Munnar",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 16",
                        dayTitle: "To Cochin - 130 kms - 03 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Cochin . On arrival you will be met and transfer to the international airport to board the flight to onward destination",
                            },
                            {
                                dayListDes: "End of our services.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: mahabalipuram,
            ptitle: "Temples of south India : 1N Chennai, 2N Mahabalipuram , 1n Pondichery , 1N Tanjore , 1N Trichy , 1N Madurai, 1N Thekkady, 1N Cochin, 2N Mumbai",
            pdays: "11N|12D",
            pfrom: "Chennai",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: mahabalipuram
                    },
                    {
                        iimg: Chennai1
                    },
                    {
                        iimg: m2
                    },
                    {
                        iimg: mumbai2
                    }
                ],
                ititle: "Temples of south India : 1N Chennai, 2N Mahabalipuram , 1n Pondichery , 1N Tanjore , 1N Trichy , 1N Madurai, 1N Thekkady, 1N Cochin, 2N Mumbai",
                iprice: "₹ 25000",
                idays: "11N|12D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrive Chennai",
                        dayList: [
                            {
                                dayListDes: "Arrive Chennai and meet the Travelpro Holiday, representative at the arrival hall. Later drive to Mahabalipuram. Arrive Mahabalipuram and check-in at Hotel. Overnight at Hotel in Mahabalipuram.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "To Mahabalipuram - 90 Kms - 2 Hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for sightseeing tour of Mahabalipuram and Kanchipuram. Mahabalipuram, with its picturesque location on a rocky outcrop between the beach and a lagoon is a happy combination of history, good beaches, hassle-free tourism and fabulous fish and lobster! Despite the many visitors drawn-in by the former Pallava-dynasty town, the place is friendly and relaxed and the villagers are remarkably unperturbed by the large crowds. Mahabalipuram is renowned for its series of charming rathas and the spectacular carved relief depicting Arjuna’s Penance. The magnificent Shore Temple, built by King Rajasimha in the seventh century, is a unique temple that houses the shrines of both Lord Shiva and Lord Vishnu. The Varaha Cave is a rock-cut mandapam (hall) with two incarnations of Vishnu — Varaha (boar) and Vamana (dwarf). The Krishna Mandapam (Hall) has a bas-relief of Lord Krishna lifting the Govardhan hill on his fingertips. Other important attractions of Mahabalipuram include the Mahishasuramardini Cave, Kotikal Mandapam, Ramanuja Mandapam, and the Dharmaraja Cave. Later visit Kanchipuram (64 Kms from Chennai), the ancient capital of the Pallavas. It is known as the city of 1000 temples that still has 124 shrines. The first temple dedicated to Lord Shiva was built here in the 7th & 8th century A.D. and has paintings on its walls. The Temples of Ekambaswara, Kailasanath, Sri Kamakshi and Varadarajaswamy are of interest. Kanchipuram is also famous for its silks.",
                            },
                            {
                                dayListDes: "Overnight at Hotel in Mahabalipuram.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "In Mahabalipuram ",
                        dayList: [
                            {
                                dayListDes: "Full day will be at leisure to explore the city or relax at the beach. Overnight at Hotel in Mahabalipuram.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "To Pondicherry - 165 Kms - 4 Hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Pondicherry. Arrive Pondicherry and check–in at Hotel. Later, proceed for the sightseeing tour of Pondicherry.  Visit Sri Aurobindo Ashram, the final resting place of the founder of the Ashram, Sri Aurobindo and the Mother. Also visit the Church Of The Sacred Heart Of Jesus with its stained glass windows built in Gothic style. The Pondicherry Museum, the Church of Our Lady of the Angels on Beach Rd. and the Church of Our Lady of the Immaculate Conception are other places of interest.",
                            },
                            {
                                dayListDes: "Overnight at Hotel in Pondicherry.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "To Tanjore - 177 Kms - 4 Hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Tanjore. Arrive Tanjore and check-in at Hotel. Later, proceed for the sightseeing tour of Tanjore. You can see and visualize the history, religion and nature in all the places of attraction here. Tanjore is essentially a place for art lovers, especially those who love temple architecture and wonder about kings who erected these magnificent monuments. During their reign, the Cholas made significant contribution to the various fields of art and architecture. The famous Thanjavur style of painting was developed by Serfoji, the 19th-century Maharaja of Thanjavur. This distinguished art of Thanjavur is a combination of raised and painted surfaces. Krishna is the deity depicted here with his complexion shown as white rather than the traditional blue. The Brihadeeswara Temple, the beautiful Palace, the Art Gallery and the Saraswati Mahal Library are some of the other things to see in Tanjore. Overnight at Hotel in Tanjore.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "To Tiruchirappally (Trichy) - 54 Kms - 2 Hrs Drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Trichy. Arrive Trichy and check-in at Hotel. Later, proceed for the sightseeing tour of Trichy. Trichy has various sites within its proximity that you can visit. Most of the places here hold great historical importance. The Rock Fort Temple, an enchanting temple unique in its own way, makes for a must-visit site in Trichy. Moreover, the Tiruvanaikkaval temple, which is famous for its Siva Lingam and the seven Gopurams, is another pilgrimage site you can visit in Trichy. Later you will be driven to Pondicherry. Overnight at Hotel in Trichy.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "To Madurai - 128 Kms - 2 Hrs Drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Madurai. Arrive Madurai and check-in at Hotel. Later, proceed for the sightseeing tour of Madurai. Visit the great Meenakshi Temple, dedicated to the Consort of Lord Shiva, with its ten towering gopurams (temple towers) rising above the surrounding courtyards and countryside of lush green fields of rice and coconut palms.  See the 'Hall of a Thousand Pillars', built in 1560, which is a great work of structural engineering and art. Each pillar is a picture book in stone, depicting the whole range of human expression.  Later, visit the Tirumalai Nayak Palace, a gracious building in the Indo-Saracenic style that is famous for the stuccowork on its domes and arches. Evening visit temple for the second time to attend temple ceremony. Overnight at Hotel in Madurai.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "To Periyar - 134 Kms - 4:30 Hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Periyar.  Arrive Periyar and check–in at Hotel. Later, proceed for the sightseeing tour of Periyar. Enjoy boat cruise on Lake Periyar in the National Park, which is known for herds of wild elephant, bison, deer and spectacular bird life. Evening proceed to visit the spice market. Overnight at Hotel in Periyar.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "To Cochin - 185 Kms - 5:30 Hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of Cochin. This includes Fort Cochin, The Dutch Palace at Mattancherry, which is renowned for its wall murals depicting scenes from the Ramayana and The Jewish Synagogue at the heart of a town locally known as the Jew Town. Cochin also boasts of The St. Francis Church, the oldest European church in India and The Santa Cruz Basilica, which was originally built in 1557 by the Portuguese. Another tourist attraction is the Chinese fishing nets which are huge cantilevered fishing nets believed to have been brought by Chinese traders to Cochin. Overnight at Hotel in Cochin.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 10",
                        dayTitle: "To Mumbai",
                        dayList: [
                            {
                                dayListDes: "Departure to Mumbai by Air from Cochin, check in to hotel, rest of the day is at leisure. Overnight stay at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 11",
                        dayTitle: "Mumbai ",
                        dayList: [
                            {
                                dayListDes: "Early Breakfast, take tour of boat journey to Elephanta Caves. Designated by UNESCO as a World Heritage Site, the Elephanta Caves are a collection of cave temples predominantly dedicated to the Hindu god Shiva. They are on Elephanta Island, or Gharapuri, in Mumbai Harbour, 10 kilometres east of Mumbai in the Indian state of Mahārāshtra. Evening return to hotel overnight stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 12",
                        dayTitle: "Mumbai Departure",
                        dayList: [
                            {
                                dayListDes: "End of our services.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: Ooty2,
            ptitle: "1N Chennai, 2N Mahabalipuram , 1n Pondichery , 1N Tanjore , 1N Trichy , 1N Madurai, 1n Periyar, 1N Cochin, 1N Ooty , 1N Mysore, 1N Bangalore ",
            pdays: "12N|13D",
            pfrom: "Chennai",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: Ooty2
                    },
                    {
                        iimg: Chennai1
                    },
                    {
                        iimg: mahabalipuram
                    },
                    {
                        iimg: banglore1
                    }
                ],
                ititle: "1N Chennai, 2N Mahabalipuram , 1n Pondichery , 1N Tanjore , 1N Trichy , 1N Madurai, 1n Periyar, 1N Cochin, 1N Ooty , 1N Mysore, 1N Bangalore ",
                iprice: "₹ 25000",
                idays: "12N|13D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrive Chennai",
                        dayList: [
                            {
                                dayListDes: "Arrive Chennai and meet the Travelpro Holiday representative at the arrival hall. Later drive to Mahabalipuram. Arrive Mahabalipuram and check-in at Hotel. Overnight at Hotel in Mahabalipuram.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "To Mahabalipuram - 90 Kms - 2 Hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for sightseeing tour of Mahabalipuram and Kanchipuram. Mahabalipuram, with its picturesque location on a rocky outcrop between the beach and a lagoon is a happy combination of history, good beaches, hassle-free tourism and fabulous fish and lobster! Despite the many visitors drawn-in by the former Pallava-dynasty town, the place is friendly and relaxed and the villagers are remarkably unperturbed by the large crowds. Mahabalipuram is renowned for its series of charming rathas and the spectacular carved relief depicting Arjuna’s Penance. The magnificent Shore Temple, built by King Rajasimha in the seventh century, is a unique temple that houses the shrines of both Lord Shiva and Lord Vishnu. The Varaha Cave is a rock-cut mandapam (hall) with two incarnations of Vishnu — Varaha (boar) and Vamana (dwarf). The Krishna Mandapam (Hall) has a bas-relief of Lord Krishna lifting the Govardhan hill on his fingertips. Other important attractions of Mahabalipuram include the Mahishasuramardini Cave, Kotikal Mandapam, Ramanuja Mandapam, and the Dharmaraja Cave. Later visit Kanchipuram (64 Kms from Chennai), the ancient capital of the Pallavas. It is known as the city of 1000 temples that still has 124 shrines. The first temple dedicated to Lord Shiva was built here in the 7th & 8th century A.D. and has paintings on its walls. The Temples of Ekambaswara, Kailasanath, Sri Kamakshi and Varadarajaswamy are of interest. Kanchipuram is also famous for its silks.",
                            },
                            {
                                dayListDes: "Overnight at Hotel in Mahabalipuram.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "In Mahabalipuram ",
                        dayList: [
                            {
                                dayListDes: "Full day will be at leisure to explore the city or relax at the beach. Overnight at Hotel in Mahabalipuram.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "To Pondicherry - 165 Kms - 4 Hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Pondicherry. Arrive Pondicherry and check–in at Hotel. Later, proceed for the sightseeing tour of Pondicherry.  Visit Sri Aurobindo Ashram, the final resting place of the founder of the Ashram, Sri Aurobindo and the Mother. Also visit the Church Of The Sacred Heart Of Jesus with its stained glass windows built in Gothic style. The Pondicherry Museum, the Church of Our Lady of the Angels on Beach Rd. and the Church of Our Lady of the Immaculate Conception are other places of interest.",
                            },
                            {
                                dayListDes: "Overnight at Hotel in Pondicherry.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "To Tanjore - 177 Kms - 4 Hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Tanjore. Arrive Tanjore and check-in at Hotel. Later, proceed for the sightseeing tour of Tanjore. You can see and visualize the history, religion and nature in all the places of attraction here. Tanjore is essentially a place for art lovers, especially those who love temple architecture and wonder about kings who erected these magnificent monuments. During their reign, the Cholas made significant contribution to the various fields of art and architecture. The famous Thanjavur style of painting was developed by Serfoji, the 19th-century Maharaja of Thanjavur. This distinguished art of Thanjavur is a combination of raised and painted surfaces. Krishna is the deity depicted here with his complexion shown as white rather than the traditional blue. The Brihadeeswara Temple, the beautiful Palace, the Art Gallery and the Saraswati Mahal Library are some of the other things to see in Tanjore. Overnight at Hotel in Tanjore.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "To Tiruchirappally (Trichy) - 54 Kms - 2 Hrs Drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Trichy. Arrive Trichy and check-in at Hotel. Later, proceed for the sightseeing tour of Trichy. Trichy has various sites within its proximity that you can visit. Most of the places here hold great historical importance. The Rock Fort Temple, an enchanting temple unique in its own way, makes for a must-visit site in Trichy. Moreover, the Tiruvanaikkaval temple, which is famous for its Siva Lingam and the seven Gopurams, is another pilgrimage site you can visit in Trichy. Later you will be driven to Pondicherry. Overnight at Hotel in Trichy.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "To Madurai - 128 Kms - 2 Hrs Drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Madurai. Arrive Madurai and check-in at Hotel. Later, proceed for the sightseeing tour of Madurai. Visit the great Meenakshi Temple, dedicated to the Consort of Lord Shiva, with its ten towering gopurams (temple towers) rising above the surrounding courtyards and countryside of lush green fields of rice and coconut palms.  See the 'Hall of a Thousand Pillars', built in 1560, which is a great work of structural engineering and art. Each pillar is a picture book in stone, depicting the whole range of human expression.  Later, visit the Tirumalai Nayak Palace, a gracious building in the Indo-Saracenic style that is famous for the stuccowork on its domes and arches. Evening visit temple for the second time to attend temple ceremony. Overnight at Hotel in Madurai.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "To Periyar - 134 Kms - 4:30 Hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Periyar.  Arrive Periyar and check–in at Hotel. Later, proceed for the sightseeing tour of Periyar. Enjoy boat cruise on Lake Periyar in the National Park, which is known for herds of wild elephant, bison, deer and spectacular bird life. Evening proceed to visit the spice market. Overnight at Hotel in Periyar.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "To Cochin - 185 Kms - 5:30 Hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of Cochin. This includes Fort Cochin, The Dutch Palace at Mattancherry, which is renowned for its wall murals depicting scenes from the Ramayana and The Jewish Synagogue at the heart of a town locally known as the Jew Town. Cochin also boasts of The St. Francis Church, the oldest European church in India and The Santa Cruz Basilica, which was originally built in 1557 by the Portuguese. Another tourist attraction is the Chinese fishing nets which are huge cantilevered fishing nets believed to have been brought by Chinese traders to Cochin. Overnight at Hotel in Cochin.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 10",
                        dayTitle: "To MumbaiTo Ooty - 295 Kms - 7:30 Hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Ooty. Arrive Ooty and check-in at Hotel. Later, proceed for the sightseeing tour of Ooty and its surroundings. Visit The Botanical Garden, which is an ideal tourist spot besides being a source of information about the region’s flora. Also visit the Ooty Lake. In addition, there are various other sites like Lamb’s Rock (8 km away from Coonoor), Kodanadu (16 km from Kotagiri), and Dodabetta Peak that offer fascinating views of the surrounding plains and hills.Overnight at Hotel in Ooty.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 11",
                        dayTitle: "To Mysore - 160kms - 4.50 Hrs drive ",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Mysore. Arrive Mysore and check-in at Hotel. Later, proceed for the sightseeing tour of Mysore. This includes the Maharaja’s Palace, built as recently as 1897; it is one of the biggest in India. Also, there is St Philomena’s Church, Sri Jauyachamarajendra Art Gallery housed in the Jagmohan Palace, which has a collection of exquisite paintings and Brindavan Gardens, which are illuminated for 3 days a week. Later visit Chamundi Hill, on top of which stands a temple dedicated to the Goddess Chamundeshwari, the royal family’s patron deity. Overnight at Hotel in Mysore.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 12",
                        dayTitle: "To Bangalore - 140kms - 3.50 Hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Bangalore. Arrive Bangalore and check-in at Hotel. Later, proceed for the sightseeing tour of Bangalore. Visit Tipu Sultan’s Palace, originally built in mud in 1537 and rebuilt by Haider Ali a couple of centuries later, Bull Temple; enshrining a massive bull carved out of a single piece of Grey granite, Lalbagh Gardens; laid out by Haider Ali in the 18th century, Cubbon Park; laid out by the British in the 19th century and Vidhan Soudha; built in granite, which incorporates features of traditional Indian architecture. Overnight at Hotel in Bangalore.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 13",
                        dayTitle: "Leave Bangalore (By Flight)",
                        dayList: [
                            {
                                dayListDes: "In-time transfer to airport to board flight for onward destination.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: mumbai2,
            ptitle: "Kerala – God’s Own Country : 1N Cochin , 2N Munnar , 2N Thekkady, 1N Alleppey , 1N Mumbai",
            pdays: "8N|9D",
            pfrom: "Cochin",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: mumbai2
                    },
                    {
                        iimg: mumbai1
                    }
                ],
                ititle: "Kerala – God’s Own Country : 1N Cochin , 2N Munnar , 2N Thekkady, 1N Alleppey , 1N Mumbai",
                iprice: "₹ 25000",
                idays: "8N|9D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrive Cochin",
                        dayList: [
                            {
                                dayListDes: "Arrive Cochin and meet the Travelpro Holiday representative at the arrival hall and transfer to the Hotel. PM Proceed for the sightseeing tour of Cochin. This includes Fort Cochin, The Dutch Palace at Mattancherry, which is renowned for its wall murals depicting scenes from the Ramayana and The Jewish Synagogue at the heart of a town locally known as the Jew Town. Cochin also boasts of The St. Francis Church, the oldest European church in India and The Santa Cruz Basilica, which was originally built in 1557 by the Portuguese. Another tourist attraction is the Chinese fishing nets which are huge cantilevered fishing nets believed to have been brought by Chinese traders to Cochin. Overnight at Hotel in Cochin.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Cochin – Munnar (130 kms / 4-5 hrs)",
                        dayList: [
                            {
                                dayListDes: "After breakfast check out and transfer to Munnar, one of the most popular hill stations in Southern India known for its sprawling tea gardens, rest of the day is at leisure, overnight at hotel",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Munnar",
                        dayList: [
                            {
                                dayListDes: "This morning you will proceed on a sightseeing tour of the picturesque Mattupetty Dam, where you will have an opportunity to go boating (optional) and then continue your tour to Echo point. Rest of the day at leisure. Overnight at the hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Munnar – Thekkady (120 kms/3-4 hrs)",
                        dayList: [
                            {
                                dayListDes: "Arrive Thekkady and check–in at Hotel. Later, proceed for the sightseeing tour of Thekkady. Enjoy boat cruise on Lake Periyar in the National Park, which is known for herds of wild elephant, bison, deer and spectacular bird life. Elephant ride (subject to availability) Overnight at Hotel in Thekkady.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Thekkady",
                        dayList: [
                            {
                                dayListDes: "Early morning proceed to visit the spice plantation. Evening enjoy the Kerala Cultural Show at the Theatre. Over night stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Thekkady – Alleppey (140 Kms / 4 Hrs)",
                        dayList: [
                            {
                                dayListDes: "Early breakfast, check out and drive to Alleppey to board the House boat, this day ejnoy the real Kerala Village life, and cruise through lakes and agoon. Over night stay on House Boat.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "Alleppey – Cochin by surface & Cochin - Mumbai by flight",
                        dayList: [
                            {
                                dayListDes: "Departure to Mumbai by Air from Cochin, check in to hotel, rest of the day is at leisure. Overnight stay at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "Mumbai",
                        dayList: [
                            {
                                dayListDes: "Early Breakfast, take tour of boat journey to Elephanta Caves. Designated by UNESCO as a World Heritage Site, the Elephanta Caves are a collection of cave temples predominantly dedicated to the Hindu god Shiva. They are on Elephanta Island, or Gharapuri, in Mumbai Harbour, 10 kilometres east of Mumbai in the Indian state of Mahārāshtra. Evening return to hotel overnight stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "Mumbai Departure",
                        dayList: [
                            {
                                dayListDes: "Tour Ends",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: Chennai2,
            ptitle: "Tour Name : Beaches, Wildlife & Temples of South India : 2N Cochin , 2N Quilon , 1N Periyar, 1N Madurai , 1N Tanjore, 1N Trichy, 1N Chennai, 2N Mumbai",
            pdays: "11N|12D",
            pfrom: "Cochin",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: Chennai2
                    },
                    {
                        iimg: mumbai1
                    }
                ],
                ititle: "Tour Name : Beaches, Wildlife & Temples of South India : 2N Cochin , 2N Quilon , 1N Periyar, 1N Madurai , 1N Tanjore, 1N Trichy, 1N Chennai, 2N Mumbai",
                iprice: "₹ 25000",
                idays: "11N|12D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrive Cochin",
                        dayList: [
                            {
                                dayListDes: "Arrive Cochin and meet the Travelpro Holiday, representative at the arrival hall and transfer to the Hotel. Overnight at Hotel in Cochin.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: " In Cochin",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of Cochin. This includes Fort Cochin, The Dutch Palace at Mattancherry, which is renowned for its wall murals depicting scenes from the Ramayana and The Jewish Synagogue at the heart of a town locally known as the Jew Town. Cochin also boasts of The St. Francis Church, the oldest European church in India and The Santa Cruz Basilica, which was originally built in 1557 by the Portuguese. Another tourist attraction is the Chinese fishing nets which are huge cantilevered fishing nets believed to have been brought by Chinese traders to Cochin. Overnight at Hotel in Cochin.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "To Quilon - 147 Kms - 4 Hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Quilon. Arrive Quilon and check–in at hotel. Later, proceed for the sightseeing tour of Quilon. Quilon is one of the oldest ports of the Malabar Coast and the hub of international spice trade. It is famous for its delicious Cashew and Seafood. Visit some historic remains and ornate-styled temples. You can also stopover at the village to learn the lifestyle and culture of locals. Overnight at Hotel in Quilon.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "To Periyar - 198 Kms - 5 Hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Periyar. Arrive Periyar and check–in at Hotel. Later, proceed for the sightseeing tour of Periyar. Enjoy boat cruise on Lake Periyar in the National Park, which is known for herds of wild elephant, bison, deer and spectacular bird life. Evening proceed to visit the spice market. Overnight at Hotel in Periyar.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "To Madurai - 134 Kms - 4:30 Hrs Drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Madurai. Arrive Madurai and check-in at Hotel. Later, proceed for the sightseeing tour of Madurai. Visit the great Meenakshi Temple, dedicated to the Consort of Lord Shiva, with its ten towering gopurams (temple towers) rising above the surrounding courtyards and countryside of lush green fields of rice and coconut palms. See the 'Hall of a Thousand Pillars', built in 1560, which is a great work of structural engineering and art. Each pillar is a picture book in stone, depicting the whole range of human expression. Later, visit the Tirumalai Nayak Palace, a gracious building in the Indo-Saracenic style that is famous for the stuccowork on its domes and arches. Evening visit temple for the second time to attend temple ceremony. Overnight at Hotel in Madurai.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "To Tanjore - 201 Kms - 4:30 Hrs Drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Tanjore. Arrive Tanjore and check-in at Hotel. Later, proceed for the sightseeing tour of Tanjore. especially those who love temple architecture and wonder about kings who these magnificent monuments. During their reign, the Cholas made significant contribution to the various fields of art and architecture. The famous Thanjavur style of painting was developed by Serfoji, the 19th-century Maharaja of Thanjavur. Krishna is the deity depicted here with his complexion shown as white rather than the traditional blue. The Brihadeeswara Temple, the beautiful Palace, the Art Gallery and the Saraswati Mahal Library are other things to see in Tanjore. Overnight at Hotel in Tanjore.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "To Tiruchirappally (Trichy) - 54 Kms - 2 Hrs Drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Trichy. Arrive Trichy and check-in at Hotel. Later, proceed for the sightseeing tour of Trichy. Trichy has various sites within its proximity that you can visit. Most of the places here hold great historical importance. The Rock Fort Temple, an enchanting temple unique in its own way, makes for a must-visit site in Trichy. Moreover, the Tiruvanaikkaval temple, which is famous for its Siva Lingam and the seven Gopurams, is another pilgrimage site you can visit in Trichy. Later you will be driven to Pondicherry. Overnight at Hotel in Trichy.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "To Chennai - 316 Kms - 8 Hrs Drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Chennai. Arrive Chennai and check-in at Hotel. Evening at Leisure. Overnight at Hotel in Chennai.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "Chennai - Mumbai",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of Chennai after breakfast. Visit the National Art Gallery, a fine old Gothic building, housing exquisite Chola bronzes. The most famous of them is of Lord Shiva in the cosmic dance pose, which has become a symbol of Indian art. Proceed to Fort St. George that boasts of the oldest Anglican Church in India - St. Mary’s Church. One of the benefactors of this Church was Elihu Yale, the English merchant after whom the University of Boston is named. The Fort Museum contains a fine collection of relics of that era. Drive along the Marina Beach, past the Madras University, the Senate House and the Palace of the Nawabs of the Carnatic, to the San Thome Cathedral where the apostle is buried. Visit the ancient Kapaleeshwar Temple, dedicated to Shiva, with a beautifully sculptured “Gopuram” (temple-tower) typical of South Indian temple architecture. Overnight stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 10",
                        dayTitle: "Mumbai",
                        dayList: [
                            {
                                dayListDes: "Departure to Mumbai by Air from Cochin, check in to hotel, rest of the day is at leisure. Overnight stay at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 11",
                        dayTitle: "Mumbai",
                        dayList: [
                            {
                                dayListDes: "Early Breakfast, take tour of boat journey to Elephanta Caves. Designated by UNESCO as a World Heritage Site, the Elephanta Caves are a collection of cave temples predominantly dedicated to the Hindu god Shiva. They are on Elephanta Island, or Gharapuri, in Mumbai Harbour, 10 kilometres east of Mumbai in the Indian state of Mahārāshtra. Evening return to hotel overnight stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 12",
                        dayTitle: "Mumbai Departure",
                        dayList: [
                            {
                                dayListDes: "End of our services.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: cochin1,
            ptitle: "2N Cochin , 1N Quilon , 1N Periyar, 1N Madurai , 1N Tanjore, 1N Trichy, 1N Chennai",
            pdays: "8N|9D",
            pfrom: "Cochin",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: cochin1
                    },
                    {
                        iimg: madurai1
                    }
                ],
                ititle: "2N Cochin , 1N Quilon , 1N Periyar, 1N Madurai , 1N Tanjore, 1N Trichy, 1N Chennai",
                iprice: "₹ 25000",
                idays: "8N|9D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrive Cochin",
                        dayList: [
                            {
                                dayListDes: "Arrive Cochin and meet the Travelpro Holiday representative at the arrival hall and transfer to the Hotel. Overnight at Hotel in Cochin.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: " In Cochin",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of Cochin. This includes Fort Cochin, The Dutch Palace at Mattancherry, which is renowned for its wall murals depicting scenes from the Ramayana and The Jewish Synagogue at the heart of a town locally known as the Jew Town. Cochin also boasts of The St. Francis Church, the oldest European church in India and The Santa Cruz Basilica, which was originally built in 1557 by the Portuguese. Another tourist attraction is the Chinese fishing nets which are huge cantilevered fishing nets believed to have been brought by Chinese traders to Cochin. Overnight at Hotel in Cochin.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "To Quilon - 147 Kms - 4 Hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Quilon. Arrive Quilon and check–in at hotel. Later, proceed for the sightseeing tour of Quilon. Quilon is one of the oldest ports of the Malabar Coast and the hub of international spice trade. It is famous for its delicious Cashew and Seafood. Visit some historic remains and ornate-styled temples. You can also stopover at the village to learn the lifestyle and culture of locals. Overnight at Hotel in Quilon.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "To Periyar - 198 Kms - 5 Hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Periyar. Arrive Periyar and check–in at Hotel. Later, proceed for the sightseeing tour of Periyar. Enjoy boat cruise on Lake Periyar in the National Park, which is known for herds of wild elephant, bison, deer and spectacular bird life. Evening proceed to visit the spice market. Overnight at Hotel in Periyar.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "To Madurai - 134 Kms - 4:30 Hrs Drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Madurai. Arrive Madurai and check-in at Hotel. Later, proceed for the sightseeing tour of Madurai. Visit the great Meenakshi Temple, dedicated to the Consort of Lord Shiva, with its ten towering gopurams (temple towers) rising above the surrounding courtyards and countryside of lush green fields of rice and coconut palms. See the 'Hall of a Thousand Pillars', built in 1560, which is a great work of structural engineering and art. Each pillar is a picture book in stone, depicting the whole range of human expression. Later, visit the Tirumalai Nayak Palace, a gracious building in the Indo-Saracenic style that is famous for the stuccowork on its domes and arches. Evening visit temple for the second time to attend temple ceremony. Overnight at Hotel in Madurai.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "To Tanjore - 201 Kms - 4:30 Hrs Drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Tanjore. Arrive Tanjore and check-in at Hotel. Later, proceed for the sightseeing tour of Tanjore. especially those who love temple architecture and wonder about kings who these magnificent monuments. During their reign, the Cholas made significant contribution to the various fields of art and architecture. The famous Thanjavur style of painting was developed by Serfoji, the 19th-century Maharaja of Thanjavur. Krishna is the deity depicted here with his complexion shown as white rather than the traditional blue. The Brihadeeswara Temple, the beautiful Palace, the Art Gallery and the Saraswati Mahal Library are other things to see in Tanjore. Overnight at Hotel in Tanjore.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "To Tiruchirappally (Trichy) - 54 Kms - 2 Hrs Drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Trichy. Arrive Trichy and check-in at Hotel. Later, proceed for the sightseeing tour of Trichy. Trichy has various sites within its proximity that you can visit. Most of the places here hold great historical importance. The Rock Fort Temple, an enchanting temple unique in its own way, makes for a must-visit site in Trichy. Moreover, the Tiruvanaikkaval temple, which is famous for its Siva Lingam and the seven Gopurams, is another pilgrimage site you can visit in Trichy. Later you will be driven to Pondicherry. Overnight at Hotel in Trichy.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "To Chennai - 316 Kms - 8 Hrs Drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Chennai. Arrive Chennai and check-in at Hotel. Evening at Leisure. Overnight at Hotel in Chennai.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "Leave Chennai (By International Flight)",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of Chennai after breakfast. Visit the National Art Gallery, a fine old Gothic building, housing exquisite Chola bronzes. The most famous of them is of Lord Shiva in the cosmic dance pose, which has become a symbol of Indian art. Proceed to Fort St. George that boasts of the oldest Anglican Church in India - St. Mary’s Church. One of the benefactors of this Church was Elihu Yale, the English merchant after whom the University of Boston is named. The Fort Museum contains a fine collection of relics of that era. Drive along the Marina Beach, past the Madras University, the Senate House and the Palace of the Nawabs of the Carnatic, to the San Thome Cathedral where the apostle is buried. Visit the ancient Kapaleeshwar Temple, dedicated to Shiva, with a beautifully sculptured “Gopuram” (temple-tower) typical of South Indian temple architecture.",
                            },
                            {
                                dayListDes: "In-time transfer to airport to board flight for onward destination.",
                            },
                            {
                                dayListDes: "End of our services.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: jaipur5,
            ptitle: "Golden Triangle : 2N Delhi, 1N Agra, 2N Jaipur",
            pdays: "5N|6D",
            pfrom: "Delhi",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: jaipur5
                    },
                    {
                        iimg: agra2
                    }
                ],
                ititle: "Golden Triangle : 2N Delhi, 1N Agra, 2Ns Jaipur",
                iprice: "₹ 25000",
                idays: "5N|6D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrive Delhi",
                        dayList: [
                            {
                                dayListDes: "Arrive Delhi and meet the Travelpro Holiday representative at the arrival hall and transfer to Hotel. Overnight at Hotel in Delhi.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Delhi",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of New & Old Delhi. Visit the 12th Century Qutab Minar; gracefully fluted and hand carved for its entire height of 234 feet. Near it stands the Iron Pillar which has withstood the ravages of time and hasn’t rusted in 1500 years. Also, visit the graceful mausoleum of the Mughal Emperor Humayun and the modern Birla Temple. Drive past the President's House, the Parliament House and the Secretariat buildings. The Red Fort, which was built in 1648 by Mughal Emperor Shah Jahan and the Jama Masjid,which is one of the largest mosques in India are other places of interest. The Raj Ghat and Shanti Vana are the cremation sites of Mahatma Gandhi and Jawaharlal Nehru respectively and make for a peaceful retreat. Overnight at Hotel in Delhi.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Delhi - Agra (approx 220 Kms / 4 -5 Hour drive)",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Agra. Arrive Agra and check-in at Hotel. Later, proceed for sightseeing tour of Agra. Visit Taj Mahal, which is the epitome of Shah Jahan’s monumental testimony of love for his empress, Mumtaz Mahal. This beautiful mausoleum has become synonymous not only with this city but with India as a whole. The construction of Taj Mahal is believed to have taken 18 years (1631-1648) with over 20,000 craftsmen working around the clock. Also visit the Agra Fort, which is on the banks of the river Yamuna and is almost in the centre of the town. This Fort represents the architecture of the period during the reign of three great Mughal emperors; Akbar, Jahangir and Shah Jahan. The conspicuous structures within this complex are Jahangiri Mahal, Anguri Bagh, Khas Mahal and Moti Masjid. Overnight at Hotel in Agra.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Agra - Jaipur (approx 235 Kms/ 4 - 5 Hour drive)",
                        dayList: [
                            {
                                dayListDes: "Early morning leave for Jaipur. En-route visit Fatehpur Sikri. Fatehpur Sikri is an impressive fortified city, which was built by Emperor Akbar. In spite of being a capital city, it was hardly ever inhabited because of shortage of water. Even today it stands magnificent, with majestic palaces, the five-tiered Panch Mahal and the tomb of Salim Chisthi, a saint who had predicted the birth of Akbar's son. Arrive Jaipur and check-in at Hotel. Overnight at Hotel in Jaipur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Jaipur",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of Amber Fort and Jaipur city. Amber Fort was the ancient capital of the State until 1728. Visit the Temple of Kali, the Hall of Victory or Jai Mahal and Jagmandir. You can also experience a unique ride on an elephant’s back to and from the top of the hill on which the Fort is situated. The city of Jaipur takes its name from Maharajah Jai Singh, a prince and an astronomer, who designed and founded the city in 1727. Visit the quaint observatory built by him, which is equipped with instruments that he designed. These devices are of remarkable sizes, with such accuracy that is difficult to attain even in today’s age. Also visit the Maharaja's City Palace, a former royal residence and now a museum containing rare manuscripts, fine specimens of Rajput and Mughal paintings and weapons. Drive through the pink-rosed residential and business areas, past the Hawa Mahal or Palace of Winds, which is the landmark of Jaipur. Overnight at Hotel in Jaipur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Jaipur - Delhi (approx 260 Kms / 05 Hour drive)",
                        dayList: [
                            {
                                dayListDes: "In-time transfer to the International airport to board the flight to onward destination.",
                            },
                            {
                                dayListDes: "End of our Services",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: agra1,
            ptitle: "2N Delhi, 1N Agra, 2N Ranthambhore, 2N Jaipur, 1N Neemrana",
            pdays: "8N|9D",
            pfrom: "Delhi",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: agra1
                    },
                    {
                        iimg: jaipur1
                    }
                ],
                ititle: "2N Delhi, 1N Agra, 2N Ranthambhore, 2N Jaipur, 1N Neemrana",
                iprice: "₹ 25000",
                idays: "8N|9D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrive Delhi",
                        dayList: [
                            {
                                dayListDes: "Arrive Delhi and meet the Travelpro Holiday representative at the arrival hall of the International airport and transfer to hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Delhi",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour New & Old Delhi, which takes you to the 12th Century Qutab Minar, gracefully fluted and hand carved for its entire height of 234 feet; nearby stands the Iron Pillar which has withstood the ravages of time and not rusted in 840 years. Visit the graceful mausoleum of the Mughal Emperor Humayun and modern Birla Temple . Drive past President's House, Parliament House and Secratariat buildings. Drive Past Red Fort built in 1648 by Mughal Emperor Shahjehan, Jama Masjid - one of the largest mosques in India , Raj Ghat and Shanti Vana - the cremation sites of Mahatma Gandhi and Jawaharlal Nehru. Also visit Chandni Chowk, shopping center of Old Delhi. Overnight will be at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "To Agra - 204kms - 4 hrs",
                        dayList: [
                            {
                                dayListDes: "Morning proceed to Agra . Arrive Agra and check in at hotel . Afternoon proceed for sightseeing tour of Agra visiting Taj Mahal, which is essentially one man's monumental testimony to love, has become synonymous not only with this city but with India as a whole. Visit this mausoleum built by Shah Jahan for his empress, Mumtaz Mahal. The construction is believed to have taken 18 years (1631-1648) with over 20,000 craftsmen working around the clock.  The fort represents the architecture in the periods of the three great Mughal emperors, Akbar, Jehangir and Shah Jahan.  The conspicuous structures within the complex are Jehangiri Mahal, Anguri Bagh, Khas Mahal and Moti Masjid. Overnight will be at hotel",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "To Ranthambore - 280kms - 7-8hrs",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Ranthambore enroute visiting Fatehpur Sikri. Fatehpur Sikri, this impressive fortified city was built by Emperor Akbar, a new capital that was hardly ever inhabited on account of shortage of water. the five -tiered Panch Mahal, and the tomb of Salim Chisthi, a saint who had predicted the birth of Akbar's son. After sightseeing continue drive to Ranthambore . Arrive Ranthambore and check in at hotel .",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "In Ranthambore",
                        dayList: [
                            {
                                dayListDes: "Morning jungle visit to park in deluxe canter. Afternoon jungle visit to park in deluxe canter.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "To Jaipur - 170kms - 4hrs",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Jaipur . Arrive Jaipur and check in at hotel . Rest of the day free to explore the city on your own . Overnight at hotel",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "In Jaipur",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for sightseeing tour of Amber Fort and Jaipur city. Amber Fort -- the ancient capital of the State until 1728.  Visit the Temple of Kali , the Hall of Victory or Jai Mahal and Jagmandir.  You can have the unique experience of traveling on elephant back to the top of the hill on which the fort is situated. City tour of Jaipur, which takes its name from Maharajah Jai Singh, prince and astronomer, who designed and founded the city in 1727.  Visit the quaint observatory built by him equipped with instruments of his own design and of remarkable size, which for sheer accuracy are difficult to beat even today.  Also visit the Maharaja's City Palace , the former royal residence and now a museum containing rare manuscripts, fine specimens of Rajput and Mughal paintings and weapons.  Drive through the pink-rose residential and business areas, past the Hawa Mahal or Palace of Winds , the landmark of Jaipur. Overnight will be at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "To Neemrana - 145 Kms - 3 Hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Neemrana . Arrive Neemrana and check in at hotel . Afternoon free to explore the city on your own. Overnight at hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "To Delhi - 265 kms - 5/6 hrs ",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Delhi . On arrival in Delhi transfer to International airport to connect flight for onward destination",
                            },
                            {
                                dayListDes: "End of our services",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: jaipur4,
            ptitle: "2N Delhi, 1N Ajmer, 1N Pushkar, 2N Jaipur, 1N Agra, 1N Delhi",
            pdays: "8N|9D",
            pfrom: "Delhi",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: dehli1
                    },
                    {
                        iimg: jaipur4
                    }
                ],
                ititle: "2N Delhi, 1N Ajmer, 1N Pushkar, 2N Jaipur, 1N Agra, 1N Delhi",
                iprice: "₹ 25000",
                idays: "8N|9D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrive Delhi",
                        dayList: [
                            {
                                dayListDes: "Arrive Delhi and meet the Travelpro Holiday representative at the arrival hall and transfer to Hotel. Overnight at Hotel in Delhi",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Delhi",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of New & Old Delhi. Visit the 12th Century Qutab Minar; gracefully fluted and hand carved for its entire height of 234 feet. Near it stands the Iron Pillar which has withstood the ravages of time and hasn’t rusted in 1500 years. Also, visit the graceful mausoleum of the Mughal Emperor Humayun and the modern Birla Temple. Drive past the President's House, the Parliament House and the Secretariat buildings. The Red Fort, which was built in 1648 by Mughal Emperor Shah Jahan and the Jama Masjid, which is one of the largest mosques in India are other places of interest. The Raj Ghat and Shanti Vana are the cremation sites of Mahatma Gandhi and Jawaharlal Nehru respectively and make for a peaceful retreat. Besides these, visit Chandni Chowk for a unique shopping experience at Old Delhi. Overnight at Hotel in Delhi.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "To Ajmer by Train",
                        dayList: [
                            {
                                dayListDes: "Early morning transfer to Railway Station to board Express Train to Ajmer. Meet the STELLAR HOLIDAYS representative at the Railway Station and transfer to Hotel. Overnight at Hotel in Ajmer.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "To Pushkar - 11 Kms - 20 minutes drive",
                        dayList: [
                            {
                                dayListDes: "Leisurely drive to Pushkar. Arrive Pushkar and check-in at Hotel. The tiny, tranquil town of Pushkar, 11 km from Ajmer, is situated on the edge of a desert along the banks of the picturesque Pushkar Lake and is an important pilgrimage spot for the Hindus. Surrounded by hills on three sides, Pushkar abounds in temples. Of these the most famous is the Brahma Temple, which is the only temple in the country dedicated to Lord Brahma. Although a serene town, Pushkar bustles with life during joyous celebrations every year. Later, visit the Pushkar Lake, which the Hindus believe is as old as creation and which has been a place of pilgrimage through the centuries. There are 52 bathing Ghats built around this lake. Overnight at Hotel in Pushkar.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "To Jaipur - 150 Kms - 3 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Jaipur. Arrive Jaipur and check-in at Hotel. Overnight at Hotel in Jaipur. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "In Jaipur",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of Amber Fort and Jaipur city. Amber Fort was the ancient capital of the State until 1728. Visit the Temple of Kali, the Hall of Victory or Jai Mahal and Jagmandir. You can also experience a unique ride on an elephant’s back to and from the top of the hill on which the Fort is situated. The city of Jaipur takes its name from Maharajah Jai Singh, a prince and an astronomer, who designed and founded the city in 1727. Visit the quaint observatory built by him, which is equipped with instruments that he designed. These devices are of remarkable sizes, with such accuracy that is difficult to attain even in today’s age. Also visit the Maharaja's City Palace, a former royal residence and now a museum containing rare manuscripts, fine specimens of Rajput and Mughal paintings and weapons. Drive through the pink-rosed residential and business areas, past the Hawa Mahal or Palace of Winds, which is the landmark of Jaipur. Overnight at Hotel in Jaipur. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "To Agra – 238 Kms – 5 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Agra. En-route visit Fatehpur Sikri. Fatehpur Sikri is an impressive fortified city, which was built by Emperor Akbar. In spite of being a capital city, it was hardly ever inhabited because of shortage of water. Even today it stands magnificent, with majestic palaces, the five-tiered Panch Mahal and the tomb of Salim Chisthi, a saint who had predicted the birth of Akbar's son. Arrive Agra and check-in at Hotel. Overnight at Hotel in Agra.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "To Delhi - 204 Kms - 4 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for sightseeing tour of Agra. Visit Taj Mahal, which is the epitome of Shah Jahan’s monumental testimony of love for his empress, Mumtaz Mahal. This beautiful mausoleum has become synonymous not only with this city but with India as a whole. The construction of Taj Mahal is believed to have taken 18 years (1631-1648) with over 20,000 craftsmen working around the clock. Also visit the Agra Fort, which is on the banks of the river Yamuna and is almost in the centre of the town. This Fort represents the architecture of the period during the reign of three great Mughal emperors; Akbar, Jahangir and Shah Jahan. The conspicuous structures within this complex are Jahangiri Mahal, Anguri Bagh, Khas Mahal and Moti Masjid. Later drive to Gwalior. Arrive Gwalior and check-in at Hotel. Later drive to Delhi. Arrive Delhi and check-in at Hotel. Overnight at Hotel in Delhi.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "Leave Delhi",
                        dayList: [
                            {
                                dayListDes: "In-time transfer to airport to board your flight for onward destination.",
                            },
                            {
                                dayListDes: "End of our services",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: udaipur1,
            ptitle: "2N Delhi, 2N Jaipur, 1N Bikaner, 2N Jaisalmer, 1N Jodhpur, 2N Udaipur",
            pdays: "10N|11D",
            pfrom: "Delhi",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: jodhpur1
                    },
                    {
                        iimg: udaipur1
                    }
                ],
                ititle: "2N Delhi, 2N Jaipur, 1N Bikaner, 2N Jaisalmer, 1N Jodhpur, 2N Udaipur",
                iprice: "₹ 25000",
                idays: "10N|11D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrive Delhi",
                        dayList: [
                            {
                                dayListDes: "Arrive Delhi and meet the Travelpro Holiday representative at the arrival hall and transfer to Hotel. Overnight at Hotel in Delhi.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "In Delhi",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of New & Old Delhi. Visit the 12th Century Qutab Minar; gracefully fluted and hand carved for its entire height of 234 feet. Near it stands the Iron Pillar which has withstood the ravages of time and hasn’t rusted in 840 years. Also, visit the graceful mausoleum of the Mughal Emperor Humayun and the modern Birla Temple. Drive past the President's House, the Parliament House and the Secretariat buildings. The Red Fort, which was built in 1648 by Mughal Emperor Shah Jahan and the Jama Masjid, which is one of the largest mosques in India are other places of interest. The Raj Ghat and Shanti Vana are the cremation sites of Mahatma Gandhi and Jawaharlal Nehru respectively and make for a peaceful retreat. Besides these, visit Chandni Chowk for a unique shopping experience at Old Delhi. ",
                            },                            
                            {
                                dayListDes: "Overnight at Hotel in Delhi. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Delhi - Jaipur (approx 275 Kms/ 4 - 5 Hour drive)",
                        dayList: [
                            {
                                dayListDes: "Early morning leave for Jaipur. Arrive Jaipur and check-in at Hotel. Rest of the Day is at leisure or own activity.",
                            },
                            {
                                dayListDes: "Overnight at Hotel in Jaipur. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Jaipur",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of Amber Fort and Jaipur city. Amber Fort was the ancient capital of the State until 1728. Visit the Temple of Kali, the Hall of Victory or Jai Mahal and Jagmandir. You can also experience a unique ride on an elephant’s back to and from the top of the hill on which the Fort is situated. The city of Jaipur takes its name from Maharajah Jai Singh, a prince and an astronomer, who designed and founded the city in 1727. Visit the quaint observatory built by him, which is equipped with instruments that he designed. These devices are of remarkable sizes, with such accuracy that is difficult to attain even in today’s age. Also visit the Maharaja's City Palace, a former royal residence and now a museum containing rare manuscripts, fine specimens of Rajput and Mughal paintings and weapons. Drive through the pink-rosed residential and business areas, past the Hawa Mahal or Palace of Winds, which is the landmark of Jaipur. Overnight at Hotel in Jaipur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Jaipur - Bikaner (approx 330 Kms / 5 - 6 Hours/ drive)",
                        dayList: [
                            {
                                dayListDes: "Early morning drive to Bikaner. Arrive Bikaner and check-in at Hotel. Afternoon proceed for the sightseeing tour of Bikaner. Bikaner, the royal fortified city has a timeless appeal. Lying in the north of the desert state, the city is dotted with many sand dunes. Bikaner retains the medieval splendour that pervades the city's lifestyle. More popularly called the 'Camel Country', Bikaner is renowned for the best riding camels in the world. An architectural masterpiece in red sandstone, the Lalgarh Palace was built by Maharaja Ganga Singh in the memory of his father Maharaja Lal Singh.",
                            },
                            {
                                dayListDes: "The palace has beautiful latticework and filigree work. Junagarh Fort was built in 1593 A.D by Raja Rai Singh, a General in the army of Emperor Akbar. This fort is a formidable structure encircled by a moat and has some beautiful palaces within. These palaces, made in red sandstone and marble, make a picturesque ensemble of courtyards, balconies, kiosks and windows dotted all over the structure. The city of Bikaner is bolstered by imposing walls and has a 16th century fort housing old palaces, temples and a mosque founded by Raja Rai Singh. The fort is distinguished by its long range of 37 pavilions, a line of balconies and aerial windows of various designs. An enormous doorway leads to the Joramal Temple. The royal Chapel is Har Mandir where royal weddings and births were once celebrated. ",
                            },
                            {
                                dayListDes: "Overnight at Hotel in Bikaner.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Bikaner to Jaisalmer (approx 330 Kms / 5 to 6 Hours drive)",
                        dayList: [
                            {
                                dayListDes: "Early morning drive to Jaisalmer. Arrive Jaisalmer and check-in at Hotel. Evening proceed for an excursion to sand dunes to view the sunset and enjoy camel rides. Throbbing in the heart of the Thar Desert, the Sand Dunes is an intricately moulded wonderland of burnished sandstone. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "Jaisalmer",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of Jaisalmer. Visit Sonar Fort, housing a fourth of the city’s residents. Also visit Patwon Ki Haveli, which is one of the most exquisite havelis with a beautifully latticed façade. Salim Singh Ki Haveli is just three centuries old and is still lived in. Its arched roof is held up by well-executed brackets in the form of peacocks. Then there is Nathmalji Ki Haveli that was built by two architect brothers. Afternoon free for independent activities. ",
                            },
                            {
                                dayListDes: "Overnight at Hotel in Jaisalmer. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "Jaisalmer to Jodhpur (approx 290 Kms / 7 Hours drive)",
                        dayList: [
                            {
                                dayListDes: "Early morning proceed to Jodhpur. Arrive Jodhpur and check-in at Hotel. Afternoon proceed for the sightseeing tour of Jodhpur. Jodhpur is set at the edge of the Thar Desert. This imperial city echoes with tales of antiquity in the emptiness of the desert. Jodhpur was founded in 1459 A.D. by Rao Jodha and was once the capital of the Marwar State. Rao Jodha was the chief of the Rathore clan of Rajputs, who claimed to be the descendants of Rama - the epic hero of the Ramayana. The 5 km long majestic Mehrangarh Fort on a 125 m high hill is one of the most impressive and formidable structures in Jodhpur. Although invincible from the outside, the Fort has four gates approachable by a winding road. Within the Fort are some magnificent palaces with marvellously carved panels, latticed windows and evocative names. Noteworthy of these are the Moti Mahal, the Phool Mahal, the Sheesh Mahal, the Sileh Khana and the Daulat Khana. Another monument to be visited is the Jaswant Thada, a cluster of royal cenotaphs in white marble, built in 1899 A.D. in memory of Maharaja Jaswant Singh II. ",
                            },
                            {
                                dayListDes: "Overnight at Hotel in Jodhpur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "Jodhpur To Udaipur (approx 85 Kms / 2 Hours drive)",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Udaipur. Arrive Udaipur and check-in at Hotel. Afternoon excursion to Eklingji and the Nagda temples. Eklingji is home to the private temple of the head of the Sisodia clan.",
                            },
                            {
                                dayListDes: "Overnight at Hotel in Udaipur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 10",
                        dayTitle: "Udaipur",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of Udaipur. Drive around the Fatehsagar Lake, built by Maharana Fateh Singh in 1754 A.D. Visit the Jagdish Temple, the largest and the most venerated temple of Udaipur. Built in 1651 A.D. by Maharana Jagat Singh I, the Jagdish Temple is beautifully decorated with friezes. Visit the Garden of Maidens – ‘Sahelion-Ki-Bari’, consisting of an ornamental pool with delicately carved ‘chhatries’ of black and white marble and soft stone. Also visit the Maharana's City Palace, an imposing edifice of granite and marble. Afternoon free. ",
                            },
                            {
                                dayListDes: "Overnight at Hotel in Udaipur. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 11",
                        dayTitle: "Udaipur Airport",
                        dayList: [
                            {
                                dayListDes: "Morning in-time transfer to airport to connect flight to connect flight for onward destination via Mumbai / Delhi.",
                            },
                            {
                                dayListDes: "End of our services",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: udaipur2,
            ptitle: "2N Delhi,1N Mandawa,1N Bikaner,2N Jaisalmer,1N Jodhpur,1N Luni,1N Ranakpur,1N Aodhi,2N Udaipur,1N Devigarh",
            pdays: "13N|14D",
            pfrom: "Delhi",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: jodhpur2
                    },
                    {
                        iimg: udaipur2
                    }
                ],
                ititle: "2N Delhi,1N Mandawa,1N Bikaner,2N Jaisalmer,1N Jodhpur,1N Luni,1N Ranakpur,1N Aodhi,2N Udaipur,1N Devigarh",
                iprice: "₹ 25000",
                idays: "13N|14D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrive Delhi",
                        dayList: [
                            {
                                dayListDes: "Arrive Delhi and meet the Travelpro Holiday representative at the arrival hall and transfer to Hotel. Overnight at Hotel in Delhi.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "In Delhi",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of New & Old Delhi. Visit the 12th Century Qutab Minar; gracefully fluted and hand carved for its entire height of 234 feet. Near it stands the Iron Pillar which has withstood the ravages of time and hasn’t rusted in 840 years. Also, visit the graceful mausoleum of the Mughal Emperor Humayun and the modern Birla Temple. Drive past the President's House, the Parliament House and the Secretariat buildings. The Red Fort, which was built in 1648 by Mughal Emperor Shah Jahan and the Jama Masjid, which is one of the largest mosques in India are other places of interest. The Raj Ghat and Shanti Vana are the cremation sites of Mahatma Gandhi and Jawaharlal Nehru respectively and make for a peaceful retreat. Besides these, visit Chandni Chowk for a unique shopping experience at Old Delhi.",
                            },                            
                            {
                                dayListDes: "Overnight at Hotel in Delhi. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "To Mandawa – 265 Kms – 5 to 6 hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Mandawa. Arrive Mandawa and check-in at Hotel. Afternoon free for independent activities. Overnight at Hotel in Mandawa.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "To Bikaner – 170 Kms – 4 hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Bikaner. Arrive Bikaner and check-in at Hotel. Afternoon proceed for the sightseeing tour of Bikaner. Bikaner, the royal fortified city has a timeless appeal. Lying in the north of the desert state, the city is dotted with many sand dunes. Bikaner retains the medieval splendour that pervades the city's lifestyle. More popularly called the 'Camel Country', Bikaner is renowned for the best riding camels in the world. An architectural masterpiece in red sandstone, the Lalgarh Palace was built by Maharaja Ganga Singh in the memory of his father Maharaja Lal Singh.",
                            },
                            {
                                dayListDes: "The palace has beautiful latticework and filigree work. Junagarh Fort was built in 1593 A.D by Raja Rai Singh, a General in the army of Emperor Akbar. This fort is a formidable structure encircled by a moat and has some beautiful palaces within. These palaces, made in red sandstone and marble, make a picturesque ensemble of courtyards, balconies, kiosks and windows dotted all over the structure. The city of Bikaner is bolstered by imposing walls and has a 16th century fort housing old palaces, temples and a mosque founded by Raja Rai Singh. The fort is distinguished by its long range of 37 pavilions, a line of balconies and aerial windows of various designs. An enormous doorway leads to the Joramal Temple. The royal Chapel is Har Mandir where royal weddings and births were once celebrated. Overnight at Hotel in Bikaner.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "To Jaisalmer – 330 Kms – 7 to 8 hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Jaisalmer. Arrive Jaisalmer and check-in at Hotel. Evening proceed for an excursion to sand dunes to view the sunset and enjoy camel rides. Throbbing in the heart of the Thar Desert, the Sam Sand Dunes is an intricately moulded wonderland of burnished sandstone. Overnight at Hotel in Jaisalmer.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "In Jaisalmer",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of Jaisalmer. Visit Sonar Fort, housing a fourth of the city’s residents. Also visit Patwon Ki Haveli, which is one of the most exquisite havelis with a beautifully latticed façade. Salim Singh Ki Haveli is just three centuries old and is still lived in. Its arched roof is held up by well-executed brackets in the form of peacocks. Then there is Nathmalji Ki Haveli that was built by two architect brothers. Afternoon free for independent activities. Overnight at Hotel in Jaisalmer.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "To Jodhpur – 290 Kms – 7 hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning proceed to Jodhpur. Arrive Jodhpur and check-in at Hotel. Afternoon proceed for the sightseeing tour of Jodhpur. Jodhpur is set at the edge of the Thar Desert. This imperial city echoes with tales of antiquity in the emptiness of the desert. Jodhpur was founded in 1459 A.D. by Rao Jodha and was once the capital of the Marwar State. Rao Jodha was the chief of the Rathore clan of Rajputs, who claimed to be the descendants of Rama - the epic hero of the Ramayana. The 5 km long majestic Mehrangarh Fort on a 125 m high hill is one of the most impressive and formidable structures in Jodhpur. Although invincible from the outside, the Fort has four gates approachable by a winding road. Within the Fort are some magnificent palaces with marvellously carved panels, latticed windows and evocative names. Noteworthy of these are the Moti Mahal, the Phool Mahal, the Sheesh Mahal, the Sileh Khana and the Daulat Khana. Another monument to be visited is the Jaswant Thada, a cluster of royal cenotaphs in white marble, built in 1899 A.D. in memory of Maharaja Jaswant Singh II. Overnight at Hotel in Jodhpur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "To Luni – 40 Kms – 1hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Luni. Arrive Luni and check-in at Hotel. Afternoon free for independent activities. Overnight at Hotel in Luni. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "To Ranakpur – 200 Kms – 4 to 5 hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Ranakpur. Arrive Ranakpur and check-in at Hotel. Ranakpur, home to the beautifully sculptured Jain temples, lies in the tranquil valley of the Aravalis. The main ‘Chaumukha Temple’ is dedicated to Tirhankara Adinath and has 29 halls supported by 1444 pillars, all distinctly carved. Two Jain temples dedicated to Neminath and Parsvanath and the Sun Temple a little distance away, are also noteworthy. Overnight at Hotel in Ranakpur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 10",
                        dayTitle: "To Aodhi – 195 Kms – 5 to 6 hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Aodhi. Arrive Aodhi and check-in at Hotel. Afternoon free for independent activities. Overnight at Hotel in Aodhi. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 11",
                        dayTitle: "To Udaipur – 85 Kms – 2 hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Udaipur. Arrive Udaipur and check-in at Hotel. Afternoon excursion to Eklingji and the Nagda temples. Eklingji is home to the private temple of the head of the Sisodia clan. Overnight at Hotel in Udaipur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 12",
                        dayTitle: "In Udaipur",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of Udaipur. Drive around the Fatehsagar Lake, built by Maharana Fateh Singh in 1754 A.D. Visit the Jagdish Temple, the largest and the most venerated temple of Udaipur. Built in 1651 A.D. by Maharana Jagat Singh I, the Jagdish Temple is beautifully decorated with friezes. Visit the Garden of Maidens – ‘Sahelion-Ki-Bari’, consisting of an ornamental pool with delicately carved ‘chhatries’ of black and white marble and soft stone. Also visit the Maharana's City Palace, an imposing edifice of granite and marble. Afternoon free. Overnight at Hotel in Udaipur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 13",
                        dayTitle: "To Deogarh – 25 Kms – ½ hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Devigarh. Arrive Devigarh and check-in at Hotel. Afternoon free for independent activities. Overnight at Hotel in Devigarh. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 14",
                        dayTitle: "To Udaipur – 25 Kms – ½ hrs drive ,To Mumbai (By Flight)",
                        dayList: [
                            {
                                dayListDes: "Morning in-time transfer to airport to connect flight for Mumbai. Meet the STELLAR HOLIDAYS representative at arrival hall and transfer to International airport to connect flight for onward destination. ",
                            },
                            {
                                dayListDes: "End of our services.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: jaisalmer1,
            ptitle: "2N Delhi, 1N Neemrana, 1N Samode, 1N Mandawa, 1N Bikaner, 2N Jaisalmer, 1N Jodhpur, 1N Ghanerao, 1N Ranakpur, 1N Aodhi, 2N Udaipur, 1N Deogarh, 2N Jaipur, 2N Agra",
            pdays: "19N|20D",
            pfrom: "Delhi",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: udaipur3
                    },
                    {
                        iimg: jaisalmer1
                    },
                    {
                        iimg: jaipur3
                    }
                ],
                ititle: "2N Delhi, 1N Neemrana, 1N Samode, 1N Mandawa, 1N Bikaner, 2N Jaisalmer, 1N Jodhpur, 1N Ghanerao, 1N Ranakpur, 1N Aodhi, 2N Udaipur, 1N Deogarh, 2N Jaipur, 2N Agra",
                iprice: "₹ 25000",
                idays: "19N|20D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrive Delhi",
                        dayList: [
                            {
                                dayListDes: "Arrive Delhi and meet the Travelpro Holiday representative at the arrival hall and transfer to Hotel. Overnight at Hotel in Delhi.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "In Delhi",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of New & Old Delhi. Visit the 12th Century Qutab Minar; gracefully fluted and hand carved for its entire height of 234 feet. Near it stands the Iron Pillar which has withstood the ravages of time and hasn’t rusted in 840 years. Also, visit the graceful mausoleum of the Mughal Emperor Humayun and the modern Birla Temple. Drive past the President's House, the Parliament House and the Secretariat buildings. The Red Fort, which was built in 1648 by Mughal Emperor Shah Jahan and the Jama Masjid, which is one of the largest mosques in India are other places of interest. The Raj Ghat and Shanti Vana are the cremation sites of Mahatma Gandhi and Jawaharlal Nehru respectively and make for a peaceful retreat. Besides these, visit Chandni Chowk for a unique shopping experience at Old Delhi.",
                            },                            
                            {
                                dayListDes: "Overnight at Hotel in Delhi. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "To Neemrana – 130 Kms – 2 hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Neemrana. Arrive Neemrana and check-in at Hotel. Rest of the day free for independent activities. Overnight at Hotel in Neemrana.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "To Samode",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Samode. Arrive Samode and check-in at Hotel. Rest of the day free for independent activities. Overnight at Hotel in Samode.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "To Mandawa – 155 Kms – 4 hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Mandawa. Arrive Mandawa and check-in at Hotel. Afternoon free for independent activities. Overnight at Hotel in Mandawa.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "To Bikaner – 170 Kms – 4 hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Bikaner. Arrive Bikaner and check-in at Hotel. Afternoon proceed for the sightseeing tour of Bikaner. Bikaner, the royal fortified city has a timeless appeal. Lying in the north of the desert state, the city is dotted with many sand dunes. Bikaner retains the medieval splendour that pervades the city's lifestyle. More popularly called the 'Camel Country', Bikaner is renowned for the best riding camels in the world. An architectural masterpiece in red sandstone, the Lalgarh Palace was built by Maharaja Ganga Singh in the memory of his father Maharaja Lal Singh. The palace has beautiful latticework and filigree work. Junagarh Fort was built in 1593 A.D by Raja Rai Singh, a General in the army of Emperor Akbar. This fort is a formidable structure encircled by a moat and has some beautiful palaces within. These palaces, made in red sandstone and marble, make a picturesque ensemble of courtyards, balconies, kiosks and windows dotted all over the structure. The city of Bikaner is bolstered by imposing walls and has a 16th century fort housing old palaces, temples and a mosque founded by Raja Rai Singh. The fort is distinguished by its long range of 37 pavilions, a line of balconies and aerial windows of various designs. An enormous doorway leads to the Joramal Temple. The royal Chapel is Har Mandir where royal weddings and births were once celebrated. Overnight at Hotel in Bikaner.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "To Jaisalmer – 330 Kms – 7 to 8 hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Jaisalmer. Arrive Jaisalmer and check-in at Hotel. Evening proceed for an excursion to sand dunes to view the sunset and enjoy camel rides. Throbbing in the heart of the Thar Desert, the Sam Sand Dunes are an intricately moulded wonderland of burnished sandstone. Overnight at Hotel in Jaisalmer.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "In Jaisalmer ",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of Jaisalmer. Visit Sonar Fort, housing a fourth of the city’s residents. Also visit Patwon Ki Haveli, which is one of the most exquisite havelis with a beautifully latticed façade. Salim Singh Ki Haveli is just three centuries old and is still lived in. Its arched roof is held up by well-executed brackets in the form of peacocks. Then there is Nathmalji Ki Haveli that was built by two architect brothers. Afternoon free for independent activities. Overnight at Hotel in Jaisalmer.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "To Jodhpur – 290 Kms – 7 hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning proceed to Jodhpur. Arrive Jodhpur and check-in at Hotel. Afternoon proceed for the sightseeing tour of Jodhpur. Jodhpur is set at the edge of the Thar Desert. This imperial city echoes with tales of antiquity in the emptiness of the desert. Jodhpur was founded in 1459 A.D. by Rao Jodha and was once the capital of the Marwar State. Although invincible from the outside, the Fort has four gates approachable by a winding road. Within the Fort are some magnificent palaces with marvellously carved panels, latticed windows and evocative names. Noteworthy of these are the Moti Mahal, the Phool Mahal, the Sheesh Mahal, the Sileh Khana and the Daulat Khana. Another monument to be visited is the Jaswant Thada, a cluster of royal cenotaphs in white marble, built in 1899 A.D. in memory of Maharaja Jaswant Singh II. Overnight at Hotel in Jodhpur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 10",
                        dayTitle: "To Ghanerao – 210 Kms – 5 hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Ghanerao. Arrive Ghanerao and check-in at Hotel. Afternoon free for independent activities. Overnight at Hotel in Ghanerao.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 11",
                        dayTitle: "To Ranakpur",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Ranakpur. Arrive Ranakpur and check-in at Hotel. Ranakpur, home to the beautifully sculptured Jain temples, lies in the tranquil valley of the Aravalis. The main ‘Chaumukha Temple’ is dedicated to Tirhankara Adinath and has 29 halls supported by 1444 pillars, all distinctly carved. Two Jain temples dedicated to Neminath and Parsvanath and the Sun Temple a little distance away, are also noteworthy. Overnight at Hotel in Ranakpur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 12",
                        dayTitle: "To Aodhi – 195 Kms – 5 to 6 hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Aodhi. Arrive Aodhi and check-in at Hotel. Afternoon free for independent activities. Overnight at Hotel in Aodhi.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 13",
                        dayTitle: "To Udaipur – 85 Kms – 2 hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Udaipur. Arrive Udaipur and check-in at Hotel. Afternoon excursion to Eklingji and the Nagda temples. Eklingji is home to the private temple of the head of the Sisodia clan. Overnight at Hotel in Udaipur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 14",
                        dayTitle: "In Udaipur",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of Udaipur. Drive around the Fatehsagar Lake, built by Maharana Fateh Singh in 1754 A.D. Visit the Jagdish Temple, the largest and the most venerated temple of Udaipur. Built in 1651 A.D. by Maharana Jagat Singh I, the Jagdish Temple is beautifully decorated with friezes. Visit the Garden of Maidens – ‘Sahelion-Ki-Bari’, consisting of an ornamental pool with delicately carved ‘chhatries’ of black and white marble and soft stone. Also visit the Maharana's City Palace, an imposing edifice of granite and marble. Afternoon free. Overnight at Hotel in Udaipur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 15",
                        dayTitle: "To Deogarh – 140 Kms – 3 to 4 hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Deogarh. Arrive Deogarh and check-in at Hotel. Afternoon free for independent activities. Overnight at Hotel in Deogarh.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 16",
                        dayTitle: "To Jaipur – 280 Kms – 7 hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Jaipur. Arrive Jaipur and check-in at Hotel. Afternoon free for independent activities. Overnight at Hotel in Jaipur",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 17",
                        dayTitle: "In Jaipur",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of Amber Fort and Jaipur city. Amber Fort was the ancient capital of the State until 1728. Visit the Temple of Kali, the Hall of Victory or Jai Mahal and Jagmandir. You can also experience a unique ride on an elephant’s back to and from the top of the hill on which the Fort is situated. The city of Jaipur takes its name from Maharajah Jai Singh, a prince and an astronomer, who designed and founded the city in 1727. Visit the quaint observatory built by him, which is equipped with instruments that he designed. These devices are of remarkable sizes, with such accuracy that is difficult to attain even in today’s age. Also visit the Maharaja's City Palace, a former royal residence and now a museum containing rare manuscripts, fine specimens of Rajput and Mughal paintings and weapons. Drive through the pink-rosed residential and business areas, past the Hawa Mahal or Palace of Winds, which is the landmark of Jaipur. Overnight at Hotel in Jaipur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 18",
                        dayTitle: "To Agra – 235 Kms – 5 to 6 hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Agra. En-route visit Fatehpur Sikri. Fatehpur Sikri is an impressive fortified city, which was built by Emperor Akbar. In spite of being a capital city, it was hardly ever inhabited because of shortage of water. Even today it stands magnificent, with majestic palaces, the five-tiered Panch Mahal and the tomb of Salim Chisthi, a saint who had predicted the birth of Akbar's son. Arrive Agra and check-in at Hotel. Overnight at Hotel in Agra.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 19",
                        dayTitle: "In Agra",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for sightseeing tour of Agra. Visit Taj Mahal, which is the epitome of Shah Jahan’s monumental testimony of love for his empress, Mumtaz Mahal. This beautiful mausoleum has become synonymous not only with this city but with India as a whole. The construction of Taj Mahal is believed to have taken 18 years (1631-1648) with over 20,000 craftsmen working around the clock. Also visit the Agra Fort, which is on the banks of the river Yamuna and is almost in the centre of the town. This Fort represents the architecture of the period during the reign of three great Mughal emperors; Akbar, Jahangir and Shah Jahan. The conspicuous structures within this complex are Jahangiri Mahal, Anguri Bagh, Khas Mahal and Moti Masjid. Overnight at Hotel in Agra.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 20",
                        dayTitle: "To Delhi – 204 Kms – 4 hrs drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Delhi. On arrival in Delhi transfer to International Airport to connect flight for onward destination.",
                            },
                            {
                                dayListDes: "End of our services.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: goa1,
            ptitle: "3N Goa, 2N Pondichery, 2N Tanjore, 1N Madurai, 2N Kovalam, 1N Alleppey, 1N Cochin",
            pdays: "12N|13D",
            pfrom: "Goa",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: cochin3
                    },
                    {
                        iimg: goa1
                    }
                ],
                ititle: "3N Goa, 2N Pondichery, 2N Tanjore, 1N Madurai, 2N Kovalam, 1N Alleppey, 1N Cochin",
                iprice: "₹ 25000",
                idays: "12N|13D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrive Goa",
                        dayList: [
                            {
                                dayListDes: "Arrive Goa, transfers to Hotel. Check in and Day is at leisure for own activities. Over night stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "In Goa",
                        dayList: [
                            {
                                dayListDes: "Half Day sightseeing of North Goa, covering, Old Goa Churches, Dona Paula Beach, Miramar Beach, Fort Aguada, Callangute Beach, Anjuna Beach & Wagator Beach, evening enjoy the local folk dance on boat cruise in river Mandovi. Back to hotel, over night stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Goa",
                        dayList: [
                            {
                                dayListDes: "Half day Sightseeing of South Goa Covering, Colva Beach, Shantadurga Temple & Mangeshi Temple. PM Back to hotel, day is at leisure. Over night stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Goa – Chennai by flight & Chennai – Pondichery by road (140 Kms / 3 Hrs Drive)",
                        dayList: [
                            {
                                dayListDes: "After breakfast, check out and you will be transferred to Airport to catch flight to Chennai. Arrive Chennai, transfer to Pondichery, over night stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Pondichery",
                        dayList: [
                            {
                                dayListDes: "Today you will be doing sightseeing of Pondichery covering below places:",
                            },
                            {
                                dayListDes: "Auroville : Auroville is located around eight km from Pondicherry and is a place that you should not miss while on a visit to pondy. Auroville is a township of universal essence. It has been conceived to accommodate people across 35 nations to live as a community. It came into existence in 1968. The idea of this utopian state was brought to the Mother several decades back, following which she gave her blessings to this noble task. The Government of India and later UNESCO welcomed the suggestion and encouraged it.",
                            },
                            {
                                dayListDes: "Arikamedu : One of the must visit places in Pondicherry is Arikamedu. Arikamedu is a great tourist spot as it has a lot of historical relevance. A lot of students arrive here on study tours periodically. It is located on the banks of the Ariyankuppam River about 4 km from Pondicherry. This place is remembered as a trade centre of the Romans a number of centuries back.",
                            },
                            {
                                dayListDes: "The French Heritage: Pondicherry is synonymous with French Heritage in India and understandably so as it served as the capital of the French Colonies in India. The town was under French administration for centuries together and hence it is imperative that this place has a strong French feel in its architecture and monuments.",
                            },
                            {
                                dayListDes: "Beaches of Pondicherry: One of the best beaches in South India lies in Pondicherry. These beaches in Pondicherry are extremely clean with clear waters and clean sands. To add to it the beaches are not very crowded. Maximum care has been given to highlight the beauty of these beaches with cafes and other eateries along with resorts and cottages for the visitors. These buildings are built in a way that it does not affect the calmness and the natural beauty of the beach.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Pondichery – Tanjavore (180 Kms / 4 Hrs)",
                        dayList: [
                            {
                                dayListDes: "Check out and proceed to Tanjavore (Tanjore), PM Arrive, rest of the day is at leisure. Over night stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "Tanjavore",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Tanjore. Arrive Tanjore and check-in at Hotel. Later, proceed for the sightseeing tour of Tanjore. especially those who love temple architecture and wonder about kings who these magnificent monuments. During their reign, the Cholas made significant contribution to the various fields of art and architecture. The famous Thanjavur style of painting was developed by Serfoji, the 19th-century Maharaja of Thanjavur. Krishna is the deity depicted here with his complexion shown as white rather than the traditional blue. The Brihadeeswara Temple, the beautiful Palace, the Art Gallery and the Saraswati Mahal Library are other things to see in Tanjore. Overnight at Hotel in Tanjore.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "Tanjavore – Madurai (160 Kms / 3 Hrs)",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Madurai. Arrive Madurai and check-in at Hotel. Later, proceed for the sightseeing tour of Madurai. Visit the great Meenakshi Temple, dedicated to the Consort of Lord Shiva, with its ten towering gopurams (temple towers) rising above the surrounding courtyards and countryside of lush green fields of rice and coconut palms. See the 'Hall of a Thousand Pillars', built in 1560, which is a great work of structural engineering and art. Each pillar is a picture book in stone, depicting the whole range of human expression. Later, visit the Tirumalai Nayak Palace, a gracious building in the Indo-Saracenic style that is famous for the stuccowork on its domes and arches. Evening visit temple for the second time to attend temple ceremony. Overnight at Hotel in Madurai.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "Madurai – Kovalam (280 Kms / 6 Hrs)",
                        dayList: [
                            {
                                dayListDes: "Drive to Kovalam, Evening check in to Hotel, rest of the day is at leisure. Over night stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 10",
                        dayTitle: "Kovalam",
                        dayList: [
                            {
                                dayListDes: "Breakfast at Hotel. Later proceed for Padmanabh Swami Temple, Raja Ravi Varma Art Gallery and Napier Museum. Free for Leisure, Overnight stay at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 11",
                        dayTitle: "Kovalam – Alleppey House boat (160 Kms / 3 Hrs)",
                        dayList: [
                            {
                                dayListDes: "Early breakfast, check out and drive to Alleppey to board the House boat, this day ejnoy the real Kerala Village life, and cruise through lakes and agoon. Over night stay on House Boat.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 12",
                        dayTitle: "Cochin (60 Kms / 2 Hrs)",
                        dayList: [
                            {
                                dayListDes: "Check out and proceed to Cochin, enroute visit Bird Sanctury in Kumarakom, check in to Hotel. PM proceed for the sightseeing tour of Cochin. This includes Fort Cochin, The Dutch Palace at Mattancherry, which is renowned for its wall murals depicting scenes from the Ramayana and The Jewish Synagogue at the heart of a town locally known as the Jew Town. Cochin also boasts of The St. Francis Church, the oldest European church in India and The Santa Cruz Basilica, which was originally built in 1557 by the Portuguese. Another tourist attraction is the Chinese fishing nets which are huge cantilevered fishing nets believed to have been brought by Chinese traders to Cochin. Overnight at Hotel in Cochin.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 13",
                        dayTitle: "Cochin Departure",
                        dayList: [
                            {
                                dayListDes: "You will be transferred to Airport to catch flight back home.",
                            },
                            {
                                dayListDes: "End of our services.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: Bandipur3,
            ptitle: "Heritage, Wildlife & Hills of South India : 2N Bangalore, 2N Mysore, 2N Bandipur, 1N Calicut, 1N Cochin, 2N Kumarakom, 1N Houseboat, 1N Periyar, 2N Munnar",
            pdays: "14N|15D",
            pfrom: "Bangalore",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: mysore1
                    },
                    {
                        iimg: Bandipur3
                    }
                ],
                ititle: "Heritage, Wildlife & Hills of South India : 2N Bangalore, 2N Mysore, 2N Bandipur, 1N Calicut, 1N Cochin, 2N Kumarakom, 1N Houseboat, 1N Periyar, 2N Munnar",
                iprice: "₹ 25000",
                idays: "14N|15D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrive Bangalore",
                        dayList: [
                            {
                                dayListDes: "Arrive at Bangalore International Airport, on arrival you will be met with Travelpro Holiday, representative and transferred to Hotel for overnight stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "In Bangalore",
                        dayList: [
                            {
                                dayListDes: "Morning, proceed for the sightseeing tour of Bangalore covering: Bull Temple, Tippu’s Palace, Botanical Garden, Bangalore Palace & Drive past Vidhana Soudha. Overnight at the hotel in Bangalore.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Bangalore - Mysore - By road - 150 Kms / 03 to 04 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning after breakfast drive to Mysore. Enroute visit Daria Daulat Bagh and the Gumbaz in Srirangapatnam. On arrival, check in at Hotel, evening visit Chamundi Temple & Big Bull Temple. Overnight stay at hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Mysore",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for an excursion to Ranganathittu the bird sanctuary near Mysore, Afternoon city tour including Maharaja's Palace built as recently as 1897, which is one of the biggest in India, St Philomena's Church etc and Sri Jayachamarajendra Art Gallery. Overnight at hotel in Mysore.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "To Bandipur - 120kms - 3 hours drive",
                        dayList: [
                            {
                                dayListDes: "After breakfast check out and proceed to Bandipur. On arrival, check in at Hotel for overnight stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Bandipur",
                        dayList: [
                            {
                                dayListDes: "Early Morning jungle visit to the Bandipur National Park (optional). Overnight at the hotel in Bandipur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "To Calicut - 150 kms - 04 hours drive",
                        dayList: [
                            {
                                dayListDes: "Early Morning jungle visit to the Bandipur National Park (optional). After breakfast drive to Calicut. On check in to resort. Evening sunset cruise at Chaliyar River. Overnight stay. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "To Cochin - 260 Kms - 7 hours drive",
                        dayList: [
                            {
                                dayListDes: "After early breakfast check out and drive to Cochin. Enroute visit Elephant Camp at Guruvayur (subject to availability). On arrival in Cochin check into Hotel for overnight stay. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "In Cochin To Kumarakom - 90 kms - 02 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning sightseeing tour of Cochin including Fort Cochin, The Dutch Palace at Mattancherry is renowned for its wall murals depicting scenes from the Ramayana. The Jewish Synagogue at the heart of what is locally called the Jew Town. The Chinese fishing nets are huge cantilevered fishing nets believed to have brought to Kochi by Chinese traders. Then drive to Kumarakom, check in to resort. Overnight at the hotel in Kumarakom.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 10",
                        dayTitle: "Kumarakom",
                        dayList: [
                            {
                                dayListDes: "Awaken to the call of birdsong and take an early morning guided visit to the bird sanctuary where a Naturalist will assist in helping you sight several rare species of birds including various migratory birds. ",
                            },                            
                            {
                                dayListDes: "Choose an afternoon walk or cycle down to the quaint village of Kumarakom for a firsthand experience of Indian village life or opt to relax at the resort trying out some of the various in-house activities including clay pottery making, weaving, fishing etc.",
                            },                            
                            {
                                dayListDes: "Relax to the strains of the flute while the sun sets around you once more, as you enjoy the sunset cruise. Complete the day with another rare cultural performance of Kerala. ",
                            },                            
                            {
                                dayListDes: "Overnight at Kumarakom. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 11",
                        dayTitle: "Houseboat in Kumarakom",
                        dayList: [
                            {
                                dayListDes: "After breakfast, check-in on the traditionally styled luxury houseboat, where you are cared for by an immaculate crew and a personal chef. Settle down to enjoy the serene charm of the famous Vembanad Lake with a breezy cruise from Kumarakom to Alleppey on a Houseboat (Kettuvallam), a popular and unique attribute of Kerala. Enjoy a romantic candle-lit dinner on the houseboat right in the middle of the lake and experience the thrills of mooring for the night right on the glistening lake! ",
                            },
                            {
                                dayListDes: "The backwaters are the finest jewels in Kerala’s crown. An intricate system of lakes, rivers and small canals that supports an incredible variety of wildlife, particularly birds, and where you will see traditional village life as it has been lived for centuries. A holiday in Kerala is incomplete without a houseboat cruise! ",
                            },
                            {
                                dayListDes: "Overnight stay on Luxury Houseboat. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 12",
                        dayTitle: "Kumarakom to Thekkady (Periyar) - 140 kms - 3 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Periyar. Arrive Periyar and check in at Hotel. Afternoon enjoy boat cruise on Lake Periyar in the National Park, known for herds of wild elephant, bison, deer and spectacular bird life. Elephant ride (subject to availability). Overnight at the Hotel in Periyar.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 13",
                        dayTitle: "In Thekkady (Periyar)",
                        dayList: [
                            {
                                dayListDes: "Early morning spice plantation tour, rest of the day is at leisure. Evening proceed to view and enjoy Kerala Cultural Programmes.  Overnight will be at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 14",
                        dayTitle: "Thekkady To Munnar - 130 Kms - 4 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Munnar, the famous hill station of Kerala. Munnar - surrounded by lush green-carpeted tea and coffee plantations, Munnar is a picturesque hill station situated in the eastern part of Kerala. The town is located at the confluence of three mountain streams-Mudrapuzha, Nallathanni, and Kudala - and is known for its clean, neat, and brightly painted ambience. The beautiful landscapes, cosy weather, and the smell of tealeaves make Munnar one of the most sought-after destinations in South India. Precisely these were the reason why the British always retreated to Munnar when the heat and humidity was on its peak in other parts of Kerala. Munnar is situated near the Anaimudi Peak, the highest peak in the Western Ghats. The hills here are covered with a carpet of flowers in bloom. Also found here is an exotic plant called Neelakurunji that flowers just once in a 12-year period. Its next scheduled flowering is in 2006. On arrive in Munnar, check in at Hotel. Overnight at the hotel in Munnar.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 15",
                        dayTitle: "To Cochin - 130 kms - 04 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Cochin. On arrival you will be met and transfer to the international airport to board the flight to onward destination.",
                            },
                            {
                                dayListDes: "End of our services.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: madurai2,
            ptitle: "1N Chennai, 2N Mahabalipuram, 1N Pondichery, 1N Tanjore, 1N Trichy, 1N Madurai, 1N Periyar, 1N Cochin",
            pdays: "9N|10D",
            pfrom: "Chennai",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: Chennai2
                    },
                    {
                        iimg: m2
                    }
                ],
                ititle: "1N Chennai, 2N Mahabalipuram, 1N Pondichery, 1N Tanjore, 1N Trichy, 1N Madurai, 1N Periyar, 1N Cochin",
                iprice: "₹ 25000",
                idays: "9N|10D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrive Chennai",
                        dayList: [
                            {
                                dayListDes: "Arrive Chennai and meet the Travelpro Holiday, representative at the arrival hall. Check-in to hotel.",
                            },
                            {
                                dayListDes: "Overnight at Hotel in Chennai.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Chennai - Mahabalipuram ",
                        dayList: [
                            {
                                dayListDes: "After breakfast check-out and proceed to Mahabalipuram, arrive Mahabalipuram. Proceed for sightseeing tour of Mahabalipuram and Kanchipuram. Mahabalipuram, with its picturesque location on a rocky outcrop between the beach and a lagoon is a happy combination of history, good beaches, hassle-free tourism and fabulous fish and lobster! Despite the many visitors drawn-in by the former Pallava-dynasty town, the place is friendly and relaxed and the villagers are remarkably unperturbed by the large crowds. Mahabalipuram is renowned for its series of charming rathas and the spectacular carved relief depicting Arjuna’s Penance. The magnificent Shore Temple, built by King Rajasimha in the seventh century, is a unique temple that houses the shrines of both Lord Shiva and Lord Vishnu. The Varaha Cave is a rock-cut mandapam (hall) with two incarnations of Vishnu — Varaha (boar) and Vamana (dwarf). The Krishna Mandapam (Hall) has a bas-relief of Lord Krishna lifting the Govardhan hill on his fingertips. Other important attractions of Mahabalipuram include the Mahishasuramardini Cave, Kotikal Mandapam, Ramanuja Mandapam, and the Dharmaraja Cave. Later visit Kanchipuram (64 Kms from Chennai), the ancient capital of the Pallavas. It is known as the city of 1000 temples that still has 124 shrines. The first temple dedicated to Lord Shiva was built here in the 7th & 8th century A.D. and has paintings on its walls. The Temples of Ekambaswara, Kailasanath, Sri Kamakshi and Varadarajaswamy are of interest. Kanchipuram is also famous for its silks. ",
                            },
                            {
                                dayListDes: "Overnight at Hotel in Mahabalipuram.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Mahabalipuram",
                        dayList: [
                            {
                                dayListDes: "Full day will be at leisure to explore the city or relax at the beach. Overnight at Hotel in Mahabalipuram.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Mahabalipuram - Pondicherry (approx 165 Kms / 4 Hours drive)",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Pondicherry. Arrive Pondicherry and check–in at Hotel. Later, proceed for the sightseeing tour of Pondicherry.  Visit Sri Aurobindo Ashram, the final resting place of the founder of the Ashram, Sri Aurobindo and the Mother. Also visit the Church Of The Sacred Heart Of Jesus with its stained glass windows built in Gothic style. The Pondicherry Museum, the Church of Our Lady of the Angels on Beach Rd. and the Church of Our Lady of the Immaculate Conception are other places of interest. Overnight at Hotel in Pondicherry.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Pondichery - Tanjore (approx 177 Kms - 4 Hours drive)",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Tanjore. Arrive Tanjore and check-in at Hotel. Later, proceed for the sightseeing tour of Tanjore. You can see and visualize the history, religion and nature in all the places of attraction here. Tanjore is essentially a place for art lovers, especially those who love temple architecture and wonder about kings who erected these magnificent monuments. During their reign, the Cholas made significant contribution to the various fields of art and architecture. The famous Thanjavur style of painting was developed by Serfoji, the 19th-century Maharaja of Thanjavur. This distinguished art of Thanjavur is a combination of raised and painted surfaces. Krishna is the deity depicted here with his complexion shown as white rather than the traditional blue. The Brihadeeswara Temple, the beautiful Palace, the Art Gallery and the Saraswati Mahal Library are some of the other things to see in Tanjore. Overnight at Hotel in Tanjore.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Tanjore - Tiruchirappally (Trichy) (approx 54 Kms / 2 Hours drive)",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Trichy. Arrive Trichy and check-in at Hotel. Later, proceed for the sightseeing tour of Trichy. Trichy has various sites within its proximity that you can visit. Most of the places here hold great historical importance. The Rock Fort Temple, an enchanting temple unique in its own way, makes for a must-visit site in Trichy. Moreover, the Tiruvanaikkaval temple, which is famous for its Siva Lingam and the seven Gopurams, is another pilgrimage site you can visit in Trichy. Later you will be driven to Pondicherry. Overnight at Hotel in Trichy.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "Trichy - Madurai (approx 128 Kms / 2 Hours drive)",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Madurai. Arrive Madurai and check-in at Hotel. Later, proceed for the sightseeing tour of Madurai. Visit the great Meenakshi Temple, dedicated to the Consort of Lord Shiva, with its ten towering gopurams (temple towers) rising above the surrounding courtyards and countryside of lush green fields of rice and coconut palms.  See the 'Hall of a Thousand Pillars', built in 1560, which is a great work of structural engineering and art. Each pillar is a picture book in stone, depicting the whole range of human expression.  Later, visit the Tirumalai Nayak Palace, a gracious building in the Indo-Saracenic style that is famous for the stuccowork on its domes and arches. Evening visit temple for the second time to attend temple ceremony. Overnight at Hotel in Madurai.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "Madurai - Periyar (approx 134 Kms / 4:30 Hours drive)",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Periyar.  Arrive Periyar and check–in at Hotel. Later, proceed for the sightseeing tour of Periyar. Enjoy boat cruise on Lake Periyar in the National Park, which is known for herds of wild elephant, bison, deer and spectacular bird life. Evening proceed to visit the spice market. Overnight at Hotel in Periyar.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "Periya - Cochin (approx 185 Kms / 5:30 Hours drive)",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of Cochin. This includes Fort Cochin, The Dutch Palace at Mattancherry, which is renowned for its wall murals depicting scenes from the Ramayana and The Jewish Synagogue at the heart of a town locally known as the Jew Town. Cochin also boasts of The St. Francis Church, the oldest European church in India and The Santa Cruz Basilica, which was originally built in 1557 by the Portuguese. Another tourist attraction is the Chinese fishing nets which are huge cantilevered fishing nets believed to have been brought by Chinese traders to Cochin. Overnight at Hotel in Cochin.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 10",
                        dayTitle: "Departure",
                        dayList: [
                            {
                                dayListDes: "End of our services.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },
        {
            pimg: munnar2,
            ptitle: "Heritage, Wildlife & Hills of South India : 2N Chennai , 2N Mysore, 2N Bandipur, 1N Calicut, 1N Cochin, 2N Kumarakom, 1N Houseboat, 1N Periyar, 2N Munnar",
            pdays: "12N|13D",
            pfrom: "Chennai ",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: munnar2
                    },
                    {
                        iimg: cochin2
                    }
                ],
                ititle: "Heritage, Wildlife & Hills of South India : 2N Chennai, 2N Mysore, 2N Bandipur, 1N Calicut, 1N Cochin, 2N Kumarakom, 1N Houseboat, 1N Periyar, 2N Munnar",
                iprice: "₹ 25000",
                idays: "12N|13D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrive Chennai",
                        dayList: [
                            {
                                dayListDes: "Arrive Chennai Airport, On arrival you will be met with Travelpro Holiday, representative and transferred to Hotel for overnight stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "In Chennai",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for the sightseeing tour of Chennai including. The St. George Fort, built in 1653, is currently used as the state government's secretariat. Santhome Cathedral was built around 14th/15th century and is another colonial structure worth visiting. Dedicated to Lord Shiva, the Kapaleeshwar Temple is the oldest temple in Chennai. Another important temple in the city dedicated to Lord Krishna is Parthsarthy Temple, Marina Beach, the second longest beach in the world. Overnight at the hotel in Chennai.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Chennai / Bangalore - By flight 1.5 Hrs. Bangalore / Mysore - By road - 150 Kms / 03 to 04 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning you will be transferred to the airport to board the flight to Bangalore. You will be met on arrival and later drive to Mysore. Enroute visit Daria Daulat Bagh and the Gumbaz in Srirangapatnam etc and Somnathpur. On arrival, check in at Hotel, evening visit Chamundi Temple & Big Bull Temple. Over night stay at hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Mysore",
                        dayList: [
                            {
                                dayListDes: "Morning proceed for an excursion to Ranganathittu the bird sanctuary near Mysore, Afternoon city tour including Maharaja's Palace built as recently as 1897, which is one of the biggest in India, St Philomena's Church etc and Sri Jayachamarajendra Art Gallery. Overnight at hotel in Mysore.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "To Bandipur - 120kms - 3 hours drive",
                        dayList: [
                            {
                                dayListDes: "After breakfast check out and proceed to Bandipur. On arrival, check in at Hotel for overnight stay.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Bandipur",
                        dayList: [
                            {
                                dayListDes: "Early Morning jungle visit to the Bandipur National Park (optional). Overnight at the hotel in Bandipur.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "To Calicut - 150 kms - 04 hours drive",
                        dayList: [
                            {
                                dayListDes: "Early Morning jungle visit to the Bandipur National Park (optional). After breakfast drive to Calicut. On check in to resort. Evening sunset cruise at Chaliyar River. Overnight stay. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "To Cochin - 200 Kms - 5 hours drive",
                        dayList: [
                            {
                                dayListDes: "After early breakfast check out and drive to Cochin. Enroute visit Elephant Camp at Guruvayur (subject to availability). On arrival in Cochin check into Hotel for overnight stay. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "In Cochin To Kumarakom - 90 kms - 02 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning sightseeing tour of Cochin including Fort Cochin, The Dutch Palace at Mattancherry is renowned for its wall murals depicting scenes from the Ramayana. The Jewish Synagogue at the heart of what is locally called the Jew Town. The Chinese fishing nets are huge cantilevered fishing nets believed to have brought to Kochi by Chinese traders. Then drive to Kumarakom, check in to resort. Overnight at the hotel in Kumarakom.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 10",
                        dayTitle: "To Thekkady (Periyar) - 140 kms - 3 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Periyar. Arrive Periyar and check in at Hotel. Afternoon enjoy boat cruise on Lake Periyar in the National Park, known for herds of wild elephant, bison, deer and spectacular bird life. Elephant ride (subject to availability). Overnight at the Hotel in Periyar.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 11",
                        dayTitle: "In Thekkady (Periyar)",
                        dayList: [
                            {
                                dayListDes: "Early morning spice plantation tour, rest of the day is at leisure. Evening proceed to view and enjoy Kerala Cultural Programmes.  Overnight will be at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 12",
                        dayTitle: "To Munnar - 130 Kms - 3 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Munnar, the famous hill station of Kerala. Munnar - surrounded by lush green-carpeted tea and coffee plantations, Munnar is a picturesque hill station situated in the eastern part of Kerala. The town is located at the confluence of three mountain streams-Mudrapuzha, Nallathanni, and Kudala - and is known for its clean, neat, and brightly painted ambience. The beautiful landscapes, cosy weather, and the smell of tealeaves make Munnar one of the most sought-after destinations in South India. Precisely these were the reason why the British always retreated to Munnar when the heat and humidity was on its peak in other parts of Kerala. Munnar is situated near the Anaimudi Peak, the highest peak in the Western Ghats. The hills here are covered with a carpet of flowers in bloom. Also found here is an exotic plant called Neelakurunji that flowers just once in a 12-year period. Its next scheduled flowering is in 2006. On arrive in Munnar, check in at Hotel. Overnight at the hotel in Munnar.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 13",
                        dayTitle: "Day 13 - To Cochin - 130 kms - 03 hours drive",
                        dayList: [
                            {
                                dayListDes: "Morning drive to Cochin. On arrival you will be met and transfer to the international airport to board the flight to onward destination.",
                            },
                            {
                                dayListDes: "End of our services.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        }
    ]
}