import kolkata1 from '../media/package/sevensisters/kolkata1.jpg';
import kolkata2 from '../media/package/sevensisters/kolkata2.jpg';
import kolkata3 from '../media/package/sevensisters/kolkata3.jpg';
import darjeeling1 from '../media/package/sevensisters/darjeeling1.jpg';
import darjeeling2 from '../media/package/sevensisters/darjeeling2.jpg';
import darjeeling3 from '../media/package/sevensisters/darjeeling3.jpg';
import gangtok1 from '../media/package/sevensisters/gangtok1.jpg';
import sevensis from '../media/package/sevensisters/sevensis.jpg';
import darjeeling4 from '../media/package/sevensisters/darjeeling4.jpg';
import kalimpong1 from '../media/package/sevensisters/kalimpong1.jpg';
import kalimpong2 from '../media/package/sevensisters/kalimpong2.jpg';
import sundarban1 from '../media/package/sevensisters/sundarban1.jpg';
import sundarban2 from '../media/package/sevensisters/sundarban2.jpg';

export const SevenSistersPackage = {
    packageName: "Seven Sisters Northeast",
    titleImg: sevensis,
    packages:  [        
        {
            pimg: kolkata1,
            ptitle: "2N Kolkata, 1N Sunderban, 1N Jaldapara Sanctuary, 3N Gangtok, 2N Darjeeling",
            pdays: "9N|10D",
            pfrom: "Kolkata",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: kolkata1
                    },
                    {
                        iimg: darjeeling1
                    },
                    {
                        iimg: gangtok1
                    }
                ],
                ititle: "2N Kolkata, 1N Sunderban, 1N Jaldapara Sanctuary, 3N Gangtok, 2N Darjeeling",
                iprice: "₹ 25000",
                idays: "9N|10D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrival at Kolkata",
                        dayList: [
                            {
                                dayListDes: "Welcome on arrival at Kolkata and transfer to Hotel, evening walk around the city to appreciate the new culture you are in, overnight at hotel.",
                            },
                            {
                                dayListDes: "Kolkata is the city of Joy, contrast and culture. Once the capital of British India - now the capital of West Bengal. A visit to Kolkata may bring visitors in touch with a combination of a rich 400 years old heritage, vibrant modern life and different cultures.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Kolkata Sightseeing",
                        dayList: [
                            {
                                dayListDes: "Morning 8.30 AM starts the half day city tour with an introduction about the glory of Bengal, visit the Flower Market - a unique wholesale market of flowers which is situated in the bank of river Ganges near the famous Howrah Bridge. Drive around the Heritage Buildings of Dalhousie Square, Visit the Mother Teresa’s House, the headquarters of the Missionaries of Charity followed by Victoria Memorial, Saint Pauls Cathedral. Hop on to an old charming Local Tram and travel to the famous Kali Temple, experience a short Hand pulled Rixo ride and Transfer to the Hotel for Lunch.",
                            },
                            {
                                dayListDes: "Late afternoon you will be taken to Dakshineswar Kali Temple. It is famous for its association - with Ramkrishna who achieved spiritual vision here. Rani Rashmoni built this temple in 1847. The 12- spired temple with its enormous courtyard is surrounded by 12 other temples dedicated to Lord Shiva. Experience the river cruise from Dakshineswar Temple to Belur Math across river Ganges by motorized boat and watch the glimpse of river along with the vibrant modern life and cultures of Kolkata.",
                            },
                            {
                                dayListDes: "Belur Math is the international headquarters of Ramkrishna Mission. It was founded in 1899 by Swami Vivekananda, the world famous yogi and disciple of Sri Ramakrishna Paramhansa. you will finish the days tour at the Mother Teresa’s House, the headquarters of the Missionaries of Charity.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Kolkata to Sundarvans and Visit the National Park",
                        dayList: [
                            {
                                dayListDes: "Morning at 7 AM transfer to Sonakhali jetty (95Kms/2Hrs) through beautiful countryside’s and rural bazaar. Arrive at Sonakhali and board on to the motorised boat. After Briefing and interpretation by our local guide cum naturalist over Tea/Coffee/Welcome drink, cruise through Hogol, Gomor, Durgaduani and Gumdi rivers to the Sudhanyakhali watch tower through creeks and canals. Experience the Island Cruise and arrive at Bali Island and check in into Sunderban Jungle Camp. Evening you might enjoy the infotainment by the local villagers. Overnight at the Camp.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "More of Sundarvans and Transfer to Kolkata",
                        dayList: [
                            {
                                dayListDes: "Morning after breakfast experience the villege walk and to be followed by adventure cruise by manually oared country boat and cruise to Dobanki to experience the Canopy Walk in the Tower, transfer back to the camp, lunch and followed by transfer to Kolkata, over night at hotel",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Kolkata - Bagdogra – Jaldapara Wildlife Santuary",
                        dayList: [
                            {
                                dayListDes: "Departure transfer to airport to connect your flight to Bagdogra, Welcome on arrival at Bagdogra and drive to Jaldapara Wildlife Sanctuary (135Kms/03Hrs) through  a large range of Tea Gardens, Alpine Landscape, Transparent River, National Parks and wildlife sanctuaries. ",
                            },
                            {
                                dayListDes: "Spread across an area of 216.56 Sq. Kms, this largest wild life reserve in West Bengal is the home of Rhinoceros Unicornis (One Horned Rhino). The whole Sanctuary is bird watchers paradise and a delight for ornithologists. The other wealth of this jungle are Indian Elephant, Royal Bengal Tiger, Leopard, Gaur, Sambar Hog Deer, Barking Deer, Indian Civet, Jungle Cat, Otter, Sloth Bear, Hispid Hare etc",
                            },
                            {
                                dayListDes: "Check in at Forest Lodge and enjoy a fresh hot lunch. Afternoon experience a 02Hrs Jeep Safari (subject to availability) inside the sanctuary. Exclusive Tribal Dance show can be arranged in the evening on request.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Elephant Safari at Jaldapara and Transfer to Gangtok",
                        dayList: [
                            {
                                dayListDes: "Early morning transfer to Hollong to experience the most thrilling attraction of the Sanctuary, you will be taken on the back of an Elephant for a safari (Subject to availability) to the selected parts inside the jungle and a lucky visitor might see the wild animals in their natural habitat. Return to the Lodge followed by transfer to Gangtok (185kms / 5 Hrs), Gangtok is  the capital of the state of Sikkim is a beautiful and clean town promising of the best monasteries and landscapes. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "Gangtok Sightseeing",
                        dayList: [
                            {
                                dayListDes: "Morning starts the half day sightseeing in and around this capital city of Sikkim, covering Enchey Monastery, Chortan, Stupa, Institute of Tibetology (Closed on Sunday),  Institute of Handicrafts and handlooms (Closed on Sunday) and, after  Roomtek Monastery & Dharma Chakra Centre (24Kms/45Mins), the sect of the Kagyu order – one of the 4 major Tibetan Buddhist Sect",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "Transfer to Darjeeling",
                        dayList: [
                            {
                                dayListDes: "After breakfast transfer to Darjeeling (105Kms/ 4Hrs.). Dorje, the mystic thunderbolt of the Lama-Religion is the origin of the name Darjeeling, which was first discovered by British in the year 1829 by Captain Lloyd. Surrounded by tea gardens Darjeeling is a land of infinite variety and charm which will cater you a mix-experience of lively nature & charming Himalayan people. This little town faces some of the highest peaks of the Himalayas. Evening free for individual activity and leisure, overnight at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "Darjeeling Sightseeing",
                        dayList: [
                            {
                                dayListDes: "Early morning a start for Tiger Hill to watch the spectacular sunrise and its amazing effects on the Mt. Kanchendzonga (Worlds 3rd highest peak), Visit Ghoom Monastery and Batashia Loop War Memorial while returning to the Hotel. Breakfast at the Hotel followed by half day local sightseeing covering Padmaja N. Zoological Garden (Closed on Thursday), Himalayan Mountaineering Institute (Closed on Thursday), Tibetian Refugee Self-help Center (Closed on Sunday) You might also Experience the famous Toy Train Joy Ride (subject to availability) This unique Mountain Railway' project was first started in 1878 by the initiative of Sir. Assly Eden and is now a World Heritage. Evening free for shopping & individual activity, overnight at hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 10",
                        dayTitle: "Departure Transfer",
                        dayList: [
                            {
                                dayListDes: "Morning Transfer to Bagdogra Airport to connect your flight to the next destination, (95kms/ 3hrs).",
                            },
                            {
                                dayListDes: "Tour Ends.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },             
        {
            pimg: kolkata3,
            ptitle: "2N Kolkata, 1N Sunderban, 1N Jaldapara, 2N Gangtok, 2N Darjeeling, 1N Tea Garden",
            pdays: "10N|11D",
            pfrom: "Kolkata",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: kolkata3
                    },
                    {
                        iimg: darjeeling2
                    },
                    {
                        iimg: gangtok1
                    }
                ],
                ititle: "2N Kolkata, 1N Sunderban, 1N Jaldapara Sanctuary, 3N Gangtok, 2N Darjeeling, 1N Tea Garden",
                iprice: "₹ 25000",
                idays: "10N|11D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrival at Kolkata",
                        dayList: [
                            {
                                dayListDes: "Welcome on arrival at Kolkata and transfer to Hotel, evening walk around the city to appreciate the new culture you are in, overnight at hotel.",
                            },
                            {
                                dayListDes: "Kolkata is the city of Joy, contrast and culture. Once the capital of British India - now the capital of West Bengal. A visit to Kolkata may bring visitors in touch with a combination of a rich 400 years old heritage, vibrant modern life and different cultures.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Kolkata Sightseeing",
                        dayList: [
                            {
                                dayListDes: "Morning 8.30 AM starts the half day city tour with an introduction about the glory of Bengal, visit the Flower Market - a unique wholesale market of flowers which is situated in the bank of river Ganges near the famous Howrah Bridge. Drive around the Heritage Buildings of Dalhousie Square, Visit the Mother Teresa’s House, the headquarters of the Missionaries of Charity followed by Victoria Memorial, Saint Pauls Cathedral. Hop on to an old charming Local Tram and travel to the famous Kali Temple, experience a short Hand pulled Rixo ride and Transfer to the Hotel for Lunch.",
                            },
                            {
                                dayListDes: "Late afternoon you will be taken to Dakshineswar Kali Temple. It is famous for its association - with Ramkrishna who achieved spiritual vision here. Rani Rashmoni built this temple in 1847. The 12- spired temple with its enormous courtyard is surrounded by 12 other temples dedicated to Lord Shiva. Experience the river cruise from Dakshineswar Temple to Belur Math across river Ganges by motorized boat and watch the glimpse of river along with the vibrant modern life and cultures of Kolkata.",
                            },
                            {
                                dayListDes: "Belur Math is the international headquarters of Ramkrishna Mission. It was founded in 1899 by Swami Vivekananda, the world famous yogi and disciple of Sri Ramakrishna Paramhansa. you will finish the days tour at the Mother Teresa’s House, the headquarters of the Missionaries of Charity.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Kolkata to Sundarvans and Visit the National Park",
                        dayList: [
                            {
                                dayListDes: "Morning at 7 AM transfer to Sonakhali jetty (95Kms/2Hrs) through beautiful countryside’s and rural bazaar. Arrive at Sonakhali and board on to the motorised boat. After Briefing and interpretation by our local guide cum naturalist over Tea/Coffee/Welcome drink, cruise through Hogol, Gomor, Durgaduani and Gumdi rivers to the Sudhanyakhali watch tower through creeks and canals. Experience the Island Cruise and arrive at Bali Island and check in into Sunderban Jungle Camp. Evening you might enjoy the infotainment by the local villagers. Overnight at the Camp.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "More of Sundarvans and Transfer to Kolkata",
                        dayList: [
                            {
                                dayListDes: "Morning after breakfast experience the villege walk and to be followed by adventure cruise by manually oared country boat and cruise to Dobanki to experience the Canopy Walk in the Tower, transfer back to the camp, lunch and followed by transfer to Kolkata, over night at hotel",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Kolkata - Bagdogra – Jaldapara Wildlife Santuary",
                        dayList: [
                            {
                                dayListDes: "Departure transfer to airport to connect your flight to Bagdogra, Welcome on arrival at Bagdogra and drive to Jaldapara Wildlife Sanctuary (135Kms/03Hrs) through  a large range of Tea Gardens, Alpine Landscape, Transparent River, National Parks and wildlife sanctuaries. ",
                            },
                            {
                                dayListDes: "Spread across an area of 216.56 Sq. Kms, this largest wild life reserve in West Bengal is the home of Rhinoceros Unicornis (One Horned Rhino). The whole Sanctuary is bird watchers paradise and a delight for ornithologists. The other wealth of this jungle are Indian Elephant, Royal Bengal Tiger, Leopard, Gaur, Sambar Hog Deer, Barking Deer, Indian Civet, Jungle Cat, Otter, Sloth Bear, Hispid Hare etc",
                            },
                            {
                                dayListDes: "Check in at Forest Lodge and enjoy a fresh hot lunch. Afternoon experience a 02Hrs Jeep Safari (subject to availability) inside the sanctuary. Exclusive Tribal Dance show can be arranged in the evening on request.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Elephant Safari at Jaldapara and Transfer to Gangtok",
                        dayList: [
                            {
                                dayListDes: "Early morning transfer to Hollong to experience the most thrilling attraction of the Sanctuary, you will be taken on the back of an Elephant for a safari (Subject to availability) to the selected parts inside the jungle and a lucky visitor might see the wild animals in their natural habitat. Return to the Lodge followed by transfer to Gangtok (80kms / 3 Hrs), Gangtok is  the capital of the state of Sikkim is a beautiful and clean town promising of the best monasteries and landscapes. ",
                            },
                            {
                                dayListDes: "Transfer to Gangtok (185kms / 5 Hrs), Gangtok is  the capital of the state of Sikkim is a beautiful and clean town promising of the best monasteries and landscapes. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "Gangtok Sightseeing",
                        dayList: [
                            {
                                dayListDes: "Morning starts the half day sightseeing in and around this capital city of Sikkim, covering Enchey Monastery, Chortan, Stupa, Institute of Tibetology (Closed on Sunday),  Institute of Handicrafts and handlooms (Closed on Sunday) and  Roomtek Monastery & Dharma Chakra Centre (24Kms/45Mins), the sect of the Kagyu order – one of the 4 major Tibetan Buddhist Sect (24Kms/45Mins). Evening free for leisure, overnight at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "Transfer to Darjeeling",
                        dayList: [
                            {
                                dayListDes: "After breakfast transfer to Darjeeling (105Kms/ 4Hrs.). Dorje, the mystic thunderbolt of the Lama-Religion was the origin of the name Darjeeling, which was first discovered by British in the year 1829 by Captain Lloyd. Surrounded by tea gardens Darjeeling is a land of infinite variety and charm which will cater you a mix-experience of lively nature & charming Himalayan people. This little town faces some of the highest peaks of the Himalayas. Evening free for individual activity and leisure, overnight at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "Darjeeling Sightseeing",
                        dayList: [
                            {
                                dayListDes: "Early morning a start for Tiger Hill to watch the spectacular sunrise and its amazing effects on the Mt. Kanchendzonga (Worlds 3rd highest peak), Visit Ghoom Monastery and Batashia Loop War Memorial while returning to the Hotel. Breakfast at the Hotel followed by half day local sightseeing covering Padmaja N. Zoological Garden (Closed on Thursday), Himalayan Mountaineering Institute (Closed on Thursday), Tibetian Refugee Self-help Center (Closed on Sunday) You might also Experience the famous Toy Train Joy Ride (subject to availability) This unique Mountain Railway' project was first started in 1878 by the initiative of Sir. Assly Eden and is now a World Heritage. Evening free for shopping & individual activity, overnight at hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 10",
                        dayTitle: "Visit to Tiger Hill to Chamong Chiabari Tea Estate",
                        dayList: [
                            {
                                dayListDes: "Early morning start for Tiger Hill to watch the spectacular sunrise and its amazing effects on the Mt. Kanchendzonga (Worlds 3rd highest peak), Visit Ghoom Monastery and Batashia Loop War Memorial while returning to the Hotel. After lunch transfer to Chamong Chiabari Tea Estate (25Kms/01.5Hrs).",
                            },
                            {
                                dayListDes: "The tea country is vast parkland of manmade landscape, silver-barked and slim elevated trees stand evenly spaced out over trimmed tea bushes throwing a light shade over them. The Tea story of the region dates back to 1835 through the initiative of the British Governor General, Lord Bentinck and today Darjeeling tea famous around the world for its flavor and is acknowledged as 'The Champagne of Teas'. The stunning view of the tea garden along with the mountains and the foothill forests of the Eastern Himalayas from our exclusive private bungalows is enchanting.  Enjoy a tour of the garden along with the factory and discover the charming character, rich culture and history with the friendly people of the tea estates of the region. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 11",
                        dayTitle: "Chamong Chiabari Tea Estate – Bagdogra Airport",
                        dayList: [
                            {
                                dayListDes: "Morning after an early breakfast visit the Tea Factory and the Plantation to interact and understand the life style of the tea industry. Afternoon transfer to Bagdogra Airport (106Kms/ 2hrs) to connect flight to your next destination. ",
                            },
                            {
                                dayListDes: "Tour Ends",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },             
        {
            pimg: darjeeling3,
            ptitle: "2N Kolkata, 2N Darjeeling, 1N Tea Garden",
            pdays: "5N|6D",
            pfrom: "Kolkata",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: kolkata2
                    },
                    {
                        iimg: darjeeling3
                    }
                ],
                ititle: "2N Kolkata, 2N Darjeeling, 1N Tea Garden",
                iprice: "₹ 25000",
                idays: "5N|6D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrival at Kolkata",
                        dayList: [
                            {
                                dayListDes: "Welcome on arrival at Kolkata and transfer to Hotel, evening walk around the city to appreciate the new culture you are in, overnight at hotel. Kolkata is the city of Joy, contrast and culture. Once the capital of British India - now the capital of West Bengal. A visit to Kolkata may bring visitors in touch with a combination of a rich 400 years old heritage, vibrant modern life and different cultures. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Kolkata Sightseeing",
                        dayList: [
                            {
                                dayListDes: "Morning 8.30 AM starts the half day city tour with an introduction about the glory of Bengal, visit the Flower Market - a unique wholesale market of flowers which is situated in the bank of river Ganges near the famous Howrah Bridge. Drive around the Heritage Buildings of Dalhousie Square, Visit the Mother Teresa’s House, the headquarters of the Missionaries of Charity followed by Victoria Memorial, Saint Pauls Cathedral. Hop on to an old charming Local Tram and travel to the famous Kali Temple, experience a short Hand pulled Rixo ride and Transfer to the Hotel for Lunch.",
                            },
                            {
                                dayListDes: "Late afternoon you will be taken to Dakshineswar Kali Temple. It is famous for its association - with Ramkrishna who achieved spiritual vision here. Rani Rashmoni built this temple in 1847. The 12- spired temple with its enormous courtyard is surrounded by 12 other temples dedicated to Lord Shiva. Experience the river cruise from Dakshineswar Temple to Belur Math across river Ganges by motorized boat and watch the glimpse of river along with the vibrant modern life and cultures of Kolkata.",
                            },
                            {
                                dayListDes: "Belur Math is the international headquarters of Ramkrishna Mission. It was founded in 1899 by Swami Vivekananda, the world famous yogi and disciple of Sri Ramakrishna Paramhansa.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Kolkata – Bagdogra – Darjeeling",
                        dayList: [
                            {
                                dayListDes: "Transfer to the next destination or departure transfer to airport to connect your flight Bagdogra, Welcome on arrival at Bagdogra and drive to Darjeeling (95Kms/ 2.5Hrs.). Dorje, the mystic thunderbolt of the Lama-Religion was the origin of the name Darjeeling, which was first discovered by a British Captain Loyd in the year 1829. Surrounded by tea gardens Darjeeling is a land of infinite variety and charm which will cater you a mix-experience of lively nature & charming Himalayan people. This little town faces some of the highest peaks of the Himalayas. Evening free for individual activity and leisure, overnight at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Darjeeling Sightseeing",
                        dayList: [
                            {
                                dayListDes: "Breakfast at the Hotel followed by half day local sightseeing covering Padmaja N. Zoological Garden (Closed on Thursday), Himalayan Mountaineering Institute (Closed on Thursday), Tibetian Refugee Self-help Center (Closed on Sunday)  and visit to the Mall and Local Market (Closed on Thursday). You might also Experience the famous Toy Train Joy Ride (subject to availability). This unique Mountain Railway' project was first started in 1878 by the initiative of Sir. Assly Eden and is now a World Heritage. Evening free for shopping & individual activity, overnight at hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Visit to Tiger Hill to Chamong Chiabari Tea Estate",
                        dayList: [
                            {
                                dayListDes: "Early morning start for Tiger Hill to watch the spectacular sunrise and its amazing effects on the Mt. Kanchendzonga (Worlds 3rd highest peak), Visit Ghoom Monastery and Batashia Loop War Memorial while returning to the Hotel. After lunch transfer to Chamong Chiabari Tea Estate (25Kms/01.5Hrs).",
                            },
                            {
                                dayListDes: "The tea country is vast parkland of man made landscape, silver-barked and slim elevated trees stand evenly spaced out over trimmed tea bushes throwing a light shade over them. The Tea story of the region dates back to 1835 through the initiative of the British Governor General, Lord Bentinck and today Darjeeling tea famous around the world for its flavor and is acknowledged as 'The Champagne of Teas'. The stunning view of the tea garden along with the mountains and the foothill forests of the Eastern Himalayas from the exclusive private bungalows is enchanting.  Enjoy a tour of the garden along with the factory and discover the charming character, rich culture and history with the friendly people of the tea estates of the region. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Chamong Chiabari Tea Estate – Bagdogra Airport",
                        dayList: [
                            {
                                dayListDes: "Morning after an early breakfast visit the Tea Factory and the Plantation to interact and understand the life style of the tea industry. Afternoon transfer to Bagdogra Airport (106Kms/ 2hrs) to connect flight to your next destination.",
                            },
                            {
                                dayListDes: "Tour Ends.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },             
        {
            pimg: kalimpong1,
            ptitle: "2N Kolkata, 1N Kalimpong, 2N Gangtok, 2N Darjeeling, 2N Tea Garden",
            pdays: "8N|9D",
            pfrom: "Kolkata",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: kalimpong1
                    },
                    {
                        iimg: darjeeling2
                    },
                    {
                        iimg: kolkata1
                    }
                ],
                ititle: "2N Kolkata, 1N Kalimpong, 2N Gangtok, 2N Darjeeling, 2N Tea Garden",
                iprice: "₹ 25000",
                idays: "8N|9D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrival at Kolkata",
                        dayList: [
                            {
                                dayListDes: "Welcome on arrival at Kolkata and transfer to Hotel, evening walk around the city to appreciate the new culture you are in, overnight at hotel.",
                            },
                            {
                                dayListDes: "Kolkata is the city of Joy, contrast and culture. Once the capital of British India - now the capital of West Bengal. A visit to Kolkata may bring visitors in touch with a combination of a rich 400 years old heritage, vibrant modern life and different cultures. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Kolkata Sightseeing",
                        dayList: [
                            {
                                dayListDes: "Morning 8.30 AM starts the half day city tour with an introduction about the glory of Bengal, visit the Flower Market - a unique wholesale market of flowers which is situated in the bank of river Ganges near the famous Howrah Bridge. Drive around the Heritage Buildings of Dalhousie Square, Visit the Mother Teresa’s House, the headquarters of the Missionaries of Charity followed by Victoria Memorial, Saint Pauls Cathedral. Hop on to an old charming Local Tram and travel to the famous Kali Temple, experience a short Hand pulled Rixo ride and Transfer to the Hotel for Lunch. ",
                            },
                            {
                                dayListDes: "Late afternoon you will be taken to Dakshineswar Kali Temple. It is famous for its association - with Ramkrishna who achieved spiritual vision here. Rani Rashmoni built this temple in 1847. The 12- spired temple with its enormous courtyard is surrounded by 12 other temples dedicated to Lord Shiva. Experience the river cruise from Dakshineswar Temple to Belur Math across river Ganges by motorized boat and watch the glimpse of river along with the vibrant modern life and cultures of Kolkata.",
                            },
                            {
                                dayListDes: "Belur Math is the international headquarters of Ramkrishna Mission. It was founded in 1899 by Swami Vivekananda, the world famous yogi and disciple of Sri Ramakrishna Paramhansa. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Kolkata – Bagdogra – Kalimpong",
                        dayList: [
                            {
                                dayListDes: "Departure transfer to airport to connect your flight Bagdogra, Welcome on arrival at Bagdogra  Airport and Transfer to Kalimpong (65kms / 02hrs), a quiet hill resort in eastern Himalaya. Kalimpong is famous for its Orchids & Nurseries and some of the some of the rarest cacti & Orchids can be seen. Oak, Maple and chestnut stretch up the ridge as far as the eye can reach. Check in at Hotel. evening visit the local market to have an in depth knowledge about the local culture and community. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Kalimpong - Gangtok",
                        dayList: [
                            {
                                dayListDes: "Breakfast at the hotel followed half day sightseeing in and around Kalimpong covering Durpin Dhara, Zong Dog Palri Fo-Brong Monastery, and Flower & Orchid Nurseries & Kalimpong Market. Transfer to Gangtok (80kms / 3 Hrs), Gangtok is  the capital of the state of Sikkim is a beautiful and clean town promising of the best monasteries and landscapes. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Gangtok Sightseeing",
                        dayList: [
                            {
                                dayListDes: "Morning starts the half day sightseeing in and around this capital city of Sikkim, covering Enchey Monastery, Chortan, Stupa, Institute of Tibetology (Closed on Sunday),  Institute of Handicrafts and handlooms (Closed on Sunday) and  Roomtek Monastery & Dharma Chakra Centre (24Kms/45Mins), the sect of the Kagyu order – one of the 4 major Tibetan Buddhist Sect (24Kms/45Mins). Evening free for leisure, overnight at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Transfer to Darjeeling",
                        dayList: [
                            {
                                dayListDes: "After breakfast transfer to Darjeeling (105Kms/ 4Hrs.). Dorje, the mystic thunderbolt of the Lama-Religion was the origin of the name Darjeeling, which was first discovered by British in the year 1829 by Captain Lloyd. Surrounded by tea gardens Darjeeling is a land of infinite variety and charm which will cater you a mix-experience of lively nature & charming Himalayan people. This little town faces some of the highest peaks of the Himalayas. Evening free for individual activity and leisure, overnight at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "Darjeeling Sightseeing",
                        dayList: [
                            {
                                dayListDes: "Breakfast at the Hotel followed by half day local sightseeing covering Padmaja N. Zoological Garden (Closed on Thursday), Himalayan Mountaineering Institute (Closed on Thursday), Tibetian Refugee Self-help Center (Closed on Sunday) You might also Experience the famous Toy Train Joy Ride (subject to availability) This unique Mountain Railway' project was first started in 1878 by the initiative of Sir. Assly Eden and is now a World Heritage. Evening free for shopping & individual activity, overnight at hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "Visit to Tiger Hill to Chamong Chiabari Tea Estate",
                        dayList: [
                            {
                                dayListDes: "Early morning start for Tiger Hill to watch the spectacular sunrise and its amazing effects on the Mt. Kanchendzonga (Worlds 3rd highest peak), Visit Ghoom Monastery and Batashia Loop War Memorial while returning to the Hotel. After lunch transfer to Chamong Chiabari Tea Estate (25Kms/01.5Hrs).",
                            },
                            {
                                dayListDes: "The tea country is vast parkland of man made landscape, silver-barked and slim elevated trees stand evenly spaced out over trimmed tea bushes throwing a light shade over them. The Tea story of the region dates back to 1835 through the initiative of the British Governor General, Lord Bentinck and today Darjeeling tea famous around the world for its flavor and is acknowledged as 'The Champagne of Teas'. The stunning view of the tea garden along with the mountains and the foothill forests of the Eastern Himalayas from the exclusive private bungalows is enchanting.  Enjoy a tour of the garden along with the factory and discover the charming character, rich culture and history with the friendly people of the tea estates of the region.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "Chamong Chiabari Tea Estate – Bagdogra Airport",
                        dayList: [
                            {
                                dayListDes: "Morning after an early breakfast visit the Tea Factory and the Plantation to interact and understand the life style of the tea industry. Afternoon transfer to Bagdogra Airport (106Kms/ 2hrs) to connect flight to your next destination.",
                            },
                            {
                                dayListDes: "Tour Ends.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },             
        {
            pimg: gangtok1,
            ptitle: "2N Kolkata, 3N Gangtok, 2N Darjeeling",
            pdays: "7N|8D",
            pfrom: "Kolkata",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: darjeeling3
                    },
                    {
                        iimg: kolkata3
                    },
                    {
                        iimg: gangtok1
                    }
                ],
                ititle: "2N Kolkata, 3N Gangtok, 2N Darjeeling",
                iprice: "₹ 25000",
                idays: "7N|8D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrival at Kolkata",
                        dayList: [
                            {
                                dayListDes: "Welcome on arrival at Kolkata and transfer to Hotel, evening walk around the city to appreciate the new culture you are in, overnight at hotel.",
                            },
                            {
                                dayListDes: "Kolkata is the city of Joy, contrast and culture. Once the capital of British India - now the capital of West Bengal. A visit to Kolkata may bring visitors in touch with a combination of a rich 400 years old heritage, vibrant modern life and different cultures. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Kolkata Sightseeing",
                        dayList: [
                            {
                                dayListDes: "Morning 8.30 AM starts the half day city tour with an introduction about the glory of Bengal, visit the Flower Market - a unique wholesale market of flowers which is situated in the bank of river Ganges near the famous Howrah Bridge. Drive around the Heritage Buildings of Dalhousie Square, Visit the Mother Teresa’s House, the headquarters of the Missionaries of Charity followed by Victoria Memorial, Saint Pauls Cathedral. Hop on to an old charming Local Tram and travel to the famous Kali Temple, experience a short Hand pulled Rixo ride and Transfer to the Hotel for Lunch. ",
                            },
                            {
                                dayListDes: "Late afternoon you will be taken to Dakshineswar Kali Temple. It is famous for its association - with Ramkrishna who achieved spiritual vision here. Rani Rashmoni built this temple in 1847. The 12- spired temple with its enormous courtyard is surrounded by 12 other temples dedicated to Lord Shiva. Experience the river cruise from Dakshineswar Temple to Belur Math across river Ganges by motorized boat and watch the glimpse of river along with the vibrant modern life and cultures of Kolkata.",
                            },
                            {
                                dayListDes: "Belur Math is the international headquarters of Ramkrishna Mission. It was founded in 1899 by Swami Vivekananda, the world famous yogi and disciple of Sri Ramakrishna Paramhansa. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Kolkata – Bagdogra – Gangtok",
                        dayList: [
                            {
                                dayListDes: "Transfer to the next destination or departure transfer to airport to connect your flight Bagdogra, Welcome on arrival at Bagdogra and drive to Gangtok (114Km/4.5Hrs), At an altitude of 5500ft Gangtok is the capital of the state of Sikkim is a beautiful and clean town promising of the best monasteries and landscapes. Take a rest and then a walk around town to appreciate the new culture you are in. overnight at hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Gangtok Sightseeing",
                        dayList: [
                            {
                                dayListDes: "Morning starts the half day sightseeing in and around this capital city of Sikkim, covering Enchey Monastery, Chortan, Stupa, Institute of Tibetology (Closed on Sunday),  Institute of Handicrafts and handlooms (Closed on Sunday) and, after  Roomtek Monastery & Dharma Chakra Centre (24Kms/45Mins), the sect of the Kagyu order – one of the 4 major Tibetan Buddhist Sect (24Kms/45Mins). ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Excursion Trip to Tsomgo Lake",
                        dayList: [
                            {
                                dayListDes: "AM start for a half day trip to Tsomgo lake (12400ft), which is only 32kms from Gangtok City, the lake is almost about 1Km long, oval in shape and 15Mt deep. The lake is considered sacred by the local people, Evening free at Gangtok for leisure and individual activity.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Transfer to Darjeeling",
                        dayList: [
                            {
                                dayListDes: "After breakfast transfer to Darjeeling (105Kms/ 4Hrs.). Dorje, the mystic thunderbolt of the Lama-Religion was the origin of the name Darjeeling, which was first discovered by a British Captain Loyd in the year 1829. Surrounded by tea gardens Darjeeling is a land of infinite variety and charm which will cater you a mix-experience of lively nature & charming Himalayan people. This little town faces some of the highest peaks of the Himalayas. Evening free for individual activity and leisure, overnight at Hotel",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "Darjeeling Sightseeing",
                        dayList: [
                            {
                                dayListDes: "Early morning a start for Tiger Hill to watch the spectacular sunrise and its amazing effects on the Mt. Kanchendzonga (Worlds 3rd highest peak), Visit Ghoom Monastery and Batashia Loop War Memorial while returning to the Hotel. Breakfast at the Hotel followed by half day local sightseeing covering Padmaja N. Zoological Garden (Closed on Thursday), Himalayan Mountaineering Institute (Closed on Thursday), Tibetian Refugee Self-help Center (Closed on Sunday) You might also Experience the famous Toy Train Joy Ride (subject to availability) This unique Mountain Railway' project was first started in 1878 by the initiative of Sir. Assly Eden and is now a World Heritage. Evening free for shopping & individual activity, overnight at hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "Departure Transfer",
                        dayList: [
                            {
                                dayListDes: "Morning Transfer to Bagdogra Airport to connect your flight to the next destination, (95kms/ 3hrs),",
                            },
                            {
                                dayListDes: "Tour Ends.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },             
        {
            pimg: darjeeling4,
            ptitle: "2N Kolkata, 1N Jaldapara, 2N Pemayangtse, 2N Darjeeling, 1N Tea Garden",
            pdays: "11N|12D",
            pfrom: "Kolkata",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: darjeeling2
                    },
                    {
                        iimg: darjeeling4
                    },,
                    {
                        iimg: kolkata1
                    },
                    {
                        iimg: gangtok1
                    }
                ],
                ititle: "2N Kolkata, 1N Jaldapara, 2N Pemayangtse, 2N Darjeeling, 1N Tea Garden",
                iprice: "₹ 25000",
                idays: "11N|12D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrival at Kolkata",
                        dayList: [
                            {
                                dayListDes: "Welcome on arrival at Kolkata and transfer to Hotel, evening walk around the city to appreciate the new culture you are in, overnight at hotel.",
                            },
                            {
                                dayListDes: "Kolkata is the city of Joy, contrast and culture. Once the capital of British India - now the capital of West Bengal. A visit to Kolkata may bring visitors in touch with a combination of a rich 400 years old heritage, vibrant modern life and different cultures. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Kolkata Sightseeing",
                        dayList: [
                            {
                                dayListDes: "Morning 8.30 AM starts the half day city tour with an introduction about the glory of Bengal, visit the Flower Market - a unique wholesale market of flowers which is situated in the bank of river Ganges near the famous Howrah Bridge. Drive around the Heritage Buildings of Dalhousie Square, Visit the Mother Teresa’s House, the headquarters of the Missionaries of Charity followed by Victoria Memorial, Saint Pauls Cathedral. Hop on to an old charming Local Tram and travel to the famous Kali Temple, experience a short Hand pulled Rixo ride and Transfer to the Hotel for Lunch. ",
                            },
                            {
                                dayListDes: "Late afternoon you will be taken to Dakshineswar Kali Temple. It is famous for its association - with Ramkrishna who achieved spiritual vision here. Rani Rashmoni built this temple in 1847. The 12- spired temple with its enormous courtyard is surrounded by 12 other temples dedicated to Lord Shiva. Experience the river cruise from Dakshineswar Temple to Belur Math across river Ganges by motorized boat and watch the glimpse of river along with the vibrant modern life and cultures of Kolkata.",
                            },
                            {
                                dayListDes: "Belur Math is the international headquarters of Ramkrishna Mission. It was founded in 1899 by Swami Vivekananda, the world famous yogi and disciple of Sri Ramakrishna Paramhansa. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Kolkata – Bagdogra – Jaldapara Wildlife Santuary",
                        dayList: [
                            {
                                dayListDes: "Departure transfer to airport to connect your flight Bagdogra, Welcome on arrival at Bagdogra and  drive to Jaldapara Wildlife Sanctuary (135Kms/03Hrs) through  a large range of Tea Gardens, Alpine Landscape, Transparent River, National Parks and wildlife sanctuaries. ",
                            },
                            {
                                dayListDes: "Spread across an area of 216.56 Sq. Kms, this largest wild life reserve in West Bengal is the home of Rhinoceros Unicornis (One Horned Rhino). The whole Sanctuary is bird watchers paradise and a delight for ornithologists. The other wealth of this jungle are Indian Elephant, Royal Bengal Tiger, Leopard, Gaur, Sambar Hog Deer, Barking Deer, Indian Civet, Jungle Cat, Otter, Sloth Beer, Hispid Hare etc. ",
                            },
                            {
                                dayListDes: "Check in at Forest Lodge and enjoy a fresh hot lunch. Afternoon experience a 02Hrs Jeep Safari (subject to availability) inside the sanctuary. Exclusive Tribal Dance show can be arranged in the evening on request.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Elephant Safari at Jaldapara and Transfer to Gangtok",
                        dayList: [
                            {
                                dayListDes: "Early morning transfer to Hollong to experience the most thrilling attraction of the Sanctuary, you will be taken on the back of an Elephant for a safari (Subject to availability) to the selected parts inside the jungle and a lucky visitor might see the wild animals in their natural habitat. Return to the Lodge followed by transfer to Gangtok (185kms / 5 Hrs), Gangtok is  the capital of the state of Sikkim is a beautiful and clean town promising of the best monasteries and landscapes. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Gangtok Sightseeing",
                        dayList: [
                            {
                                dayListDes: "Morning starts the half day sightseeing in and around this capital city of Sikkim, covering Enchey Monastery, Chortan, Stupa, Institute of Tibetology (Closed on Sunday),  Institute of Handicrafts and handlooms (Closed on Sunday) and  Roomtek Monastery & Dharma Chakra Centre (24Kms/45Mins), the sect of the Kagyu order – one of the 4 major Tibetan Buddhist Sect (24Kms/45Mins). Evening free for leisure, overnight at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Excursion Trip to Tsomgo Lake",
                        dayList: [
                            {
                                dayListDes: "Morning start for a half day trip to Tsomgo lake (12400ft), which is only 32kms from Gangtok City, the lake is almost about 1Km long, oval in shape and 15Mt deep. The lake is considered sacred by the local people, Evening free at Gangtok for leisure and individual activity.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "Gangtok to Pemayangtse",
                        dayList: [
                            {
                                dayListDes: "Morning transfer to Pemayangtse (123kms/6 hrs), en route visit Ravangla - a small township in South Sikkim at the base of Menam Hill. Located at an altitude of 6,800 ft, Pemayangtse is a very small Himalayan village in West Sikkim.  Pelling is the nearest motorable point from Mt. Kanchendzonga - worlds 03rd highest peak, offers a good sunrise and all day long view of Khangchendzonga and the entire Eastern Himalayan range.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "Pemayangtse Sightseeing",
                        dayList: [
                            {
                                dayListDes: "After breakfast start for full day sightseeing in an around Pemayangtes, in the fast half you will be covering Changay Falls Singshore Bridge (Worlds 2nd highest hanging bridge) Dentam Bazar – a beautiful Sikimease villege &  Pemayangtse Monastery – the 2nd oldest monastery of Sikkim, return to hotel for lunch followed by second half of the day’s sightseeing covering Rimbi Falls, the sacred Kechodpalri Lake and  Kanchendzonga Falls. overnight at hotel. evening free for individual activity and leisure, overnight at hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "Pemayangtse to Darjeeling",
                        dayList: [
                            {
                                dayListDes: "Morning transfer to Darjeeling, Dorje, the mystic thunderbolt of the Lama-Religion is the origin of the name Darjeeling, which was first discovered by British in the year 1829 by Captain Lloyd. Surrounded by tea gardens Darjeeling is a land of infinite variety and charm which will cater you a mix-experience of lively nature & charming Himalayan people. This little town faces some of the highest peaks of the Himalayas. Evening free for individual activity and leisure,  overnight at Hotel. Take a rest and then a walk around town to appreciate the new culture you are in. Overnight at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 10",
                        dayTitle: "Darjeeling Sightseeing",
                        dayList: [
                            {
                                dayListDes: "Breakfast at the Hotel followed by half day local sightseeing covering Padmaja N. Zoological Garden (Closed on Thursday), Himalayan Mountaineering Institute (Closed on Thursday), Tibetian Refugee Self-help Center (Closed on Sunday) You might also Experience the famous Toy Train Joy Ride (subject to availability) This unique Mountain Railway' project was first started in 1878 by the initiative of Sir. Assly Eden and is now a World Heritage. Evening free for shopping & individual activity, overnight at hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 11",
                        dayTitle: "Visit to Tiger Hill to Chamong Chiabari Tea Estate",
                        dayList: [
                            {
                                dayListDes: "Early morning start for Tiger Hill to watch the spectacular sunrise and its amazing effects on the Mt. Kanchendzonga (Worlds 3rd highest peak), Visit Ghoom Monastery and Batashia Loop War Memorial while returning to the Hotel. After lunch transfer to Chamong Chiabari Tea Estate (25Kms/01.5Hrs).",
                            },
                            {
                                dayListDes: "The tea country is vast parkland of man made landscape, silver-barked and slim elevated trees stand evenly spaced out over trimmed tea bushes throwing a light shade over them. The Tea story of the region dates back to 1835 through the initiative of the British Governor General, Lord Bentinck and today Darjeeling tea famous around the world for its flavor and is acknowledged as 'The Champagne of Teas'. The stunning view of the tea garden along with the mountains and the foothill forests of the Eastern Himalayas from the exclusive private bungalows is enchanting.  Enjoy a tour of the garden along with the factory and discover the charming character, rich culture and history with the friendly people of the tea estates of the region. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 12",
                        dayTitle: "Chamong Chiabari Tea Estate – Bagdogra Airport",
                        dayList: [
                            {
                                dayListDes: "Morning after an early breakfast visit the Tea Factory and the Plantation to interact and understand the life style of the tea industry. Afternoon transfer to Bagdogra Airport (106Kms/ 2hrs) to connect flight to your next destination. ",
                            },
                            {
                                dayListDes: "Tour Ends.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },             
        {
            pimg: kalimpong2,
            ptitle: "2N Kolkata, 1N Kalimpong, 3N Gangtok, 2N Pemayangtse, 2N Darjeeling",
            pdays: "10N|11D",
            pfrom: "Kolkata",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: kalimpong2
                    },
                    {
                        iimg: darjeeling4
                    },,
                    {
                        iimg: kolkata3
                    },
                    {
                        iimg: gangtok1
                    }
                ],
                ititle: "2N Kolkata, 1N Kalimpong, 3N Gangtok, 2N Pemayangtse, 2N Darjeeling",
                iprice: "₹ 25000",
                idays: "10N|11D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrival at Kolkata",
                        dayList: [
                            {
                                dayListDes: "Welcome on arrival at Kolkata and transfer to Hotel, evening walk around the city to appreciate the new culture you are in, overnight at hotel. Kolkata is the city of Joy, contrast and culture. Once the capital of British India - now the capital of West Bengal. A visit to Kolkata may bring visitors in touch with a combination of a rich 400 years old heritage, vibrant modern life and different cultures. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Kolkata Sightseeing",
                        dayList: [
                            {
                                dayListDes: "Morning 8.30 AM starts the half day city tour with an introduction about the glory of Bengal, visit the Flower Market - a unique wholesale market of flowers which is situated in the bank of river Ganges near the famous Howrah Bridge. Drive around the Heritage Buildings of Dalhousie Square, Visit the Mother Teresa’s House, the headquarters of the Missionaries of Charity followed by Victoria Memorial, Saint Pauls Cathedral. Hop on to an old charming Local Tram and travel to the famous Kali Temple, experience a short Hand pulled Rixo ride and Transfer to the Hotel for Lunch. ",
                            },
                            {
                                dayListDes: "Late afternoon you will be taken to Dakshineswar Kali Temple. It is famous for its association - with Ramkrishna who achieved spiritual vision here. Rani Rashmoni built this temple in 1847. The 12- spired temple with its enormous courtyard is surrounded by 12 other temples dedicated to Lord Shiva. Experience the river cruise from Dakshineswar Temple to Belur Math across river Ganges by motorized boat and watch the glimpse of river along with the vibrant modern life and cultures of Kolkata.",
                            },
                            {
                                dayListDes: "Belur Math is the international headquarters of Ramkrishna Mission. It was founded in 1899 by Swami Vivekananda, the world famous yogi and disciple of Sri Ramakrishna Paramhansa. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Kolkata – Bagdogra – Kalimpong",
                        dayList: [
                            {
                                dayListDes: "Departure transfer to airport to connect your flight to Bagdogra, Welcome on arrival at Bagdogra  Airport and Transfer to Kalimpong (65kms / 02hrs), a quiet hill resort in eastern Himalaya. Kalimpong is famous for its Orchids & Nurseries and some of the some of the rarest cacti & Orchids can be seen. Oak, Maple and chestnut stretch up the ridge as far as the eye can reach. Check in at Hotel. evening visit the local market to have an in depth knowledge about the local culture and community. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "Kalimpong - Gangtok",
                        dayList: [
                            {
                                dayListDes: "Breakfast at the hotel followed half day sightseeing in and around Kalimpong covering Durpin Dhara, Zong Dog Palri Fo-Brong Monastery, and Flower & Orchid Nurseries & Kalimpong Market. Transfer to Gangtok (80kms / 3 Hrs), Gangtok is  the capital of the state of Sikkim is a beautiful and clean town promising of the best monasteries and landscapes. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Gangtok Sightseeing",
                        dayList: [
                            {
                                dayListDes: "Morning starts the half day sightseeing in and around this capital city of Sikkim, covering Enchey Monastery, Chortan, Stupa, Institute of Tibetology (Closed on Sunday),  Institute of Handicrafts and handlooms (Closed on Sunday) and  Roomtek Monastery & Dharma Chakra Centre (24Kms/45Mins), the sect of the Kagyu order – one of the 4 major Tibetan Buddhist Sect . Evening free for leisure, overnight at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Excursion Trip to Tsomgo Lake",
                        dayList: [
                            {
                                dayListDes: "AM start for a half day trip to Tsomgo lake (12400ft), which is only 32kms from Gangtok City, the lake is almost about 1Km long, oval in shape and 15Mt deep. The lake is considered sacred by the local people, Evening free at Gangtok for leisure and individual activity.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "Gangtok to Pemayangtse",
                        dayList: [
                            {
                                dayListDes: "Morning transfer to Pemayangtse (123kms/6 hrs), en route visit Ravangla - a small township in South Sikkim at the base of Menam Hill. Located at an altitude of 6,800 ft, Pemayangtse is a very small Himalayan village in West Sikkim.  Pelling is the nearest motorable point from Mt. Kanchendzonga - worlds 03rd highest peak, offers a good sunrise and all day long view of Khangchendzonga and the entire Eastern Himalayan range.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "Pemayangtse Sightseeing",
                        dayList: [
                            {
                                dayListDes: "After breakfast start for full day sightseeing in an around Pemayangtes, in the fast half you will be covering Changay Falls Singshore Bridge (Worlds 2nd highest hanging bridge) Dentam Bazar – a beautiful Sikimease village &  Pemayangtse Monastery – the 2ns oldest monastery of Sikkim, return to hotel for lunch followed by the second half of the days sightseeing covering Rimbi Falls, The Sacred Kechodpalri Lake and  Kanchendzonga Falls. Evening free for individual activity and leisure, overnight at hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "Pemayangtse to Darjeeling",
                        dayList: [
                            {
                                dayListDes: "Morning transfer to Darjeeling, (128kms/5hrs).  Dorje, the mystic thunderbolt of the Lama-Religion is the origin of the name Darjeeling, which was first discovered in the year 1829 by Captain Lloyd. Surrounded by tea gardens Darjeeling is a land of infinite variety and charm which will cater you a mix-experience of lively nature & charming Himalayan people. This little town faces some of the highest peaks of the Himalayas. Take a rest and then a walk around town to appreciate the new culture you are in. Overnight at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 10",
                        dayTitle: "Darjeeling Sightseeing",
                        dayList: [
                            {
                                dayListDes: "Early morning a start for Tiger Hill to watch the spectacular sunrise and its amazing effects on the Mt. Kanchendzonga (Worlds 3rd highest peak), Visit Ghoom Monastery and Batashia Loop War Memorial while returning to the Hotel. Breakfast at the Hotel followed by half day local sightseeing covering Padmaja N. Zoological Garden (Closed on Thursday), Himalayan Mountaineering Institute (Closed on Thursday), Tibetian Refugee Self-help Center (Closed on Sunday) You might also Experience the famous Toy Train Joy Ride (subject to availability) This unique Mountain Railway' project was first started in 1878 by the initiative of Sir. Assly Eden and is now a World Heritage. Evening free for shopping & individual activity, overnight at hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 11",
                        dayTitle: "Darjeeling – Bagdogra",
                        dayList: [
                            {
                                dayListDes: "Morning Transfer to Bagdogra Airport  / New Jalpaiguri  Railway Station (95kms/ 3hrs).",
                            },
                            {
                                dayListDes: "Tour Ends. ",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },             
        {
            pimg: sundarban1,
            ptitle: "2N Kolkata, 1N Sundarban, 1N Jaldapara, 2N Gangtok, 2N Pemayangtse, 2N Darjeeling, 1N Tea Garden",
            pdays: "12N|13D",
            pfrom: "Kolkata",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: sundarban1
                    },
                    {
                        iimg: darjeeling3
                    },,
                    {
                        iimg: kolkata3
                    },
                    {
                        iimg: gangtok1
                    },
                    {
                        iimg: sundarban2
                    }
                ],
                ititle: "2N Kolkata, 1N Sundarban, 1N Jaldapara, 2N Gangtok, 2N Pemayangtse, 2N Darjeeling, 1N Tea Garden",
                iprice: "₹ 25000",
                idays: "12N|13D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrival at Kolkata",
                        dayList: [
                            {
                                dayListDes: "Welcome on arrival at Kolkata and transfer to Hotel, evening walk around the city to appreciate the new culture you are in, overnight at hotel. Kolkata is the city of Joy, contrast and culture. Once the capital of British India - now the capital of West Bengal. A visit to Kolkata may bring visitors in touch with a combination of a rich 400 years old heritage, vibrant modern life and different cultures. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Kolkata Sightseeing",
                        dayList: [
                            {
                                dayListDes: "Morning 8.30 AM starts the half day city tour with an introduction about the glory of Bengal, visit the Flower Market - a unique wholesale market of flowers which is situated in the bank of river Ganges near the famous Howrah Bridge. Drive around the Heritage Buildings of Dalhousie Square, Visit the Mother Teresa’s House, the headquarters of the Missionaries of Charity followed by Victoria Memorial, Saint Pauls Cathedral. Hop on to an old charming Local Tram and travel to the famous Kali Temple, experience a short Hand pulled Rixo ride and Transfer to the Hotel for Lunch. ",
                            },
                            {
                                dayListDes: "Late afternoon you will be taken to Dakshineswar Kali Temple. It is famous for its association - with Ramkrishna who achieved spiritual vision here. Rani Rashmoni built this temple in 1847. The 12- spired temple with its enormous courtyard is surrounded by 12 other temples dedicated to Lord Shiva. Experience the river cruise from Dakshineswar Temple to Belur Math across river Ganges by motorized boat and watch the glimpse of river along with the vibrant modern life and cultures of Kolkata.",
                            },
                            {
                                dayListDes: "Belur Math is the international headquarters of Ramkrishna Mission. It was founded in 1899 by Swami Vivekananda, the world famous yogi and disciple of Sri Ramakrishna Paramhansa. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Kolkata to Sundarvans and Visit the National Park",
                        dayList: [
                            {
                                dayListDes: "Morning at 7 AM transfer to Sonakhali jetty (95Kms/2Hrs) through beautiful countryside’s and rural bazaar. Arrive at Sonakhali and board on the motorised boat. After Briefing and interpretation by our local guide cum naturalist over Tea/Coffee/Welcome drink, cruise through Hogol, Gomor, Durgaduani and Gumdi rivers to the Sudhanyakhali watch tower through creeks and canals. Experience the Island Cruise and arrive at Bali Island and check in into Sunderban Jungle Camp. Evening you might enjoy the infotainment by the local villagers. Overnight at the Camp.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: " More of Sundarvans and Transfer to Kolkata",
                        dayList: [
                            {
                                dayListDes: "Morning after breakfast experience the villege walk and to be followed by adventure cruise by manually oared country boat and cruise to Dobanki to experience the Canopy Walk on the Tower, transfer back to the camp, lunch and followed by transfer to Kolkata, over night at hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Kolkata – Bagdogra – Jaldapara Wildlife Santuary",
                        dayList: [
                            {
                                dayListDes: "Departure transfer to airport to connect your flight Bagdogra, Welcome on arrival at Bagdogra and  drive to Jaldapara Wildlife Sanctuary (135Kms/03Hrs) through  a large range of Tea Gardens, Alpine Landscape, Transparent River, National Parks and wildlife sanctuaries",
                            },
                            {
                                dayListDes: "Spread across an area of 216.56 Sq. Kms, this largest wild life reserve in West Bengal is the home of Rhinoceros Unicornis (One Horned Rhino). The whole Sanctuary is bird watchers paradise and a delight for ornithologists. The other wealth of this jungle are Indian Elephant, Royal Bengal Tiger, Leopard, Gaur, Sambar Hog Deer, Barking Deer, Indian Civet, Jungle Cat, Otter, Sloth Beer, Hispid Hare etc. ",
                            },
                            {
                                dayListDes: "Check in at Forest Lodge and enjoy a fresh hot lunch. Afternoon experience a 02Hrs Jeep Safari (subject to availability) inside the sanctuary. Exclusive Tribal Dance show can be arranged in the evening on request.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Elephant Safari at Jaldapara and Transfer to Gangtok",
                        dayList: [
                            {
                                dayListDes: "Early morning transfer to Hollong to experience the most thrilling attraction of the Sanctuary, you will be taken on the back of an Elephant for a safari (Subject to availability) to the selected parts inside the jungle and a lucky visitor might see the wild animals in their natural habitat. Return to the Lodge followed by transfer to Gangtok (185kms / 5 Hrs), Gangtok is  the capital of the state of Sikkim is a beautiful and clean town promising of the best monasteries and landscapes.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "Gangtok Sightseeing",
                        dayList: [
                            {
                                dayListDes: "Morning starts the half day sightseeing in and around this capital city of Sikkim, covering Enchey Monastery, Chortan, Stupa, Institute of Tibetology (Closed on Sunday),  Institute of Handicrafts and handlooms (Closed on Sunday) and  Roomtek Monastery & Dharma Chakra Centre (24Kms/45Mins), the sect of the Kagyu order – one of the 4 major Tibetan Buddhist Sect . Evening free for leisure, overnight at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "Gangtok to Pemayangtse",
                        dayList: [
                            {
                                dayListDes: "Morning transfer to Pemayangtse (123kms/6 hrs), en route visit Ravangla - a small township in South Sikkim at the base of Menam Hill. Located at an altitude of 6,800 ft, Pemayangtse is a very small Himalayan village in West Sikkim.  Pelling is the nearest motorable point from Mt. Kanchendzonga - worlds 03rd highest peak, offers a good sunrise and all day long view of Khangchendzonga and the entire Eastern Himalayan range.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "Pemayangtse Sightseeing",
                        dayList: [
                            {
                                dayListDes: "After breakfast start for full day sightseeing in an around Pemayangtes, in the first half you will be covering Changay Falls Singshore Bridge (Worlds 2nd highest hanging bridge) Dentam Bazar – a beautiful Sikimease villege &  Pemayangtse Monastery – the 2ns oldest monastery of Sikkim, return to hotel for lunch followed by the second half of the days sightseeing covering Rimbi Falls, the Sacred Kechodpalri Lake and  Kanchendzonga Falls. overnight at hotel. evening free for individual activity and leisure, overnight at hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 10",
                        dayTitle: "Pemayangtse to Darjeeling",
                        dayList: [
                            {
                                dayListDes: "Morning transfer to Darjeeling, (128kms/5hrs).  Dorje, the mystic thunderbolt of the Lama-Religion is the origin of the name Darjeeling, which was first discovered by British in the year 1829 by Captain Lloyd. Surrounded by tea gardens Darjeeling is a land of infinite variety and charm which will cater you a mix-experience of lively nature & charming Himalayan people. This little town faces some of the highest peaks of the Himalayas.  Take a rest and then a walk around town to appreciate the new culture you are in. Overnight at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 11",
                        dayTitle: "Darjeeling Sightseeing",
                        dayList: [
                            {
                                dayListDes: "Early morning a start for Tiger Hill to watch the spectacular sunrise and its amazing effects on the Mt. Kanchendzonga (Worlds 3rd highest peak), Visit Ghoom Monastery and Batashia Loop War Memorial while returning to the Hotel. Breakfast at the Hotel followed by half day local sightseeing covering Padmaja N. Zoological Garden (Closed on Thursday), Himalayan Mountaineering Institute (Closed on Thursday), Tibetian Refugee Self-help Center (Closed on Sunday) You might also Experience the famous Toy Train Joy Ride (subject to availability) This unique Mountain Railway' project was first started in 1878 by the initiative of Sir. Assly Eden and is now a World Heritage. Evening free for shopping & individual activity, overnight at hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 12",
                        dayTitle: "Visit to Tiger Hill to Chamong Chiabari Tea Estate",
                        dayList: [
                            {
                                dayListDes: "Early morning start for Tiger Hill to watch the spectacular sunrise and its amazing effects on the Mt. Kanchendzonga (Worlds 3rd highest peak), Visit Ghoom Monastery and Batashia Loop War Memorial while returning to the Hotel. After lunch transfer to Chamong Chiabari Tea Estate (25Kms/01.5Hrs).",
                            },
                            {
                                dayListDes: "The tea country is vast parkland of man made landscape, silver-barked and slim elevated trees stand evenly spaced out over trimmed tea bushes throwing a light shade over them. The Tea story of the region dates back to 1835 through the initiative of the British Governor General, Lord Bentinck and today Darjeeling tea famous around the world for its flavor and is acknowledged as 'The Champagne of Teas'. The stunning view of the tea garden along with the mountains and the foothill forests of the Eastern Himalayas from the exclusive private bungalows is enchanting.  Enjoy a tour of the garden along with the factory and discover the charming character, rich culture and history with the friendly people of the tea estates of the region. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 13",
                        dayTitle: "Chamong Chiabari Tea Estate – Bagdogra Airport",
                        dayList: [
                            {
                                dayListDes: "Morning after an early breakfast visit the Tea Factory and the Plantation to interact and understand the life style of the tea industry. Afternoon transfer to Bagdogra Airport (106Kms/ 2hrs) to connect flight to your next destination. ",
                            },
                            {
                                dayListDes: "Tour Ends.",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        },             
        {
            pimg: sundarban2,
            ptitle: "3N Kolkata, 1N Sundarban, 1N Jaldapara, 1N Kalimpong, 2N Gangtok, 2N Pemayangtse, 2N Darjeeling, 1N Tea Garden",
            pdays: "13N|14D",
            pfrom: "Kolkata",
            pprice: "₹ 25000",
            itineary: {
                iimgs: [
                    {
                        iimg: sundarban2
                    },
                    {
                        iimg: darjeeling1
                    },
                    {
                        iimg: kolkata1
                    },
                    {
                        iimg: gangtok1
                    },
                    {
                        iimg: kalimpong2
                    }
                ],
                ititle: "3N Kolkata, 1N Sundarban, 1N Jaldapara, 1N Kalimpong, 2N Gangtok, 2N Pemayangtse, 2N Darjeeling, 1N Tea Garden",
                iprice: "₹ 25000",
                idays: "13N|14D",
                days:[
                    {
                        dayName: "DAY 1",
                        dayTitle: "Arrival at Kolkata",
                        dayList: [
                            {
                                dayListDes: "Welcome on arrival at Kolkata and transfer to Hotel, evening walk around the city to appreciate the new culture you are in, overnight at hotel. Kolkata is the city of Joy, contrast and culture. Once the capital of British India - now the capital of West Bengal. A visit to Kolkata may bring visitors in touch with a combination of a rich 400 years old heritage, vibrant modern life and different cultures. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 2",
                        dayTitle: "Kolkata Sightseeing",
                        dayList: [
                            {
                                dayListDes: "Morning 8.30 AM starts the half day city tour with an introduction about the glory of Bengal, visit the Flower Market - a unique wholesale market of flowers which is situated in the bank of river Ganges near the famous Howrah Bridge. Drive around the Heritage Buildings of Dalhousie Square, Visit the Mother Teresa’s House, the headquarters of the Missionaries of Charity followed by Victoria Memorial, Saint Pauls Cathedral. Hop on to an old charming Local Tram and travel to the famous Kali Temple, experience a short Hand pulled Rixo ride and Transfer to the Hotel for Lunch. ",
                            },
                            {
                                dayListDes: "Late afternoon you will be taken to Dakshineswar Kali Temple. It is famous for its association - with Ramkrishna who achieved spiritual vision here. Rani Rashmoni built this temple in 1847. The 12- spired temple with its enormous courtyard is surrounded by 12 other temples dedicated to Lord Shiva. Experience the river cruise from Dakshineswar Temple to Belur Math across river Ganges by motorized boat and watch the glimpse of river along with the vibrant modern life and cultures of Kolkata.",
                            },
                            {
                                dayListDes: "Belur Math is the international headquarters of Ramkrishna Mission. It was founded in 1899 by Swami Vivekananda, the world famous yogi and disciple of Sri Ramakrishna Paramhansa. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 3",
                        dayTitle: "Kolkata to Sundarvans and Visit the National Park",
                        dayList: [
                            {
                                dayListDes: "Morning at 7 AM transfer to Sonakhali jetty (95Kms/2Hrs) through beautiful countryside’s and rural bazaar. Arrive at Sonakhali and board on to the motorised boat. After Briefing and interpretation by our local guide cum naturalist over Tea/Coffee/Welcome drink, cruise through Hogol, Gomor, Durgaduani and Gumdi rivers to the Sudhanyakhali watch tower through creeks and canals. Experience the Island Cruise and arrive at Bali Island and check in into Sunderban Jungle Camp. Evening you might enjoy the infotainment by the local villagers. Overnight at the Camp.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 4",
                        dayTitle: "More of Sundarvans and Transfer to Kolkata",
                        dayList: [
                            {
                                dayListDes: "Morning after breakfast experience the villege walk and to be followed by adventure cruise on a manually oared country boat and cruise to Dobanki to experience the Canopy Walk in the Tower, transfer back to the camp, lunch and followed by transfer to Kolkata, over night at hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 5",
                        dayTitle: "Kolkata – Bagdogra – Jaldapara Wildlife Santuary",
                        dayList: [
                            {
                                dayListDes: "Departure transfer to airport to connect your flight Bagdogra, Welcome on arrival at Bagdogra and  drive to Jaldapara Wildlife Sanctuary (135Kms/03Hrs) through  a large range of Tea Gardens, Alpine Landscape, Transparent River, National Parks and wildlife sanctuaries.",
                            },
                            {
                                dayListDes: "Spread across an area of 216.56 Sq. Kms, this largest wild life reserve in West Bengal is the home of Rhinoceros Unicornis (One Horned Rhino). The whole Sanctuary is bird watchers paradise and a delight for ornithologists. The other wealth of this jungle are Indian Elephant, Royal Bengal Tiger, Leopard, Gaur, Sambar Hog Deer, Barking Deer, Indian Civet, Jungle Cat, Otter, Sloth Beer, Hispid Hare etc.",
                            },
                            {
                                dayListDes: "Check in at Forest Lodge and enjoy a fresh hot lunch. Afternoon experience a 02Hrs Jeep Safari (subject to availability) inside the sanctuary. Exclusive Tribal Dance show can be arranged in the evening on request.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 6",
                        dayTitle: "Elephant Safari at Jaldapara and Transfer Kalimpong",
                        dayList: [
                            {
                                dayListDes: "Early morning transfer to Hollong to experience the most thrilling attraction of the Sanctuary, you will be taken on the back of an Elephant for a safari (Subject to availability) to the selected parts inside the jungle and a lucky visitor might see the wild animals in their natural habitat. Return to the Lodge followed by transfer to Kalimpong (153kms / 3.5hrs), a quiet hill resort in eastern Himalaya. Kalimpong is famous for its Orchids & Nurseries and some of the some of the rarest cacti & Orchids can be seen. Oak, Maple and chestnut stretch up the ridge as far as the eye can reach. Check in at Hotel. evening visit the local market to have an in depth knowledge about the local culture and community.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 7",
                        dayTitle: "Kalimpong - Gangtok",
                        dayList: [
                            {
                                dayListDes: "Breakfast at the hotel followed half day sightseeing in and around Kalimpong covering Durpin Dhara, Zong Dog Palri Fo-Brong Monastery, and Flower & Orchid Nurseries & Kalimpong Market. Transfer to Gangtok (80kms / 3 Hrs), Gangtok is  the capital of the state of Sikkim is a beautiful and clean town promising of the best monasteries and landscapes.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 8",
                        dayTitle: "Gangtok Sightseeing",
                        dayList: [
                            {
                                dayListDes: "Morning starts the half day sightseeing in and around this capital city of Sikkim, covering Enchey Monastery, Chortan, Stupa, Institute of Tibetology (Closed on Sunday),  Institute of Handicrafts and handlooms (Closed on Sunday) and  Roomtek Monastery & Dharma Chakra Centre (24Kms/45Mins), the sect of the Kagyu order – one of the 4 major Tibetan Buddhist Sect. Evening free for leisure, overnight at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 9",
                        dayTitle: "Gangtok to Pemayangtse",
                        dayList: [
                            {
                                dayListDes: "Morning transfer to Pemayangtse (123kms/6 hrs), en route visit Ravangla - a small township in South Sikkim at the base of Menam Hill. Located at an altitude of 6,800 ft, Pemayangtse is a very small Himalayan village in West Sikkim.  Pelling is the nearest motorable point from Mt. Kanchendzonga - worlds 03rd highest peak, offers a good sunrise and all day long view of Khangchendzonga and the entire Eastern Himalayan range.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 10",
                        dayTitle: "Pemayangtse Sightseeing",
                        dayList: [
                            {
                                dayListDes: "After breakfast start for full day sightseeing in an around Pemayangtes, in the first half you will be covering Changay Falls Singshore Bridge (Worlds 2nd highest hanging bridge) Dentam Bazar – a beautiful Sikimease villege &  Pemayangtse Monastery – the 2ns oldest monastery of Sikkim, return to hotel for lunch followed by the second half of the days sightseeing covering Rimbi Falls, the Sacred Kechodpalri Lake and  Kanchendzonga Falls. overnight at hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 11",
                        dayTitle: "Pemayangtse to Darjeeling",
                        dayList: [
                            {
                                dayListDes: "Morning transfer to Darjeeling (128kms/5hrs).  Dorje, the mystic thunderbolt of the Lama-Religion is the origin of the name Darjeeling, which was first discovered by British in the year 1829 by Captain Lloyd. Surrounded by tea gardens Darjeeling is a land of infinite variety and charm which will cater you a mix-experience of lively nature & charming Himalayan people. This little town faces some of the highest peaks of the Himalayas. Evening free for individual activity and leisure,  overnight at Hotel. Take a rest and then a walk around town to appreciate the new culture you are in. Overnight at Hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 12",
                        dayTitle: "Darjeeling Sightseeing",
                        dayList: [
                            {
                                dayListDes: "Breakfast at the Hotel followed by half day local sightseeing covering Padmaja N. Zoological Garden (Closed on Thursday), Himalayan Mountaineering Institute (Closed on Thursday), Tibetian Refugee Self-help Center (Closed on Sunday) You might also Experience the famous Toy Train Joy Ride (subject to availability) This unique Mountain Railway' project was first started in 1878 by the initiative of Sir. Assly Eden and is now a World Heritage. Evening free for shopping & individual activity, overnight at hotel.",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 13",
                        dayTitle: "Visit to Tiger Hill to Chamong Chiabari Tea Estate",
                        dayList: [
                            {
                                dayListDes: "Early morning start for Tiger Hill to watch the spectacular sunrise and its amazing effects on the Mt. Kanchendzonga (Worlds 3rd highest peak), Visit Ghoom Monastery and Batashia Loop War Memorial while returning to the Hotel. After lunch transfer to Chamong Chiabari Tea Estate (25Kms/01.5Hrs).The tea country is vast parkland of man made landscape, silver-barked and slim elevated trees stand evenly spaced out over trimmed tea bushes throwing a light shade over them. The Tea story of the region dates back to 1835 through the initiative of the British Governor General, Lord Bentinck and today Darjeeling tea famous around the world for its flavor and is acknowledged as 'The Champagne of Teas'. The stunning view of the tea garden along with the mountains and the foothill forests of the Eastern Himalayas from the exclusive private bungalows is enchanting.  Enjoy a tour of the garden along with the factory and discover the charming character, rich culture and history with the friendly people of the tea estates of the region. ",
                            }
                        ]
                    },
                    {
                        dayName: "DAY 13",
                        dayTitle: "Chamong Chiabari Tea Estate – Bagdogra Airport",
                        dayList: [
                            {
                                dayListDes: "Morning after an early breakfast visit the Tea Factory and the Plantation to interact and understand the life style of the tea industry. Afternoon transfer to Bagdogra Airport (106Kms/ 2hrs)  to connect flight to your next destination.",
                            },
                            {
                                dayListDes: "Tour Ends. ",
                            }
                        ]
                    }
                ],
                inclusions: [],
                exclusions: [],

            }
        }
    ]
}